<template>
  <v-alert
    class="main-alert"
    :color="color"
    :theme="them"
    density="compact"
    :icon="icon"
  >
    {{ content }}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  props: {
    content: {
      type: String,
    },

    icon: {
      type: String,
      default: "mdi-alert-circle",
    },
  },
};
</script>