<template>
  <div>
    <div v-if="memberLoading" class="ps-5 pe-5 pt-3">
      <SkeletonOne />
    </div>
    <div v-else class="member-attendance">
      <div class="d-flex justify-space-between align-center">
        <!-- box info -->

        <!-- person -->
        <div class="box-info align-center gap-10 d-flex">
          <!-- person data -->
          <img
            loading="lazy"
            :src="
              member.member_image
                ? `https://dev.agenda.42.guru/files/` + member.member_image
                : require('@/assets/images/noProfilePic.jpg')
            "
            alt=""
            @error="handleMemberBrokenImage"
          />
          <div class="ele-name">
            {{ member.first_name + " " + member.last_name }}
          </div>
          <div class="ele-tag" v-if="member.role">{{ $t(member.role) }}</div>
        </div>
        <!-- buttons -->
        <div
          v-if="userCurrentRole != 'Member' && withBtns"
          class="box-btns align-center justify-end gap-10 d-flex"
        >
          <div v-if="member.online" class="ele-tag align-center gap-1 d-flex">
            <div class="primary-dot"></div>
            <div>{{ $t("online") }}</div>
          </div>
          <!-- {{ member.attendance }} -->

          <v-btn
            v-if="member.attendance != 'Present'"
            class="squer-rouneded-btn rdx-4 member-temb border-primary check squer-sm pa-0 ma-0"
            color="primary"
            elevation="0"
            @click="confirm(member.member_id)"
            :loading="actionLoading"
          >
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6673 4.76666L6.72354 14.7667L3.33398 11.3579"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </v-btn>
          <v-btn
            class="squer-rouneded-btn rdx-4 member-temb close squer-sm pa-0 ma-0 ms-2 bg-red"
            elevation="0"
            @click="cansel(member.member_id)"
            :loading="actionLoading"
            v-if="member.attendance != 'Absent'"
          >
            {{ membermember }}
            <svg
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8332 4.16651L4.1665 15.8332M15.8332 15.8332L4.1665 4.1665"
                stroke="#D0D5DD"
                stroke-linecap="round"
                stroke-width="1.5"
              />
            </svg>
          </v-btn>
        </div>
        <div class="ele-tag bg-primary" v-if="member.meeting_minutes_status">
          {{ $t(member.meeting_minutes_status) }}
        </div>
      </div>
      <div v-if="member.meeting_minutes_rejection_reason" class="border-light">
        <div class="text-gray">
          {{ member.meeting_minutes_rejection_reason }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonOne from "@/components/layout/SkeletonOne.vue";

export default {
  name: "MemberAttendance",
  components: {
    SkeletonOne,
  },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
    withBtns: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      actionLoading: false,
      memberLoading: false,
    };
  },
  created() {
    // console.log("member", this.member);
  },

  methods: {
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/Image-2.svg");
    },
    confirm(id) {
      this.memberLoading = true;

      setTimeout(() => {
        this.memberLoading = false;
      }, 3000);
      this.$emit("confirmAttendace", id);
    },

    cansel(id) {
      this.memberLoading = true;
      setTimeout(() => {
        this.memberLoading = false;
      }, 3000);
      this.$emit("canselAttendace", id);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;

.member-attendance {
  padding: 10px 20px;
  height: 60px;
  // &:last-child {
  //   padding: 20px 20px;
  // }
  .ele-tag {
    font-size: 10px;
    background: $light-gray;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 55px;
    height: 22px;
    padding: 0 10px;
  }

  .box-info {
    img {
      width: 36px;
      height: 36px;
      clip-path: inset(0px 0px 0px round 14px);
    }

    .ele-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.v-expansion-panel-text {
  &:last-child {
    .member-attendance {
      padding-bottom: 20px;
    }
  }

  &:first-child {
    .member-attendance {
      padding-top: 20px;
    }
  }
}

.member-temb {
  border: 1px solid $light-gray;
  border-radius: 8px;

  &.check {
    &:hover {
      background: $primary;
      border-color: $primary;

      path {
        stroke: $white;
      }
    }
  }

  &.close {
    &:hover {
      background: $red;
      border-color: $red;

      path {
        stroke: $white;
      }
    }
  }
}
</style>
