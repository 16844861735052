<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="squer-rouneded-btn squer-36 bg-red squer-sm pa-0 ma-0 ms-2"
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.39844 3.15005L12.5984 3.15005M9.79844 12.95H4.19844C3.42524 12.95 2.79844 12.3232 2.79844 11.55V3.85005C2.79844 3.46345 3.11184 3.15005 3.49844 3.15005H10.4984C10.885 3.15005 11.1984 3.46345 11.1984 3.85005V11.55C11.1984 12.3232 10.5716 12.95 9.79844 12.95ZM5.59844 3.15005H8.39844C8.78504 3.15005 9.09844 2.83665 9.09844 2.45005V1.75005C9.09844 1.36345 8.78504 1.05005 8.39844 1.05005H5.59844C5.21184 1.05005 4.89844 1.36345 4.89844 1.75005V2.45005C4.89844 2.83665 5.21184 3.15005 5.59844 3.15005Z"
            stroke="white"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="flex-10g">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="main-header">{{ $t("deleteAttach") }}</div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="mt-5 mb-10 auth-form border-light rounded-4 ms-4">
          <!-- mdi-check -->
          <v-checkbox
            class="pt-2 pb-2 squer-check-box sm-checkbox rounded-2 ps-2 pe-4"
            v-model="form.agree"
            value="true"
            color="primary"
            :label="$t('agreeTodeleteThisAttachment')"
            true-icon="mdi-check"
            hide-details
          ></v-checkbox>
          <!-- input to type reason -->
          <v-text-field
            class="border-t form-input-box"
            v-model="form.deleteReason"
            type="text"
            :placeholder="$t('deleteReason')"
            :label="$t('EnterdeleteAttachReson')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ai } from "@/plugins/axios";
export default {
  name: "deleteAttachment",
  components: {},
  props: {
    id: {
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        agree: false,
        deactivationReason: "",
      },
    };
  },
  methods: {
    openDi() {
      this.$emit("openDi");
    },
    async submitForm() {
      try {
        this.loading = true;
        await ai.delete("method/agenda.api.meeting.agenda.delete_agenda_file", {
          params: {
            file_id: this.$props.id,
          },
        });
        this.$emit("fileDeleted");
        this.loading = false;
        this.dialog = false;
        this.form.agree = "";
        this.form.deactivationReason = "";
      } catch (error) {
        this.dialog = false;
        this.loading = false;
        console.log(error);
      }
    },
  },
};
</script>
