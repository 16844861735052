<template>
  <!-- <v-radio-group class="no-details" v-model="Selectedtask"> -->
  <draggable
    class="dragArea list-group w-full"
    :list="list"
    @change="log"
    transition="fade"
    :transition-mode="{ onMove: 'bounce' }"
  >
    <div
      v-for="(task, index) in list"
      :key="index"
      class="task-checkbox align-center gap-5 d-flex mt-5 justify-space-between"
    >
      <div class="flex-10g">
        <div
          class="align-center d-flex justify-center px-2 pt-1 border-slim-light rounded"
        >
          {{ index + 1 }}
        </div>

        <div class="title-height d-flex align-center">
          {{ task.task_title }}
        </div>
        <div
          v-if="task.task_assignee"
          class="justify-center align-center text-blue-gray siz-10 tag-rounded-box pa-1 gap-5"
          v-for="assignee in task.task_assignee"
          :key="assignee.id"
        >
          <div class="flex-shrink-0">
            {{ assignee.full_name }}
          </div>
          <img
            loading="lazy"
            :src="`https://dev.agenda.42.guru/` + assignee.user_image"
            alt="userImg"
            class="sm-tag-img"
            @error="handleMemberImage"
          />
        </div>
      </div>
      <div class="d-flex gap-10">
        <div class="flex-10g">
          <div v-if="isOwner" class="align-center gap-10 hover-btns">
            <editAgendaTask :task="task" />
            <delete-dialog
              :textAndIcon="false"
              :id="task.task_id"
              :headerTitle="'deleteTask'"
              :checkboxLabel="'confirmTaskDeletion'"
              :deleteReasonText="'EnterdeleteTaskReson'"
              :deleteReasonLable="'deleteReason'"
              @delete="deleteTask(task.task_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </draggable>
  <!-- </v-radio-group> -->
</template>
<script>
import deleteDialog from "@/components/ui/dialogs/deleteDialog.vue";
import editAgendaTask from "@/components/ui/dialogs/editAgendaTask.vue";
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import { ai } from "@/plugins/axios";

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
    deleteDialog,
    editAgendaTask,
  },
  props: {
    tasks: {
      type: Object,
      default: () => [],
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Selectedtask: null,
      enabled: true,
      dragging: false,
      dragging: false,
      list: [],
    };
  },

  mounted() {
    this.list = this.tasks;
  },
  updated() {
    this.list = this.tasks;
  },
  watch: {
    tasks: {
      handler: function (val, oldVal) {
        this.list = val;
      },
      deep: true,
    },
  },

  methods: {
    log(event) {
      console.log(event);
    },
    onMoveCallback(evt, originalEvent) {
      // return false; — for cancel
    },
    // handleMemberBrokenImage(e) {
    //   e.target.src = require("@/assets/images/noProfilePic.jpg");
    // },
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    async deleteTask(taskID) {
      try {
        await ai.delete("method/agenda.api.meeting.agenda_task.delete_task", {
          params: {
            task_id: taskID,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;
.title-height {
  height: 40px;
  min-height: 40px;
}
.sortable-chosen {
  // background-color: #f5f5f5;
  // box-shadow: 0 0 0 3px #f5f5f521;
  // padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  cursor: grabbing;
}

.member-time-data {
  display: flex;
  justify-content: space-between;
  width: 44px;
  height: 22px;
  background: #f2f4f7;
  border-radius: 12px;
  font-size: 10px;
}
.sm-tag-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin-inline-end: 0;
}
.task-checkbox {
  .hover-btns {
    display: none;
  }
  &:hover {
    .hover-btns {
      display: flex;
    }
  }
}
</style>
