<template>
  <div class="flex m-10">
    <!-- {{ recommendations }} -->
    <draggable
      class="dragArea list-group w-full"
      :list="list"
      @change="log"
      transition="fade"
      :transition-mode="{ onMove: 'bounce' }"
    >
      <!-- <transition-group> -->
      <div
        v-for="(recommend, index) in list"
        :key="index"
        class="recommend-box d-flex justify-space-between align-center"
      >
        <div class="recommend-info align-center gap-10 d-flex">
          <div class="pa-0 ma-0">
            <div
              class="align-center d-flex justify-center px-2 pt-1 border-slim-light rounded"
            >
              {{ index + 1 }}
            </div>
          </div>

          <div class="recommend-name">{{ recommend.title }}</div>
        </div>

        <div class="recommend-box-control">
          <edit-meeting-recommendation
            :recommendation="recommend"
            @refreshOldData="emitRefreshOldData"
          />
          <delete-meeting-recommendation
            :recommendation="recommend"
            @refreshOldData="emitRefreshOldData"
          />
        </div>
      </div>
      <!-- </transition-group> -->
    </draggable>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import EditMeetingRecommendation from "@/components/ui/dialogs/EditMeetingRecommendation.vue";
import DeleteMeetingRecommendation from "@/components/ui/dialogs/DeleteMeetingRecommendation.vue";
export default defineComponent({
  components: {
    DeleteMeetingRecommendation,
    EditMeetingRecommendation,
    draggable: VueDraggableNext,
  },
  props: {
    recommendations: {
      type: Array,
      default: () => [],
    },
    stopEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      list: [],
    };
  },
  mounted() {
    this.list = this.recommendations;
    console.log(this.list);
  },

  watch: {
    recommendations: {
      handler: function (val) {
        this.list = val;
      },
      deep: true,
    },
  },

  updated() {
    this.list = this.recommendations;
  },

  methods: {
    log(event) {
      console.log(event);
    },
    onMoveCallback(evt, originalEvent) {
      // return false; — for cancel
    },
    emitRefreshOldData() {
      this.$emit("refreshOldData", true);
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;

.sortable-chosen {
  // background-color: #f5f5f5;
  box-shadow: 0 0 0 3px #f5f5f521;
  // padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  cursor: grabbing;
}
.recommend-box {
  min-height: 46px;
  margin-bottom: 5px;

  .recommend-box-control {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    .recommend-box-control {
      display: flex;
    }
  }
}

@media (max-width: 576px) {
  .recommend-name {
    font-size: 14px;
  }
}
</style>
