<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <FooterView />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {},
};
</script>
