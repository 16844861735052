<template>
  <v-card
    elevation="0"
    class="gap-5 main-task-box ps-10 pe-10 d-flex align-center justify-space-between dark-brown-color"
  >
    <div
      @click="toTaskDetails(taskDt.task_id, taskDt.space_id)"
      class="gap-10 align-center d-flex flex-grow-1 ps-2"
    >
      <svg
        v-if="taskDt.status != 'Completed'"
        class="mt-n1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="18"
          height="18"
          rx="9"
          stroke="#D0D5DD"
          stroke-width="2"
        />
      </svg>

      <svg
        v-if="taskDt.status == 'Completed'"
        class="mt-n1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="10" fill="#667085" />
        <path
          d="M14 7L8.03374 13L6 10.9548"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="gap-10 d-flex align-center mt-n1">
        <div class="task-title a-dark">
          {{ taskDt.task_title }}
        </div>
        <div class="task-desc">
          {{ taskDt.task_description }}
        </div>
        <div
          class="justify-center flex-grow-1 task-time align-center text-blue-gray siz-10"
          v-if="taskDt.agenda"
        >
          {{ taskDt.agenda }}
        </div>
        <div
          v-if="taskDt.sub_tasks"
          class="gap-5 sub_tasks_num d-flex align-center text-blue-gray siz-10"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6Z"
              stroke="#98A2B3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="mt-2px">
            {{ taskDt.sub_tasks }}
          </div>
          {{ $t("subTasks") }}
        </div>
      </div>
    </div>
    <div class="flex-shrink-0 gap-10 d-flex align-center">
      <div v-if="taskDt.username" class="multy-member-info pa-2px">
        <!--        <div-->
        <!--          v-if="taskDt.team && taskDt.team.length > 1"-->
        <!--          class="justify-center flex-grow-1 align-center text-blue-gray siz-10 ps-1"-->
        <!--        >-->
        <!--          {{ taskDt.team[0].name }} + {{ taskDt.team.length - 1 }}-->
        <!--          {{ $t("others") }}-->
        <!--        </div>-->

        <div
          class="justify-center flex-grow-1 align-center text-blue-gray siz-10 ps-1"
        >
          {{ taskDt.username }}
        </div>

        <div class="d-flex align-center">
          <div class="justify-center img-container d-flex align-center">
            <img
              loading="lazy"
              class="tag-img"
              :src="`https://dev.agenda.42.guru/files/` + taskDt.user_image"
              alt=""
              @error="handleMemberImage"
            />
          </div>
        </div>
      </div>
      <div
        class="justify-center ele-tag flex-grow-1 task-time align-center text-blue-gray siz-10"
      >
        {{ formatDate(taskDt.task_date) }}
      </div>
      <div v-if="controller" class="gap-10 table-ele-box-control align-center">
        <div v-if="isVisible" class="gap-10 table-ele-box-control align-center">
          <request-extension-time :task="taskDt" @refreshTasks="refreshTasks" />
          <assign-member
            row="true"
            :task="taskDt"
            :inPage="true"
            @refreshTasks="refreshTasks"
          />
        </div>
        <v-btn
          class="bg-white squer-rouneded-btn squer-40 pa-0 ma-0 hv-gray-1"
          elevation="0"
          link
          :to="`/${entity_id}/${taskDt.space_id}/tasks/edit/${taskDt.task_id}`"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.86014 2.80013C9.13338 2.52675 9.57648 2.52656 9.84995 2.79972L11.0577 4.00609C11.3313 4.27936 11.3315 4.72269 11.0581 4.99619L5.35679 10.7006C5.2593 10.7982 5.13515 10.8648 4.99995 10.892L2.45312 11.4052L2.96722 8.86134C2.99449 8.72641 3.06093 8.60252 3.15824 8.50516L8.86014 2.80013Z"
              stroke="#475467"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
        <delete-task :task_id="taskDt.task_id" @delete="deleteTask" />
      </div>
    </div>
    <!-- </div> -->
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import DeleteTask from "@/components/ui/dialogs/DeleteTask.vue";
import RequestExtensionTime from "@/components/ui/dialogs/RequestExtensionTime.vue";
import AssignMember from "@/components/ui/dialogs/AssignMember.vue";
import { ai } from "@/plugins/axios";

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
    DeleteTask,
    RequestExtensionTime,
    AssignMember,
  },
  name: "ActiveTaskBox",
  props: {
    taskDt: {
      type: Object,
    },
    controller: {
      type: Boolean,
      default: true,
    },
    taskPermission: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      Selectedtask: [],
      entity_id: this.$route.params.entity_id,
    };
  },
  mounted() {
    if (this.isVisible) {
      console.log("visible");
    } else {
      console.log("invisible");
    }
  },
  methods: {
    toTaskDetails(id, space_id) {
      this.$router.push(`/${this.entity_id}/${space_id}/tasks/${id}`);
    },
    formatDate(timestamp) {
      if (timestamp == undefined || timestamp == "") return "";
      const date = new Date(timestamp);
      const now = new Date();

      const dateFormatter = new Intl.DateTimeFormat("ar", {
        hour: "numeric",
        minute: "numeric",
      });

      if (this.isSameDay(date, now)) {
        return ` ${this.$t("today")} • ` + dateFormatter.format(date);
      } else if (this.isYesterday(date, now)) {
        return ` ${this.$t("yesterday")} • ` + dateFormatter.format(date);
      } else {
        return (
          date.toLocaleString("ar", { dateStyle: "long" }) +
          " • " +
          dateFormatter.format(date)
        );
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    },
    isYesterday(date1, date2) {
      const yesterday = new Date(date2);
      yesterday.setDate(date2.getDate() - 1);
      return this.isSameDay(date1, yesterday);
    },
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/Image-2.svg");
    },
    refreshTasks() {
      this.$emit("refreshTasks", true);
    },
    async deleteTask() {
      try {
        await ai.delete("method/agenda.api.space.task.delete_task", {
          params: {
            task_id: this.taskDt.task_id,
          },
        });
        this.$emit("refreshTasks");
      } catch (error) {
        console.log(error);
      }
    },
  },
});
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.multy-member-info {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  min-width: 83px;
  height: 22px;
  background: #f2f4f7;
  border-radius: 12px;
  font-size: 10px;

  .img-container:not(:first-child) {
    margin-inline-start: -5px;
    border: 2px solid #f2f4f7;
    border-radius: 24px;
  }

  .img-container:first-child {
    // margin-inline-start: -5px;
    border: 2px solid transparent;
    border-radius: 24px;
  }
}
.main-task-box {
  // padding: 10px 40px 10px 40px;
  min-height: 60px;
  min-width: 925px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .table-ele-box-control {
    display: none;
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    background: #f2f4f7;
    .ele-tag {
      background: #fff;
    }
    .multy-member-info {
      background: #fff;
      .img-container:not(:first-child) {
        border: 2px solid #fff;
      }
    }
    .table-ele-box-control {
      display: flex;
    }
  }

  &:nth-child(odd) {
    background: #f9fafb;
    &:hover {
      .box-info {
        .ele-tag {
          background: #fff;
        }
      }
    }
  }

  .v-selection-control .v-label {
    color: #0a0a0a !important;
    font-weight: bold !important;
  }

  .task-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: $label-gray;
  }

  .task-time {
    display: flex;
    justify-content: space-between;
    algin-items: center;
    min-width: 44px;
    height: 22px;
    background: #f2f4f7;
    border-radius: 12px;
    padding: 0 12px;
    font-size: 10px;
  }
}
</style>
