<template>
  <div class="confirm-attendance-btn">
    <v-btn
      v-if="type === 'anAttend'"
      elevation="0"
      @click="setMemberStatus"
      :loading="loading"
      class="py-2 px-3 d-flex align-center gap-10 border-slim-light green-text rounded"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3346 3.81323L5.37962 11.8132L2.66797 9.08625"
          stroke="#12B76A"
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span>{{ $t("anAttend") }}</span>
    </v-btn>
    <v-btn
      v-if="type === 'anAbsent'"
      @click="setMemberStatus"
      class="py-2 px-3 d-flex align-center gap-10 border-slim-light red-text rounded"
      elevation="0"
      :loading="loading"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6654 3.33325L3.33203 12.6666M12.6654 12.6666L3.33203 3.33325"
          stroke="#E0316B"
          stroke-width="1.25"
          stroke-linecap="round"
        />
      </svg>
      <span>{{ $t("anAbsent") }}</span>
    </v-btn>
    <v-btn
      v-if="type === 'anApologized'"
      @click="setMemberStatus"
      class="py-2 px-3 d-flex align-center gap-10 border-slim-light red-text rounded"
      elevation="0"
      :loading="loading"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.9">
          <path
            d="M5.59844 10.4001L10.3984 5.6001M7.99844 14.4001C4.46381 14.4001 1.59843 11.5347 1.59844 8.0001C1.59844 4.46547 4.46381 1.6001 7.99844 1.6001C11.5331 1.6001 14.3984 4.46548 14.3984 8.0001C14.3984 11.5347 11.5331 14.4001 7.99844 14.4001Z"
            stroke="#E0316B"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </g>
      </svg>

      <span>{{ $t("anApologized") }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "confirmAttendanceBtn",
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    setMemberStatus() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit("setMemberStatus");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-attendance-btn {
  .red-text {
    color: #e0316b;
  }
  .green-text {
    color: #12b76a;
  }
}
</style>
