<template>
  <div class="contact-box d-flex justify-space-between align-center">
    <!-- link avater -->
    <div class="link-info align-center gap-10 d-flex">
      <v-btn
        class="link-icone-btn squer-rouneded-btn border-rd-8 bg-light-gray pa-0 ma-0"
        elevation="0"
      >
        <img
          loading="lazy"
          :src="require(`@/assets/images/contact_icone/${img}`)"
          alt=""
        />
      </v-btn>
      <br />
      <!-- <v-text-field
        v-model="addLink"
        type="text"
        :label="$t('')"
        outlined
        solo
        dense
        variant="solo"
        hide-details="auto"
        persistent-placeholder
      ></v-text-field> -->

      <input
        v-if="input"
        type="text"
        class="min-w-300"
        :placeholder="$t('enter_link')"
        @input="$emit('input', $event.target.value)"
      />
      <div v-else class=" ">
        <div class="link-name">{{ label }}</div>

        <a
          :href="
            hrefType == 'href'
              ? link
              : hrefType == 'tel'
              ? 'tel:' + link
              : hrefType == 'mail'
              ? 'mailto:' + link
              : link
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ link }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactBox",
  props: {
    hrefType: {
      type: String,
      default: "href",
    },
    input: {
      default: false,
    },

    img: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    link: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      // undefinedImage: require('@/ass""ets/images/undefined.svg')
      addLink: "",
    };
  },
  mounted() {
    this.addLink = this.link;
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;
.contact-box {
  a {
    color: $dark;
    transition: all 0.3s ease;
    &:hover {
      color: $primary;
    }
  }
  padding: 20px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $light;
  }

  .link-info {
    img {
      width: 20px;
      height: 20px;
      // border-radius: 50%;
    }
    .link-name {
      font-size: 12px;
      color: $label-gray;
    }
  }
}
</style>
