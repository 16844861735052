<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        class="bg-light-gray squer-rouneded-btn squer-40 pa-0 ma-0 hv-gray-1"
        elevation="0"
        v-bind="props"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.86014 2.80013C9.13338 2.52675 9.57648 2.52656 9.84995 2.79972L11.0577 4.00609C11.3313 4.27936 11.3315 4.72269 11.0581 4.99619L5.35679 10.7006C5.2593 10.7982 5.13515 10.8648 4.99995 10.892L2.45312 11.4052L2.96722 8.86134C2.99449 8.72641 3.06093 8.60252 3.15824 8.50516L8.86014 2.80013Z"
            stroke="#475467"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("editAgendaTask") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="my-10 border auth-form rounded-4">
          <div class="form-inputs-container slim-form">
            <v-text-field
              class="form-input-box"
              v-model="form.title"
              type="text"
              :placeholder="$t('taskHead')"
              :label="$t('enterTaskHead')"
              outlined
              solo
              dense
              variant="solo"
              hide-details="auto"
              persistent-placeholder
            ></v-text-field>
            <v-text-field
              class="form-input-box"
              v-model="form.description"
              type="text"
              :placeholder="$t('taskDecs')"
              :label="$t('enterTaskDecs')"
              outlined
              solo
              dense
              variant="solo"
              hide-details="auto"
              persistent-placeholder
            ></v-text-field>

            <div class="position-relative">
              <meeting-task-assginee
                @memberSelected="handleMemberSelected"
                :selectedItems="task.task_assignee"
              />
            </div>
            <!-- start icone fild -->
            <div
              class="gap-10 form-input-box d-flex align-center date-container border-t"
            >
              <date-picker @selectedDate="setDate" class="10" />
              <v-btn
                class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                evaluation="0"
                flat
              >
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.875 7.57139H17.625M3.91071 1V2.71449M15.375 1V2.71428M18.75 5.71428L18.75 18C18.75 19.6569 17.4069 21 15.75 21H3.75C2.09315 21 0.75 19.6568 0.75 18V5.71428C0.75 4.05742 2.09315 2.71428 3.75 2.71428H15.75C17.4069 2.71428 18.75 4.05742 18.75 5.71428Z"
                    stroke="#667085"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
              <v-text-field
                v-model="form.date"
                type="text"
                placeholder="الأحد، 1 يناير 2022"
                :label="$t('date')"
                outlined
                solo
                dense
                variant="solo"
                hide-details="auto"
                persistent-placeholder
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import DatePicker from "@/components/shared/DatePicker.vue";
import meetingTaskAssginee from "@/components/ui/meetingAgenda/meetingTaskAssginee";

import { ai } from "@/plugins/axios";
export default {
  name: "editAgnedaTask",

  components: {
    DatePicker,
    meetingTaskAssginee,
  },

  props: {
    task: {
      type: Object,
    },
  },
  data() {
    return {
      // selectedMembers: [],
      dialog: false,
      loading: false,

      form: {
        title: "",
        description: "",
        task_assignee: [
          {
            member: "facility_coordinator@agenda.com",
          },
        ],
        date: "",
      },
    };
  },
  updated() {
    this.form = {
      title: this.task.task_title,
      description: this.task.task_description,
      date: this.formatDate(this.task.task_date),
    };
    this.handleMemberSelected(this.task.task_assignee);
  },
  methods: {
    async submitForm() {
      if (this.form.title) {
        console.log("clicked");
        this.loading = true;
        try {
          const request = {
            task_title: this.form.title,
            task_description: this.form.description,
            task_assignee: this.form.task_assignee,
            task_date: this.form.date,
            task_id: this.task.task_id,
          };
          await ai.put(
            `method/agenda.api.meeting.agenda_task.update_task`,
            request
          );
          this.dialog = false;
          this.loading = false;
        } catch (err) {
          this.errorMessage = err.response?.data.message.detail;
          console.log(err);
          this.loading = false;
        }
      }
    },
    setDate(date) {
      this.form.date = date;
    },
    formatDate(date) {
      return date.split(" ")[0];
    },

    handleMemberSelected(selectedValue) {
      let selectedMembers = selectedValue.map((member) => ({
        member: member.member_id || member.member,
      }));
      this.form.task_assignee = selectedMembers;
    },
  },
};
</script>
