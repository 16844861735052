<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0"
        elevation="0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.39844 21.6L2.39884 17.9996C2.39907 16.0116 4.01077 14.4 5.99884 14.4H11.9984M17.5689 18.3L14.9974 15.9M14.9974 15.9L17.5689 13.5M14.9974 15.9H21.5974M14.3984 6.00002C14.3984 7.98825 12.7867 9.60002 10.7984 9.60002C8.81021 9.60002 7.19844 7.98825 7.19844 6.00002C7.19844 4.0118 8.81021 2.40002 10.7984 2.40002C12.7867 2.40002 14.3984 4.0118 14.3984 6.00002Z"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              @click="dialog = false"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("representativeMember") }}
            </div>
          </div>
          <v-btn
            color="primary"
            class="new-plus ios-r"
            elevation="0"
            @click="submitForm"
            :loading="loading"
          >
            <div class="siz-14 me-2">
              {{ $t("save") }}
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </inner-header>
      <div
        class="popup-content popup-padding-70 fixed-h-popup dark-brown-color"
      >
        <v-form ref="form" class="auth-form py-5">
          <v-expansion-panels
            multiple
            class="expansion-circle"
            elevation="0"
            v-model="panel"
          >
            <v-expansion-panel elevation="0" class="dark-brown-color">
              <v-expansion-panel-title
                hide-actions
                class="d-flex gap-20 justify-start ps-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("alternateMember") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mb-10">
                <v-text-field
                  class="form-input-box border-light rounded-8 bg-transparent"
                  v-model="form.assigned_member"
                  type="text"
                  :placeholder="$t('alternateMember')"
                  :label="$t('alternateMember')"
                  outlined
                  solo
                  dense
                  variant="solo"
                  hide-details="auto"
                  persistent-placeholder
                  :rules="rules"
                ></v-text-field>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" class="dark-brown-color">
              <v-expansion-panel-title
                hide-actions
                class="d-flex gap-20 justify-start ps-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("delegationDate") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mb-8">
                <v-row class="ma-0 border-light rounded-8">
                  <v-col class="pt-0 pb-0" md="6">
                    <div class="date-container form-input-box">
                      <date-picker @selectedDate="setStartDate" class="10" />

                      <div class="gap-10 d-flex align-center">
                        <v-btn
                          class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                          evaluation="0"
                          flat
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.875 8.57139H19.625M5.91071 2V3.71449M17.375 2V3.71428M20.75 6.71428L20.75 19C20.75 20.6569 19.4069 22 17.75 22H5.75C4.09315 22 2.75 20.6568 2.75 19V6.71428C2.75 5.05742 4.09315 3.71428 5.75 3.71428H17.75C19.4069 3.71428 20.75 5.05742 20.75 6.71428Z"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-btn>

                        <v-text-field
                          v-model="form.start_date"
                          type="text"
                          :placeholder="$t('delegationStartDate')"
                          :label="$t('delegationStartDate')"
                          outlined
                          solo
                          dense
                          variant="solo"
                          hide-details="auto"
                          persistent-placeholder
                          :rules="rules"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>

                  <v-col class="pt-0 pb-0 border-s" md="6">
                    <div class="date-container form-input-box">
                      <date-picker @selectedDate="setEndDate" class="10" />

                      <div class="gap-10 d-flex align-center">
                        <v-btn
                          class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                          evaluation="0"
                          flat
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.875 8.57139H19.625M5.91071 2V3.71449M17.375 2V3.71428M20.75 6.71428L20.75 19C20.75 20.6569 19.4069 22 17.75 22H5.75C4.09315 22 2.75 20.6568 2.75 19V6.71428C2.75 5.05742 4.09315 3.71428 5.75 3.71428H17.75C19.4069 3.71428 20.75 5.05742 20.75 6.71428Z"
                              stroke="#667085"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </v-btn>

                        <v-text-field
                          v-model="form.end_date"
                          type="text"
                          :placeholder="$t('delegationEndDate')"
                          :label="$t('delegationEndDate')"
                          outlined
                          solo
                          dense
                          variant="solo"
                          hide-details="auto"
                          persistent-placeholder
                          :rules="rules"
                        ></v-text-field>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" class="dark-brown-color">
              <v-expansion-panel-title
                hide-actions
                class="d-flex gap-20 justify-start ps-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("delegationDetails") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <!-- select -->
                <div class="border-light rounded-8 mt-6">
                  <div class="form-input-box position-relative">
                    <label class="form-label" for="">{{
                      $t("delegationReason")
                    }}</label>

                    <Select2
                      class="select-form w-100"
                      v-model="form.reason"
                      :options="delegationReasons"
                      :settings="{ settingOption: value, settingOption: value }"
                      @change="myChangeEvent($event)"
                      @select="mySelectEvent($event)"
                      :placeholder="$t('delegationReason')"
                      :searchable="false"
                      :rules="rules"
                    />

                    <div class="select-arrow-down">
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.20313 1.40001L4.00313 4.60001L0.803126 1.40001"
                          stroke="#0A0A0A"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <v-text-field
                    class="form-input-box border-0 rounded-0 bg-transparent"
                    v-model="form.notice"
                    type="text"
                    :placeholder="$t('delegationNote')"
                    :label="$t('note_optional')"
                    outlined
                    solo
                    dense
                    variant="solo"
                    hide-details="auto"
                    persistent-placeholder
                    :rules="rules"
                  ></v-text-field>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import Select2 from "vue3-select2-component";
import InnerHeader from "@/components/shared/InnerHeader";
import DatePicker from "@/components/shared/DatePicker";
import { ai } from "@/plugins/axios";

export default {
  name: "representativeMember",

  components: {
    Select2,
    InnerHeader,
    DatePicker,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      panel: [0, 1],
      form: {
        nameOFRepresentativeMember: "",
        assigned_member: "",
        start_date: "",
        end_date: "",
        reason: "",
        notice: "",
      },
      delegationReasons: ["Emergency", "Vacation"],

      rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
    };
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },

    setStartDate(date) {
      this.form.start_date = date;
    },
    setEndDate(date) {
      this.form.end_date = date;
    },
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;

        try {
          const request = this.constructRequest();
          await ai.post(
            `method/agenda.api.space.delegation.create_delegation`,
            request
          );
          this.loading = false;
          this.$router.push(`/${this.entity_id}/${this.space_id}/delegations`);

          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log(err);
        }
      }
    },
    constructRequest() {
      return {
        space_id: this.space_id,
        assigned_member: this.form.assigned_member,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        reason: this.form.reason,
        notice: this.form.notice,
      };
    },
  },
};
</script>
