<template>
  <div class="company-card-skeleton dark-brown-color">
    <div
      class="skeleton-image-container d-flex justify-center align-center border-b"
    >
      <div class="skeleton-image"></div>
    </div>
    <div
      class="skeleton-text-container d-flex align-center justify-center px-8"
    >
      <div class="skeleton-text rounded-lg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyCardSkeleton",
};
</script>
