<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="squer-rouneded-btn squer-40 pa-0 ma-0 bg-red"
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.60156 3.60001L14.4016 3.60001M11.2016 14.8H4.80156C3.91791 14.8 3.20156 14.0837 3.20156 13.2V4.40001C3.20156 3.95818 3.55973 3.60001 4.00156 3.60001H12.0016C12.4434 3.60001 12.8016 3.95818 12.8016 4.40001V13.2C12.8016 14.0837 12.0852 14.8 11.2016 14.8ZM6.40156 3.60001H9.60156C10.0434 3.60001 10.4016 3.24184 10.4016 2.80001V2.00001C10.4016 1.55818 10.0434 1.20001 9.60156 1.20001H6.40156C5.95974 1.20001 5.60156 1.55818 5.60156 2.00001V2.80001C5.60156 3.24184 5.95974 3.60001 6.40156 3.60001Z"
            stroke="white"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("deleteTask") }}
          </div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 mt-5 mb-10 ms-4">
          <!-- mdi-check -->
          <v-checkbox
            class="squer-check-box sm-checkbox rounded-2 ps-2 pe-4 pt-3 pb-3"
            v-model="form.agree"
            value="true"
            color="primary"
            :label="$t('confirmTaskDeletion')"
            true-icon="mdi-check"
            hide-details
          ></v-checkbox>
          <!-- input to type reason -->
          <v-text-field
            class="form-input-box border-t"
            v-model="form.deleteReason"
            type="text"
            :placeholder="$t('EnterdeleteTaskReson')"
            :label="$t('deleteReason')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DeleteTask",
  components: {},
  props: {
    task_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        agree: false,
        deleteReason: "",
      },
    };
  },
  methods: {
    openDi() {
      this.$emit("openDi");
    },
    async submitForm() {
      if (this.form.agree) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("delete", this.$props.id);
        }, 500);
      }
    },
  },
};
</script>
