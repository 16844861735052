<template v-slot:activator="{ props }">
  <v-btn
    class="squer-rouneded-btn squer-sm bg-white pa-0 ma-0 border-light ms-2"
    elevation="0"
    @click="submitForm"
    :loading="loading"
  >
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8327 4.16663L4.16602 15.8333M15.8327 15.8333L4.16602 4.16663"
        stroke="#475467"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  </v-btn>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "deleteMeetingRecommendation",
  components: {},
  props: {
    recommendation: {
      default: null,
    },
  },
  mounted() {},

  data() {
    return {
      errorMessage: null,
      loading: false,
    };
  },

  methods: {
    async submitForm() {
      this.loading = true;
      if (this.recommendation) {
        try {
          await ai.delete(
            `method/agenda.api.meeting.recommendation.delete_recommendation`,
            {
              data: {
                recommendation_id: this.recommendation.recommendation_id,
              },
            }
          );
          this.$emit("refreshOldData", true);
          setTimeout(() => {
            this.loading = false;
          }, 100);
        } catch (err) {
          this.errorMessage = err.response?.data.message.detail;
          console.log(err);
          this.loading = false;
        }
      }
    },
  },
};
</script>
