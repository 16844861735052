<template>
  <div class="content">
    <!-- from board app  -->
    <!-- menue -->
    <div class="app-dropdown-list">
      <transition name="slide-y-transition">
        <div v-if="isDropped" class="list">
          <!-- {{ spaceModules }} -->
          <div class="moduels-container mb-0 py-0 px-8 border-t-dark-gray">
            <div class="board-module-apps d-flex align-start gap-20">
              <!-- module app list -->
              <div
                v-if="spaceModules.includes('Basic')"
                class="app d-flex justify-center gap-5 flex-column align-center"
              >
                <div
                  class="justify-center app-img main-apps d-flex align-center pa-5"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.9548 3.19995C27.5257 3.19995 28.7992 4.45601 28.7992 6.00543L28.7992 10.7945C28.7992 12.344 27.5257 13.6 25.9548 13.6H21.6881C20.1172 13.6 18.8437 12.3439 18.8437 10.7945L18.8437 6.00543C18.8437 4.45601 20.1172 3.19995 21.6881 3.19995L25.9548 3.19995Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.04366 3.19995C4.47272 3.19995 3.19922 4.45601 3.19922 6.00543L3.19923 10.7945C3.19923 12.344 4.47273 13.6 6.04367 13.6H10.3103C11.8813 13.6 13.1548 12.3439 13.1548 10.7945L13.1548 6.00543C13.1548 4.45601 11.8813 3.19995 10.3103 3.19995L6.04366 3.19995Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M25.9548 18.4C27.5257 18.4 28.7992 19.6561 28.7992 21.2055V25.9945C28.7992 27.544 27.5257 28.8 25.9548 28.8H21.6881C20.1172 28.8 18.8437 27.5439 18.8437 25.9945L18.8437 21.2055C18.8437 19.6561 20.1172 18.4 21.6881 18.4H25.9548Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.04367 18.4C4.47273 18.4 3.19923 19.6561 3.19923 21.2055L3.19923 25.9945C3.19923 27.544 4.47273 28.8 6.04368 28.8H10.3103C11.8813 28.8 13.1548 27.5439 13.1548 25.9945L13.1548 21.2055C13.1548 19.6561 11.8813 18.4 10.3103 18.4H6.04367Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="app-text">
                  <p class="dark-brown-color">{{ $t("mainApps") }}</p>
                </div>
                <div class="module-list-apps mt-5 d-flex gap-10 flex-column">
                  <div
                    v-for="(app, index) in basicApps"
                    :key="index"
                    class="module-list-app d-flex align-center"
                  >
                    <router-link
                      :to="app.link"
                      :class="app.color"
                      class="flex-10g"
                    >
                      <v-btn
                        class="module-list-btn app-btn rounded-lg mt-0"
                        elevation="0"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-html="app.svgPath"
                        ></svg>
                      </v-btn>
                      <div class="btn-text">
                        <div class="text-center app-name siz-12">
                          {{ $t(app.text) }}
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- Governance  -->
              <div
                class="app d-flex justify-center gap-5 flex-column align-center"
                v-if="spaceModules.includes('Governance')"
              >
                <div
                  class="justify-center app-img governance-apps d-flex align-center pa-5"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9995 11.9996H12.1611M24.0007 28.8L15.077 20.0031C13.3501 20.6723 11.2869 20.4095 9.37017 19.8488C4.80545 18.5135 2.20313 13.7831 3.55773 9.28327C4.91233 4.78342 9.71089 2.21808 14.2756 3.55343C18.8403 4.88878 21.4427 9.61914 20.0881 14.119L19.8755 15.2728L28.7992 24.0696V28.8H24.0007Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="app-text">
                  <p class="dark-brown-color">{{ $t("governaceApps") }}</p>
                </div>
                <div class="module-list-apps mt-5 d-flex gap-10 flex-column">
                  <v-row>
                    <v-col
                      v-for="(app, index) in governaceApps"
                      :key="index"
                      class="module-list-app d-flex align-center"
                    >
                      <router-link
                        :to="app.link"
                        :class="app.color"
                        class="flex-10g"
                      >
                        <v-btn
                          class="module-list-btn app-btn rounded-lg mt-0"
                          elevation="0"
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            v-html="app.svgPath"
                          ></svg>
                        </v-btn>
                        <div class="btn-text">
                          <div class="text-center app-name siz-12">
                            {{ $t(app.text) }}
                          </div>
                        </div>
                      </router-link>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- generalAssembly -->
              <div
                v-if="spaceModules.includes('General Assembly')"
                class="app d-flex justify-center gap-5 flex-column align-center"
              >
                <div
                  class="justify-center app-img general-assembly-apps d-flex align-center pa-5"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3664 27.4277L21.3668 23.1426C21.367 20.7757 19.4483 18.8568 17.0814 18.8568H7.48511C5.11853 18.8568 3.19997 20.7752 3.1997 23.1418L3.19922 27.4277M28.7988 27.4279L28.7992 23.1429C28.7994 20.7759 26.8807 18.8571 24.5138 18.8571M20.541 5.41402C21.5934 6.19487 22.2754 7.44668 22.2754 8.85778C22.2754 10.2689 21.5934 11.5207 20.541 12.3016M16.6576 8.85755C16.6576 11.2243 14.739 13.143 12.3722 13.143C10.0054 13.143 8.0868 11.2243 8.0868 8.85755C8.0868 6.49079 10.0054 4.57214 12.3722 4.57214C14.739 4.57214 16.6576 6.49079 16.6576 8.85755Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="app-text">
                  <p class="dark-brown-color">
                    {{ $t("generalAssemblyApps") }}
                  </p>
                </div>
                <div class="module-list-apps mt-5 d-flex gap-10 flex-column">
                  <div
                    v-for="(app, index) in generalAssemblyApps"
                    :key="index"
                    class="module-list-app d-flex align-center"
                  >
                    <router-link
                      :to="app.link"
                      :class="app.color"
                      class="flex-10g"
                    >
                      <v-btn
                        class="module-list-btn app-btn rounded-lg mt-0"
                        elevation="0"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-html="app.svgPath"
                        ></svg>
                      </v-btn>
                      <div class="btn-text">
                        <div class="text-center app-name siz-12">
                          {{ $t(app.text) }}
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>

              <!-- strategy -->
              <div
                class="app d-flex justify-center gap-5 flex-column align-center"
                v-if="spaceModules.includes('strategy')"
              >
                <div
                  class="justify-center app-img strategy-apps d-flex align-center pa-5"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9995 11.9996H12.1611M24.0007 28.8L15.077 20.0031C13.3501 20.6723 11.2869 20.4095 9.37017 19.8488C4.80545 18.5135 2.20313 13.7831 3.55773 9.28327C4.91233 4.78342 9.71089 2.21808 14.2756 3.55343C18.8403 4.88878 21.4427 9.61914 20.0881 14.119L19.8755 15.2728L28.7992 24.0696V28.8H24.0007Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="app-text">
                  <p class="dark-brown-color">{{ $t("strategyApps") }}</p>
                </div>
                <div class="module-list-apps mt-5 d-flex gap-10 flex-column">
                  <div
                    v-for="(app, index) in startegyApps"
                    :key="index"
                    class="module-list-app d-flex align-center"
                  >
                    <router-link
                      :to="app.link"
                      :class="app.color"
                      class="flex-10g"
                    >
                      <v-btn
                        class="module-list-btn app-btn rounded-lg mt-0"
                        elevation="0"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-html="app.svgPath"
                        ></svg>
                      </v-btn>
                      <div class="btn-text">
                        <div class="text-center app-name siz-12">
                          {{ $t(app.text) }}
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- tools -->
              <div
                class="app d-flex justify-center gap-5 flex-column align-center"
              >
                <div
                  class="justify-center app-img strategy-apps d-flex align-center pa-5"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9995 11.9996H12.1611M24.0007 28.8L15.077 20.0031C13.3501 20.6723 11.2869 20.4095 9.37017 19.8488C4.80545 18.5135 2.20313 13.7831 3.55773 9.28327C4.91233 4.78342 9.71089 2.21808 14.2756 3.55343C18.8403 4.88878 21.4427 9.61914 20.0881 14.119L19.8755 15.2728L28.7992 24.0696V28.8H24.0007Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="app-text">
                  <p class="dark-brown-color">{{ $t("toolsApps") }}</p>
                </div>
                <div class="module-list-apps mt-5 d-flex gap-10 flex-column">
                  <div
                    v-for="(app, index) in toolsApps"
                    :key="index"
                    class="module-list-app d-flex align-center"
                  >
                    <router-link
                      :to="app.link"
                      :class="app.color"
                      class="flex-10g"
                    >
                      <v-btn
                        class="module-list-btn app-btn rounded-lg mt-0"
                        elevation="0"
                      >
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          v-html="app.svgPath"
                        ></svg>
                      </v-btn>
                      <div class="btn-text">
                        <div class="text-center app-name siz-12">
                          {{ $t(app.text) }}
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- under apps
    <transition name="slide-y-transition">
      <after-space-apps
        @showUnderApps="showUnderApps"
        v-if="(!isDroppedDelayed && spaceDt && onlySpacePage) || subSpaceDt"
        :allAppFour="allAppFour"
      />
    </transition> -->
    <div>
      <form @submit.prevent="uploadImage">
        <input type="file" ref="fileInput" @change="handleFileChange" />
        <button type="submit">Upload</button>
      </form>
    </div>
    <h1>Bubble hover effect</h1>

    <div class="justify-center d-flex">
      <!-- <Dropdown
        v-model="selectedCity"
        :options="cities"
        optionLabel="name"
        placeholder="إختر مدينة"
        class="d-flex align-center w-25 pa-2 custom-dropdown"
      /> -->
    </div>

    <div class="button_container">
      <div class="button_su" v-for="(button, index) in buttons" :key="index">
        <span class="su_button_circle" ref="circle"></span>
        <a
          href="#"
          class="button_su_inner"
          @mouseenter="explodeCircle(index, $event)"
          @mouseleave="desplodeCircle(index, $event)"
        >
          <span class="button_text_container">{{ button.text }}</span>
        </a>
      </div>
    </div>
    <p class="credit"></p>

    <!-- preview -->
    <div
      class="justify-center mb-5 overflow-hidden rounded-lg preview d-flex align-center"
    >
      <img loading="lazy" :src="slides[selectedImg].url" alt="" />
    </div>

    <!-- thumbnails -->
    <div class="thumbnails-conteiner">
      <div
        class="justify-center arrow-container arrow-one-container d-flex align-center"
      >
        <div class="arrow" @click="prevSlide">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.59844 7.19995L14.3984 12L9.59844 16.8"
              stroke="#0A0A0A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <Carousel
        id="thumbnails"
        :items-to-show="3"
        :wrap-around="true"
        v-model="currentSlide"
        ref="carousel"
        class="thumbnails"
        :center="false"
        :center-padding="0"
      >
        <Slide @click="selectImg" v-for="(slide, index) in slides" :key="index">
          <div class="carousel__item" @click="slideTo(index)">
            <img loading="lazy" :src="slide.url" alt="" />
          </div>
        </Slide>
      </Carousel>
      <div
        class="justify-center arrow-container arrow-two-container d-flex align-center"
      >
        <div class="arrow" @click="nextSlide">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4016 7.19995L9.60156 12L14.4016 16.8"
              stroke="#0A0A0A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Dropdown from "primevue/dropdown";
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentSlide: 0,
      selectedImg: 0,
      selectedFile: null,
      buttons: [
        { text: "Simple button" },
        { text: "Another button" },
        { text: "A third button" },
        { text: "A fourth button" },
      ],
      selectedCity: null,
      selected: "",
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],
      active: {
        url: "https://dev.agenda.42.guru//files/images8ba21c.jpeg",
      },
      slides: [
        {
          url: "https://dev.agenda.42.guru//files/images8ba21c.jpeg",
          avatarUrl: "https://dev.agenda.42.guru//files/images8ba21c.jpeg",
          name: "Slide 1",
        },
        {
          url: "https://dev.agenda.42.guru//files/imagesd3bde8.jpeg",
          avatarUrl: "https://dev.agenda.42.guru//files/imagesd3bde8.jpeg",
          name: "Slide 2",
        },
        {
          url: "https://dev.agenda.42.guru//files/img5.jpg",
          avatarUrl: "https://dev.agenda.42.guru//files/img5.jpg",
          name: "Slide 3",
        },
        {
          url: "https://dev.agenda.42.guru//files/images96ff76.jpeg",
          avatarUrl: "https://dev.agenda.42.guru//files/images96ff76.jpeg",
          name: "Slide 4",
        },
        {
          url: "https://dev.agenda.42.guru//files/images1e34c0.jpeg",
          avatarUrl: "https://dev.agenda.42.guru//files/images1e34c0.jpeg",
          name: "Slide 5",
        },
        {
          url: "https://dev.agenda.42.guru//files/imagese6ca84.jpeg",
          avatarUrl: "https://dev.agenda.42.guru//files/imagese6ca84.jpeg",
          name: "Slide 6",
        },
      ],
    };
  },
  methods: {
    selectImg() {
      this.selectedImg = this.currentSlide;
    },
    slideTo(val) {
      this.currentSlide = val;
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    nextSlide() {
      if (this.currentSlide < this.slides.length - 1) {
        this.currentSlide++;
      } else {
        this.currentSlide = 0;
      }
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        console.error("No file selected");
        return;
      }

      try {
        const formData = new FormData();
        formData.append("image", this.selectedFile);

        // Use Axios to send the formData to your server
        // await axios.post("/upload-endpoint", formData);

        // console.log("Image uploaded successfully!");
        console.log(formData);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },

    explodeCircle(index, event) {
      const circle = this.$refs.circle[index];
      const relX = event.pageX - event.target.getBoundingClientRect().left;
      const relY = event.pageY - event.target.getBoundingClientRect().top;

      circle.style.left = relX + "px";
      circle.style.top = relY + "px";
      circle.classList.remove("desplode-circle");
      circle.classList.add("explode-circle");
    },
    desplodeCircle(index, event) {
      const circle = this.$refs.circle[index];
      const relX = event.pageX - event.target.getBoundingClientRect().left;
      const relY = event.pageY - event.target.getBoundingClientRect().top;

      circle.style.left = relX + "px";
      circle.style.top = relY + "px";
      circle.classList.remove("explode-circle");
      circle.classList.add("desplode-circle");
    },
  },
};
</script>

<style scoped lang="scss">
body {
  font-family: "Inconsolata", sans-serif;
  background: #2a364f;
}

h1 {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.credit {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}

.content {
  padding-top: 45px;
  padding-bottom: 20px;
}

.button_container {
  width: 176px;
  margin: 0 auto;
  margin-top: 30px;
  padding-top: 40px;
}

.button_su {
  overflow: hidden;
  position: relative;
  display: inline-block;
  border-radius: 3px;
  margin-bottom: 30px;
}

.su_button_circle {
  background-color: red;
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
}

.button_su_inner {
  display: inline-block;
  background: #f8b627;
  color: #f4f4f4;
  font-size: 16px;
  font-weight: normal;
  width: 132px;
  text-align: center;
  border-radius: 3px;
  transition: 400ms;
  text-decoration: none;
  padding: 22px;
  z-index: 100000;
}

.button_text_container {
  position: relative;
  z-index: 10000;
}

.explode-circle {
  animation: explode 0.5s forwards;
}

.desplode-circle {
  animation: desplode 0.5s forwards;
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(42, 53, 80, 0.2);
  }
  100% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: rgba(20, 180, 87, 0.8);
  }
}

@keyframes desplode {
  0% {
    width: 400px;
    height: 400px;
    margin-left: -200px;
    margin-top: -200px;
    background-color: rgba(20, 180, 87, 0.8);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(129, 80, 108, 0.6);
  }
}

::v-deep .p-dropdown:hover {
  border-color: #d1d5db;
}
::v-deep .p-dropdown.p-focus {
  box-shadow: none;
  border-color: #d1d5db;
}
::v-deep .p-dropdown-trigger {
  background-color: #f2f4f7;
  padding: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
}
.custom-dropdown .p-dropdown-item {
  background-color: #f2f2f2;
  color: #333;
}

.custom-dropdown .p-dropdown-item:hover {
  background-color: #ddd;
  color: #333;
}
.preview {
  position: relative;
  width: 830px;
  height: 340px;
  transition: all ease-in-out;
  img {
    width: 100%;
  }
}

.thumbnails-conteiner {
  position: relative;
  width: 830px;
  .thumbnails {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .carousel__track {
      .carousel__slide {
        position: relative;
        overflow: hidden;
        width: 264px;
        height: 200px;
        .carousel__item {
          position: relative;
          width: 100%;
          height: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          img {
            width: 244px;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .arrow-container {
    position: absolute;
    top: 0;

    height: 100%;
    width: 40px;
    z-index: 2;
    &.arrow-one-container {
      right: 0;
    }
    &.arrow-two-container {
      left: 0;
    }
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      gap: 10px;
      border-radius: 20px;
      background: #f2f4f7;
      color: #0a0a0a;
      cursor: pointer;
    }
  }
}
</style>
