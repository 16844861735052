<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-if="textAndIcon"
        v-bind="props"
        elevation="0"
        class="justify-center px-4 py-4 rounded-lg d-flex align-center bg-red"
        height="53"
        @click="openDi()"
      >
        <svg
          class="me-2"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8588 7.7019C14.1516 7.40901 14.1516 6.93414 13.8588 6.64124C13.5659 6.34835 13.091 6.34835 12.7981 6.64124L13.8588 7.7019ZM7.14124 12.2981C6.84835 12.591 6.84835 13.0659 7.14124 13.3588C7.43414 13.6516 7.90901 13.6516 8.2019 13.3588L7.14124 12.2981ZM12.7981 13.3588C13.091 13.6516 13.5659 13.6516 13.8588 13.3588C14.1516 13.0659 14.1516 12.591 13.8588 12.2981L12.7981 13.3588ZM8.2019 6.64124C7.90901 6.34835 7.43414 6.34835 7.14124 6.64124C6.84835 6.93414 6.84835 7.40901 7.14124 7.7019L8.2019 6.64124ZM17.75 10C17.75 14.0041 14.5041 17.25 10.5 17.25V18.75C15.3325 18.75 19.25 14.8325 19.25 10H17.75ZM10.5 17.25C6.49593 17.25 3.25 14.0041 3.25 10H1.75C1.75 14.8325 5.66751 18.75 10.5 18.75V17.25ZM3.25 10C3.25 5.99593 6.49593 2.75 10.5 2.75V1.25C5.66751 1.25 1.75 5.16751 1.75 10H3.25ZM10.5 2.75C14.5041 2.75 17.75 5.99593 17.75 10H19.25C19.25 5.16751 15.3325 1.25 10.5 1.25V2.75ZM12.7981 6.64124L9.96967 9.46967L11.0303 10.5303L13.8588 7.7019L12.7981 6.64124ZM9.96967 9.46967L7.14124 12.2981L8.2019 13.3588L11.0303 10.5303L9.96967 9.46967ZM13.8588 12.2981L11.0303 9.46967L9.96967 10.5303L12.7981 13.3588L13.8588 12.2981ZM11.0303 9.46967L8.2019 6.64124L7.14124 7.7019L9.96967 10.5303L11.0303 9.46967Z"
            fill="white"
          />
        </svg>

        {{ $t("deleteMeetingNow") }}
      </v-btn>
      <v-btn
        v-else
        v-bind="props"
        class="pa-0 ma-0 btn-48px bg-red ios-r"
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.0132 9.2246C16.3549 8.88289 16.3549 8.32887 16.0132 7.98716C15.6715 7.64545 15.1175 7.64545 14.7758 7.98716L16.0132 9.2246ZM7.98756 14.7754C7.64585 15.1171 7.64585 15.6711 7.98756 16.0128C8.32927 16.3545 8.88329 16.3545 9.225 16.0128L7.98756 14.7754ZM14.7758 16.0128C15.1175 16.3545 15.6715 16.3545 16.0132 16.0128C16.3549 15.6711 16.3549 15.1171 16.0132 14.7754L14.7758 16.0128ZM9.225 7.98716C8.88329 7.64545 8.32927 7.64545 7.98756 7.98716C7.64585 8.32887 7.64585 8.88289 7.98756 9.2246L9.225 7.98716ZM20.7254 12C20.7254 16.8187 16.8191 20.725 12.0004 20.725V22.475C17.7856 22.475 22.4754 17.7852 22.4754 12H20.7254ZM12.0004 20.725C7.18171 20.725 3.27539 16.8187 3.27539 12H1.52539C1.52539 17.7852 6.21521 22.475 12.0004 22.475V20.725ZM3.27539 12C3.27539 7.18131 7.18171 3.27499 12.0004 3.27499V1.52499C6.21521 1.52499 1.52539 6.21481 1.52539 12H3.27539ZM12.0004 3.27499C16.8191 3.27499 20.7254 7.18131 20.7254 12H22.4754C22.4754 6.21481 17.7856 1.52499 12.0004 1.52499V3.27499ZM14.7758 7.98716L11.3817 11.3813L12.6191 12.6187L16.0132 9.2246L14.7758 7.98716ZM11.3817 11.3813L7.98756 14.7754L9.225 16.0128L12.6191 12.6187L11.3817 11.3813ZM16.0132 14.7754L12.6191 11.3813L11.3817 12.6187L14.7758 16.0128L16.0132 14.7754ZM12.6191 11.3813L9.225 7.98716L7.98756 9.2246L11.3817 12.6187L12.6191 11.3813Z"
            fill="white"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head letter-42 ms-6 dark-brown-color">
            {{ $t("endMeeting") }}
          </div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          :disabled="!form.agree"
          :loading="loading"
          @click="endMeeting"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 my-10">
          <!-- mdi-check -->
          <v-checkbox
            class="squer-check-box sm-checkbox rounded-2 ps-2 pe-4"
            v-model="form.agree"
            value="true"
            color="primary"
            :label="$t('confirmEndMeeting')"
            true-icon="mdi-check"
            hide-details
          ></v-checkbox>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "endMeetingDialogDialog",
  components: {},
  props: {
    meeting_id: {
      type: String,
      required: true,
    },
    textAndIcon: {
      type: Boolean,
    },
    isMemberOwner: {
      type: Boolean,
    },
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      dialog: false,
      loading: false,

      form: {
        agree: false,
      },
    };
  },
  methods: {
    async endMeeting() {
      this.loading = true;
      try {
        const response = await ai.put(
          "method/agenda.api.meeting.meeting.end_meeting",
          {
            meeting_id: this.meeting_id,
          }
        );
        this.loading = false;
        this.dialog = false;
        if (this.isMemberOwner) {
          this.$router.push(
            `/${this.entity_id}/${this.space_id}/meetings/meeting-minutes/${response.data.message.meeting_min_id}`
          );
        } else {
          this.$router.push(`/${this.entity_id}/${this.space_id}/meetings`);
        }
      } catch (error) {
        this.loading = false;
        this.dialog = false;
        console.log(error);
      }
    },
    openDi() {
      this.$emit("openDi");
    },
  },
};
</script>
