<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        elevation="0"
        class="px-5 py-4 rounded-lg fill-w-btn siz-14 send-gray-btn border-slim-light w-100 pa-0 mt-2"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        {{ $t("addNewVoting") }}
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("addNewVoting") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="isLoading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form v-model="valid" ref="form" class="news-form">
          <div class="form-inputs-container border-slim-light my-10 rounded-lg">
            <v-text-field
              v-model="vote.title"
              :label="$t('votes.add.title_label')"
              :placeholder="$t('votes.add.title_placeholder')"
              class="bg-transparent form-input-box"
              dense
              hide-details="auto"
              outlined
              persistent-placeholder
              solo
              type="email"
              variant="solo"
            ></v-text-field>
            <v-text-field
              v-for="choice in vote.choices"
              :key="choice.id"
              v-model="choice.content"
              :label="choice.label"
              :placeholder="choice.placeholder"
              class="bg-transparent form-input-box"
              dense
              hide-details="auto"
              outlined
              persistent-placeholder
              solo
              type="email"
              variant="solo"
            ></v-text-field>
            <v-btn
              class="border-0 fill-w-btn text-gray add-more-button dark-brown-color"
              elevation="0"
              @click="addChoice()"
            >
              <svg
                fill="none"
                height="24"
                viewBox="0 0 25 24"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5031 7.19995L12.5031 16.8M17.3031 12L7.70312 12"
                  stroke="#475467"
                  stroke-linecap="round"
                  stroke-width="1.5"
                />
              </svg>
              {{ $t("votes.add.add_new_choice_button") }}
            </v-btn>
          </div>
          <!-- 
          <div class="center-header my-10">
            {{ $t("votes.add.vote_settings_title") }}
          </div>
          <div class="border-light rounded-lg mb-10">
            <v-row class="ma-0">
              <v-col md="6" sm="12" class="pt-0 pb-0">
                <div class="date-container">
                  <date-picker @selectedDate="setVoteStartDate" class="10" />
                  <div
                    class="gap-10 form-input-box d-flex align-center ps-2 border-0"
                  >
                    <v-btn
                      class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                      evaluation="0"
                      flat
                    >
                      <img
                        loading="lazy"
                        :src="
                          require('@/assets/images/date-picker-calendar.svg')
                        "
                        alt=""
                      />
                    </v-btn>

                    <v-text-field
                      v-model="vote.start_date"
                      :label="$t('votes.add.vote_start_date_label')"
                      :placeholder="$t('votes.add.vote_start_date_placeholder')"
                      class="bg-transparent form-input-box pa-0 border-0"
                      dense
                      hide-details="auto"
                      outlined
                      persistent-placeholder
                      solo
                      type="text"
                      variant="solo"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col md="6" sm="12" class="pt-0 pb-0 border-s">
                <div class="date-container">
                  <date-picker @selectedDate="setVoteEndDate" class="10" />
                  <div
                    class="gap-10 form-input-box d-flex align-center ps-2 border-0"
                  >
                    <v-btn
                      class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                      evaluation="0"
                      flat
                    >
                      <img
                        loading="lazy"
                        :src="
                          require('@/assets/images/date-picker-calendar.svg')
                        "
                        alt=""
                      />
                    </v-btn>

                    <v-text-field
                      v-model="vote.vote_end_date"
                      :label="$t('votes.add.vote_end_date_label')"
                      :placeholder="$t('votes.add.vote_end_date_placeholder')"
                      class="bg-transparent form-input-box pa-0 border-0"
                      dense
                      hide-details="auto"
                      outlined
                      persistent-placeholder
                      solo
                      type="text"
                      variant="solo"
                    ></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div> -->

          <!-- <v-card elevation="0" class="mt-6 mb-8 base-box">
            <v-switch
              width="20px"
              height="20px"
              v-model="vote.allow_editing_vote"
              hide-details
              inset
              :label="$t('votes.add.allow_editing_vote_radio_label')"
              color="primary"
            ></v-switch>
            <v-switch
              width="20px"
              height="20px"
              v-model="vote.allow_view_results"
              hide-details
              inset
              :label="
                $t('votes.add.allow_viewing_vote_result_during_radio_label')
              "
              color="primary"
            ></v-switch>
            <v-switch
              width="20px"
              height="20px"
              v-model="vote.allow_view_user_results"
              hide-details
              inset
              :label="
                $t(
                  'votes.add.allow_view_users_vote_results_after_voting_radio_label'
                )
              "
              color="primary"
            ></v-switch>
          </v-card> -->
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import DatePicker from "@/components/shared/DatePicker.vue";
import { ai } from "@/plugins/axios";
import moment from "moment";
import { socket } from "@/plugins/socket";

export default {
  name: "addNewVoting",

  components: {
    DatePicker,
  },

  props: {
    agendaItem: {
      type: Object,
    },
    meeting_id: {
      type: String,
    },
  },
  data() {
    return {
      valid: false,
      votingAddressRules: [
        (v) => !!v || this.$t("votingAddressRequired"),
        (v) => (v && v.length <= 120) || this.$t("max120"),
      ],
      enterChooseTextRules: [
        (v) => !!v || this.$t("enterChooseTextRequired"),
        (v) => (v && v.length <= 120) || this.$t("max120"),
      ],
      dialog: false,
      choicesCount: 1,
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      vote: {
        title: "",
        choices: [
          {
            id: 1,
            label: this.$t("votes.add.choice_label") + " #" + 1,
            placeholder:
              this.$t("votes.add.choice_placeholder") +
              " " +
              this.ordinalsAr(1),
            content: "",
          },
        ],
        start_date: "",
        vote_end_date: "",
        allow_editing_vote: false,
        allow_view_results: false,
        allow_view_user_results: false,
      },
      isLoading: false,
    };
  },
  methods: {
    async createVote(createRequest) {
      this.isLoading = true;
      try {
        await ai.post(
          "method/agenda.api.meeting.agenda_vote.create_new_vote",
          createRequest
        );
        this.resetForm();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    constructRequest() {
      const duration = moment
        .duration(
          moment(this.vote.vote_end_date)
            .startOf("day")
            .diff(moment(this.vote.start_date).endOf("day"))
        )
        .asSeconds();
      const request = {
        agenda_id: this.agendaItem.agenda_id,
        space_id: this.space_id,
        vote_title: this.vote.title,
        vote_duration: duration,
        // start_date: moment(this.vote.start_date)
        //   .startOf("day")
        //   .format("YYYY-MM-DD HH:mm:ss"),
        // end_date: moment(this.vote.vote_end_date)
        //   .endOf("day")
        //   .format("YYYY-MM-DD HH:mm:ss"),
        // enable_changing_vote: this.vote.allow_editing_vote,
        // show_results: this.vote.allow_view_results,
        // show_result_after_voting: this.vote.allow_view_user_results,
        vote_options_table: [],
      };
      this.vote.choices.forEach((choice) => {
        request.vote_options_table.push({ vote_option: choice.content });
      });
      return request;
    },
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      this.isLoading = true;
      if (valid) {
        await this.createVote(this.constructRequest());
        this.dialog = false;
        this.isLoading = false;
        this.$emit("refreshOldData");
      }
    },
    setVoteStartDate(date) {
      this.vote.start_date = date;
    },
    setVoteEndDate(date) {
      this.vote.vote_end_date = date;
    },
    addChoice() {
      this.choicesCount++;
      this.vote.choices.push({
        id: this.choicesCount,
        label: this.$t("votes.add.choice_label") + " #" + this.choicesCount,
        placeholder:
          this.$t("votes.add.choice_placeholder") +
          " " +
          this.ordinalsAr(this.choicesCount),
        content: "",
      });
    },
    ordinalsAr(num, isFeminine = false) {
      num %= 100; // only handle the lowest 2 digits (1-99) ignore others
      let the = "ال",
        unit = num % 10,
        ordinal =
          the +
          [
            ,
            "أول",
            "ثاني",
            "ثالث",
            "رابع",
            "خامس",
            "سادس",
            "سابع",
            "ثامن",
            "تاسع",
            "عاشر",
          ][num === 10 ? num : unit], // letters for lower part of ordinal string
        female = isFeminine ? "ة" : "", // add letter "ة" if Feminine
        ones = (unit === 1 ? the + "حادي" : ordinal) + female; // special cases for 21, 31, 41, etc.
      return num < 11
        ? ordinal + (isFeminine && num === 1 ? "ى" : female) // from 1 to 10
        : num < 20
        ? ones + " عشر" + female // from 11 to 19
        : (unit ? ones + " و" : "") + // else 20 to 99
          "ال" + // always add "ال"
          [, , "عشر", "ثلاث", "أربع", "خمس", "ست", "سبع", "ثمان", "تسع"][ // letters for 20, 30, 40...
            ~~(num / 10)
          ] +
          "ون";
    },
    resetForm() {
      this.vote = {
        title: "",
        choices: [
          {
            id: 1,
            label: this.$t("votes.add.choice_label") + " #" + 1,
            placeholder:
              this.$t("votes.add.choice_placeholder") +
              " " +
              this.ordinalsAr(1),
            content: "",
          },
        ],
        start_date: "",
        vote_end_date: "",
        allow_editing_vote: false,
        allow_view_results: false,
        allow_view_user_results: false,
      };
    },

    // async createVoteOptions(createRequest) {
    //   try {
    //     const options = createRequest.vote_options_table;
    //     for (let index in options) {
    //       console.log("e", options[index]);
    //       await ai.post("method/agenda.api.home.vote.create_new_vote_option", {
    //         vote_option: options[index].vote_option,
    //       });
    //     }
    //     await this.createVote(createRequest);
    //     this.isLoading = false;
    //   } catch (err) {
    //     this.isLoading = false;
    //   }
    // },

    // async submitForm() {
    //   const { valid } = await this.$refs.form.validate();
    //   this.isLoading = true;
    //   if (valid) {
    //     await this.createVoteOptions(this.constructRequest());
    //   }
    // },
  },

  mounted() {},
  async created() {
    console.log("vote sockect", this.eventID);
    socket.on(this.eventID, (...args) => {
      const res = args["0"];
      console.log("vote sockect", res);
    });
  },
};
</script>
