<template>
  <router-link to="/add_entity" elevation="0" class="new-company flex-column">
    <v-btn
      link
      to="/add_entity"
      color="transparent"
      class="main-header-btn pa-0 ma-0"
      elevation="0"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </v-btn>
    <p class="mt-6 new-com text-dark">
      {{ $t("addNewCompany") }}
    </p>
  </router-link>
</template>

<script>
export default {};
</script>
