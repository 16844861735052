<template>
  <div
    class="term-container-ele rounded-4"
    :class="border ? 'border-light' : 'mb-0'"
  >
    <div class="mt-0 sec-box-heading siz-18 pt-5 pr-5">
      {{ $t("voteDetils") }}
    </div>
    <div class="box-title">
      <div class="vote-box">
        <div
          class="meeting-info justify-space-between align-center gap-10 d-flex"
        >
          <div class="align-center d-flex gap-10">
            <div class="vote-title text-black">{{ vote.vote_title }}</div>
          </div>
          <div
            v-if="!memberVoteDt.voted"
            class="d-flex siz-14 font-weight-regular gap-10 text-blue-gray"
          >
            <!-- {{ voteStatistics }} -->

            {{
              voteStatistics.total_member_votes +
              " " +
              $t("votes.view.out_of") +
              " " +
              voteStatistics.total_members_count +
              " " +
              $t("votes.view.voted")
            }}
          </div>
          <div v-else class="text-primary siz-14">
            {{ $t("votingDone") }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(choice, choiceName, index) in voteStatistics.status"
      :key="index"
      class="box-title"
    >
      <div class="vote-box">
        <div
          class="meeting-info justify-space-between align-center gap-10 d-flex"
        >
          <div class="align-center d-flex gap-10">
            <div
              :class="
                memberVoteDt.vote_option == choiceName
                  ? 'bg-primary border-color-p'
                  : ''
              "
              class="list-num pt-3px"
            >
              {{ index + 1 }}
            </div>
            <div class="vote-name text-black">{{ choiceName }}</div>
          </div>
          <div
            class="gap-10"
            :class="userCurrentRole != 'Member' ? 'd-flex' : 'd-none'"
          >
            <voting-progress-bar
              :current-value="choice.total_number_votes"
              :refrance-value="voteStatistics.total_member_votes"
              :viewPercentage="true"
            ></voting-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VotingProgressBar from "@/components/ui/meetingAgenda/VotingProgressBar.vue";

export default {
  name: "VoteDetailRow",
  components: { VotingProgressBar },
  props: {
    memberVoteDt: {
      type: Object,
      default: () => {},
    },
    vote: {
      type: Object,
      default: () => {},
    },
    voteStatistics: {
      type: Object,
      default: () => {},
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;

.sec-box-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #0a0a0a;
}
.text-black {
  color: #0a0a0a;
}
</style>
