<template>
  <div
    class="mt-5 task-time-line dt-item-timeline main-elements-timeline meeting-ele dark-brown-color"
  >
    <div class="mb-10">
      <!-- main task -->
      <v-form
        lazy-validation
        ref="form"
        class="mb-5 border auth-form rounded-4"
      >
        <v-text-field
          ref="taskTitleField"
          class="bg-transparent form-input-box"
          v-model="form.task_title"
          type="text"
          :placeholder="$t('task_title')"
          :label="$t('task_title')"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          :rules="rules"
          @input="checkFormValidity"
        ></v-text-field>
        <v-text-field
          ref="taskDescriptionField"
          class="bg-transparent form-input-box"
          v-model="form.task_description"
          type="text"
          :placeholder="$t('task_description')"
          :label="$t('task_description')"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          :rules="rules"
          @input="checkFormValidity"
        ></v-text-field>

        <div class="date-container border-b-light">
          <date-picker @selectedDate="setTaskDate" class="10" />
          <div class="gap-10 form-input-box d-flex align-center ps-5">
            <v-btn
              class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
              evaluation="0"
              flat
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.875 7.57139H17.625M3.91071 1V2.71449M15.375 1V2.71428M18.75 5.71428L18.75 18C18.75 19.6569 17.4069 21 15.75 21H3.75C2.09315 21 0.75 19.6568 0.75 18V5.71428C0.75 4.05742 2.09315 2.71428 3.75 2.71428H15.75C17.4069 2.71428 18.75 4.05742 18.75 5.71428Z"
                  stroke="#667085"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>

            <v-text-field
              ref="taskDateField"
              v-model="form.task_date"
              type="text"
              :placeholder="$t('task_date')"
              :label="$t('task_date')"
              outlined
              solo
              dense
              variant="solo"
              hide-details="auto"
              persistent-placeholder
              :rules="rules"
              @input="checkFormValidity"
            ></v-text-field>
          </div>
        </div>

        <main-task-member
          @selectedMembers="selectedMembersMainTask"
          :currentMembers="selectedMembersFirst"
        />
      </v-form>
      <!-- sub task -->
      <div class="sub-task-time" v-for="(e, index) in subTasksNum" :key="index">
        <SubTaskForm
          :subtask="form.subtasks[index]"
          :subtaskFormIndex="index"
          @subtaskDataUpdated="subtaskDataUpdated"
        />
      </div>

      <div class="">
        <v-btn
          elevation="0"
          class="mt-0 border border-0 auth-form rounded-4 fill-w-btn siz-14 send-gray-btn rounded-4-bottom rounded-0 w-100 dark-brown-color"
          @click="AddSubTask"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
              stroke="#475467"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          {{ $t("addSubTask") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import Select2 from "vue3-select2-component";
import SubTaskForm from "@/components/ui/tasks/SubTaskForm";
import mainTaskMember from "@/components/ui/tasks/mainTaskMember";
import { ai } from "@/plugins/axios";
import moment from "moment";
import DatePicker from "@/components/shared/DatePicker";

export default {
  name: "taskLine",
  components: {
    SubTaskForm,
    Select2,
    mainTaskMember,
    DatePicker,
  },
  props: {
    current_members: {},
    task: {},
    space_id: {
      type: String,
      required: true,
    },
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("pushAllTasks", val);
        this.$emit("afterEdit", this.form);
      },
      deep: true,
    },
  },
  data() {
    return {
      valid: false,
      selectedMembers: [],
      task_id: this.$route.params.task_id,
      form: {
        parent_task: {
          taskCategory: "",
          task_title: "",
          task_description: "",
          task_date: "",
          task_assignee: [],
          first_name: "",
          last_name: "",
          user_image: "",
          space: this.space_id,
        },
        subtasks: [],
      },
      subTasksNum: 0,
      AssignMemberEmail: "",
      space_id: "",
      errorMessage: "",
      members: [],
      selectedMembersFirst: [],
      rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
    };
  },

  methods: {
    async checkFormValidity() {
      const { valid } = await this.$refs.form.validate();

      this.$emit("formValidity", valid);
    },
    selectedMembersMainTask(e) {
      this.selectedMembersFirst = e;
      let selectedMembers = e.map((member) => ({
        member: member.member_id || member.member,
      }));
      this.form.task_assignee = selectedMembers;
    },

    async setTaskDate(date) {
      this.form.task_date = date;
      const { valid } = await this.$refs.form.validate();

      this.$emit("formValidity", valid);
    },
    setPerantTask() {
      const request = {
        task_title: this.form.task_title,
        task_description: this.form.task_description,
        task_date: this.form.task_date,
        task_assignee: this.form.task_assignee,
        space_id: this.space_id,
        status: "Created",
      };
      return request;
    },
    // sub task logic
    AddSubTask() {
      this.subTasksNum++;
    },
    subtaskDataUpdated(subtaskFormData) {
      if (subtaskFormData.index != null) {
        this.form.subtasks[subtaskFormData.index] = subtaskFormData;
      } else {
        this.form.subtasks.push(subtaskFormData);
      }
    },
    async getTaskDetails(e) {
      if (this.task_id) {
        try {
          this.loading = true;
          const response = await ai.get(
            `method/agenda.api.space.task.get_task_details`,
            { params: { task_id: this.task_id } }
          );
          this.loading = false;

          // Directly set the form properties
          this.form.task_title = response.data.message.task_title;
          this.form.task_description = response.data.message.task_description;

          this.form.first_name = response.data.message.first_name;
          this.form.last_name = response.data.message.last_name;
          this.form.user_image = response.data.message.user_image;
          this.form.task_date = this.OnlyDate(response.data.message.task_date);
          // set sub task
          this.subTasksNum = response.data.message.subtasks.length;
          this.form.subtasks = response.data.message.subtasks;

          // set assgin
          // this.form.task_assignee =
          // response.data.message.task_assignee;
          this.selectedMembersMainTask(response.data.message.task_assignee);
          this.$emit("afterEdit", response.data.message);
        } catch (err) {
          console.log(err);
          this.loading = false;
        }
      }
    },
    OnlyDate(creation) {
      const dateParts = creation.split(" ");
      const date = dateParts[0];
      return date;
    },
  },

  async created() {
    this.setPerantTask();
    this.getTaskDetails();
    // if (this.currentTask) {
    // } else {
    // }
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;

.task-time-line {
  .v-timeline-item__body {
    width: 100%;
    padding-block-end: 0;
  }

  .v-timeline-item:first-child .v-timeline-divider {
    padding-block-start: 0px !important;
  }
}
.sub-task-time {
  .v-timeline-divider__inner-dot {
    width: 8px !important;
    height: 8px !important;
  }
}
</style>
