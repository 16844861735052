<template>
  <div>
    <div
      class="new-header-page bg-white py-5 d-flex align-center justify-space-between pe-10 rounded-lg w-100"
    >
      <div
        class="layout-container d-flex align-center justify-space-between px-5"
      >
        <div class="header-back-name d-flex align-center">
          <transition name="slide-x-transition">
            <router-link
              class="maringForSummary"
              :to="{
                name: 'space',
                params: {
                  entity_id: entity_id,
                  space_id: space_id,
                },
              }"
            >
              <v-btn class="app-btn smooth_rounded" elevation="0">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="11.9996" r="1.25" fill="white" />
                </svg>
              </v-btn>
            </router-link>
          </transition>
          <div class="header-title-content d-flex align-center">
            <div class="align-center d-flex gap-15">
              <v-btn
                class="bg-primary pa-0 ma-0 app-btn smooth_rounded mt-0"
                elevation="0"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C11.2552 2 12.4428 2.28906 13.5 2.80423M16.5 5L9.5 12L7.5 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
            </div>
            <div class="side-head ms-6 letter-42">
              {{ $t("tasks") }}
            </div>
          </div>
        </div>
        <v-btn
          v-if="memberPermissions['Task']?.create"
          color="transparent"
          class="new-plus bg-dark-blue ios-r"
          elevation="0"
          link
          :to="`/${entity_id}/${space_id}/tasks/add`"
        >
          <div class="siz-14 me-2">
            {{ $t("add") }}
          </div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 4L10 16M16 10L4 10"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </v-btn>
      </div>
    </div>
    <div class="layout-padding layout-container mt-value">
      <!-- summary navigator -->
      <div class="summary-navigator d-flex align-center justify-center">
        <div class="new-tab-vis">
          <div
            :class="panel == 0 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('', 0)"
          >
            {{ $t("allT") }}
          </div>
          <div
            :class="panel == 1 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('In Progress', 1)"
          >
            {{ $t("active") }}
          </div>
          <div
            :class="panel == 2 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('Completed', 2)"
          >
            {{ $t("endedT") }}
          </div>
          <div
            :class="panel == 3 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('lateTasks', 3)"
          >
            {{ $t("late") }}
          </div>
          <div
            :class="panel == 4 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('meetingsTasks', 4)"
          >
            {{ $t("meetings") }}
          </div>
          <div
            :class="panel == 5 ? 'activeTab' : ''"
            class="tab-title"
            @click="taskFilter('myTasks', 5)"
          >
            {{ $t("myTasks") }}
          </div>
          <div class="tab-title btn-cir">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.0002 1.20001L6.0002 10.8M10.8002 6.00001L1.2002 6.00001"
                stroke="#475467"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="tab-title btn-cir">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.9081 1.89808C11.3051 1.50089 11.9489 1.50061 12.3462 1.89747L14.1009 3.65017C14.4983 4.0472 14.4986 4.69129 14.1015 5.08864L5.81824 13.3764C5.67659 13.5181 5.49623 13.6149 5.29979 13.6544L1.59961 14.4L2.34652 10.7042C2.38613 10.5081 2.48266 10.3282 2.62404 10.1867L10.9081 1.89808Z"
                stroke="#475467"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <v-card
        v-if="loading"
        class="main-page-container dark-brown-color mt-35px form-container"
        elevation="0"
      >
        <SkeletonRow
      /></v-card>
      <v-card
        v-if="allTasks?.length > 0 && !loading"
        class="main-page-container dark-brown-color mt-35px form-container"
        elevation="0"
      >
        <v-card elevation="0">
          <v-card>
            <v-card elevation="0">
              <div
                v-if="
                  selectedMenuItem === 'allTasks' ||
                  selectedMenuItem === 'activeTasks'
                "
                class="active-tasks-container"
              >
                <div v-if="allTasks?.length > 0">
                  <active-task-box
                    v-for="e in allTasks"
                    :key="e.id"
                    :taskDt="e"
                    @refreshTasks="refreshTasks"
                    :taskPermission="userTasksPermissions.write"
                    :isVisible="true"
                  />
                </div>
                <empty-page
                  v-else
                  class="pb-10 pt-10"
                  :text="$t('noTasksYet')"
                  :svg="svgData"
                />
              </div>
            </v-card>
          </v-card>
        </v-card>
      </v-card>
      <div
        v-else-if="!loading"
        class="justify-center w-400-auto d-flex align-center flex-column py-10"
      >
        <!-- image -->
        <empty-page :text="$t('noTasksYet')" :svg="svgData" />

        <div>
          <div class="mb-10 text-center siz-12 text-gray">
            {{ $t("uCanAddTask") }}
          </div>
          <v-btn
            v-if="memberPermissions['Task']?.create"
            color="primary"
            class="justify-center siz-14 pa-5 d-algin-flex align-center w-400px"
            elevation="0"
            link
            :to="`/${entity_id}/${space_id}/tasks/add`"
          >
            {{ $t("addNewTask") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import SkeletonRow from "@/components/ui/rows/SkeletonRow.vue";
import InnerHeader from "@/components/shared/InnerHeader";
import Select2 from "vue3-select2-component";
import ActiveTaskBox from "@/components/ui/tasks/ActiveTaskBox";
import emptyPage from "@/views/default-pages/emptyPage.vue";

import { ai } from "@/plugins/axios";
import { load } from "webfontloader";

export default defineComponent({
  name: "tasks",
  components: {
    InnerHeader,
    ActiveTaskBox,
    Select2,
    SkeletonRow,
    emptyPage,
  },

  data() {
    return {
      selectedMenuItem: "allTasks",
      memberPermissions: [],
      userTasksPermissions: {},
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      panel: [0],
      loading: true,
      svgData: ` <svg
            width="123"
            height="110"
            viewBox="0 0 123 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64.7202 12.6187L75.9574 97.9626L12.4572 109.491L0.203125 23.6619L64.7202 12.6187Z"
              fill="#D0D5DD"
            />
            <path
              d="M66.462 6.37036L2.67188 20.9496L19.6242 105.955L82.3493 90.9879L66.462 6.37036Z"
              fill="white"
            />
            <path
              d="M19.6237 106.275C19.4714 106.275 19.3396 106.167 19.3101 106.018L2.35781 21.0123C2.32389 20.8427 2.43141 20.6763 2.60037 20.6379C66.5837 6.05229 66.4551 5.98254 66.6369 6.10286L82.6631 90.9297C82.6945 91.0974 82.5889 91.2606 82.4231 91.3003L19.6237 106.276V106.275ZM3.04581 21.1921L19.8727 105.567L81.9783 90.748L66.2087 6.7563L3.04581 21.1921Z"
              fill="#0A0A0A"
            />
            <path
              d="M14.5899 41.0795C14.4414 41.0795 14.3122 40.9771 14.2782 40.8324C11.9051 30.6737 11.8789 30.8324 11.9986 30.6526C12.1189 30.4734 11.9909 30.6059 21.0277 29.0615C21.1902 29.034 21.34 29.1313 21.3867 29.2855L23.3317 38.6622C23.3656 38.8311 23.2606 38.9963 23.093 39.0366L14.5899 41.0801V41.0795ZM12.6546 31.0903L14.828 40.3729L22.6431 38.4862L20.8555 29.738L12.6546 31.0897V31.0903Z"
              fill="#0A0A0A"
            />
            <path
              d="M17.9085 56.8543L16.0685 47.5065C16.0353 47.3363 16.1428 47.1712 16.3117 47.1328L25.0305 45.1468C25.2039 45.1046 25.3869 45.2217 25.417 45.4073C27.1079 55.7055 27.1297 55.5545 27.0061 55.7222C26.8833 55.8905 27.0253 55.8617 18.2695 57.109C18.0909 57.1346 17.9405 57.015 17.9085 56.8543ZM16.7565 47.6876L18.4775 56.4313L26.3796 55.2639L24.8397 45.8457L16.7565 47.687V47.6876Z"
              fill="#0A0A0A"
            />
            <path
              d="M21.6302 73.9953L19.9348 64.5988C19.9041 64.4286 20.0142 64.2654 20.1825 64.2289L28.9499 62.3396C29.1316 62.3012 29.3063 62.4222 29.3339 62.6059C30.8763 72.9496 30.8993 72.8017 30.7745 72.9681C30.6491 73.1345 30.7892 73.0974 21.9886 74.2558C21.8119 74.2788 21.6596 74.1585 21.6302 73.9953ZM20.6196 64.7896L22.2055 73.5806L30.1537 72.5028L28.7502 63.0379L20.6196 64.7896Z"
              fill="#0A0A0A"
            />
            <path
              d="M26.9884 93.1007C26.8393 93.1007 26.7107 92.997 26.6767 92.853C24.303 82.6476 24.2774 82.8037 24.3971 82.6252C24.5161 82.446 24.3971 82.6085 33.4262 81.0341C33.5983 81.0041 33.7583 81.1173 33.7923 81.2863L35.6815 90.6341C35.7155 90.8018 35.6111 90.9669 35.4447 91.0079L26.9884 93.1001V93.1007ZM25.0524 83.0629L27.2259 92.3916L34.9929 90.4607L33.2259 81.7151L25.0524 83.0623V83.0629Z"
              fill="#0A0A0A"
            />
            <path
              d="M33.2809 33.5806C34.1794 34.2987 35.3327 34.5483 36.4796 34.5483C38.7727 34.5483 41.2463 33.4526 43.1766 31.5154C45.773 32.8293 50.2466 31.9166 53.3065 28.5035C55.4677 29.4386 59.1151 28.4088 61.2585 24.995C61.3525 24.8453 61.3071 24.6482 61.158 24.5541C61.0076 24.4606 60.8105 24.5054 60.717 24.6546C58.8796 27.5813 55.6329 28.677 53.7513 27.9915C54.6908 26.773 55.1247 25.5883 55.0089 24.5438C54.7317 22.2706 51.933 22.3173 51.101 24.2219C50.4188 25.9282 51.3756 27.397 52.7177 28.2008C49.7794 31.3662 45.7449 32.021 43.6265 31.0232C44.6223 29.9256 45.8025 28.0427 45.5804 26.6226C45.4005 25.4718 44.173 24.725 42.9385 25.2971C41.9958 25.7336 41.206 26.8498 41.1017 27.8904C40.9577 29.187 41.5401 30.4376 42.6044 31.1826C39.4114 34.2635 35.5977 34.4229 33.8646 33.2197C36.1801 31.5141 36.9884 29.6139 36.0809 27.9646C35.2937 26.5464 33.5055 27.125 32.5442 28.3614C31.4274 29.8181 31.5567 31.8302 32.7868 33.1339C31.2162 34.0696 29.213 34.949 27.4364 34.5163C27.2214 34.2258 26.781 34.4747 26.8322 34.7864C26.8815 35.0891 27.3071 35.155 27.4626 35.1691C27.8178 35.2658 28.1506 35.269 28.5078 35.269C29.8505 35.1845 31.1849 34.8626 33.2822 33.5826L33.2809 33.5806ZM51.6898 24.4683C52.2364 23.219 54.1705 22.9656 54.3721 24.6174C54.4962 25.7349 53.8133 26.8862 53.1541 27.7144C52.0661 27.1077 51.1004 25.9416 51.6898 24.4683ZM41.7372 27.9563C41.8402 26.9266 42.8367 25.7355 43.7974 25.7355C44.3042 25.7355 44.8489 26.0907 44.9468 26.7205C45.0966 27.6779 44.3657 29.116 43.5945 30.0933C43.4594 30.2648 43.2719 30.4555 43.0473 30.7096C42.125 30.1048 41.6156 29.052 41.7372 27.957V27.9563ZM33.0498 28.7506C33.4396 28.2494 34.198 27.7234 34.8118 27.7746C35.1151 27.7976 35.3468 27.9614 35.5196 28.2725C36.5257 30.1022 34.6889 31.8418 33.3538 32.7966C32.2434 31.724 32.0982 29.9915 33.0492 28.7506H33.0498Z"
              fill="#475467"
            />
            <path
              d="M36.3018 50.1873C39.3354 52.4491 44.2532 50.2436 46.7946 47.5319C47.4852 48.1175 48.5054 48.6116 50.131 48.6116C52.8234 48.6116 55.5351 47.0449 57.3194 45.1863C59.4071 46.6187 64.7729 45.7841 65.9358 43.0155C66.0042 42.8523 65.9274 42.6647 65.7649 42.5963C65.6017 42.5271 65.4141 42.6046 65.3457 42.7671C64.3837 45.0577 59.6138 45.8283 57.7962 44.7326C58.5233 43.9019 59.4218 42.6679 59.4218 41.1883C59.3655 38.8356 56.6871 38.8363 55.8558 40.6769C55.1345 42.2737 55.811 43.8571 56.817 44.7851C54.7812 46.8843 51.5313 48.5137 48.5668 47.7617C48.1066 47.6452 47.6164 47.3931 47.1933 47.0302C48.321 45.674 49.4161 43.8244 48.737 42.2731C48.1322 40.9534 46.5194 41.6644 45.8436 42.7806C45.0167 44.1457 45.2606 45.8987 46.3351 47.0871C44.0791 49.4942 39.6708 51.658 36.8721 49.8065C38.7965 48.6692 40.0049 47.3067 40.3799 45.7201C40.6782 44.4606 39.8078 43.1127 38.5214 42.8663C35.3623 42.2603 33.2797 47.2395 35.8186 49.706C34.5604 50.2878 33.4142 50.7627 32.0746 50.8535C31.7943 50.8535 31.3009 50.5342 30.9412 50.7307C30.8593 50.7748 30.7511 50.8644 30.7012 51.0372C30.6026 51.3828 31.0615 51.5991 31.2689 51.3124C31.4762 51.3515 31.7764 51.4929 32.0926 51.4929C33.6382 51.3899 34.8753 50.851 36.3018 50.1873ZM56.4394 40.9399C56.9885 39.7239 58.7428 39.5377 58.7825 41.1959C58.7825 42.4823 57.9383 43.6062 57.2849 44.346C56.4318 43.5819 55.8429 42.2609 56.4394 40.9399ZM46.3908 43.1127C46.6442 42.7191 47.2874 42.1745 47.7514 42.1988C47.8615 42.2065 48.0228 42.2507 48.1533 42.5348C48.7153 43.8199 47.6036 45.5243 46.7383 46.5764C45.9114 45.5953 45.7629 44.2116 46.3908 43.1127ZM35.4449 45.9121C35.8365 44.3435 36.9994 43.4654 38.0138 43.4654C39.2746 43.4654 39.9914 44.5828 39.7572 45.5729C39.4135 47.0244 38.2468 48.291 36.3678 49.3598C36.3261 49.2926 34.8906 48.1335 35.4449 45.9127V45.9121Z"
              fill="#475467"
            />
            <path
              d="M49.8899 65.1519C50.7213 65.6863 51.9258 65.8834 52.8659 65.8834C55.657 65.8834 58.6355 64.3487 60.5888 62.4402C61.0381 62.8978 61.609 63.2793 62.2496 63.4917C65.328 64.5132 68.8442 62.3961 71.0586 59.9116C71.1763 59.7797 71.1648 59.5775 71.0323 59.4597C70.9005 59.3426 70.6989 59.3535 70.5805 59.486C68.585 61.7241 65.3766 63.8559 62.4506 62.885C61.9162 62.7077 61.4183 62.373 61.0355 61.9826C61.6794 61.2889 62.217 60.4684 62.6375 59.5333C62.9984 58.6802 63.0938 57.0764 62.3232 56.4063C62.0096 56.133 61.4432 55.9237 60.5261 56.4729C58.5651 57.6985 58.7738 60.1746 60.1677 61.9557C56.7155 65.3554 52.2637 65.8354 50.3309 64.6738C51.7018 63.086 52.7565 60.2988 51.1034 59.0073C49.0752 57.4853 46.4935 62.0652 49.3786 64.757C47.4733 66.6277 44.2349 67.8681 41.6647 66.5637L41.6531 66.5599C43.3184 65.3055 44.0653 63.8444 43.8176 62.3161C43.5386 60.4197 41.4464 59.8642 40.0819 61.1161C38.5018 62.6489 38.9344 65.0828 40.5338 66.5426C39.1245 67.3989 37.7747 67.934 35.3191 68.3429C34.9075 68.4018 34.0499 68.2386 33.8323 68.5913C33.6032 68.9605 34.0992 69.3151 34.3514 69.0092C34.6195 68.9823 35.1597 69.0124 35.417 68.9753C37.6 68.6117 39.2019 68.1407 41.0739 66.9605C43.1725 68.3775 47.1341 67.9129 49.8899 65.1525V65.1519ZM60.8615 57.0188C61.1379 56.853 61.3735 56.7698 61.5661 56.7698C62.3987 56.7698 62.3917 58.4735 62.0518 59.2767C61.6736 60.117 61.1923 60.8607 60.6189 61.4911C59.5066 60.0185 59.2736 58.0108 60.8615 57.0181V57.0188ZM48.8045 61.118C48.9562 60.4421 49.5815 59.3413 50.2554 59.3413C50.4096 59.3413 50.5607 59.3989 50.7162 59.5154C51.9642 60.4908 50.9863 62.8293 50.057 64.0255C49.9789 64.1036 49.9002 64.1945 49.8221 64.286C48.937 63.4617 48.553 62.238 48.8045 61.118ZM39.7389 63.6447C39.6403 62.1855 40.6592 61.0847 41.7357 61.0847C42.457 61.0847 43.0611 61.5666 43.1853 62.414C43.4048 63.7663 42.7002 65.0322 41.0906 66.1791C40.3149 65.5327 39.8023 64.5804 39.7389 63.6447Z"
              fill="#475467"
            />
            <path
              d="M72.9663 77.1404C72.8627 76.9983 72.6617 76.9663 72.519 77.0706C71.038 78.1509 67.6857 80.5977 65.4188 79.8809C66.3993 78.8428 67.5212 76.4812 67.006 75.1711C66.8633 74.8076 66.5247 74.3532 65.7247 74.2508C63.1206 73.9256 62.4991 77.9538 63.7913 79.4719C63.9481 79.66 64.1299 79.838 64.3347 80.0037C62.4652 81.6972 59.3837 83.0021 56.5984 82.1176C57.5968 81.262 58.3596 80.1202 58.8243 78.7711C59.3023 77.0386 58.2752 75.3778 56.5069 75.8072C53.6806 76.4549 52.5901 80.5144 55.3344 82.27C52.9734 83.8994 49.3619 85.0578 46.6886 84.2124C48.0115 83.1532 48.5574 81.8508 48.2719 80.4197C47.8182 77.989 45.5846 77.9071 44.2969 79.4975C42.999 81.0994 43.6345 83.3016 45.3843 84.3506C43.7446 85.2344 41.9961 85.6242 40.2605 85.7285C40.1702 85.7253 39.6582 85.6524 39.431 85.6076C39.3933 85.5922 39.3523 85.5832 39.3094 85.5832C38.8947 85.5832 38.855 86.1253 39.2102 86.2136C39.4118 86.2642 40.1132 86.3679 40.2739 86.3679C42.3142 86.2482 44.3462 85.7362 46.0966 84.6809C49.1871 85.8853 53.3216 84.5388 56.0006 82.5823C58.423 83.6069 62.1644 82.9234 64.8902 80.364C67.3305 81.4597 70.7577 79.1506 72.8979 77.589C73.0406 77.4847 73.0719 77.2844 72.9676 77.1416L72.9663 77.1404ZM44.5145 82.5157C43.2339 79.9148 47.0522 77.3759 47.6435 80.5401C47.9034 81.8412 47.3446 82.9848 45.9821 83.9416C45.3882 83.6735 44.8582 83.1589 44.5152 82.5157H44.5145ZM55.1545 81.2652C53.6063 79.7599 54.5491 76.9112 56.6534 76.4287C57.9782 76.1093 58.535 77.4111 58.2131 78.5804C57.7491 79.9256 56.953 81.054 55.9046 81.8546C55.6326 81.7253 55.3849 81.5314 55.1545 81.2645V81.2652ZM65.6435 74.8856C66.0473 74.9375 66.2911 75.102 66.4102 75.4053C66.7935 76.38 65.7887 78.4575 65.2403 79.1154C65.1155 79.2716 64.967 79.4303 64.8249 79.5749C64.6163 79.4156 64.4339 79.2434 64.2809 79.0591C63.3292 77.9423 63.7209 74.6399 65.6435 74.8856Z"
              fill="#475467"
            />
            <path
              d="M25.4374 19.5449C24.8563 19.2543 24.1299 19.4962 23.8387 20.126C22.6278 22.741 19.0496 31.4028 18.5107 33.1551C18.2201 33.9302 17.9296 34.8505 17.5904 35.5282C17.2294 35.095 16.3033 33.7772 16.2829 33.7362C16.0627 33.5148 15.5411 31.8393 14.2489 32.3314C13.4099 32.6674 13.2141 33.767 13.8617 34.414C14.4365 35.4194 14.4173 35.1865 14.5401 35.431C16.0416 37.6108 16.8166 38.2886 17.6883 38.3858C18.5728 38.3858 18.9817 37.9775 19.6211 36.7935C20.2816 35.5698 21.3811 32.0178 22.1933 30.3942V30.3455C23.0905 28.3711 23.7798 25.9961 26.0198 21.1423C26.3104 20.5612 26.0685 19.8348 25.4387 19.5442L25.4374 19.5449Z"
              fill="#FF7449"
            />
            <path
              d="M115.285 22.063L67.6719 51.8031L70.1909 56.017C70.1909 56.017 104.629 34.5596 117.513 26.4707L115.285 22.063H115.285Z"
              fill="#12B76A"
            />
            <path
              d="M67.7231 51.8032L59.8281 59.1172L70.1935 56.017L67.7231 51.8032Z"
              fill="white"
            />
            <path
              d="M121.866 22.0554C121.582 21.5344 121.217 20.984 120.934 20.4752C120.86 19.8531 119.541 19.0371 118.364 19.7578C67.057 51.176 67.6343 51.3648 67.3111 51.6662L59.61 58.8836C59.3655 59.1121 59.5959 59.5204 59.9204 59.4237C70.641 56.2199 70.4977 56.3287 70.6141 56.1322L121.273 24.5123C122.121 23.9677 122.381 22.8566 121.865 22.056L121.866 22.0554ZM61.0397 58.4209L67.6132 52.2602L69.7092 55.8237L61.0397 58.4209ZM70.3037 55.5716L68.1233 51.8518L115.16 22.52L117.099 26.3542L70.3037 55.5709V55.5716Z"
              fill="#0A0A0A"
            />
            <path
              d="M89.7978 6.35299C89.2199 6.18787 87.7101 5.70275 87.0637 5.49539C87.6852 3.31555 88.3821 1.07939 88.576 0.739548C88.8288 0.495708 88.5562 0.0822676 88.2349 0.212188C87.9079 0.343388 87.1072 2.98083 86.4602 5.30211C83.689 4.41699 83.6762 4.43811 83.5226 4.69091C83.3824 4.92067 83.5815 5.24067 83.8618 5.18307C84.1716 5.24963 85.2512 5.58883 86.2893 5.91971C85.858 7.49347 85.5296 8.78627 85.5226 8.81827C85.4778 9.01667 85.6275 9.20931 85.8349 9.20931C85.9808 9.20931 86.1133 9.10883 86.1466 8.96035C86.3085 8.24803 86.5818 7.21123 86.889 6.11171C87.9443 6.44707 89.5821 6.98083 89.7095 6.98083C90.0787 6.98083 90.1524 6.45475 89.7972 6.35299H89.7978Z"
              fill="#FF7449"
            />
            <path
              d="M96.5956 12.6903C96.2776 12.6372 96.2014 12.6845 93.3144 16.6717C91.0603 14.6666 91.1326 14.6903 91.0673 14.657C90.9227 14.5853 90.7435 14.632 90.6545 14.7677C90.4939 15.0135 90.4753 15.0276 92.9355 17.1927C92.2264 18.1655 91.5646 19.0544 91.1166 19.5792C91.002 19.7136 91.018 19.9159 91.1524 20.0304C91.2868 20.145 91.4891 20.129 91.6036 19.9946C92.0568 19.4628 92.714 18.5828 93.4168 17.6196C94.1617 18.2788 95.2907 19.2733 95.8539 19.7424C95.9908 19.8557 96.1918 19.8365 96.3051 19.7015C96.4184 19.5658 96.3998 19.3636 96.2641 19.2503C95.7617 18.8324 94.7716 17.9639 93.7956 17.0986C94.4478 16.1981 96.307 13.5997 96.6488 13.3079C96.9611 13.1978 96.9195 12.7447 96.5956 12.6903Z"
              fill="#FF7449"
            />
          </svg>`,
      allTasks: [],
      active_tasks: [],
      finised_tasks: [],
      my_tasks: [],
      late_tasks: [],
      meetings_tasks: [],
    };
  },

  methods: {
    async taskFilter(e, s) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.space.task.get_all_tasks`,
          {
            params: {
              space_id: this.space_id,
              page: 1,
              size: 500,
              status: e,
            },
          }
        );
        this.loading = false;
        this.allTasks = response.data.message.tasks;
        this.panel = [];
        this.panel.push(s);
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    async refreshTasks() {
      this.finised_tasks = [];
      this.active_tasks = [];
      await this.taskFilter();
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    groupTasksByProperty(taskList, property) {
      if (taskList.length) {
        return taskList.reduce((result, task) => {
          const groupValue = task[property.toLowerCase()];
          if (!result[groupValue]) {
            result[groupValue] = [];
          }
          result[groupValue].push(task);
          return result;
        }, {});
      }
    },

    openModal(e) {
      this.panel = [];
      this.panel.push(e);
    },

    async refreshTasks() {
      await this.taskFilter();
    },
    async refreshTaskDetails() {
      this.taskFilter();
    },

    async memberPermissionInSpace() {
      if (localStorage.getItem(this.space_id)) {
        this.memberPermissions = JSON.parse(
          localStorage.getItem(this.space_id)
        );
      } else {
        try {
          const response = await ai.get(
            `method/agenda.api.space.role.get_member_permissions`,
            { params: { space_id: this.space_id } }
          );
          this.memberPermissions = response.data.message;
          this.localStorage(this.space_id, response.data.message);
        } catch (err) {
          console.log(err);
        }
      }

      // need check
      this.$nextTick(() => {
        if (this.memberPermissions["Task"]?.read) {
          this.taskFilter();
        } else {
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.memberPermissionInSpace();
  },
  async created() {
    document.title = this.$t("tasks");
  },
});
</script>
