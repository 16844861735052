<template>
  <!-- sidebar  actions -->
  <div class="action-side-page meeting-layout ps-10">
    <div class="expansion-center-container">
      <v-expansion-panels
        v-model="panel"
        multiple
        class="bg-transparent expansion-circle"
        elevation="0"
      >
        <!-- meeting info -->
        <v-expansion-panel
          color="transparent"
          class="bg-transparent"
          elevation="0"
        >
          <v-expansion-panel-title
            hide-actions
            class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
            elevation="0"
          >
            <v-btn elevation="0" class="icone-arrow white-cir"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                  stroke="#0A0A0A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <div class="expansion-label">
              {{ $t("meetingInfo") }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="mb-8">
            <div class="meeting-sidebar black-dark-color white-svg">
              <div class="mb-5 text">
                <p class="quick-desc black-dark-color">
                  {{ meeting_details.meeting_description }}
                </p>
              </div>
              <div class="links">
                <div
                  v-if="meeting_details?.meeting_url"
                  class="link d-flex align-center"
                >
                  <div class="gap-10 d-flex align-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.67596 4.96845L4.57024 4.96844C3.78381 4.96844 3.02266 5.27735 2.46449 5.84378C1.90633 6.41021 1.58921 7.16967 1.60193 7.9807C1.58918 8.79172 1.90642 9.55129 2.46449 10.1176C3.0353 10.6969 3.77105 11.0059 4.55751 11.0059H6.66323M9.32717 11.0316H11.4329C12.2193 11.0316 12.9805 10.7227 13.5386 10.1563C14.0968 9.58984 14.4139 8.83037 14.4012 8.01934C14.4012 7.22127 14.0841 6.46177 13.5259 5.89534C12.9677 5.32891 12.2194 5.0071 11.4329 5.00709H9.32717M4.83652 7.97187L11.1537 7.97187"
                        stroke="#475467"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <p
                    class="copy ms-2"
                    @click="copy(meeting_details.meeting_url)"
                  >
                    {{ $t("copyMeetingLink") }}
                  </p>
                </div>
                <div v-if="meeting_details?.location" class="location">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00088 14.4C8.00088 14.4 13.0096 9.9478 13.0096 6.60867C13.0096 3.84244 10.7671 1.59998 8.00088 1.59998C5.23466 1.59998 2.99219 3.84244 2.99219 6.60867C2.99219 9.9478 8.00088 14.4 8.00088 14.4Z"
                      stroke="#475467"
                    />
                    <path
                      d="M9.60109 6.40008C9.60109 7.28373 8.88474 8.00008 8.00109 8.00008C7.11743 8.00008 6.40109 7.28373 6.40109 6.40008C6.40109 5.51642 7.11743 4.80008 8.00109 4.80008C8.88474 4.80008 9.60109 5.51642 9.60109 6.40008Z"
                      stroke="#475467"
                    />
                  </svg>
                  <span>{{ meeting_details.location }}</span>
                </div>
                <div class="date">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.58203 5.71424H13.082M3.93917 1.33331V2.4763M11.582 1.33331V2.47616M13.832 5.47616L13.832 11.6666C13.832 13.3235 12.4889 14.6666 10.832 14.6666H4.83203C3.17518 14.6666 1.83203 13.3235 1.83203 11.6666V5.47616C1.83203 3.81931 3.17518 2.47616 4.83203 2.47616H10.832C12.4889 2.47616 13.832 3.81931 13.832 5.47616Z"
                      stroke="#475467"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>
                    {{ meeting_details.meeting_date }}
                  </span>
                </div>
                <div class="time">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2434 10.0743C10.5054 10.1616 10.7886 10.0201 10.8759 9.75809C10.9632 9.49612 10.8216 9.21296 10.5597 9.12563L10.2434 10.0743ZM8.00156 8.79997H7.50156C7.50156 9.01519 7.63928 9.20626 7.84345 9.27432L8.00156 8.79997ZM8.50156 5.45481C8.50156 5.17867 8.2777 4.95481 8.00156 4.95481C7.72542 4.95481 7.50156 5.17867 7.50156 5.45481H8.50156ZM10.5597 9.12563L8.15968 8.32563L7.84345 9.27432L10.2434 10.0743L10.5597 9.12563ZM8.50156 8.79997V5.45481H7.50156V8.79997H8.50156ZM13.9016 7.99997C13.9016 11.2585 11.26 13.9 8.00156 13.9V14.9C11.8123 14.9 14.9016 11.8107 14.9016 7.99997H13.9016ZM8.00156 13.9C4.74308 13.9 2.10156 11.2585 2.10156 7.99997H1.10156C1.10156 11.8107 4.1908 14.9 8.00156 14.9V13.9ZM2.10156 7.99997C2.10156 4.74149 4.74308 2.09998 8.00156 2.09998V1.09998C4.1908 1.09998 1.10156 4.18921 1.10156 7.99997H2.10156ZM8.00156 2.09998C11.26 2.09998 13.9016 4.74149 13.9016 7.99997H14.9016C14.9016 4.18921 11.8123 1.09998 8.00156 1.09998V2.09998Z"
                      fill="#475467"
                    />
                  </svg>
                  <span
                    class="siz-14"
                    v-if="meeting_details && checkRoute('before_start')"
                  >
                    {{ formatTime(meeting_details.meeting_time) }}
                  </span>
                  <span
                    class="siz-14"
                    v-if="
                      checkRoute('started_meeting') ||
                      checkRoute('meeting_summary')
                    "
                  >
                    {{ formatTime(meeting_details.start_time) }}
                  </span>
                  <span
                    class="siz-14"
                    v-if="
                      (meeting_details && checkRoute('editMeetingMinutes')) ||
                      checkRoute('endedMeeting')
                    "
                  >
                    {{ formatTime(meeting_details.start_time) }}
                  </span>
                  <span
                    v-if="
                      checkRoute('editMeetingMinutes') ||
                      checkRoute('endedMeeting')
                    "
                    >-</span
                  >
                  <span
                    class="siz-14"
                    v-if="
                      (meeting_details && checkRoute('editMeetingMinutes')) ||
                      checkRoute('endedMeeting')
                    "
                  >
                    {{ formatTime(meeting_details.end_time) }}
                  </span>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- attendence -->
        <v-expansion-panel
          color="transparent"
          class="bg-transparent"
          elevation="0"
        >
          <v-expansion-panel-title
            hide-actions
            class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
            elevation="0"
          >
            <v-btn elevation="0" class="icone-arrow white-cir"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                  stroke="#0A0A0A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <div class="expansion-label">
              {{ $t("attendance") }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="mb-8">
            <attendanceDialog
              v-if="isMemberOwner"
              :meeting_id="meeting_details.meeting_id"
              :members="attendace"
              :before_start="checkRoute('before_start')"
              @refreshAttendance="getAttendance"
            />
            <div v-else class="d-flex justify-center flex-column gap-20">
              <v-btn
                v-if="meeting_details?.member?.status === 'Pending'"
                elevation="0"
                class="justify-center px-4 py-4 bg-primary rounded-lg delete-btn d-flex align-center"
                height="53"
                @click="submitAttendance('Confirmed')"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-3"
                >
                  <path
                    d="M14.8307 2.66669H6.66404C4.73106 2.66669 3.16406 4.23368 3.16406 6.16667V17.8334C3.16406 19.7664 4.73106 21.3334 6.66404 21.3334H18.3306C20.2636 21.3334 21.8306 19.7664 21.8306 17.8334V11.4166M20.664 5.58334L12.4973 13.75L10.164 11.4166"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {{ $t("acceptAttendMeeting") }}
              </v-btn>
              <div
                v-if="meeting_details?.member?.status === 'Confirmed'"
                class="bg-dark-gray pa-3 px-6 text-center rounded-lg text-green"
              >
                {{ $t("accepted") }}
              </div>
              <v-btn
                v-if="meeting_details?.member?.status === 'Pending'"
                elevation="0"
                class="justify-center px-4 py-4 bg-red rounded-lg delete-btn d-flex align-center"
                height="53"
                @click="submitAttendance('Apologized')"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="me-3"
                >
                  <g opacity="0.9">
                    <path
                      d="M8.90156 15.6L16.1016 8.40002M12.5016 21.6C7.19963 21.6 2.90156 17.302 2.90156 12C2.90156 6.69809 7.19963 2.40002 12.5016 2.40002C17.8035 2.40002 22.1016 6.69809 22.1016 12C22.1016 17.302 17.8035 21.6 12.5016 21.6Z"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
                {{ $t("apologyAttendMeeting") }}
              </v-btn>
              <div
                v-if="meeting_details?.member?.status === 'Apologized'"
                class="bg-dark-gray pa-3 px-6 text-center rounded-lg text-red"
              >
                {{ $t("hasAplogized") }}
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- actions -->
        <v-expansion-panel
          color="transparent"
          class="bg-transparent"
          elevation="0"
          v-if="!checkRoute('editMeetingMinutes')"
        >
          <v-expansion-panel-title
            hide-actions
            class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
            elevation="0"
          >
            <v-btn elevation="0" class="icone-arrow white-cir"
              ><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                  stroke="#0A0A0A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
            <div class="expansion-label">
              {{ $t("actions") }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="mb-8">
            <div class="meeting-sidebar">
              <div class="mb-4 side-act">
                <div class="gap-16 btns d-flex flex-column">
                  <button
                    v-if="isMemberOwner && checkRoute('before_start')"
                    @click="
                      this.$router.push(
                        `/${entity_id}/${space_id}/${meeting_details.meeting_id}/edit`
                      )
                    "
                    class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 4.00012L16 7.00012M3.5 13.0001L13.3595 2.79631C14.4211 1.73473 16.1422 1.73473 17.2038 2.79631C18.2654 3.85789 18.2654 5.57906 17.2038 6.64064L7 16.5001L2 18.0001L3.5 13.0001Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    {{ $t("editTheMeeting") }}
                  </button>
                  <delete-dialog
                    v-if="isMemberOwner && checkRoute('before_start')"
                    :id="space_id"
                    :btnTitle="'deleteMeeting'"
                    :headerTitle="'deleteMeeting'"
                    :checkboxLabel="'confirmTodeleteMeeting'"
                    :deleteReasonText="'deleteMeetingReason'"
                    :deleteReasonLable="'deleteReasonLable'"
                    @delete="deleteMeeting"
                    :textAndIcon="true"
                  />
                  <cancel-meeting-dialog
                    v-if="isMemberOwner && checkRoute('before_start')"
                    :meeting_id="meeting_details.meeting_id"
                  />

                  <end-meeting-dialog
                    v-if="isMemberOwner && checkRoute('started_meeting')"
                    :meeting_id="meeting_id"
                    :textAndIcon="true"
                    :isMemberOwner="isMemberOwner"
                  />
                  <v-btn
                    elevation="0"
                    class="justify-center px-4 py-4 bg-white rounded-lg delete-btn d-flex align-center"
                    height="53"
                    link
                    :to="`/${entity_id}/${space_id}/meetings/meeting-logs/${meeting_id}`"
                  >
                    <svg
                      class="me-2"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.0979 10C17.0979 14.4183 13.5152 18 9.09582 18C4.6764 18 1.09375 14.4183 1.09375 10C1.09375 5.58172 4.6764 2 9.09582 2C12.0577 2 14.6438 3.60879 16.0274 6M14.9027 10.8779L16.9032 8.87792L18.9037 10.8779M12.0937 12.1814L9.09375 11.1814V7"
                        stroke="#475467"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {{ $t("recordMeetingOperations") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import attendanceDialog from "@/components/ui/dialogs/attendanceDialog.vue";
import endMeetingDialog from "@/components/ui/dialogs/endMeetingDialog.vue";
import deleteDialog from "@/components/ui/dialogs/deleteDialog";
import cancelMeetingDialog from "@/components/ui/meetingAgenda/cancelMeetingDialog.vue";

import { ai } from "@/plugins/axios";

export default {
  components: {
    attendanceDialog,
    endMeetingDialog,
    deleteDialog,
    cancelMeetingDialog,
  },

  props: {
    meeting_details: {
      default: [],
    },
    isMemberOwner: {
      default: false,
    },
  },
  data() {
    return {
      panel: [0],
      attendace: [],
      canCopy: false,
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
    };
  },
  watch: {
    meeting_details: function (val) {
      if (val) {
        this.getAttendance();
      }
    },
  },
  methods: {
    async getAttendance() {
      try {
        const response = await ai.get(
          "method/agenda.api.meeting.meeting_attendee.get_meeting_attendance",
          {
            params: {
              meeting_id: this.meeting_details?.meeting_id,
            },
          }
        );
        this.attendace = response.data.message;
      } catch (error) {
        console.log(error);
      }
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
    },
    formatTime(timeString) {
      if (timeString) {
        const [hours, minutes, seconds] = timeString.split(":");
        const date = new Date(0);

        // Convert hours, minutes, and seconds to numbers
        date.setUTCHours(Number(hours));
        date.setUTCMinutes(Number(minutes));
        date.setUTCSeconds(Number(seconds));

        // Format the time as "hh:mm A"
        const formattedTime = date.toLocaleTimeString(this.lang, {
          hour: "2-digit",
          minute: "2-digit",
        });

        console.log(
          formattedTime.replace(/([0-9]{2}:[0-9]{2}):[0-9]{2}/, "$1")
        );

        return formattedTime.replace(/([0-9]{2}:[0-9]{2}):[0-9]{2}/, "$1");
      }
    },
    async deleteMeeting() {
      this.loading = true;
      try {
        await ai.delete("method/agenda.api.meeting.meeting.delete_meeting", {
          params: {
            meeting_id: this.meeting_details.meeting_id,
          },
        });
        this.loading = false;
        this.$router.push(`/${this.entity_id}/${this.space_id}/meetings`);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    checkRoute(routeName) {
      if (this.$route.name == routeName) {
        const status = true;
        return status;
      } else {
        const status = false;
        return status;
      }
    },
    async submitAttendance(status) {
      try {
        ai.put(
          "method/agenda.api.meeting.meeting_attendee.manage_meeting_attendance",
          {
            meeting_id: this.meeting_details.meeting_id,
            status: status,
          }
        );
        console.log("status", status);
        this.$emit("refresh", true);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.member_id = JSON.parse(localStorage.getItem("user"))?.member_id;
  },
  async created() {
    this.canCopy = !!navigator.clipboard;
  },
};
</script>
