<template>
  <div
    class="justify-center gap-20 counter-container align-center d-flex w-100"
    :class="justify ? 'justify-space-between' : 'justify-space-between'"
  >
    <transition name="fade" mode="out-in">
      <div
        class="flex-shrink-0 opacity-0 counter-btn pa-0 ma-0"
        elevation="0"
        @click="incrementCounter"
        v-if="!showControllers"
      >
        .
      </div>
      <v-btn
        class="flex-shrink-0 counter-btn squer-rouneded-btn bg-light-gray pa-0 ma-0"
        elevation="0"
        @click="incrementCounter"
        v-else
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </transition>

    <div
      class="justify-center gap-5 d-flex align-center shirnk-0 counter-persantage"
    >
      <!-- {{ counter }}  -->
      <input
        class="counter-input-w"
        type="number"
        v-model="counter"
        @change="updatedCounterType"
      />

      <span v-if="pesentageSgin">%</span>
    </div>

    <transition name="fade" mode="out-in">
      <div
        class="flex-shrink-0 opacity-0 counter-btn pa-0 ma-0"
        elevation="0"
        @click="incrementCounter"
        v-if="!showControllers"
      ></div>
      <v-btn
        v-else
        class="flex-shrink-0 counter-btn squer-rouneded-btn bg-light-gray pa-0 ma-0"
        elevation="0"
        @click="decrementCounter"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    percentage: {
      type: Number,
    },
    pesentageSgin: {
      type: Boolean,
      default: true,
    },
    justify: {
      type: Boolean,
      default: false,
    },
    showControllers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      counter: 0,
      // showControllers: false,
    };
  },
  updated() {
    this.counter = this.percentage;
  },
  methods: {
    incrementCounter() {
      if (this.counter < 100) {
        this.counter++;
        this.$emit("percentage", this.counter);
      }
    },
    decrementCounter() {
      if (this.counter > 0) {
        this.counter--;
        this.$emit("percentage", this.counter);
      }
    },
    updatedCounterType() {
      // if percentageSgin is true make max of input 100
      if (this.pesentageSgin) {
        if (this.counter > 100) {
          this.counter = 100;
        }
      }
    },
  },

  mounted() {
    if (this.percentage) {
      this.counter = this.percentage;
    }
  },
};
</script>

<style lang="scss" scoped>
.counter-input-w {
  max-width: 30px;
  direction: ltr;
}
.counter-container {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  .counter-btn {
    width: 33px !important;
    height: 33px !important;
    min-width: 33px !important;
    min-height: 33px !important;
    // opacity: 0;
    // display: none;
  }
}

.counter-persantage {
  // width: 33px !important;
  // height: 33px !important;
  min-width: 38px !important;
  min-height: 33px !important;
  margin-top: 6px;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  // add trasition
  // transition: opacity 0.2s  linear ;
}

.slideR-enter-active,
.slideR-leave-active {
  transition: transform 0.2s linear;
}

.slideR-enter-from,
.slideR-leave-to {
  transform: translateX(100%);
}
.slideL-enter-active,
.slideL-leave-active {
  transition: transform 0.2s linear;
  // change opacity
  transition: opacity 0.2s linear;
}

.slideL-enter-from,
.slideL-leave-to {
  transform: translateX(-100%);
  // change opacity
  opacity: 0;
}

@media (max-width: 1200px) {
  .counter-persantage {
    font-size: 16px;
    width: 35px !important;
    min-width: 35px !important;
    min-height: 30px !important;
  }
  .counter-container {
    padding-block-start: 10px;
    padding-block-end: 10px;
    gap: 10px;
    .counter-btn {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
      min-height: 30px !important;
      img {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .counter-persantage {
    font-size: 14px;
    width: 30px !important;
    min-width: 30px !important;
    min-height: 25px !important;
  }
  .counter-container {
    padding-block-start: 10px;
    padding-block-end: 10px;
    gap: 10px;
    .counter-btn {
      width: 25px !important;
      height: 25px !important;
      min-width: 25px !important;
      min-height: 25px !important;
      img {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .counter-persantage {
    font-size: 10px;
    width: 25px !important;
    min-width: 25px !important;
    min-height: 22px !important;
  }
  .counter-container {
    padding-block-start: 10px;
    padding-block-end: 10px;
    gap: 8px;
    .counter-btn {
      width: 20px !important;
      height: 20px !important;
      min-width: 20px !important;
      min-height: 20px !important;
      img {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }
}
@media (max-width: 600px) {
  .counter-persantage {
    font-size: 16px;
    width: 35px !important;
    min-width: 35px !important;
    min-height: 30px !important;
  }
  .counter-container {
    padding-block-start: 10px;
    padding-block-end: 10px;
    gap: 20px;
    .counter-btn {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
      min-height: 30px !important;
      img {
        width: 18px !important;
        height: 18px !important;
      }
    }
  }
}
</style>
