import { createRouter, createWebHistory, viewDepthKey } from "vue-router";
import myEntities from "@/views/myEntities.vue";
import notifications from "@/views/notifications.vue";
import personalSpaces from "@/views/user/personal-space/personalSpaces.vue";
import members from "@/views/space/members/members.vue";
import addSpaceMember from "@/views/space/members/add.vue";
import memberProfile from "@/views/space/members/profile.vue";
import tasks from "@/views/space/tasks/list.vue";
import addTask from "@/views/space/tasks/add.vue";
import editTask from "@/views/space/tasks/edit.vue";
import taskDetails from "@/views/space/tasks/view.vue";
import shareholdersList from "@/views/space/shareholders/List.vue";
import viewShareholder from "@/views/space/shareholders/View.vue";
import addShareholder from "@/views/space/shareholders/Add.vue";
import editShareholder from "@/views/space/shareholders/edit.vue";
import boardLayout from "@/layout/boardLayout.vue";
import meetingLayout from "@/layout/meetingLayout.vue";
import authLayout from "@/layout/authLayout.vue";
import launchpadLayout from "@/layout/launchpadLayout.vue";
import logoCentered from "@/layout/logoCentered.vue";
const routes = [
  // launchpad
  {
    path: "/",
    name: "launchpad",
    meta: {
      layout: launchpadLayout,
      authGard: true,
    },
    component: () => import("@/views/launghPad.vue"),
  },
  // {
  //   path: "/",
  //   name: "home",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },

  //   component: () => import("@/views/space/company-panal/home"),
  // },
  // mangment for space
  {
    path: "/:entity_id/:space_id",
    component: () => import("@/views/space/workspace/mainSpaceHome.vue"),
    children: [
      {
        path: "",
        name: "space",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/workspace/space.vue"),
      },
      {
        path: "settings",
        name: "SpaceSetting",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () =>
          import("@/views/space/space-settings/SpaceSetting.vue"),
      },
      {
        path: "logs",
        name: "spaceLogs",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/company-panal/spaceLogs.vue"),
      },
      {
        path: "rotations",
        name: "rotations",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/rotations/list.vue"),
      },
      {
        path: "rotations/add",
        name: "addRotaion",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/rotations/addRotation.vue"),
      },
      {
        path: "rotation/:id",
        name: "editRotation",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/rotations/updateRotation"),
      },
      {
        path: "info",
        name: "viewSpace",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/info/view"),
      },
      {
        path: "info/edit",
        name: "editSpace",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/info/edit"),
      },
      {
        path: "roles",
        name: "roles",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/roles/list.vue"),
      },
      {
        path: "roles/:role",
        name: "updatedRole",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/roles/update.vue"),
      },
      {
        path: "roles/add_role_permissions",
        name: "addRolePermissions",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/roles/add.vue"),
      },
      {
        path: "recycle",
        name: "recycle",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/company-panal/recycle.vue"),
      },
      {
        path: "customize",
        name: "customize",
        meta: {
          layout: boardLayout,
          authGard: true,
        },
        component: () => import("@/views/space/space-settings/customize.vue"),
      },
      {
        path: "governance",
        name: "GovernanceSettings",
        meta: {
          layout: boardLayout,
          authGard: true,
        },

        component: () =>
          import("@/views/space/governance-settings/GovernanceSettings"),
      },
    ],
  },
  // mangment for entity
  {
    path: "/:entity_id/settings/list",
    name: "entitySettings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/settings"),
  },
  // mangment for account
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/space/profile/profile.vue"),
  },
  {
    path: "/profile/edit",
    name: "editProfile",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/space/profile/editProfile.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/test.vue"),
  },

  // end mangment
  {
    path: "/account-settings",
    name: "accountSettings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/settings/accountSettings.vue"),
  },

  {
    path: "/settings",
    name: "settings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/settings/settings.vue"),
  },
  {
    path: "/settings/edit",
    name: "editSettings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/settings/editSettings.vue"),
  },

  // {
  //   path: "/:entity_id/Subscription",
  //   name: "SubscriptionViews",
  //   meta: {
  //     layout: boardLayout,
  //   },
  //   //component: () =>
  //     import(
  //        "@/views/SubscriptionViews.vue"
  //     ),
  // },

  // -------------------------- end development reference  routes ------------

  // -------------------------- start workspace --------------------------
  {
    path: "/:entity_id",
    name: "entity",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/workspace/entity_details.vue"),
  },

  // -------------------------- end workspace --------------------------

  // -------------------------- start addDelegation --------------------------
  {
    path: "/:entity_id/:space_id/delegations",
    name: "delegationList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/delegation/list.vue"),
  },
  {
    path: "/:entity_id/:space_id/delegations/add",
    name: "addDelegation",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/delegation/add.vue"),
  },

  // -------------------------- start vistor routes --------------------------

  {
    path: "/contact-us",
    name: "contact",
    meta: {
      layout: logoCentered,
      authGard: false,
    },

    component: () => import("@/views/contact-us.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    meta: {
      layout: logoCentered,
      authGard: false,
    },
    component: () => import("@/views/terms-and-conditions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    meta: {
      layout: logoCentered,
      authGard: false,
    },
    component: () => import("@/views/privacy-policy.vue"),
  },

  // -------------------------- end vistor routes -------------------------

  // -------------------------- start auth routes --------------------------

  {
    path: "/login",
    name: "login",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () => import("@/views/auth/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () => import("@/views/auth/register/register.vue"),
  },
  {
    path: "/auth/restore-account",
    name: "restoreAccount",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () => import("@/views/auth/restore-account.vue"),
    children: [
      {
        path: "",
        name: "restoreBy",
        meta: {
          layout: authLayout,
        },
        component: () => import("@/views/auth/forget-password/restore-by.vue"),
      },
      {
        path: "restore-by-phone",
        name: "restoreByPhone",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/restore-by-phone.vue"),
      },
      {
        path: "restore-by-email",
        name: "restoreByEmail",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/restore-by-email.vue"),
      },
      {
        path: "verification-code-by-mail",
        name: "VerificationCodeByMail",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/verification-code-by-mail.vue"),
      },
      {
        path: "verification-code-by-phone",
        name: "VerificationCodeByphone",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/verification-code-by-phone.vue"),
      },
      {
        path: "reset-password",
        name: "resetPassword",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/reset-password.vue"),
      },
      {
        path: "reset-password-sucess",
        name: "resetPasswordSucess",
        meta: {
          layout: authLayout,
        },
        component: () =>
          import("@/views/auth/forget-password/reset-password-sucess.vue"),
      },
    ],
  },

  {
    path: "/register/password",
    name: "registerPassword",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () => import("@/views/auth/register/password.vue"),
  },
  {
    path: "/register/verification-code-register",
    name: "verificationCodeRegister",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () =>
      import("@/views/auth/register/verification-code-register.vue"),
  },
  {
    path: "/register/register-sucess",
    name: "registerSucess",
    meta: {
      layout: authLayout,
      authGard: false,
    },
    component: () => import("@/views/auth/register/register-sucess.vue"),
  },

  {
    path: "/personal-logs",
    name: "personalLogs",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/personalLogs.vue"),
  },
  {
    path: "/view/profile/as-vistor/:member_id",
    name: "profilePreview",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/user-info/profilePreview.vue"),
  },
  {
    path: "/:member_id/profile-customize",
    name: "profileCustomize",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/user-info/profileCustomize.vue"),
  },
  // personal-space
  {
    path: "/personal-space/add",
    name: "addPersonalSpace",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import(
        "@/views/user/personal-space/addPersonalSpace/addPersonalSpace.vue"
      ),
  },
  {
    path: "/personal-space/add/form",
    name: "addPersonalSpaceTwo",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import(
        "@/views/user/personal-space/addPersonalSpace/addPersonalSpaceTwo.vue"
      ),
  },
  // personal-space

  {
    path: "/add_entity",
    name: "addEntity",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/company-panal/addEntity.vue"),
  },

  {
    path: "/:entity_id/:space_id/news",
    name: "spaceNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/news/List.vue"),
  },
  {
    path: "/:entity_id/:space_id/news/add",
    name: "addSpaceNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/news/Add.vue"),
  },
  {
    path: "/:entity_id/:space_id/news/edit/:news_article_id",
    name: "spaceEditNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/news/Edit.vue"),
  },
  {
    path: "/:entity_id/:space_id/news/view/:news_article_id",
    name: "spaceViewNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/news/View.vue"),
  },
  {
    path: "/:entity_id/:space_id/space_profile",
    name: "spaceProfile",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/board/spaceProfile.vue"),
  },

  // items
  {
    path: "/:entity_id/:space_id/items",
    name: "itemsList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/items/List.vue"),
  },
  {
    path: "/:entity_id/:space_id/items/add",
    name: "addItems",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/items/add.vue"),
  },
  {
    path: "/:entity_id/:space_id/items/:agenda_id",
    name: "viewItems",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/items/view.vue"),
  },
  {
    path: "/:entity_id/:space_id/recommendations",
    name: "recommendationsList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/recommendations/List.vue"),
  },
  {
    path: "/:entity_id/:space_id/recommendations/view/:recommendation_id",
    name: "viewRecommendation",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/recommendations/View.vue"),
  },
  {
    path: "/:entity_id/:space_id/recommendations/add",
    name: "addNewRecommendation",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/recommendations/add.vue"),
  },
  {
    path: "/:entity_id/:space_id/recommendations/edit/:recommendation_id",
    name: "editRecommendation",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/recommendations/edit.vue"),
  },
  //===================== strat meeting pages ==========================
  {
    path: "/:entity_id/:space_id/meetings/add",
    name: "add_meeting",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/meetingSetup.vue"),
  },
  {
    path: "/:entity_id/:space_id/meetings/suggest-meeting",
    name: "meetingProposal",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/meetingProposal.vue"),
  },

  {
    path: "/:entity_id/:space_id/suggested-meetings/:suggested_meeting_id",
    name: "meetingProposalView",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/meetingProposalView.vue"),
  },
  {
    path: "/:entity_id/:space_id/complete-suggested-meeting/:suggested_meeting_id",
    name: "completeSuggestedMeeting",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/meetings/completeSuggestedMeeting.vue"),
  },
  {
    path: "/:entity_id/:space_id/meetings",
    name: "meetings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/meetings.vue"),
  },
  // meeting flow
  // meetingLayout

  // ------------------
  {
    path: "/:entity_id/:space_id/meetings/start-meeting/:meeting_id",
    name: "before_start",
    meta: {
      layout: meetingLayout,
    },

    component: () =>
      import("@/views/space/meetings/current-meeting-items/before_start.vue"),
  },
  {
    path: "/:entity_id/:space_id/meetings/start-meeting/current-meeting/:meeting_id",
    name: "started_meeting",
    meta: {
      layout: meetingLayout,
    },

    component: () =>
      import(
        "@/views/space/meetings/current-meeting-items/started_meeting.vue"
      ),
  },
  {
    path: "/:entity_id/:space_id/meetings/meeting_summary/:meeting_id",
    name: "meeting_summary",
    meta: {
      layout: meetingLayout,
    },

    component: () =>
      import(
        "@/views/space/meetings/current-meeting-items/meeting_summary.vue"
      ),
  },
  {
    path: "/:entity_id/:space_id/meetings/meeting-logs/:meeting_id",
    name: "meetingLogs",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/meetings/current-meeting-items/meetingLogs.vue"),
  },
  {
    path: "/:entity_id/:space_id/meetings/ended-meeting/:meeting_id",
    name: "endedMeeting",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/endedMeeting.vue"),
  },

  {
    path: "/:entity_id/:space_id/:meeting_id/edit",
    name: "editMeeting",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/meetings/editMeeting.vue"),
  },

  {
    path: "/:entity_id/:space_id/meetings/meeting-send-to-members/:meeting_id",
    name: "meetingSendToMembers",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () =>
      import(
        "@/views/space/meetings/current-meeting-items/meetingSendToMembers.vue"
      ),
  },
  {
    path: "/:entity_id/:space_id/meetings/admin/confirm-attendance/:id",
    name: "adminconfirmAttendance",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () =>
      import("@/views/space/meetings/adminConfirmAttendance.vue"),
  },
  {
    path: "/:entity_id/:space_id/meetings/meeting-minutes/:meeting_minutes_id",
    name: "meeting_minutes",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () =>
      import(
        "@/views/space/meetings/current-meeting-items/meeting_minutes.vue"
      ),
  },
  {
    path: "/:entity_id/:space_id/meetings/meeting-minutes/:meeting_minutes_id/edit",
    name: "editMeetingMinutes",
    meta: {
      layout: meetingLayout,
    },
    component: () =>
      import(
        "@/views/space/meetings/current-meeting-items/editMeetingMinutes.vue"
      ),
  },
  // -------------------------- start votes routes --------------------------

  {
    path: "/:entity_id/:space_id/voting",
    name: "votesList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/space/votes/List"),
  },
  {
    path: "/:entity_id/:space_id/voting/:voting_id",
    name: "viewVote",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/votes/View.vue"),
  },
  {
    path: "/:entity_id/:space_id/voting/add",
    name: "addVote",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/space/votes/Add.vue"),
  },
  {
    path: "/:entity_id/:space_id/voting/:voting_id/edit",
    name: "editVote",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
    component: () => import("@/views/space/votes/Edit.vue"),
  },

  // -------------------------- end votes routes --------------------------
  // -------------------------- start sh routes --------------------------
  {
    path: "/:entity_id/:space_id/shareholders",
    name: "shareholdersList",
    component: shareholdersList,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/shareholders/view/:shareholder_id",
    name: "viewShareholder",
    component: viewShareholder,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  ,
  {
    path: "/:entity_id/:space_id/shareholders/add",
    component: addShareholder,
    name: "addShareholder",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/shareholders/edit/:shareholder_id",
    component: editShareholder,
    name: "editShareholder",
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },

  // -------------------------- end sh routes --------------------------
  // -------------------------- start members routes  space --------------------------
  {
    path: "/:entity_id/:space_id/members",
    name: "members",
    component: members,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/members/add",
    name: "addSpaceMember",
    component: addSpaceMember,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/members/:id",
    name: "memberProfile",
    component: memberProfile,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  // -------------------------- end votes routes --------------------------

  // -------------------------- start tasks routes --------------------------

  {
    path: "/:entity_id/:space_id/tasks",
    name: "tasks",
    component: tasks,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/tasks/add",
    name: "addTask",
    component: addTask,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/tasks/edit/:task_id",

    name: "editTask",
    component: editTask,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/:entity_id/:space_id/tasks/:task_id",
    name: "taskDetails",
    component: taskDetails,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },

  {
    path: "/my-entities",
    name: "myEntities",
    component: myEntities,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: notifications,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  {
    path: "/personal-spaces",
    name: "personalSpaces",
    component: personalSpaces,
    meta: {
      layout: boardLayout,
      authGard: true,
    },
  },
  // -------------------------- end tasks routes --------------------------

  // -------------------------- start files routes --------------------------

  {
    path: "/:entity_id/:space_id/:folder_id/filesList",
    name: "filesList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/files/filesList.vue"),
  },

  // -------------------------- end files routes --------------------------

  // -------------------------- start discussions routes --------------------------
  {
    path: "/:entity_id/:space_id/files/edit",
    name: "editFileLog",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/files/editFileLog.vue"),
  },
  {
    path: "/:entity_id/:space_id/folders",
    name: "folders",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/files/folders.vue"),
  },
  // start discustions
  {
    path: "/:entity_id/:space_id/discussions",
    name: "discussionsList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/discussions/List.vue"),
  },
  {
    path: "/:entity_id/:space_id/discussions/add",
    name: "addDiscussions",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/discussions/add.vue"),
  },
  {
    path: "/:entity_id/:space_id/discussions/details/:discussion_id",
    name: "discussionsDetails",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/discussions/discussionsDetails.vue"),
  },

  {
    path: "/:entity_id/:space_id/calender/table",
    name: "calenderTable",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/calender/table.vue"),
  },
  {
    path: "/:entity_id/:space_id/calender/timeline",
    name: "timeline",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/calender/timeLine.vue"),
  },

  {
    path: "/:entity_id/:space_id/sub-spaces",
    name: "subSpaces",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/sub-space/list.vue"),
  },
  {
    path: "/:entity_id/create/add_space",
    name: "addNewSpace",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/new-space/addNewSpace.vue"),
  },
  {
    path: "/:entity_id/create/add_space/step-two",
    name: "addNewSpaceStepTwo",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/new-space/stepTwo.vue"),
  },
  {
    path: "/:entity_id/my-spaces",
    name: "mySpaces",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/spaces/mySpaces.vue"),
  },

  {
    path: "/:entity_id/sub_space/:space_id",
    name: "viewSubSpace",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/sub-space/View.vue"),
  },
  // ======================= start groups routes =========================
  {
    path: "/:entity_id/:space_id/groups",
    name: "groups",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/groups.vue"),
  },
  {
    path: "/:entity_id/:space_id/groups/add",
    name: "addGroup",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/addGroup.vue"),
  },
  {
    path: "/:entity_id/:space_id/groups/:group_id",
    name: "groupList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/groupList.vue"),
  },
  {
    path: "/:entity_id/:space_id/groups/:group_id/edit",
    name: "editGroup",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/editGroup.vue"),
  },
  {
    path: "/:entity_id/:space_id/groups/:group_id/add-member",
    name: "addMembersToGroup",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/addMemberToGroup.vue"),
  },
  {
    path: "/:entity_id/:space_id/groups/:group_id/add-member-xls",
    name: "addMembersAsXLS",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/groups/addMembersAsXLS.vue"),
  },
  // ======================= end groups routes ===========================

  // ======================= start election routes ===========================
  {
    path: "/:entity_id/:space_id/election",
    name: "election",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/election.vue"),
  },
  {
    path: "/:entity_id/:space_id/election/add",
    name: "addNewElection",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/add.vue"),
  },
  {
    path: "/:entity_id/:space_id/election/add-member-to-committe",
    name: "addMemberToCommitte",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/addMemberToCommitte.vue"),
  },
  {
    path: "/:entity_id/:space_id/election/current-election/:election_id",
    name: "currentElection",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/currentElection.vue"),
  },
  {
    path: "/:entity_id/:space_id/election/ended-election/:election_id",
    name: "endedElection",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/endedElection.vue"),
  },
  {
    path: "/:entity_id/:space_id/election/archived-election/:election_id",
    name: "archivedElection",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/election/archivedElection.vue"),
  },
  // ======================= end election routes ===========================
  // ======================= start nomination routes ===========================
  {
    path: "/:entity_id/:space_id/nomination",
    name: "nomination",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/nomination/nomination.vue"),
  },
  {
    path: "/:entity_id/:space_id/nomination/:nomination_id",
    name: "nomination_membership",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/nomination/nomination_membership.vue"),
  },
  // acceptNominationRequset
  {
    path: "/:entity_id/:space_id/nomination/:nomination_id/:req_id",
    name: "acceptNominationRequset",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/nomination/acceptNominationRequset.vue"),
  },
  {
    path: "/:entity_id/:space_id/nomination/add",
    name: "addNomination",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/nomination/add.vue"),
  },
  {
    path: "/:entity_id/:space_id/nomination/add-election-from-nomination",
    name: "addElecionFromNomination",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/nomination/addElecionFromNomination.vue"),
  },
  {
    path: "/:entity_id/:space_id/nomination/current-nomination/:nomination_id",
    name: "currentNomination",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/nomination/currentNomination.vue"),
  },
  {
    path: "/:entity_id/:space_id/nomination/ended-nomination/:nomination_id",
    name: "endedNonimation",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/nomination/endedNonimation.vue"),
  },
  // ======================= start nomination routes ===========================
  {
    path: "/:entity_id/:space_id/add_sub_space",
    name: "addSubSpace",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/sub-space/new-sub-space/addSubSpace.vue"),
  },
  {
    path: "/:entity_id/:space_id/add_sub_space/step_2",
    name: "subSpaceStepTwo",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/space/sub-space/new-sub-space/subSpaceStepTwo.vue"),
  },

  {
    path: "/profile/user-info",
    name: "userInfo",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/user-info/userInfo"),
  },

  {
    path: "/profile/edit-info",
    name: "EditInfo",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/user/user-info/editInfo.vue"),
  },

  //-------------------------- end governance settings routes --------------------------

  // -------------------------- start data routes -----------------------
  // -------------------------- start space file -----------------------
  {
    path: "/:entity_id/:space_id/space-file",
    name: "spaceFile",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/spaceFile.vue"),
  },

  //-------------------------- end data routes --------------------------

  // -------------------------- start goals routes -----------------------
  {
    path: "/:entity_id/:space_id/goals",
    name: "goals",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/goals/goals.vue"),
  },

  //-------------------------- end goals routes --------------------------

  // -------------------------- start initiatives routes -----------------------
  {
    path: "/:entity_id/:space_id/initiatives",
    name: "initiatives",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/templates/initiativeView.vue"),
  },

  //-------------------------- end initiatives routes --------------------------

  // -------------------------- start insights routes -----------------------
  {
    path: "/:entity_id/:space_id/insights",
    name: "insights",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/templates/insightView.vue"),
  },

  //-------------------------- end insights routes --------------------------

  // -------------------------- start notifications routes -----------------------
  // {
  //   path: "/:entity_id/:space_id/notifications",
  //   name: "notifications",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },

  //   component: () => import("@/views/space/templates/notificationView.vue"),
  // },

  //-------------------------- end notifications routes --------------------------

  // -------------------------- start strategy routes -----------------------
  {
    path: "/:entity_id/:space_id/strategy",
    name: "strategy",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/space/templates/strategyView.vue"),
  },

  //-------------------------- end strategy routes --------------------------

  // ========================= start entity list routes ======================
  {
    path: "/:entity_id/delegations/list",
    name: "entityDelegations",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/delegations"),
  },
  {
    path: "/:entity_id/discussions/list",
    name: "entityDiscussions",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/discussions"),
  },
  {
    path: "/:entity_id/items/list",
    name: "entityItems",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/items"),
  },
  {
    path: "/:entity_id/meetings/list",
    name: "entityMeetings",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/meetings"),
  },
  {
    path: "/:entity_id/members/list",
    name: "entityMembers",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/members/members"),
  },
  {
    path: "/:entity_id/members/:id",
    name: "entityMemberProfile",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/members/memberInfo"),
  },
  {
    path: "/:entity_id/members/add",
    name: "addEntityMember",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/members/add"),
  },
  {
    path: "/:entity_id/subscriptionView",
    name: "subscriptionView",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/entity/subscriptions/subscreption_view.vue"),
  },
  {
    path: "/:entity_id/subscriptions-bills",
    name: "subscriptionBills",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () =>
      import("@/views/entity/subscriptions/subscriptionBills.vue"),
  },
  {
    path: "/:entity_id/payment-data",
    name: "paymentData",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/subscriptions/paymentData.vue"),
  },
  {
    path: "/:entity_id/payment",
    name: "payment",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/subscriptions/payment.vue"),
  },

  // ------------------------- start entity news routes----------------------
  {
    path: "/:entity_id/news/list",
    name: "entityNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/news/List.vue"),
  },
  {
    path: "/:entity_id/news/add",
    name: "addEntityNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/news/Add.vue"),
  },
  {
    path: "/:entity_id/news/edit/:news_article_id",
    name: "entityEditNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/news/Edit.vue"),
  },
  {
    path: "/:entity_id/news/view/:news_article_id",
    name: "entityViewNews",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/news/View.vue"),
  },
  // ------------------------- end entity news routes----------------------

  // ------------------------- start entity recommendations routes-------------------
  {
    path: "/:entity_id/recommendations/list",
    name: "entityRecommendations",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/recommendations"),
  },
  {
    path: "/:entity_id/roles/list",
    name: "entityRoles",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/roles"),
  },
  {
    path: "/:entity_id/rotations/list",
    name: "entityRotations",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/rotations"),
  },
  {
    path: "/:entity_id/shareholders/list",
    name: "entityShareholders",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/shareholders"),
  },
  {
    path: "/:entity_id/tasks/list",
    name: "entityTasksList",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/tasks"),
  },
  {
    path: "/:entity_id/voting/list",
    name: "entityVotes",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/voting"),
  },
  {
    path: "/:entity_id/logs/list",
    name: "entityLogs",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/list/entityLogs"),
  },

  {
    path: "/:entity_id/customize",
    name: "entityCustomize",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/workspace/entityCustomize"),
  },
  {
    path: "/:entity_id/entity_data/list",
    name: "viewEntityData",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/info/viewEntityData"),
  },
  {
    path: "/:entity_id/entity_data/edit",
    name: "editEntityData",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/info/editEntityData"),
  },
  {
    path: "/:entity_id/calender/table",
    name: "entityCalender",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/calender/table"),
  },
  {
    path: "/:entity_id/calender/timeline",
    name: "entityTimeLine",
    meta: {
      layout: boardLayout,
      authGard: true,
    },

    component: () => import("@/views/entity/calender/timeLine"),
  },
  // sub spaces

  // ------------------------- end entity data routes ----------------------
  // ------------------------- end entity routes --------------------------

  // -------------------------- start default routes --------------------------

  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    meta: {
      layout: logoCentered,
      authGard: false,
    },

    component: () => import("@/views/default-pages/404.vue"),
  },
  {
    path: "/offline",
    name: "offline",
    meta: {
      layout: logoCentered,
      authGard: false,
    },

    component: () => import("@/views/default-pages/NoInternetPage.vue"),
  },
];

const router = createRouter(
  // process.env.VUE_APP_BASE_URL ||
  {
    history: createWebHistory("/"),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
    },
    pathToRegexpOptions: {
      sensitive: false,
    },
  }
);
router.beforeEach((to, from, next) => {
  if (to.matched.some((auth) => auth.meta.authGard)) {
    if (!localStorage.getItem("user")) {
      // next(from);
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
