<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0 w"
        elevation="0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.99868 8.99975H9.11985M17.9995 21.6L11.3068 15.0024C10.0116 15.5043 8.46418 15.3072 7.02665 14.8866C3.60311 13.8851 1.65137 10.3374 2.66732 6.96247C3.68327 3.58758 7.28219 1.66357 10.7057 2.66508C14.1293 3.6666 16.081 7.21437 15.0651 10.5893L14.9057 11.4546L21.5984 18.0522V21.6H17.9995Z"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              @click="dialog = false"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("changeMemberRole") }}
            </div>
          </div>
          <v-btn
            color="primary"
            class="new-plus ios-r"
            elevation="0"
            :loading="loading"
            @click="changeRole()"
          >
            <div class="siz-14 me-2">
              {{ $t("save") }}
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </inner-header>
      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="mt-5 mb-10 border auth-form rounded-4">
          <!-- {{ member }} -->
          <!-- member -->
          <div class="gap-10 d-flex pa-5 align-center">
            <img
              loading="lazy"
              :src="`https://dev.agenda.42.guru/` + member.user_image"
              class="member-pic"
              alt=""
              @error="handleBoardBrokenImage"
            />
            <div class="font-weight-bold">
              {{ member.full_name }}
            </div>
          </div>
          <!-- select -->
          <div class="border-t form-input-box position-relative">
            <label class="form-label" for="">{{ $t("role") }}</label>

            <Select2
              class="select-form w-100"
              v-model="form.role"
              :options="roles"
              :settings="{ settingOption: value, settingOption: value }"
              @change="myChangeEvent($event)"
              @select="mySelectEvent($event)"
              :placeholder="$t('role')"
              :searchable="false"
            />

            <div class="select-arrow-down">
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.20313 1.40001L4.00313 4.60001L0.803126 1.40001"
                  stroke="#0A0A0A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <!-- Fisrt Form  -->
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import Select2 from "vue3-select2-component";
import InnerHeader from "@/components/shared/InnerHeader";
import { ai } from "@/plugins/axios";

export default {
  name: "memberRole",

  components: {
    Select2,
    InnerHeader,
  },

  props: {
    member: {
      default: {},
    },
    member_id: {
      type: String,
      required: true,
    },
    member_role: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      space_id: this.$route.params.space_id,
      dialog: false,
      roles: [],

      form: {
        role: this.member_role,
      },
    };
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    handleBoardBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    async loadRoles() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.role.get_space_roles`,
          { params: { space_id: this.space_id } }
        );
        this.roles = response.data.message.roles.map((role) => {
          return {
            id: role.role_id,
            text: role.role_name,
          };
        });

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async changeRole() {
      this.loading = true;
      try {
        await ai.put("method/agenda.api.space.member.update_member_role", {
          space_id: this.space_id,
          role: this.form.role,
          member_id: this.member_id,
        });
        this.$emit("refresh");
        this.loading = false;
        this.dialog = false;
      } catch (error) {
        this.loading = true;
        this.dialog = false;
        console.log(error);
      }
    },
  },
  mounted() {
    this.loadRoles();
  },
};
</script>
