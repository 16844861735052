<template>
  <div class="updated-ele main-elements-timeline memberShipEleTime">
    <!-- {{ boards }} -->
    <div
      class="position-relative"
      v-for="boardInfo in boards"
      :key="boardInfo.space_id"
    >
      <v-timeline justify="start">
        <v-timeline-item
          v-for="e in boardInfo"
          :key="e.id"
          :class="e.status == 'Active' ? 'activeTimeLine' : ''"
          direction="horizontal"
          size="small"
        >
          <img
            loading="lazy"
            class="timeLine-image"
            :src="`https://dev.agenda.42.guru/files/` + e.entity_logo"
            @error="handleBrokenEntityImage"
            alt=""
          />

          <template v-slot:icon> </template>

          <v-card class="elevation-0">
            <div class="text-container-side">
              <div class="align-center d-flex justify-space-between">
                <!-- jop desc -->
                <div class="align-md-center d-flex d-md-flex gap-10">
                  <div class="">
                    {{ e.rotation_name }}
                  </div>

                  <div class="timeline-date ma-0">
                    {{ e.position }}
                  </div>
                </div>
                <!-- date -->
                <div class="timeline-date">
                  {{ getYear(e.rotation_start_date) }} -
                  {{ getYear(e.rotation_end_date) }}
                </div>
              </div>
              <p class="mt-2 timeline-title d-flex align-center gap-5">
                {{ e.entity_name }}
                <span class="ms-1 me-1 point">.</span>
                {{ e.entity_location }}
              </p>
            </div>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberShipTimeline",
  props: {
    boards: {
      type: Array,
      default: () => [],
    },
    boardInfo: {
      type: Object,
      // default: () => [],
    },
    fasilty: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleBrokenEntityImage(e) {
      e.target.src = require("@/assets/images/entityPlaceholder.png");
    },
    addClassFTime() {
      const fTiemLine = document.querySelectorAll(".main-elements-timeline");

      // fTiemLine.forEach((e) => {
      //   const firstEle = e.querySelectorAll(".v-timeline");
      //   firstEle[0].classList.add("gradientTop");
      //   firstEle[firstEle.length - 1].classList.add("gradientBottom");
      // });
    },
    getYear(value) {
      return value != undefined || value != null
        ? new Date(value).getFullYear()
        : "";
    },
  },
  mounted() {},
  computed: {
    entityImage() {
      return `https://dev.agenda.42.guru//` + this.boardInfo[0].entity_logo;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;

// v-timeline-divider__before
// v-timeline-divider__after

.timeline-date {
}
.memberShipEleTime {
  .v-timeline-item {
    &.activeTimeLine {
      .v-timeline-divider__dot .v-timeline-divider__inner-dot {
        background: $primary !important;
      }
    }
  }
  .v-timeline--vertical.v-timeline
    .v-timeline-item:last-child
    .v-timeline-item__body {
    padding-block-end: 0px;
  }

  .timeLine-image {
    position: absolute;
    right: 0;
    top: 11px;
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}
</style>
