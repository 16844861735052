<template>
  <div class="element-bx d-flex justify-space-between align-center">
    <div class="element-info align-center justify-space-between w-100 d-flex">
      <div class="align-center d-flex gap-10">
        <v-btn
          v-if="element?.icon"
          class="squer-rouneded-btn bg-light-gray pa-0 ma-0"
          elevation="0"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5999 6.1043L11.9999 12.7043L20.9999 6.1043M8.9999 12.6043L3.5999 18.1043M20.3999 17.6043L14.3999 12.1043M4.7999 19.0956C3.47442 19.0956 2.3999 18.0211 2.3999 16.6956V7.3043C2.3999 5.97881 3.47442 4.9043 4.7999 4.9043H19.1999C20.5254 4.9043 21.5999 5.97881 21.5999 7.3043V16.6956C21.5999 18.0211 20.5254 19.0956 19.1999 19.0956H4.7999Z"
              stroke="#475467"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
        {{ element.label }}
      </div>
      <div>
        {{ element.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableElementBox",
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    data() {
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;

.element-bx {
  a {
    color: $dark;
    transition: all 0.3s ease;

    &:hover {
      color: $primary;
    }
  }

  padding: 20px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $light;
  }

  .element-info {
    img {
      width: 24px;
      height: 24px;
    }

    .element-name {
      font-size: 12px;
      color: $label-gray;
    }
  }
}
</style>
