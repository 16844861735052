<template>
  <div class="flex m-10 voting-transition">
    <!-- {{ list }} -->
    <!-- <draggable
      class="w-full dragArea list-group"
      :list="list"
      @change="log"
      transition="fade"
      :transition-mode="{ onMove: 'bounce' }"
    > -->
    <div v-for="(vote, index) in list" :key="index" class="vote-box">
      <div class="gap-10 vote-info justify-space-between align-center d-flex">
        <div
          :class="memberVoted ? 'bg-red' : ' '"
          class="gap-10 align-center d-flex"
        >
          <!-- {{ findMemberInVoteOptions() }} -->
          <div
            class="justify-center px-2 pt-1 align-center d-flex border-slim-light rounded"
          >
            {{ index + 1 }}
          </div>
          <div class="vote-name text-blue-gray">
            {{ vote?.vote_option }}
          </div>

          <!-- <div class="vote-comment">{{ vote.members }}</div> -->
        </div>
        <div class="gap-10 align-center d-flex">
          <voting-progress-bar
            v-if="voteIsOpen && showProgressBar"
            :viewPercentage="true"
            :currentValue="vote?.total_number_of_votes"
            :refranceValue="voteing?.total_member_vote"
            :vote_option_percentage="
              (vote?.total_number_of_votes * 100) / voteing?.total_member_vote
            "
          ></voting-progress-bar>
        </div>
      </div>

      <div v-if="isMemberOwner" class="notes">
        <div v-for="(member, index) in vote?.members" :key="index">
          <div
            v-if="member.vote_comment !== null"
            class="note-box align-center text-light-blue-gray d-flex justify-space-between gap-10"
          >
            <div>
              <!-- {{ index + 1 }}.  -->
              {{ member.vote_comment }}
            </div>
            <div class="mini-member-row d-flex align-center gap-5">
              <div class="mini-member-img d-flex align-center justify-center">
                <img
                  :src="`https://dev.agenda.42.guru/` + member.user_image"
                  alt=""
                  @error="handleMemberBrokenImage"
                />
              </div>
              <div class="mini-member-text">{{ member.full_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </draggable> -->
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import votingProgressBar from "@/components/ui/meetingAgenda/VotingProgressBar";

export default defineComponent({
  name: "Voting",
  components: {
    draggable: VueDraggableNext,
    votingProgressBar,
  },
  props: {
    voteing: {
      type: Object,
      default: () => [],
    },
    voting_number: {
      default: {},
    },
    member_vote_option: {
      default: {},
    },
    voteIsOpen: {
      type: Boolean,
      default: false,
    },
    showProgressBar: {
      type: Boolean,
      default: false,
    },
    isMemberOwner: {
      type: Boolean,
    },
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      list: [],
      // comments:[]
    };
  },
  mounted() {
    this.list = this.voteing.vote_options_table;
    //    this.comments = this.voteing.vote_options_table.flatMap((option) =>
    //   option.members.filter((member) => member.vote_comment !== null)
    // );
  },
  updated() {
    this.list = this.voteing.vote_options_table;
  },
  watch: {
    voteing: {
      handler: function (val) {
        this.list = val;
      },
      deep: true,
    },
    // voting_number: {
    //   handler: function (val) {
    //     this.findMemberInVoteOptions();
    //   },
    //   deep: true,
    // },
  },

  methods: {
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    log(event) {
      console.log(event);
    },
    onMoveCallback(evt, originalEvent) {
      // return false; — for cancel
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;

.sortable-chosen {
  box-shadow: 0 0 0 3px #f5f5f521;
  cursor: grabbing;
}

.vote-box {
  // border: 1px solid #d0d5dd;
  padding: 10px 0;
  .notes {
    .note-box {
      height: 48px;
      border: 1px solid #eaecf0;
      border-radius: 0px 0px 4px 4px;
      margin: 20px 0;
      padding: 10px 28px;
      .mini-member-row {
        padding: 2px;
        gap: 5px;
        border-radius: 12px;
        background: #f2f4f7;
        color: #475467;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        .mini-member-img {
          width: 18px;
          height: 18px;
          overflow: hidden;
          border-radius: 24px;
          img {
            width: 18px;
            height: 18px;
            border-radius: 24px;
          }
        }
        .mini-member-text {
          display: flex;
          padding: 0px 2px 0px 10px;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
</style>
