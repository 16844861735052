<template>
  <div>
    <v-btn
      :loading="isLoading"
      elevation="0"
      class="mt-2 fill-w-btn siz-14 send-gray-btn-light w-100 pa-0 border-slim-light rounded-lg white-svg"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
          stroke="#475467"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
      <input
        class="inner-input-file"
        type="file"
        multiple
        @change="handleFileUpload"
      />

      {{ $t("addNewAttachment") }}
    </v-btn>
    <div>
      <!-- <ul>
      <li v-for="file in files" :key="file.name">
        <a :href="getFileUrl(file)" target="_blank">
          <div>{{ file.name }}</div>
        </a>

        <div>{{ fileTypeLabel(file.type) }}</div>
        <div>{{ (file.size / 1000000).toFixed(2) }} MB</div>
      </li>
    </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    agendaId: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      files: [],
    };
  },
  methods: {
    handleFileUpload(e) {
      this.files = Array.from(e.target.files);
      this.files.forEach((f) => (f.agenda_id = this.agendaId));
      this.$emit("upload", this.files);
      // //       // alert('file uploaded');
    },
    getFileUrl(file) {
      // Replace this URL with a URL that points to your server endpoint
      const baseUrl = "https://example.com/files/";
      return baseUrl + file.name;
    },
    fileTypeLabel(fileType) {
      const typeMap = {
        "application/pdf": "PDF",
        "application/msword": "DOC",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "DOCX",
        "application/vnd.ms-excel": "XLS",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "XLSX",
        "text/plain": "TXT",
        // Add more type mappings as needed
      };
      return typeMap[fileType] || fileType;
    },
  },
};
</script>
