<template>
  <div>
    <div class="skeleton-row">
      <div class="skeleton-group">
        <div class="sk-description">
          <div class="sk-line sk-line-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.skeleton-group {
  width: 100%;
  // padding: 15px;
}
.skeleton-card {
  background: #f1f1f1;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.sk-description .sk-line {
  background: #eee;
  border-radius: 8px;
  height: 60px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.sk-description .sk-line-1 {
  width: 100%;
}
.sk-description .sk-line-3 {
  width: 60%;
}
.sk-btns .sk-btn-1 {
  margin-right: 40px;
}
.sk-btns .sk-btn-2 {
  margin-left: 40px;
}

.sk-description .sk-line::before,
.sk-btns .sk-btn::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: 0;
  // background-image: linear-gradient(
  //   to right,
  //   #dddddd58 0%,
  //   #ddddddaa 10%,
  //   #dddddd91 15%,
  //   #dddddd6e 20%,
  //   #dddddd5a 25%,
  //   #dddddd47 50%,
  //   #dddddd1e 75%,
  //   #dddddd05 100%
  // );
  background: linear-gradient(to right, #eee 20%, #dddddd7f 50%, #eee 80%);

  background-repeat: no-repeat;
  background-size: 450px 150px;
  animation: shimmer 1.9s linear infinite;
}

.sk-details span::before {
  animation-delay: 0.2s;
}
.sk-btns .sk-btn-2::before {
  animation-delay: 0.22s;
}
@keyframes shimmer {
  0% {
    background-position: 450px 0;
  }
  100% {
    background-position: -450px 0;
  }
}

@media only screen and (max-width: 767px) {
  .skeleton-row {
    flex-direction: column;
  }
  .skeleton-group {
    width: calc(100% - 30px);
    padding: 15px;
  }
}
</style>
