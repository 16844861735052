<template>
  <div>
    <input type="file" @change="handleFileUpload" />
    <div v-if="preview">
      <img loading="lazy" :src="preview" v-if="isImage" />
      <p v-else>{{ preview }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      preview: null,
    };
  },
  computed: {
    isImage() {
      return this.preview && this.preview.startsWith("data:image/");
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file.type.startsWith("image/")) {
        // If uploaded file is an image, preview it
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.preview = reader.result;
        };
      } else {
        // If uploaded file is not an image, preview its name
        this.preview = file.name;
      }
      this.file = file;
    },
  },
};
</script>
