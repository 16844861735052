<template>
  <div class="skeleton dark-brown-color rounded-lg">
    <div class="pa-5 skeleton-header">
      <div class="mb-5 avatar" :style="{ display: displayAvatar }"></div>
      <div :class="['title', titleMargin]" class="mb-8 rounded-xl"></div>
    </div>
    <div
      :style="{ display: displaytaskContainer }"
      class="border-t task-container d-flex flex-column align-center justify-space-around"
    >
      <div class="task d-flex align-center gap-10">
        <div class="rounded-pill task-circle"></div>
        <div class="task-line rounded-xl"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "spaceCardSkeleton",
  props: {
    displayAvatar: {
      type: String,
      default: "block",
    },
    titleMargin: {
      type: String,
      default: "",
    },
    displaytaskContainer: {
      type: String,
      default: "flex",
    },
  },
};
</script>
