<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
        @click="openDi()"
        class="d-flex align-center ms-3 cursor-pointer"
      >
        <div class="bg-white d-flex align-center pa-1 rounded-xl pr-4 ms-n4">
          <div class="d-flex align-center">
            <div
              class="attendace-container d-flex align-center ms-n3"
              v-for="(member, index) in visibleMembers"
              :key="index"
            >
              <div class="rounded-circle">
                <img
                  class="attendace-img"
                  loading="lazy"
                  :src="`https://dev.agenda.42.guru/` + member.user_image"
                  alt=""
                  @error="handleMemberBrokenImage"
                />
              </div>
            </div>
          </div>
          <div v-if="members?.length > 3" class="text-gray pt-1 px-1">
            +{{ members.length - 3 }}
          </div>
        </div>
      </div>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="pa-0 ma-0 btn-48px main-header-btn ios-r ms-8 me-6"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head letter-42 dark-brown-color">
            {{ $t("attendance") }}
          </div>
        </div>
        <v-btn
          v-if="!before_start && !isEnded"
          color="primary"
          class="main-header-btn pa-0 ma-0 btn-48px me-8"
          elevation="0"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <attendance-tabs
          v-if="before_start"
          :filters="beforeStartFilters"
          :activeTab="activeTabBeforeStart"
          @tabActivated="activateTabBeforeStart"
        />
        <attendance-tabs
          v-else
          :filters="startedFilters"
          :activeTab="activeTabStarted"
          @tabActivated="activateTabStarted"
        />

        <!-- if before start -->
        <div v-if="before_start">
          <!-- Confirmed Members -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabBeforeStart === 'Confirmed'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="(member, index) in confirmedMembers"
                :key="index"
                v-if="confirmedMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="`https://dev.agenda.42.guru/` + member.user_image"
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ member.full_name }}
                  </div>
                </div>
                <div class="attendance-btns d-flex align-center gap-10">
                  <confirm-attendance-btn
                    :type="'anAbsent'"
                    @setMemberStatus="
                      setMemberStatus('Absent', member.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anApologized'"
                    @setMemberStatus="
                      setMemberStatus('Apologized', member.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noMembersConfirmedAttendance") }}
              </div>
            </div>
          </div>
          <!-- Pending Members -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabBeforeStart === 'Pending'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="(member, index) in pendingMembers"
                :key="index"
                v-if="pendingMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="`https://dev.agenda.42.guru/` + member.user_image"
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ member.full_name }}
                  </div>
                </div>
                <div class="attendance-btns d-flex align-center gap-10">
                  <confirm-attendance-btn
                    :type="'anAttend'"
                    @setMemberStatus="
                      setMemberStatus('Confirmed', member.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anApologized'"
                    @setMemberStatus="
                      setMemberStatus('Apologized', member.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noMemberNotConfirmedYet") }}
              </div>
            </div>
          </div>
          <!-- Declined Members -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabBeforeStart === 'Apologized'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="(member, index) in apologizedMembers"
                :key="index"
                v-if="apologizedMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="`https://dev.agenda.42.guru/` + member.user_image"
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ member.full_name }}
                  </div>
                </div>
                <div class="attendance-btns d-flex align-center gap-10">
                  <confirm-attendance-btn
                    :type="'anAttend'"
                    @setMemberStatus="
                      setMemberStatus('Confirmed', member.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anAbsent'"
                    @setMemberStatus="
                      setMemberStatus('Absent', member.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noApologized") }}
              </div>
            </div>
          </div>
        </div>

        <!-- v-else -->
        <div v-else>
          <!-- attendance -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabStarted === 'Confirmed'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="(attend, index) in attendedMembers"
                :key="index"
                v-if="attendedMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="`https://dev.agenda.42.guru/` + attend.user_image"
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ attend.full_name }}
                  </div>
                  <!-- <div
                    class="member-role"
                    :class="{ president: attend.role === 'الرئيس' }"
                  >
                    {{ $t(`${attend.role}`) }}
                  </div> -->
                </div>
                <div
                  v-if="!isEnded"
                  class="attendance-btns d-flex align-center gap-10"
                >
                  <confirm-attendance-btn
                    :type="'anAbsent'"
                    @setMemberStatus="
                      setMemberStatus('Absent', attend.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anApologized'"
                    @setMemberStatus="
                      setMemberStatus('Apologized', attend.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noAttend") }}
              </div>
            </div>
          </div>
          <!-- absent -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabStarted === 'Absent'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="(absent, index) in absentMembers"
                :key="index"
                v-if="absentMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="`https://dev.agenda.42.guru/` + absent.user_image"
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ absent.full_name }}
                  </div>
                  <!-- <div
                    class="member-role"
                    :class="{ president: absent.role === 'الرئيس' }"
                  >
                    {{ $t(`${absent.role}`) }}
                  </div> -->
                </div>
                <div
                  v-if="!isEnded"
                  class="attendance-btns d-flex align-center gap-10"
                >
                  <confirm-attendance-btn
                    :type="'anAttend'"
                    @setMemberStatus="
                      setMemberStatus('Confirmed', absent.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anApologized'"
                    @setMemberStatus="
                      setMemberStatus('Apologized', absent.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noAbsent") }}
              </div>
            </div>
          </div>
          <!-- apologized -->
          <div
            class="border-slim-light pa-0 pt-0 pb-0 mb-10 rounded-lg"
            v-if="activeTabStarted === 'Apologized'"
          >
            <div class="attendace-container">
              <div
                class="attend-row d-flex align-center justify-space-between py-2 px-5"
                v-for="apologized in apologizedMembers"
                :key="index"
                v-if="apologizedMembers?.length > 0"
              >
                <div class="flex-10g">
                  <div class="attendance-img">
                    <img
                      class="attendace-img"
                      loading="lazy"
                      :src="
                        `https://dev.agenda.42.guru/` + apologized.user_image
                      "
                      alt=""
                      @error="handleMemberBrokenImage"
                    />
                  </div>
                  <div class="member-name dark-brown-color">
                    {{ apologized.full_name }}
                  </div>
                  <!-- <div
                    class="member-role"
                    :class="{
                      president: apologized.role === 'الرئيس',
                    }"
                  >
                    {{ $t(`${apologized.role}`) }}
                  </div> -->
                </div>
                <div
                  v-if="!isEnded"
                  class="attendance-btns d-flex align-center gap-10"
                >
                  <confirm-attendance-btn
                    :type="'anAttend'"
                    @setMemberStatus="
                      setMemberStatus('Confirmed', apologized.member_id)
                    "
                  />
                  <confirm-attendance-btn
                    :type="'anAbsent'"
                    @setMemberStatus="
                      setMemberStatus('Absent', apologized.member_id)
                    "
                  />
                </div>
              </div>
              <div v-else class="pa-5 text-center font-weight-bold">
                {{ $t("noApologized") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import attendanceTabs from "@/components/ui/attendanceTabs.vue";
import confirmAttendanceBtn from "@/components/ui/confirmAttendanceBtn.vue";
import { ai } from "@/plugins/axios";

export default {
  name: "attendanceDialog",
  components: {
    attendanceTabs,
    confirmAttendanceBtn,
  },
  props: {
    meeting_id: {
      type: String,
    },
    members: {
      type: Array,
      required: true,
    },
    before_start: {
      type: Boolean,
      required: true,
    },
    isEnded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      visibleMembers: [],
      activeTabBeforeStart: "Confirmed",
      beforeStartFilters: [
        { type: "Confirmed", label: "confirmedAttendance" },
        { type: "Pending", label: "notConfirmedYet" },
        { type: "Apologized", label: "apologizeForAttendance" },
      ],
      attendedMembers: [],
      absentMembers: [],
      apologizedMembers: [],

      activeTabStarted: "Confirmed",
      startedFilters: [
        { type: "Confirmed", label: "Attended" },
        { type: "Absent", label: "absent" },
        { type: "Apologized", label: "Apologized" },
      ],
      confirmedMembers: [],
      pendingMembers: [],
      declinedMembers: [],
    };
  },
  methods: {
    async submitForm() {
      this.$emit("refreshAttendance");
      this.dialog = false;
    },
    openDi() {
      this.$emit("openDi");
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    // Handle activation of "beforeStart" tabs
    activateTabBeforeStart(tab) {
      this.activeTabBeforeStart = tab;
    },
    // Handle activation of "started" tabs
    activateTabStarted(tab) {
      this.activeTabStarted = tab;
    },
    async setMemberStatus(status, member_id) {
      try {
        ai.put(
          "method/agenda.api.meeting.meeting_attendee.manage_meeting_attendance",
          {
            meeting_id: this.meeting_id,
            status: status,
            member_id: member_id,
          }
        );
        this.$emit("refreshAttendance");
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (this.members?.length > 3) {
      this.visibleMembers = this.members.slice(0, 3);
    } else {
      this.visibleMembers = this.members;
    }
    this.attendedMembers = this.members.filter(
      (member) => member.status === "Confirmed"
    );
    this.absentMembers = this.members.filter(
      (member) => member.status === "Absent"
    );
    this.apologizedMembers = this.members.filter(
      (member) => member.status === "Apologized"
    );

    this.confirmedMembers = this.members.filter(
      (member) => member.status === "Confirmed"
    );
    this.pendingMembers = this.members.filter(
      (member) => member.status === "Pending"
    );
    // this.declinedMembers = this.members.filter(
    //   (member) => member.status === "Declined"
    // );
  },
};
</script>
