import { createStore } from "vuex";

export default createStore({
  state: {
    passedSteps: [],
    setRolePermissions: [],
    profileImg: "",
    entityImg: "",
    user: {},
    userCurrentRole: "",
  },
  getters: {
    getUser: ({ user }) => user,
  },
  mutations: {
    addPassedStep(state, step) {
      // Check if step is already in the array
      if (!state.passedSteps.includes(step)) {
        state.passedSteps.push(step);
      }
    },
    login(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    logout(state) {
      state.user = {};
      localStorage.removeItem("user");
      console.log("Logged out");
    },
    initialiseStore(state) {
      if (localStorage.getItem("user")) {
        state.user = localStorage.getItem("user");
      }
    },
    UpdateProfileImage(state, data) {
      state.profileImg = data;
    },
    UpdateEntityImage(state, data) {
      state.entityImg = data;
    },

    setUserRole(state, data) {
      state.userCurrentRole = data;
    },
    setRolePermissions(state, data) {
      state.setRolePermissions = data;
    },
  },
  actions: {
    addPassedStep({ commit }, step) {
      commit("addPassedStep", step);
    },
    saveUser({ commit, state }, user) {
      commit("login", user);
      // update state user
      state.user = user;
    },
    profileImg({ commit }, data) {
      commit("UpdateProfileImage", data);
      console.log("UpdateProfileImage", data);
    },
    setUserRole({ commit }, data) {
      commit("setUserRole", data);
      console.log("setUserRole", data);
    },
    entityImg({ commit }, data) {
      commit("UpdateEntityImage", data);
      console.log("UpdateEntityImage", data);
    },
    setRolePermissions({ commit }, data) {
      commit("setRolePermissions", data);
      console.log("setRolePermissions", data);
    },
  },
  modules: {},
});
