<template>
  <div class="def-page-container">
    <!-- <personal-hero v-if="home" />
      <entity-hero v-if="entity" />
      <space-hero v-if="board" /> -->
    <hero />

    <!-- <div
      @click.self="outAppclicked()"
      class="layout-padding layout-container fixedApps"
      :class="{ 'mt-260px': !editSpace && !editEntity && !editUser }"
    >
      <board-app :closeNow="closeNow" v-if="board" />
    </div> -->
    <div class="main-apps-container-w">
      <div
        @click.self="outAppclicked()"
        class="layout-padding layout-container fixedApps"
      >
        <board-app :closeNow="closeNow" v-if="board" />
      </div>
    </div>
    <div
      :class="{ 'super-mt-160px': !editSpace && !editEntity && !editUser }"
      class="list-view d-flex layout-padding layout-container"
    >
      <div class="pb-14 over-y-vis flex-grow-1" @click="outAppclicked()">
        <router-view
          @meeting_details="handleMeetingData"
          @isMemberOwner="handelMeetingOwner"
          v-slot="{ Component }"
          :refreshNow="refreshNow"
        >
          <transition name="translate" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>

      <meetingSideAction
        :meeting_details="meeting_details"
        :isMemberOwner="isMemberOwner"
        @refresh="refreshMeeting"
      />
    </div>
  </div>
</template>

<script>
// import hero from "@/components/layout/hero";
import hero from "@/components/layout/hero.vue";
import { ai } from "@/plugins/axios";
import meetingSideAction from "@/components/ui/meetingAgenda/meetingSideAction.vue";

export default {
  name: "meetingLayout",
  components: {
    hero,
    meetingSideAction,
  },

  computed: {
    member_id() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.member_id;
    },
    closeNow() {
      return this.closeApps;
    },
  },
  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      memberId: "",
      board: false,
      closeApps: false,
      entity: false,
      home: true,
      spaceUpdated: false,
      entityUpdated: false,
      userUpdated: false,
      editSpace: false,
      editEntity: false,
      editUser: false,
      logoPic: "",
      coverPic: "",
      form: {
        logo: null,
        cover_image: null,
      },
      mt: false,
      isMemberOwner: false,
      meeting_details: [],
      refreshNow: false,
    };
  },

  methods: {
    //   @meeting_details="handleMeetingData"
    // @isMemberOwner="isMemberOwner"

    handleMeetingData(e) {
      this.meeting_details = e;
    },
    handelMeetingOwner(e) {
      this.isMemberOwner = e;
    },
    outAppclicked() {
      this.closeApps = !this.closeApps;
    },
    async loadSpaceInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: this.space_id } }
        );
        this.form.logo = response.data.message.logo;
        this.form.cover_image = response.data.message.cover_image;

        this.logoPic =
          `https://dev.agenda.42.guru/` + response.data.message.logo;
        this.coverPic =
          `https://dev.agenda.42.guru/` + response.data.message.cover_image;
      } catch (err) {
        console.log(err);
      }
    },

    async loadEntityInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_entity_information`,
          { params: { entity_id: this.entity_id } }
        );
        this.form.logo = response.data.message.company_logo;
        this.form.cover_image = response.data.message.company_cover;

        this.logoPic =
          `https://dev.agenda.42.guru/` + response.data.message.company_logo;
        this.coverPic =
          `https://dev.agenda.42.guru/` + response.data.message.company_cover;
      } catch (error) {
        console.log(error);
      }
    },
    async loadUserInfo(memberId) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: memberId } }
        );

        this.form.logo = response.data.message.member_information.user_image;
        this.form.cover_image =
          response.data.message.member_information.banner_image;

        this.logoPic =
          `https://dev.agenda.42.guru/` +
          response.data.message.member_information.user_image;
        console.log(this.logoPic);
        this.coverPic =
          `https://dev.agenda.42.guru/` +
          response.data.message.member_information.banner_image;
      } catch (error) {
        console.log(error);
      }
    },

    handelApp() {
      if (
        this.$route.name === "add-new-board" ||
        this.$route.name === "entity" ||
        this.$route.name === "editEntity" ||
        this.$route.name === "addEntityMember" ||
        this.$route.name === "addEntityNews" ||
        this.$route.name === "entityEditNews" ||
        this.$route.name === "entityViewNews" ||
        this.$route.name === "add-new-board" ||
        this.$route.name === "entityNews" ||
        this.$route.name === "entityMembers" ||
        this.$route.name === "entityMemberProfile" ||
        this.$route.name === "entityMeetings" ||
        this.$route.name === "entityItems" ||
        this.$route.name === "entityFiles" ||
        this.$route.name === "entityDiscussions" ||
        this.$route.name === "entityTasksList" ||
        this.$route.name === "entityDelegations" ||
        this.$route.name === "entityRotations" ||
        this.$route.name === "subscriptionBills" ||
        this.$route.name === "subscriptionView" ||
        this.$route.name === "payment-data" ||
        this.$route.name === "payment" ||
        this.$route.name === "entityRoles" ||
        this.$route.name === "entityVotes" ||
        this.$route.name === "entityLogs" ||
        this.$route.name === "entityRecommendations" ||
        this.$route.name === "addNewSpaceStepTwo" ||
        this.$route.name === "editEntityData" ||
        this.$route.name === "viewEntityData" ||
        this.$route.name === "addNewSpace" ||
        this.$route.name === "entitySettings" ||
        this.$route.name === "entityShareholders" ||
        this.$route.name === "entityCalender" ||
        this.$route.name === "entityTimeLine"
      ) {
        this.board = false;
        this.home = false;
        this.entity = true;
      } else if (
        this.$route.name === "home" ||
        this.$route.name === "addEntity" ||
        this.$route.name === "userInfo" ||
        this.$route.name === "EditInfo" ||
        this.$route.name === "myEntities" ||
        this.$route.name === "settings" ||
        this.$route.name === "editSettings" ||
        this.$route.name === "accountSettings" ||
        this.$route.name === "personalLogs" ||
        this.$route.name === "personalTasks" ||
        this.$route.name === "addPersonalSpace" ||
        this.$route.name === "addPersonalSpaceTwo"
      ) {
        this.board = false;
        this.entity = false;
        this.home = true;
      } else {
        this.board = true;
        this.entity = false;
        this.home = false;
      }

      if (this.$route.name === "editSpace") {
        this.editSpace = true;
        const lastSpaceInfo = localStorage.getItem("space_info");
        if (lastSpaceInfo) {
          this.form.logo = JSON.parse(lastSpaceInfo).logo;
          this.form.cover_image = JSON.parse(lastSpaceInfo).cover_image;
          this.logoPic =
            `https://dev.agenda.42.guru/` + JSON.parse(lastSpaceInfo).logo;
          this.coverPic =
            `https://dev.agenda.42.guru/` +
            JSON.parse(lastSpaceInfo).cover_image;
        } else {
          this.loadSpaceInfo();
        }
      } else if (this.$route.name === "editEntityData") {
        this.editEntity = true;
        this.loadEntityInfo();
      } else if (this.$route.name === "EditInfo") {
        this.editUser = true;
        this.loadUserInfo(this.memberId);
      } else {
        this.editSpace = false;
        this.editEntity = false;
        this.editUser = false;
      }
    },
    refreshMeeting() {
      this.refreshNow = true;
      console.log("emit", this.refreshNow);
    },
  },

  watch: {
    $route(to, from) {
      this.entity_id = this.$route.params.entity_id;
      this.space_id = this.$route.params.space_id;
      this.handelApp();
    },
  },

  mounted() {
    this.memberId = JSON.parse(localStorage.getItem("user")).member_id;
    this.handelApp();
  },
};
</script>
