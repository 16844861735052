<template>
  <v-app
    ref="vApp"
    :rtl="isRTL"
    :dir="localeDir"
    :class="!userLogged ? 'authLauout' : ''"
  >
    <v-main v-if="networkStatus">
      <component :is="layoutComponent" />
    </v-main>

    <offline v-else />
  </v-app>
</template>

<script>
import { fr } from "vuetify/lib/locale/index.mjs";
import { useNetwork } from "@vueuse/core";
import offline from "@/components/pages/offline.vue";
import { ai } from "@/plugins/axios";
import { socket } from "@/plugins/socket";

const {
  isOnline,
  // offlineAt,
  // downlink,
  // downlinkMax,
  // effectiveType,
  // saveData,
  // type,
} = useNetwork();

export default {
  name: "App",

  components: {
    offline,
  },
  computed: {
    localeDir() {
      return this.$i18n.locale === "ar" ? "rtl" : "ltr";
    },
    isRTL() {
      return this.$i18n.locale === "ar";
    },
    layoutComponent() {
      return this.$route.meta.layout;
    },
  },

  data() {
    return {
      userRoles: [],
      rolePermissions: [],
      memberInformation: [],
      space_id: "",
      userId: "",
      memberId: "",
      userLogged: false,
      networkStatus: true,
    };
  },
  watch: {
    space_id: {
      handler: function (val, oldVal) {
        console.log("space_id changed!", this.space_id);
      },
      deep: true,
    },
    $route(to, from) {
      this.setMaxViewportScale();
      if (this.$route.path.includes("undefined")) {
        // this.$router.push({ name: "notFound" });
        localStorage.setItem("undefined", "undefined");
      } else {
        // localStorage.removeItem("undefined");
      }
    },

    isOnlineValue: {
      handler: function (val, oldVal) {
        console.log("space_id changed!", this.space_id);
      },
      deep: true,
    },
  },

  async created() {
    if (this.$store.state.user) {
      console.log("user store", this.$store.state.user);
      localStorage.getItem("user", JSON.stringify(this.$store.state.user));
      // console.log("user", localStorage.getItem("user"));
    }
    // if session storage contain user update user in vuex store
    if (localStorage.getItem("user")) {
      this.$store.dispatch(
        "saveUser",
        JSON.parse(localStorage.getItem("user"))
      );
    }

    if (localStorage.getItem("space_id")) {
      this.space_id = localStorage.getItem("space_id");
    }
    localStorage.getItem("tour", true);
    const locale = localStorage.getItem("locale");
    if (locale) {
      this.$i18n.locale = locale;
      this.$vuetify.rtl = locale === "ar";
      // this.$vuetify.lang.current = locale;
    }

    // if locale theme is dark
    const theme = localStorage.getItem("theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "dark";
    }

    if (localStorage.getItem("user")) {
    }

    socket.on(this.memberId, (...args) => {
      const res = args[0];
      console.log("notification", res);
    });
  },
  mounted() {
    window.addEventListener("resize", this.setMaxViewportScale);
    window.addEventListener("scroll", this.roundedToPageContainer);
    this.setMaxViewportScale();
    this.networkStatus = isOnline.value;
    console.log("isOnline.value", isOnline.value);
    console.log("this.networkStatus", this.networkStatus);
  },

  methods: {
    setMaxViewportScale() {
      const viewport = document.querySelector('meta[name="viewport"]');
      const screenWidth = window.screen.width;
      // console.log("Screen Width:", screenWidth);

      let initialScale = 1;
      let maximumScale = 1;
      let width = "1440";
      // let width = "device-width";
      // let width = screenWidth;

      if (localStorage.getItem("user")) {
        this.userLogged = true;

        if (screenWidth >= 1280 && screenWidth < 1440) {
          // // alert("1");
          initialScale = maximumScale = 0.9;
        } else if (screenWidth >= 1024 && screenWidth < 1280) {
          // // alert("2");
          initialScale = maximumScale = 0.8;
        } else if (screenWidth >= 992 && screenWidth < 1024) {
          // // alert("3");
          initialScale = maximumScale = 0.7;
        } else if (screenWidth < 992 && screenWidth > 820) {
          // // alert("4");
          initialScale = maximumScale = 0.89;
          // // alert(screenWidth);
        } else if (screenWidth <= 820 && screenWidth > 620) {
          // // alert("5");
          initialScale = maximumScale = 0.5;
        } else if (screenWidth <= 620 && screenWidth > 200) {
          // // alert("5");
          initialScale = maximumScale = 0.28;
        } else {
          // // alert("6");
          initialScale = maximumScale = 1;
          // // alert(screenWidth);
        }

        viewport.content = `width=${width}, initial-scale=${initialScale}, maximum-scale=${maximumScale}, user-scalable=0`;
        viewport.content = `width=${width}, initial-scale=${initialScale}, maximum-scale=${maximumScale}, user-scalable=0`;
      } else {
        this.userLogged = false;
      }
    },
    roundedToPageContainer() {
      const elements = document.getElementsByClassName("fixed-inner-header");
      if (elements.length > 0) {
        if (window.scrollY > 60) {
          const element = elements[0];
          element.style.borderTopLeftRadius = "0px";
          element.style.borderTopRightRadius = "0px";
        } else {
          const element = elements[0];
          element.style.borderTopLeftRadius = "16px";
          element.style.borderTopRightRadius = "16px";
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnlineStatus);
    window.removeEventListener("offline", this.handleOnlineStatus);
    window.addEventListener("scroll", this.roundedToPageContainer);
  },
};
</script>
<style></style>
