import axios from "axios";
import router from "../router";

import store from "../store/auth/index";
import { lang } from "moment";
// export const ai = axios.create({
//   baseURL: `https://dev.agenda.42.guru/api/`,
// });
export const ai = axios.create({
  baseURL:
    process.env.VUE_APP_BASE_URL && process.env.VUE_APP_BASE_URL !== "false"
      ? process.env.VUE_APP_BASE_URL + "/api/"
      : undefined,
});
ai.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      config.headers["Authorization"] = `Bearer ${user.token}`;
      config.headers["Accept-Language"] = localStorage.getItem("lang");
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

ai.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!localStorage.getItem("user")) {
      // router.push("/login");
    }

    if (
      (!localStorage.getItem("entity_info") && localStorage.getItem("user")) ||
      (!localStorage.getItem("space_info") && localStorage.getItem("user"))
    ) {
      // router.push("/undefined");
      // router.push({ name: "notFound" });
    }

    if (error.response.status == 500) {
      // router.push({ name: "notFound" });
    }
  }
);
