<template>
  <!-- v-model="dialog" -->
  <v-card class="pa-0 fixed-h-popup" width="auto">
    <!-- <template v-slot:activator="{ props }">
      <v-btn
        class="bg-light-gray text-blue-gray mb-5 rounded-4"
        elevation="0"
        v-bind="props"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.56522 18L9.73913 13.8261H16C17.1046 13.8261 18 12.9307 18 11.8261V4C18 2.89543 17.1046 2 16 2H4C2.89543 2 2 2.89543 2 4V11.8261C2 12.9307 2.89543 13.8261 4 13.8261H5.56522V18Z"
            stroke="#475467"
            stroke-width="1.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div class="button-card-text ms-2">
          {{ $t("addNewcomment") }}
        </div>
      </v-btn>
    </template> -->

    <v-card class="upload-file-popup w-100">
      <!-- <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42">{{ $t("addTaskComment") }}</div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="isLoading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div> -->

      <!-- <div class="popup-content popup-padding-70 fixed-h-popup"> -->
      <v-form
        class="auth-form rounded-4"
        :class="noComment == 0 ? 'border-light' : 'border-t-light'"
      >
        <div class="d-flex justify-space-between align-center">
          <v-text-field
            class="form-input-box bg-transparent border-b-0"
            v-model="form.addNewcomment"
            type="text"
            :placeholder="$t('addNewcomment')"
            :label="$t('addNewcomment')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
          <v-btn
            color="primary"
            class="img-24 pa-0 ma-0 me-4"
            elevation="0"
            @click="submitForm"
            :disabled="!form.addNewcomment"
            :loading="isLoading"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 5.71997L8.06747 17.72L4 13.6295"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </v-form>

      <!-- </div> -->
    </v-card>
  </v-card>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "addTaskComment",

  components: {},
  data() {
    return {
      dialog: false,
      isLoading: false,

      form: {
        addNewcomment: "",
      },
    };
  },
  props: {
    task_id: {
      type: String,
    },
    noComment: {
      default: 0,
    },
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        const response = await ai.post(
          `method/agenda.api.space.task.add_comment`,
          { task_id: this.task_id, comment: this.form.addNewcomment }
        );
        this.isLoading = false;
        this.dialog = false;
        this.form.addNewcomment = "";

        this.refreshTaskDetails(response.data.message);
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    refreshTaskDetails(e) {
      this.$emit("refreshTaskDetails", e);
    },
  },
};
</script>
