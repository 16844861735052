<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-if="row"
        v-bind="props"
        class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0 hv-gray-1"
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.60156 14.4L1.60183 11.9997C1.60198 10.6744 2.67645 9.60001 4.00183 9.60001H8.00156M12.6873 9.00001L14.4016 10.6M14.4016 10.6L12.6873 12.2M14.4016 10.6H10.0016M9.60156 4.00001C9.60156 5.32549 8.52704 6.40001 7.20156 6.40001C5.87608 6.40001 4.80156 5.32549 4.80156 4.00001C4.80156 2.67452 5.87608 1.60001 7.20156 1.60001C8.52704 1.60001 9.60156 2.67452 9.60156 4.00001Z"
            stroke="#475467"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
      <v-btn
        v-if="!row && !card && !sideAction"
        v-bind="props"
        elevation="0"
        class="fill-w-btn siz-14 send-gray-btn mt-0 rounded-4-bottom rounded-0 border-0 w-100 dark-brown-color"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        {{ $t("addOtherTeamOrMember") }}
      </v-btn>
      <v-btn
        v-if="card"
        v-bind="props"
        class="action-btn border-light"
        elevation="0"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9992 7.20166L11.9992 16.8017M16.7992 12.0017L7.19922 12.0017"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        <div class="ms-3">
          {{ $t("addOtherTeamOrMember") }}
        </div>
      </v-btn>
      <v-btn
        v-if="sideAction"
        v-bind="props"
        class="main-submit-form bg-primary rounded-lg"
        block
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9992 7.20166L11.9992 16.8017M16.7992 12.0017L7.19922 12.0017"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        <span class="mx-3">{{ $t("addOtherMember") }}</span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("assignAnotherMemberToTask") }}
          </div>
        </div>
        <v-btn
          class="main-header-btn bg-primary img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 mt-5 mb-10 ms-4">
          <!-- if there is member -->

          <div v-if="!inPage && task.username">
            <div
              class="d-flex gap-10 justify-space-between pa-5 align-center border-b-light"
            >
              <div class="d-flex gap-10 align-center">
                <img
                  loading="lazy"
                  :src="`https://dev.agenda.42.guru/` + task.user_image"
                  class="member-pic"
                  alt=""
                  @error="handleMemberImage"
                />
                <div class="font-weight-bold">{{ task.username }}</div>
              </div>
            </div>
          </div>

          <div class="position-relative">
            <all-space-members
              :selectedItems="selectedItems"
              @memberSelected="memberSelected"
            />
          </div>

          <v-input
            v-if="errorMessage"
            :error-messages="errorMessage"
            class="text-center"
            error
          >
          </v-input>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";
import meetingSendToMembers from "@/views/space/meetings/current-meeting-items/meetingSendToMembers.vue";
import allSpaceMembers from "@/components/shared/allSpaceMembers";

export default {
  name: "AssignMember",
  components: {
    meetingSendToMembers,
    allSpaceMembers,
  },
  props: {
    id: {
      default: "",
    },
    selectedItems: {
      default: () => [],
    },
    row: {
      default: false,
    },
    card: {
      default: false,
    },
    sideAction: {
      default: false,
    },
    task: {
      default: () => {},
    },
    inPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      errorMessage: "",
      dialog: false,
      loading: false,

      form: {
        name: "",
        id: "",
      },
    };
  },
  methods: {
    memberSelected(e) {
      this.form.name = e.text;
      this.form.id = e.id;
      this.$emit("addAssginedMember", e);
      console.log(e);
    },
    submitForm() {
      if (this.inPage) {
        this.emitLoadMember();
      } else {
        const memberInfo = this.loadMember(this.form.name);
        if (memberInfo) {
          this.changeAssignee(this.form.name);
          this.dialog = false;
          this.$emit("refreshTasks", true);
          console.log("clicked");
        }
      }
    },
    emitLoadMember() {
      this.$emit("loadMember", this.form.name);
      this.dialog = false;
    },
    async loadMember(memberId) {
      try {
        const response = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: memberId } }
        );
        return response.data.message.member_information;
      } catch (err) {
        this.errorMessage = err.response?.data.message.detail;
        console.log(err);
      }
    },
    async changeAssignee(memberId) {
      this.loading = true;

      try {
        const request = {
          task_id: this.task.task_id,
          new_assignee: memberId,
        };
        await ai.post(
          `method/agenda.api.home.task.change_task_assignee`,
          request
        );
        this.loading = false;
      } catch (err) {
        this.errorMessage = err.response?.data.message.detail;
        this.loading = false;
        console.log(err);
      }
    },
    openDi() {
      this.$emit("openDi");
    },
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
  },
};
</script>
