<template>
  <div class="layout-padding layout-container mt-5">
    <!-- updated -->
    <div class="d-flex">
      <v-card
        class="main-page-container pb-10 flex-grow-1 meeting-header"
        elevation="0"
      >
        <inner-header mb="mb-0" class="with-action-side">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/meetings/start-meeting/${meeting_id}`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9709 15L15.832 10M15.832 10L10.9709 5M15.832 10H4.16537"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                عنوان الاجتماع التجريبي
              </div>
            </div>
          </div>
        </inner-header>
        <!-- meeting Status -->
        <div class="meeting-status-container ma-0">
          <div class="meeting-status text-center py-3">
            {{ $t("sendMinutesToMembers") }}
          </div>
        </div>
        <!-- all items -->
        <div class="items ma-10">
          <div class="text-center font-weight-bold mt-10 mb-6">
            {{ $t("attendance") }}
          </div>
          <div class="border-slim-light">
            <div class="pa-5 d-flex align-center justify-center gap-16">
              <div class="attendance-title-num">4</div>
              <div class="font-weight-bold">
                {{ $t("anAttend") }}
              </div>
            </div>
            <div class="border-t">
              <div class="d-flex align-center px-5 py-4 gap-10">
                <div class="attendance-img-container">
                  <img
                    loading="lazy"
                    :src="
                      `https://dev.agenda.42.guru//files/` +
                      attend?.member_image
                    "
                    alt=""
                    @error="handleMemberBrokenImage"
                  />
                </div>
                <div class="member-name">
                  {{ attend?.first_name + " " + attend?.last_name }}
                </div>
                <div class="member-role">
                  {{ $t(`${attend?.role}`) }}
                </div>
              </div>
            </div>
            <div
              class="pa-5 d-flex align-center justify-center gap-16 border-t"
            >
              <div class="attendance-title-num">4</div>
              <div class="font-weight-bold">
                {{ $t("anAbsent") }}
              </div>
            </div>
            <div class="border-t">
              <div class="d-flex align-center px-5 py-4 gap-10">
                <div class="attendance-img-container">
                  <img
                    loading="lazy"
                    :src="
                      `https://dev.agenda.42.guru//files/` +
                      attend?.member_image
                    "
                    alt=""
                    @error="handleMemberBrokenImage"
                  />
                </div>
                <div class="member-name">
                  {{ attend?.first_name + " " + attend?.last_name }}
                </div>
                <div class="member-role">
                  {{ $t(`${attend?.role}`) }}
                </div>
              </div>
            </div>
          </div>

          <!-- ---- discussed -->
          <div class="center-header mb-0 mt-8">{{ $t("itemDiscussed") }}</div>
          <MeetingItemsDetails
            :items="items_data"
            @refreshOldData="refreshOldData"
            :stopVoting="true"
            :showOptions="false"
            :stopEdit="true"
          />

          <!-- ---- postponed  -->
          <div class="center-header mb-0 mt-8">{{ $t("postponedItems") }}</div>
          <div
            v-for="e in postponed_items"
            :key="e.id"
            class="meeting-row align-center justify-space-between d-flex"
          >
            <div class="align-center d-flex gap-10">
              <v-btn class="meating-btn-icone pa-0 ma-0" elevation="0">
                <div class="align-center d-flex gap-10">{{ e.id }}</div>
              </v-btn>

              <div class="font-weight-medium">{{ e.agenda_title }}</div>
            </div>
          </div>
          <!-- ---- canceled  -->
          <div class="center-header mb-0 mt-8">{{ $t("canceledItems") }}</div>
          <div
            v-for="e in canceled_items"
            :key="e.id"
            class="meeting-row align-center justify-space-between d-flex"
          >
            <div class="align-center d-flex gap-10">
              <v-btn class="meating-btn-icone pa-0 ma-0" elevation="0">
                <div class="align-center d-flex gap-10">{{ e.id }}</div>
              </v-btn>

              <div class="font-weight-medium">{{ e.agenda_title }}</div>
            </div>
          </div>
        </div>
      </v-card>
      <!-- side action -->
      <div class="action-side-page ps-10">
        <!-- actions -->
        <div class="expansion-center-container">
          <v-expansion-panels
            v-model="panel"
            multiple
            class="bg-transparent expansion-circle"
            elevation="0"
          >
            <!-- meeting info -->
            <v-expansion-panel
              color="transparent"
              class="bg-transparent"
              elevation="0"
            >
              <v-expansion-panel-title
                hide-actions
                class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow white-cir"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("meetingInfo") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mb-8">
                <div class="meeting-sidebar">
                  <div class="mb-5 text">
                    <p class="quick-desc">
                      {{ testDate.description }}
                    </p>
                  </div>
                  <div class="links">
                    <div
                      v-if="testDate?.meeting_url"
                      class="link d-flex align-center"
                    >
                      <div class="gap-10 d-flex align-center">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.67596 4.96845L4.57024 4.96844C3.78381 4.96844 3.02266 5.27735 2.46449 5.84378C1.90633 6.41021 1.58921 7.16967 1.60193 7.9807C1.58918 8.79172 1.90642 9.55129 2.46449 10.1176C3.0353 10.6969 3.77105 11.0059 4.55751 11.0059H6.66323M9.32717 11.0316H11.4329C12.2193 11.0316 12.9805 10.7227 13.5386 10.1563C14.0968 9.58984 14.4139 8.83037 14.4012 8.01934C14.4012 7.22127 14.0841 6.46177 13.5259 5.89534C12.9677 5.32891 12.2194 5.0071 11.4329 5.00709H9.32717M4.83652 7.97187L11.1537 7.97187"
                            stroke="#475467"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <p class="copy ms-2" @click="copy(testDate.meeting_url)">
                        {{ $t("copyMeetingLink") }}
                      </p>
                    </div>
                    <div class="location">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00088 14.4C8.00088 14.4 13.0096 9.9478 13.0096 6.60867C13.0096 3.84244 10.7671 1.59998 8.00088 1.59998C5.23466 1.59998 2.99219 3.84244 2.99219 6.60867C2.99219 9.9478 8.00088 14.4 8.00088 14.4Z"
                          stroke="#475467"
                        />
                        <path
                          d="M9.60109 6.40008C9.60109 7.28373 8.88474 8.00008 8.00109 8.00008C7.11743 8.00008 6.40109 7.28373 6.40109 6.40008C6.40109 5.51642 7.11743 4.80008 8.00109 4.80008C8.88474 4.80008 9.60109 5.51642 9.60109 6.40008Z"
                          stroke="#475467"
                        />
                      </svg>
                      <span>{{ testDate.location }}</span>
                    </div>
                    <div class="date">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.58203 5.71424H13.082M3.93917 1.33331V2.4763M11.582 1.33331V2.47616M13.832 5.47616L13.832 11.6666C13.832 13.3235 12.4889 14.6666 10.832 14.6666H4.83203C3.17518 14.6666 1.83203 13.3235 1.83203 11.6666V5.47616C1.83203 3.81931 3.17518 2.47616 4.83203 2.47616H10.832C12.4889 2.47616 13.832 3.81931 13.832 5.47616Z"
                          stroke="#475467"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span>
                        {{ testDate.date }}
                      </span>
                    </div>
                    <div class="time">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2434 10.0743C10.5054 10.1616 10.7886 10.0201 10.8759 9.75809C10.9632 9.49612 10.8216 9.21296 10.5597 9.12563L10.2434 10.0743ZM8.00156 8.79997H7.50156C7.50156 9.01519 7.63928 9.20626 7.84345 9.27432L8.00156 8.79997ZM8.50156 5.45481C8.50156 5.17867 8.2777 4.95481 8.00156 4.95481C7.72542 4.95481 7.50156 5.17867 7.50156 5.45481H8.50156ZM10.5597 9.12563L8.15968 8.32563L7.84345 9.27432L10.2434 10.0743L10.5597 9.12563ZM8.50156 8.79997V5.45481H7.50156V8.79997H8.50156ZM13.9016 7.99997C13.9016 11.2585 11.26 13.9 8.00156 13.9V14.9C11.8123 14.9 14.9016 11.8107 14.9016 7.99997H13.9016ZM8.00156 13.9C4.74308 13.9 2.10156 11.2585 2.10156 7.99997H1.10156C1.10156 11.8107 4.1908 14.9 8.00156 14.9V13.9ZM2.10156 7.99997C2.10156 4.74149 4.74308 2.09998 8.00156 2.09998V1.09998C4.1908 1.09998 1.10156 4.18921 1.10156 7.99997H2.10156ZM8.00156 2.09998C11.26 2.09998 13.9016 4.74149 13.9016 7.99997H14.9016C14.9016 4.18921 11.8123 1.09998 8.00156 1.09998V2.09998Z"
                          fill="#475467"
                        />
                      </svg>
                      <span class="siz-14" v-if="testDate">
                        {{ testDate.time }}
                      </span>
                      -
                      <span class="siz-14" v-if="testDate">
                        {{ testDate.time }}
                      </span>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <!-- attendence -->
            <v-expansion-panel
              color="transparent"
              class="bg-transparent"
              elevation="0"
            >
              <v-expansion-panel-title
                hide-actions
                class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow white-cir"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("attendance") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mb-8">
                <attendanceDialog :members="members" />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              color="transparent"
              class="bg-transparent"
              elevation="0"
            >
              <v-expansion-panel-title
                hide-actions
                class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow white-cir"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("actions") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mb-8">
                <div class="meeting-sidebar">
                  <div class="btns d-flex flex-column gap-10">
                    <button
                      class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey"
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 18H4.24999C3.14542 17.9999 2.24999 17.1045 2.25 15.9999L2.25008 3.99999C2.25008 2.89542 3.14551 2 4.25008 2H13.2503C14.3549 2 15.2503 2.89543 15.2503 4V8M5.75031 6H11.7503M5.75031 9H11.7503M5.75031 12H8.75031M13.2858 17L11.2503 14.9548M11.2503 14.9548L13.1938 13M11.2503 14.9548H17.2729C18.0889 14.9548 18.7503 14.2933 18.7503 13.4774C18.7503 12.6614 18.0889 12 17.2729 12H15.2503"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      {{ $t("retrieveTheRecord") }}
                    </button>
                    <button
                      class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey"
                    >
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_14922_133581)">
                          <path
                            d="M19.25 11.25V10H15.5V16.25H16.75V13.75H18.625V12.5H16.75V11.25H19.25Z"
                            fill="white"
                          />
                          <path
                            d="M12.375 16.25H9.875V10H12.375C12.8721 10.0005 13.3487 10.1983 13.7002 10.5498C14.0517 10.9013 14.2495 11.3779 14.25 11.875V14.375C14.2495 14.8721 14.0517 15.3487 13.7002 15.7002C13.3487 16.0517 12.8721 16.2495 12.375 16.25ZM11.125 15H12.375C12.5407 14.9998 12.6996 14.9339 12.8167 14.8167C12.9339 14.6996 12.9998 14.5407 13 14.375V11.875C12.9998 11.7093 12.9339 11.5504 12.8167 11.4333C12.6996 11.3161 12.5407 11.2502 12.375 11.25H11.125V15Z"
                            fill="white"
                          />
                          <path
                            d="M7.375 10H4.25V16.25H5.5V14.375H7.375C7.70638 14.3746 8.02407 14.2427 8.25839 14.0084C8.49271 13.7741 8.62455 13.4564 8.625 13.125V11.25C8.62462 10.9186 8.4928 10.6009 8.25846 10.3665C8.02413 10.1322 7.7064 10.0004 7.375 10ZM5.5 13.125V11.25H7.375L7.37563 13.125H5.5Z"
                            fill="white"
                          />
                          <path
                            d="M14.25 8.74997V6.24997C14.2522 6.16783 14.2366 6.08617 14.2043 6.01064C14.1719 5.93511 14.1235 5.8675 14.0625 5.81247L9.6875 1.43747C9.63248 1.37643 9.56488 1.32805 9.48934 1.29568C9.41381 1.2633 9.33215 1.24771 9.25 1.24997H3C2.66878 1.25094 2.3514 1.38295 2.11719 1.61716C1.88298 1.85137 1.75097 2.16875 1.75 2.49997V17.5C1.75 17.8315 1.8817 18.1494 2.11612 18.3839C2.35054 18.6183 2.66848 18.75 3 18.75H13V17.5H3V2.49997H8V6.24997C8.00097 6.58119 8.13298 6.89857 8.36719 7.13278C8.6014 7.36699 8.91878 7.499 9.25 7.49997H13V8.74997H14.25ZM9.25 6.24997V2.74997L12.75 6.24997H9.25Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_14922_133581">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      {{ $t("downloadAsPDF") }}
                    </button>
                    <button
                      class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_14922_133584)">
                          <path
                            d="M14.582 8.74997V6.24997C14.5843 6.16783 14.5687 6.08617 14.5363 6.01064C14.5039 5.93511 14.4556 5.8675 14.3945 5.81247L10.0195 1.43747C9.96452 1.37643 9.89691 1.32805 9.82137 1.29568C9.74584 1.2633 9.66418 1.24771 9.58203 1.24997H3.33203C3.00081 1.25094 2.68343 1.38295 2.44922 1.61716C2.21501 1.85137 2.083 2.16875 2.08203 2.49997V17.5C2.08203 17.8315 2.21373 18.1494 2.44815 18.3839C2.68257 18.6183 3.00051 18.75 3.33203 18.75H13.332V17.5H3.33203V2.49997H8.33203V6.24997C8.333 6.58119 8.46501 6.89857 8.69922 7.13278C8.93343 7.36699 9.25081 7.499 9.58203 7.49997H13.332V8.74997H14.582ZM9.58203 6.24997V2.74997L13.082 6.24997H9.58203Z"
                            fill="white"
                          />
                          <path
                            d="M17.918 10.1389L15.8346 16.3889H14.8624L13.2652 12.5695L11.668 16.3889H10.6957L8.54297 10.1389H10.0013L11.2513 13.75L12.7791 10.1389H13.7513L15.2096 13.75L16.4596 10.1389H17.918Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_14922_133584">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      {{ $t("downloadAsAText") }}
                    </button>
                    <button class="recordMeetingOperations">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.0979 10C17.0979 14.4183 13.5152 18 9.09582 18C4.6764 18 1.09375 14.4183 1.09375 10C1.09375 5.58172 4.6764 2 9.09582 2C12.0577 2 14.6438 3.60879 16.0274 6M14.9027 10.8779L16.9032 8.87792L18.9037 10.8779M12.0937 12.1814L9.09375 11.1814V7"
                          stroke="#475467"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{ $t("recordMeetingOperations") }}
                    </button>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import InnerHeader from "@/components/shared/InnerHeader";
import MeetingItemsDetails from "@/components/ui/meetingAgenda/MeetingItemsDetails";
import attendanceDialog from "@/components/ui/dialogs/attendanceDialog.vue";
import FinishedItems from "@/components/ui/meetingAgenda/FinishedItems";
import MemberAttendance from "@/components/ui/meetingAgenda/MemberAttendance";
import meetingInfo from "@/components/ui/dialogs/meetingInfo";

export default defineComponent({
  name: "UrgentMeeting",
  components: {
    InnerHeader,
    MemberAttendance,
    FinishedItems,
    meetingInfo,
    MeetingItemsDetails,
    attendanceDialog,
  },

  data() {
    return {
      panel: [0],
      edit_report: false,
      members: [
        {
          name: "فيصل العبدالكريم",
          role: "الرئيس",
          img: "Personal image.png",
          attendance: "Attended",
        },
        {
          name: "عبدالله الشمري",
          role: "المدير التنفيذي",
          img: "Personal image.png",
          attendance: "Absent",
        },
        {
          name: "أحمد الغامدي",
          role: "مدير التسويق",
          img: "Personal image.png",
          attendance: "Attended",
        },
        {
          name: "علي الحسن",
          role: "مهندس برمجيات",
          img: "Personal image.png",
          attendance: "Attended",
        },
        {
          name: "محمد العنزي",
          role: "مدير الإنتاج",
          img: "Personal image.png",
          attendance: "Absent",
        },
        {
          name: "عبدالرحمن الدوسري",
          role: "مدير المبيعات",
          img: "Personal image.png",
          attendance: "Apologized",
        },
        {
          name: "لؤي الجاسم",
          role: "محاسب",
          img: "Personal image.png",
          attendance: "Attended",
        },
        {
          name: "سلمان العلي",
          role: "مهندس مدني",
          img: "Personal image.png",
          attendance: "Apologized",
        },
        {
          name: "فارس العتيبي",
          role: "مدير المشتريات",
          img: "Personal image.png",
          attendance: "Absent",
        },
        {
          name: "عبدالمجيد السلمان",
          role: "مساعد إداري",
          img: "Personal image.png",
          attendance: "Apologized",
        },
      ],

      testDate: {
        location: "غرفة الإجتماعات الرئيسية",
        date: "الأحد، 1 ديسمبر 2022",
        time: "10:00 ص",
        meeting_url: "http//wwwdwdw",
        description: "عنوان البند الأول الذي سيتم مناقشته في الإجتماع",
      },

      items_data: [
        {
          location: "غرفة الإجتماعات الرئيسية",
          date: "الأحد، 1 ديسمبر 2022",
          time: "10:00 ص",
          meeting_url: "http//wwwdwdw",

          id: 1,
          duration: 110,
          status: "",
          agenda_title: "عنوان البند الأول الذي سيتم مناقشته في الإجتماع",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
          recommendation: [
            {
              order: 1,
              recommendation_title:
                "عنوان البند الأول الذي سيتم مناقشته في الإجتماع",
            },
            {
              order: 2,
              recommendation_title:
                "عنوان البند الثاني الذي سيتم مناقشته في الإجتماع",
            },
          ],
          tasks: [
            {
              task_id: 1,
              task_title: "مهمة 1",
              member_name: "اسم العضو",
              member_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
            {
              task_id: 2,
              task_title: "مهمة 2",
              member_name: "اسم العضو",
              member_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
            {
              task_id: 3,
              task_title: "مهمة 3",
              member_name: "اسم العضو",
              member_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
          ],
          vote: [
            {
              vote_id: 1,
              vote_title: "هنا يظهر عنوان التصويت ويكون في الغالب سؤال",
              start_at: "1 مارس 2023 - 12:30م",
              end_at: "4 مارس 2023 - 12:30م",
              duration: "72 ساعة",
              totalNumberOfVoters: 120,
              voting_number: 46,
              choicesNumberOfVoters: [34, 12],
              vote_options: [
                {
                  id: 1,
                  content: "الاختيار الأول الذي أدخله منشئ التصويت هنا",
                  votesCount: 34,
                },
                {
                  id: 2,
                  content: "الاختيار الثاني الذي تم إدخاله هنا",
                  votesCount: 12,
                },
              ],
              users: [
                {
                  id: 1,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 2,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 2,
                },
                {
                  id: 3,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 4,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 8,
                },
                {
                  id: 5,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 6,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 2,
                },
                {
                  id: 7,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 8,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 8,
                },
              ],
            },
          ],

          attachments: [
            {
              file_id: 1,
              file_name: "عنوان الملف الأول",
              type: "pdf",
              file_size: "100",
              link: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
            {
              file_id: 1,
              file_name: "عنوان الملف",
              type: "pdf",
              file_size: "100",
              link: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
          ],
        },
        {
          id: 2,
          duration: 30,
          status: "",
          agenda_title: "عنوان البند الثاني الذي سيتم مناقشته في الإجتماع",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
          recommendation: [
            {
              order: 1,
              recommendation_title:
                "عنوان البند الأول الذي سيتم مناقشته في الإجتماع",
            },
          ],
          tasks: [
            {
              task_id: 1,
              task_title: "مهمة 1",
              member_name: "اسم العضو",
              member_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
            {
              task_id: 2,
              task_title: "مهمة 2",
              member_name: "اسم العضو",
              member_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
          ],
          vote: [
            {
              vote_id: 1,
              vote_title: "هنا يظهر عنوان التصويت ويكون في الغالب سؤال",
              start_at: "1 مارس 2023 - 12:30م",
              end_at: "4 مارس 2023 - 12:30م",
              duration: "72 ساعة",
              totalNumberOfVoters: 120,
              voting_number: 46,
              choicesNumberOfVoters: [34, 12],
              vote_options: [
                {
                  id: 1,
                  content: "الاختيار الأول الذي أدخله منشئ التصويت هنا",
                  votesCount: 34,
                },
                {
                  id: 2,
                  content: "الاختيار الثاني الذي تم إدخاله هنا",
                  votesCount: 12,
                },
              ],
              users: [
                {
                  id: 1,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 2,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 2,
                },
                {
                  id: 3,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 4,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 8,
                },
                {
                  id: 5,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 6,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 2,
                },
                {
                  id: 7,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 1,
                },
                {
                  id: 8,
                  image: "",
                  username: "اسم العضو",
                  numberOfShares: 8,
                },
              ],
            },
          ],
          attachments: [
            {
              file_id: 1,
              file_name: "عنوان الملف",
              type: "pdf",
              file_size: "100",
              link: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
            },
          ],
        },
      ],

      postponed_items: [
        {
          id: 1,
          duration: 10,
          status: "",
          agenda_title: "عنوان البند الأول الذي تم تأجيلة",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
        },
        {
          id: 2,
          duration: 20,
          status: "",
          agenda_title: "عنوان البند الثاني الذي تم تأجيلة",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
        },
      ],
      canceled_items: [
        {
          id: 1,
          duration: 40,
          status: "",
          agenda_title: "عنوان البند الأول الذي تم حذفة",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
        },
        {
          id: 2,
          duration: 110,
          status: "",
          agenda_title: "عنوان البند الثاني الذي تم حذفة",
          agenda_description:
            "هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل. هنا النص الذي أدخله العضو في خانة وصف البند وقد يمتد ذلك الوصف لأكثر من سطر في الكثير من الحالات وسيكون ظاهرًا حينها بمثل هذا الشكل• ",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    editable() {
      this.edit_report = true;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
  },
});
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;
.meeting-status-container {
  background-color: #f5bc5e;
  color: #fff;
}
.meeting-row {
  background-color: #f9fafb;
  padding: 20px;
  gap: 20px;
  // width: 830px;
  height: 86px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}
</style>
