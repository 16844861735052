<template>
  <div
    class="header-holder"
    :class="!space_id && entity_id ? 'entity_mood' : ''"
  >
    <div
      ref="headerEle"
      elevation="0"
      class="fixed-inner-header main-shadow-right main-shadow-left"
    >
      <!-- transtion-header -->
      <div class="main-header" v-if="pageHeadeing">{{ pageHeadeing }}</div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerHeader",
  props: {
    pageHeadeing: {
      type: String,
      default: "",
    },

    mb: {
      type: String,
      default: "mb-6",
    },
  },

  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      fixed: false,
      offsetY: 0,
      width: "",
    };
  },
  watch: {
    width(newVal) {},
  },

  methods: {},
  mounted() {
    this.$nextTick(() => {});
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.transtion-header {
  transition: all 0.9s ease-in;
}

.fixed-inner-header {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;

  border-bottom: 1px solid #f5f5f4;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 14px 0px;
  background: #fff;

  inset: 0;
  z-index: 999;

  .main-header {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.fixed-h-popup {
  .fixed-inner-header {
    margin-top: 0 !important;
    position: static;

    transform: translateX(0);
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .fixed-inner-header {
    // padding: 16px 0px;

    // height: auto !important;
    .main-header {
      font-size: 18px;
    }
  }
}
@media (max-width: 500px) {
  .fixed-inner-header {
    // padding: 16px 0px;

    // height: auto !important;
    .main-header {
      font-size: 12px;
    }
  }
}

// dark theme
.v-theme--dark {
  .fixed-inner-header {
    border-bottom: 1px solid $Brown;
    div {
      color: #fff;
    }
  }
}
</style>
