<template>
  <div
    @click="clickToCloseMenue"
    class="def-page-container"
    :class="underAppsOpen ? 'underAppsOpen' : ''"
  >
    <!-- <alert
      v-if="spaceUpdated || entityUpdated || userUpdated"
      :content="$t('updated')"
      color="primary"
      icon="mdi-alert-circle-outline"
    />
    <div v-if="editSpace || editEntity || editUser">
      <v-card
        elevation="0"
        class="justify-center d-flex align-center flex-column"
      >
        <upload-image
          @uploadImage="setImageCover"
          class="rounded-0 cover-pic w-100"
          :cricled="false"
          :currentImage="coverPic"
        ></upload-image>
      </v-card>

 
      <div class="user-info d-flex me-10 ms-10 layout-padding layout-container">
        <div class="user-image smooth_rounded">
          <upload-image
            @uploadImage="setImageAvatar"
            class="profile-pic smooth_rounded"
            :currentImage="logoPic"
          ></upload-image>
        </div>
      </div>
    </div> -->

    <hero @click="clickToCloseMenue" />
    <!-- <entityHero /> -->
    <!-- 'mt-260px': !editSpace && !editEntity && !editUser, -->
    <!-- <div :class="board ? 'main-apps-container-w' : ''"> -->
    <div :class="$route.name == 'space' ? 'main-apps-container-w' : 'd-none'">
      <div
        @click.self="outAppclicked()"
        class="layout-padding layout-container fixedApps"
      >
        <board-app :closeNow="closeNow" v-if="board" />
        <entity-app :closeNow="closeNow" v-if="entity" />
        <personal-app :closeNow="closeNow" v-if="home" />
      </div>
    </div>

    <div
      :class="!space_id && entity_id ? 'mt-192px' : ''"
      class="pb-14 over-y-vis"
      @click="outAppclicked()"
    >
      <Suspense>
        <template #default>
          <router-view
            @showUnderApps="handelDisapperApps"
            v-slot="{ Component }"
          >
            <!-- <transition name="translate" mode="out-in"> -->
            <transition name="fade-transition">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <!-- This will be shown while the component is loading -->
          <div>Loading...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
// import hero from "@/components/layout/hero";
import hero from "@/components/layout/hero.vue";
// import personalHero from "@/components/layout/apps/heros/personal_hero.vue";
// import entityHero from "@/components/layout/apps/heros/entity_hero.vue";
// import spaceHero from "@/components/layout/apps/heros/space_hero.vue";
import { ai } from "@/plugins/axios";
import uploadImage from "@/components/ui/uploadImage";

export default {
  name: "DefaultLayout",
  components: {
    hero,
    uploadImage,
    // personalHero,
    // entityHero,
    // spaceHero,
  },
  computed: {
    member_id() {
      const user = JSON.parse(localStorage.getItem("user"));
      return user?.member_id;
    },
    closeNow() {
      return this.closeApps;
    },
  },
  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      memberId: "",
      board: false,
      closeApps: false,
      entity: false,
      home: true,
      spaceUpdated: false,
      entityUpdated: false,
      userUpdated: false,
      editSpace: false,
      editEntity: false,
      editUser: false,
      logoPic: "",
      coverPic: "",
      fixedApps: "",
      form: {
        logo: null,
        cover_image: null,
      },
      mt: false,
      underAppsOpen: false,
    };
  },

  methods: {
    clickToCloseMenue(event) {
      const dropdown = document.querySelector(".app-dropdown-list");
      const dropdownlist = document.querySelector(".list");
      if (dropdown) {
        if (dropdown.contains(event.target)) {
          this.outAppclicked();
        } else {
        }
      }
      // if (dropdownlist) {
      //   if (dropdownlist.contains(event.target)) {
      //     // this.outAppclicked();
      //     this.closeApps = !this.closeApps;
      //     alert(this.closeApps);
      //   } else {
      //   }
      // }
    },
    handelDisapperApps(e) {
      this.underAppsOpen = e;
    },
    outAppclicked() {
      this.closeApps = !this.closeApps;
    },
    async loadSpaceInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: this.space_id } }
        );
        this.form.logo = response.data.message.logo;
        this.form.cover_image = response.data.message.cover_image;

        this.logoPic =
          `https://dev.agenda.42.guru/` + response.data.message.logo;
        this.coverPic =
          `https://dev.agenda.42.guru/` + response.data.message.cover_image;
      } catch (err) {
        console.log(err);
      }
    },

    async loadEntityInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_entity_information`,
          { params: { entity_id: this.entity_id } }
        );
        this.form.logo = response.data.message.company_logo;
        this.form.cover_image = response.data.message.company_cover;

        this.logoPic =
          `https://dev.agenda.42.guru/` + response.data.message.company_logo;
        this.coverPic =
          `https://dev.agenda.42.guru/` + response.data.message.company_cover;
      } catch (error) {
        console.log(error);
      }
    },
    async loadUserInfo(memberId) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: memberId } }
        );

        this.form.logo = response.data.message.member_information.user_image;
        this.form.cover_image =
          response.data.message.member_information.banner_image;

        this.logoPic =
          `https://dev.agenda.42.guru/` +
          response.data.message.member_information.user_image;
        console.log(this.logoPic);
        this.coverPic =
          `https://dev.agenda.42.guru/` +
          response.data.message.member_information.banner_image;
      } catch (error) {
        console.log(error);
      }
    },

    handelApp() {
      if (
        this.$route.name === "add-new-board" ||
        this.$route.name === "entity" ||
        this.$route.name === "editEntity" ||
        this.$route.name === "addEntityMember" ||
        this.$route.name === "addEntityNews" ||
        this.$route.name === "entityEditNews" ||
        this.$route.name === "entityViewNews" ||
        this.$route.name === "add-new-board" ||
        this.$route.name === "entityNews" ||
        this.$route.name === "entityMembers" ||
        this.$route.name === "entityMemberProfile" ||
        this.$route.name === "entityMeetings" ||
        this.$route.name === "entityItems" ||
        this.$route.name === "entityFiles" ||
        this.$route.name === "entityDiscussions" ||
        this.$route.name === "entityTasksList" ||
        this.$route.name === "entityDelegations" ||
        this.$route.name === "entityRotations" ||
        this.$route.name === "subscriptionBills" ||
        this.$route.name === "subscriptionView" ||
        this.$route.name === "payment-data" ||
        this.$route.name === "payment" ||
        this.$route.name === "entityRoles" ||
        this.$route.name === "entityVotes" ||
        this.$route.name === "entityLogs" ||
        this.$route.name === "entityRecommendations" ||
        this.$route.name === "addNewSpaceStepTwo" ||
        this.$route.name === "editEntityData" ||
        this.$route.name === "viewEntityData" ||
        this.$route.name === "addNewSpace" ||
        this.$route.name === "entitySettings" ||
        this.$route.name === "entityShareholders" ||
        this.$route.name === "entityCalender" ||
        this.$route.name === "entityTimeLine" ||
        this.$route.name === "mySpaces" ||
        this.$route.name === "entityCustomize" ||
        this.$route.name === "profilePreview"
      ) {
        this.board = false;
        this.home = false;
        this.entity = true;
      } else if (
        this.$route.name === "home" ||
        this.$route.name === "addEntity" ||
        this.$route.name === "userInfo" ||
        this.$route.name === "EditInfo" ||
        this.$route.name === "myEntities" ||
        this.$route.name === "personalSpaces" ||
        this.$route.name === "settings" ||
        this.$route.name === "editSettings" ||
        this.$route.name === "accountSettings" ||
        this.$route.name === "personalLogs" ||
        this.$route.name === "personalTasks" ||
        this.$route.name === "addPersonalSpace" ||
        this.$route.name === "addPersonalSpaceTwo" ||
        this.$route.name === "profileCustomize" ||
        this.$route.name === "notifications"
      ) {
        this.board = false;
        this.entity = false;
        this.home = true;
      } else {
        this.board = true;
        this.entity = false;
        this.home = false;
      }

      if (this.$route.name === "editSpace") {
        this.editSpace = true;
        const lastSpaceInfo = localStorage.getItem("space_info");
        if (lastSpaceInfo) {
          this.form.logo = JSON.parse(lastSpaceInfo).logo;
          this.form.cover_image = JSON.parse(lastSpaceInfo).cover_image;
          this.logoPic =
            `https://dev.agenda.42.guru/` + JSON.parse(lastSpaceInfo).logo;
          this.coverPic =
            `https://dev.agenda.42.guru/` +
            JSON.parse(lastSpaceInfo).cover_image;
        } else {
          this.loadSpaceInfo();
        }
      } else if (this.$route.name === "editEntityData") {
        this.editEntity = true;
        this.loadEntityInfo();
      } else if (this.$route.name === "EditInfo") {
        this.editUser = true;
        this.loadUserInfo(this.memberId);
      } else {
        this.editSpace = false;
        this.editEntity = false;
        this.editUser = false;
      }
    },
    setImageAvatar(formData) {
      this.form.logo = formData.get("image");
      this.submitFormAvatar();
    },
    setImageCover(formData) {
      this.form.cover_image = formData.get("image");
      this.submitFormCover();
      //
    },
    async submitFormAvatar() {
      if (this.editSpace) {
        this.isLoading = true;
        try {
          const spaceInfo = new FormData();
          spaceInfo.append("logo", this.form.logo);
          spaceInfo.append("space_id", this.space_id);
          const res = await ai.post(
            "method/agenda.api.space.space.update_space",
            spaceInfo
          );

          this.isLoading = false;
          this.spaceUpdated = true;
          setTimeout(() => {
            this.spaceUpdated = false;
          }, 2000);
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      } else if (this.editEntity) {
        try {
          const entityInfo = new FormData();
          entityInfo.append("company_logo", this.form.logo);
          entityInfo.append("entity_id", this.entity_id);
          const response = await ai.post(
            "method/agenda.api.entity.entity.update_entity",
            entityInfo
          );

          localStorage.setItem(
            "entity_info",
            JSON.stringify(response.data.message)
          );
          this.$router.push(`/${this.entity_id}/entity_data/list`);

          this.isLoading = false;
          this.entityUpdated = true;
          setTimeout(() => {
            this.entityUpdated = false;
          }, 2000);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      } else if (this.editUser) {
        try {
          const userInfo = new FormData();
          userInfo.append("user_image", this.form.logo);
          userInfo.append("member_id", this.memberId);
          await ai.post(
            "method/agenda.api.user.user.update_user_info",
            userInfo
          );

          this.isLoading = false;
          this.userUpdated = true;
          setTimeout(() => {
            this.userUpdated = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      }
    },
    async submitFormCover() {
      if (this.editSpace) {
        this.isLoading = true;
        try {
          const spaceInfo = new FormData();
          spaceInfo.append("cover_image", this.form.cover_image);
          spaceInfo.append("space_id", this.space_id);
          const res = await ai.post(
            "method/agenda.api.space.space.update_space",
            spaceInfo
          );

          this.isLoading = false;
          this.spaceUpdated = true;
          setTimeout(() => {
            this.spaceUpdated = false;
          }, 2000);
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      } else if (this.editEntity) {
        try {
          const entityInfo = new FormData();
          entityInfo.append("company_cover", this.form.cover_image);
          entityInfo.append("entity_id", this.entity_id);
          await ai.post(
            "method/agenda.api.entity.entity.update_entity",
            entityInfo
          );

          this.isLoading = false;
          this.entityUpdated = true;
          setTimeout(() => {
            this.entityUpdated = false;
          }, 2000);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      } else if (this.editUser) {
        try {
          const userInfo = new FormData();
          userInfo.append("banner_image", this.form.cover_image);
          userInfo.append("member_id", this.memberId);
          await ai.post(
            "method/agenda.api.user.user.update_user_info",
            userInfo
          );

          this.isLoading = false;
          this.userUpdated = true;
          setTimeout(() => {
            this.userUpdated = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      }
    },
  },

  watch: {
    $route(to, from) {
      this.entity_id = this.$route.params.entity_id;
      this.space_id = this.$route.params.space_id;
      this.handelApp();
    },
  },

  mounted() {
    this.memberId = JSON.parse(localStorage.getItem("user")).member_id;
    this.handelApp();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss"></style>
