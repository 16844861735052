<template>
  <div
    class="attendance-tabs d-flex align-center border-slim-light overflow-hidden my-10"
  >
    <button
      class="attend-tab py-3 px-6 rounded-0"
      v-for="(filter, index) in filters"
      :key="index"
      @click="activateTab(filter.type)"
      :class="{ 'active-tab': activeTab === filter.type }"
    >
      {{ $t(`${filter.label}`) }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filters: Array,
    activeTab: String,
  },
  methods: {
    activateTab(filterType) {
      this.$emit("tabActivated", filterType);
    },
  },
};
</script>

<style lang="scss" scoped>
.attendance-tabs {
  .attend-tab {
    width: 276px;
    height: 44px;
    background-color: #fff;
    color: #0a0a0a;
    &:nth-child(2) {
      border-right: 1px solid #eaecf0;
      border-left: 1px solid #eaecf0;
    }
  }
  .active-tab {
    background-color: #12b76a;
    color: #fff;
  }
}
</style>
