<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-if="sideAction"
        v-bind="props"
        class="main-submit-form bg-white rounded-lg dark-brown-color"
        block
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9969 14.3999L11.3969 13.1999V8.18216M20.9969 11.9999C20.9969 6.69797 16.6988 2.3999 11.3969 2.3999C6.09494 2.3999 1.79688 6.69797 1.79688 11.9999C1.79688 17.3018 6.09494 21.5999 11.3969 21.5999C12.0121 21.5999 12.6138 21.542 13.1969 21.4314M19.1969 15.5999V18.5999M19.1969 18.5999V21.5999M19.1969 18.5999H22.1969M19.1969 18.5999H16.1969"
            stroke="#101010"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span class="mx-3">{{ $t("requestExtensionTime") }}</span>
      </v-btn>
      <v-btn
        v-if="!card & !sideAction"
        v-bind="props"
        class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0 hv-gray-1"
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0031 9.59997L7.60313 8.79997V5.45482M14.0031 7.99997C14.0031 4.46535 11.1377 1.59998 7.60313 1.59998C4.0685 1.59998 1.20312 4.46535 1.20312 7.99997C1.20312 11.5346 4.0685 14.4 7.60313 14.4C8.01328 14.4 8.41442 14.3614 8.80313 14.2877M12.8031 10.4V12.4M12.8031 12.4V14.4M12.8031 12.4H14.8031M12.8031 12.4H10.8031"
            stroke="#475467"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
      <v-btn
        v-if="card"
        v-bind="props"
        class="action-btn border-light"
        elevation="0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9969 14.3999L11.3969 13.1999V8.18216M20.9969 11.9999C20.9969 6.69797 16.6988 2.3999 11.3969 2.3999C6.09494 2.3999 1.79688 6.69797 1.79688 11.9999C1.79688 17.3018 6.09494 21.5999 11.3969 21.5999C12.0121 21.5999 12.6138 21.542 13.1969 21.4314M19.1969 15.5999V18.5999M19.1969 18.5999V21.5999M19.1969 18.5999H22.1969M19.1969 18.5999H16.1969"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <div>
          {{ $t("requestExtensionTime") }}
        </div>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 overflow-visible">
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              @click="dialog = false"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("requestExtensionTime") }}
            </div>
          </div>
          <v-btn
            :loading="loading"
            @click="submitForm"
            color="primary"
            class="new-plus ios-r"
            elevation="0"
          >
            <div class="siz-14 me-2">
              {{ $t("save") }}
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </inner-header>

      <div class="popup-contentfixed-h-popup dark-brown-color">
        <v-form class="auth-form border-light rounded-4 ma-10">
          <!-- date -->
          <div class="date-container border-b-light">
            <date-picker @selectedDate="setTaskDate" class="10" />
            <div class="form-input-box d-flex align-center gap-10 ps-5">
              <v-btn
                class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                evaluation="0"
                flat
              >
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.875 7.57139H17.625M3.91071 1V2.71449M15.375 1V2.71428M18.75 5.71428L18.75 18C18.75 19.6569 17.4069 21 15.75 21H3.75C2.09315 21 0.75 19.6568 0.75 18V5.71428C0.75 4.05742 2.09315 2.71428 3.75 2.71428H15.75C17.4069 2.71428 18.75 4.05742 18.75 5.71428Z"
                    stroke="#667085"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>

              <v-text-field
                v-model="form.date"
                type="text"
                :placeholder="$t('extentionTo')"
                :label="$t('extentionTo')"
                outlined
                solo
                dense
                variant="solo"
                hide-details="auto"
                persistent-placeholder
              ></v-text-field>
            </div>
          </div>
          <v-text-field
            class="form-input-box"
            v-model="form.task_extention_reason"
            type="text"
            :placeholder="$t('resontToExtention')"
            :label="$t('enterResontToExtention')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
          <v-input
            v-if="errorMessage"
            :error-messages="errorMessage"
            class="text-center"
            error
          >
          </v-input>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import InnerHeader from "@/components/shared/InnerHeader";
import { ai } from "@/plugins/axios";
import DatePicker from "@/components/shared/DatePicker";

export default {
  name: "DeleteTask",
  components: {
    InnerHeader,
    DatePicker,
  },
  props: {
    id: {
      default: "",
    },
    card: {
      default: false,
    },
    task: {
      required: true,
    },

    sideAction: {
      default: false,
    },
    inPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      errorMessage: "",
      form: {
        date: "",
        task_extention_reason: "",
      },
    };
  },
  methods: {
    setTaskDate(date) {
      this.form.date = date;
    },
    async submitForm() {
      if (this.form.date && this.form.task_extention_reason) {
        await this.changeDueDate();
        if (!this.errorMessage) {
          if (this.inPage) {
            this.dialog = false;
            this.$emit("refreshTaskDetails", true);
          } else {
            this.loading = false;
            this.$emit("refreshTasks", true);
          }
        }
      }
    },
    async changeDueDate() {
      this.loading = true;
      try {
        const request = {
          task_id: this.task.task_id,
          new_date: this.form.date,
          task_extention_reason: this.form.task_extention_reason,
        };
        await ai.post(`method/agenda.api.home.task.task_extension`, request);
        this.loading = false;
      } catch (err) {
        this.errorMessage = err.response?.data.message.detail;
        this.loading = false;
        console.log(err);
      }
    },
    openDi() {
      this.$emit("openDi");
    },
  },
};
</script>

<style lang="scss" scoped></style>
