<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        height="52"
        elevation="0"
        v-bind="props"
        :disabled="diabledBack"
        color="red"
        class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey gap-10"
      >
        {{ $t("confirmEndTask") }}
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center gap-24">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head letter-42">
            {{ $t("confirmEndTask") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="endTask"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <!-- <v-form class="mt-5 mb-6 border auth-form rounded-4 ps-4">
        </v-form> -->

        <div
          class="justify-center gap-24 mt-10 ps-4 mb-15 d-flex align-center flex-column"
        >
          <div>
            {{ $t("douWantToEndTask") }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "endTask",
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    endTask() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.dialog = false;
        this.$emit("endTask");
      }, 500);
    },
  },
};
</script>
