<template>
  <div>
    <div class="skeleton-row">
      <div class="skeleton-group">
        <div class="sk-description">
          <div class="sk-line sk-line-1"></div>
          <div class="sk-line sk-line-1"></div>
          <div class="sk-line sk-line-1"></div>
          <div class="sk-line sk-line-1"></div>
          <div class="sk-line sk-line-1"></div>
          <div class="sk-line sk-line-1"></div>
          <!-- Add more lines here if needed -->
        </div>
      </div>
    </div>
  </div>
</template>
