<template>
  <alert
    v-if="error"
    :content="$t('copyLinkeDone')"
    color="success"
    icon="mdi-alert-circle-outline"
  />
  <div class="table-ele-box pa-0 ps-10 pe-10 d-flex align-center" :key="index">
    <!-- box info -->

    <!-- person -->
    <div
      @click="toMember(member.member_id)"
      class="flex-1 gap-10 align-center d-flex justify-space-between"
    >
      <div class="gap-10 box-info align-center d-flex flex-grow-1">
        <!-- person data -->
        <img
          loading="lazy"
          :src="
            member.user_image
              ? `https://dev.agenda.42.guru/` + member.user_image
              : require('@/assets/images/noProfilePic.jpg')
          "
          alt=""
          @error="handleMemberBrokenImage"
        />
        <div class="ele-name">
          {{ member.full_name }}
        </div>
        <div class="ele-tag" v-if="member.role_name">
          {{ $t(`${member.role_name}`) }}
        </div>
      </div>
      <!-- <div class="ele-tag bg-red" v-if="member.job_status">
        {{ member.job_status }}
      </div> -->

      <!-- representative -->
      <!-- <div
        class="gap-10 representative-container align-center d-flex"
        v-if="member.on_behalf_of_member"
      >
        <div class="ele-tag">
                 <img
              loading="lazy" 
            :src="
              member.on_behalf_of_member_image
                ? member.on_behalf_of_member_image
                : require('@/assets/images/Image-2.svg')
            "
            alt=""
          />
          <div class="representative-name">
            {{ member.on_behalf_of_member }}
          </div>
        </div>
        <div class="ele-tag">
          {{ member.delegation_date }}
        </div>
      </div> -->
      <!-- sicne -->
      <!-- <div class="ele-tag">{{ formatDate(member.creation_date) }}</div> -->
      <!-- if info -->
      <!-- <div class="mt-1">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.9999 6.9999L6.9999 9.7999M6.9999 4.92451V4.8999M1.3999 6.9999C1.3999 3.90711 3.90711 1.3999 6.9999 1.3999C10.0927 1.3999 12.5999 3.90711 12.5999 6.9999C12.5999 10.0927 10.0927 12.5999 6.9999 12.5999C3.90711 12.5999 1.3999 10.0927 1.3999 6.9999Z"
            stroke="#475467"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div> -->
    </div>

    <div class="gap-10 d-fle align-centerx" v-if="actions">
      <div class="table-ele-box-control align-center gap-10">
        <div>
          <v-btn
            class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0"
            elevation="0"
            @click.prevent="copy('link need to copy')"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.01 7.45264L6.85145 7.45264C5.67181 7.45264 4.53008 7.91599 3.69284 8.76564C2.85559 9.61528 2.37991 10.7545 2.39899 11.971C2.37986 13.1875 2.85572 14.3269 3.69284 15.1764C4.54904 16.0453 5.65266 16.5088 6.83236 16.5088H9.99094M13.9868 16.5474H17.1454C18.3251 16.5474 19.4668 16.084 20.304 15.2344C21.1413 14.3847 21.617 13.2455 21.5979 12.029C21.5979 10.8319 21.1222 9.69263 20.285 8.84298C19.4477 7.99333 18.3251 7.51062 17.1454 7.5106H13.9868M7.25088 11.9578L16.7266 11.9578"
                stroke="#475467"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
        <representativeMember />
        <changeMemberRole
          :member="member"
          :member_id="member.member_id"
          :member_role="member.role_name"
          @refresh="refresh"
        />
        <suspendMemberAcc :member="member" />

        <!-- <memberMangment
          :memberId="member.member_id"
          :member_role="member.role"
          @refresh="refresh"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import memberMangment from "@/components/ui/dialogs/memberMangment";
import representativeMember from "@/components/ui/dialogs/representativeMember";
import changeMemberRole from "@/components/ui/dialogs/changeMemberRole";
import suspendMemberAcc from "@/components/ui/dialogs/suspendMemberAcc";

export default {
  name: "membersRow",
  components: {
    // memberMangment,
    representativeMember,
    changeMemberRole,
    suspendMemberAcc,
  },
  props: {
    member: {
      type: Object,
      default: () => {},
    },
    data() {
      return {};
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    space_id: {
      type: String,
      default: () => {},
    },
    entity_id: {
      type: String,
      default: () => {},
    },

    member_role: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      success: false,
    };
  },

  methods: {
    toMember(id) {
      if (this.space_id) {
        this.$router.push(`/${this.entity_id}/${this.space_id}/members/${id}`);
      } else {
        this.$router.push(`/${this.entity_id}/members/${id}`);
      }
    },
    formatDate(timestamp) {
      if (timestamp == undefined || timestamp == "") return "";
      const date = new Date(timestamp);
      const now = new Date();

      const dateFormatter = new Intl.DateTimeFormat("ar", {
        hour: "numeric",
        minute: "numeric",
      });

      if (this.isSameDay(date, now)) {
        return ` ${this.$t("today")} • ` + dateFormatter.format(date);
      } else if (this.isYesterday(date, now)) {
        return ` ${this.$t("yesterday")} • ` + dateFormatter.format(date);
      } else {
        return (
          date.toLocaleString("ar", { dateStyle: "long" }) +
          " • " +
          dateFormatter.format(date)
        );
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    },
    isYesterday(date1, date2) {
      const yesterday = new Date(date2);
      yesterday.setDate(date2.getDate() - 1);
      return this.isSameDay(date1, yesterday);
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/Image-2.svg");
    },
    refresh() {
      this.$emit("refresh");
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
      this.success = true;
      setTimeout(() => {
        // this.resetForm();
        this.success = false;
      }, 2000);
    },
  },
};
</script>
