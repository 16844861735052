<template>
  <div
    class="meeting-min-timeline dt-item-timeline main-elements-timeline meeting-ele"
  >
    <div class="mb-6 meetingTimeContent">
      <div
        v-for="(e, index) in items"
        :key="e.id"
        class="mt-6 expansion-center-container black-dark-color"
      >
        <v-expansion-panels class="dark-brown-color" elevation="0">
          <v-expansion-panel elevation="0">
            <v-expansion-panel-title
              class="mt-0 meeting-row align-center justify-space-between d-flex"
              elevation="0"
              :class="
                e.type == 'Partially Delayed' || e.type === 'Delayed'
                  ? 'bg-dark-item'
                  : e.type == 'New'
                  ? 'white-bg-header'
                  : e.type == 'Partially Canceled' || e.type === 'Canceled'
                  ? 'bg-dark-item'
                  : e.type == 'Discussed'
                  ? 'bg-primary-item'
                  : 'white-bg-header'
              "
            >
              <div class="gap-10 align-center d-flex flex-grow-1">
                <!-- num -->
                <v-btn class="meating-btn-icone pa-0 ma-0" elevation="0">
                  <div class="gap-10 align-center d-flex">
                    {{ index + 1 }}
                  </div>
                </v-btn>

                <!-- meeting title -->
                <div class="siz-18 font-weight-medium">
                  {{ e.agenda_title }}
                </div>
                <!-- meeting type -->
                <div
                  v-if="e.type === 'Partially Delayed' || e.type === 'Delayed'"
                  class="item-type-padge"
                >
                  {{ $t("delayed") }}
                </div>
                <div
                  v-if="
                    e.type === 'Partially Canceled' || e.type === 'Canceled'
                  "
                  class="item-type-padge"
                >
                  {{ $t("canceled") }}
                </div>
              </div>

              <div class="gap-10 align-center d-flex">
                <!-- time -->
                <div v-if="showDuration" class="time-alarm" elevation="0">
                  <div class="mt-1 siz-16" v-if="e.duration != null">
                    {{ formatSeconds(e.duration) }}
                  </div>

                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.5318 2.30886C15.1901 1.96715 14.636 1.96715 14.2943 2.30886C13.9526 2.65057 13.9526 3.20459 14.2943 3.5463L15.5318 2.30886ZM16.3813 5.63326C16.723 5.97497 17.277 5.97497 17.6187 5.63326C17.9604 5.29155 17.9604 4.73753 17.6187 4.39582L16.3813 5.63326ZM9.52717 8.07251C9.52717 7.58926 9.13542 7.19751 8.65217 7.19751C8.16892 7.19751 7.77717 7.58926 7.77717 8.07251H9.52717ZM7.77717 11.087C7.77717 11.5703 8.16892 11.962 8.65217 11.962C9.13542 11.962 9.52717 11.5703 9.52717 11.087H7.77717ZM6.33333 0.19751C5.85008 0.19751 5.45833 0.589261 5.45833 1.07251C5.45833 1.55576 5.85008 1.94751 6.33333 1.94751V0.19751ZM10.7391 1.94751C11.2224 1.94751 11.6141 1.55576 11.6141 1.07251C11.6141 0.589261 11.2224 0.19751 10.7391 0.19751V1.94751ZM13.5987 5.09147C13.257 5.43318 13.257 5.9872 13.5987 6.32891C13.9404 6.67062 14.4944 6.67062 14.8361 6.32891L13.5987 5.09147ZM15.9565 3.97106L16.5752 3.35234L15.9565 3.97106ZM7.77717 8.07251V11.087H9.52717V8.07251H7.77717ZM6.33333 1.94751H10.7391V0.19751H6.33333V1.94751ZM14.8361 6.32891L16.5752 4.58978L15.3378 3.35234L13.5987 5.09147L14.8361 6.32891ZM15.4293 11.2754C15.4293 15.0183 12.3951 18.0526 8.65217 18.0526V19.8026C13.3616 19.8026 17.1793 15.9848 17.1793 11.2754H15.4293ZM8.65217 18.0526C4.90924 18.0526 1.875 15.0183 1.875 11.2754H0.125C0.125 15.9848 3.94275 19.8026 8.65217 19.8026V18.0526ZM1.875 11.2754C1.875 7.53248 4.90924 4.49823 8.65217 4.49823V2.74823C3.94275 2.74823 0.125 6.56598 0.125 11.2754H1.875ZM8.65217 4.49823C12.3951 4.49823 15.4293 7.53248 15.4293 11.2754H17.1793C17.1793 6.56598 13.3616 2.74823 8.65217 2.74823V4.49823ZM14.2943 3.5463L15.3378 4.58978L16.5752 3.35234L15.5318 2.30886L14.2943 3.5463ZM15.3378 4.58978L16.3813 5.63326L17.6187 4.39582L16.5752 3.35234L15.3378 4.58978Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="">
                <!-- after terms title -->
                <div
                  class="border-t-0 term-container-ele rounded-4-bottom border-light"
                >
                  <div class="box-title">
                    <!-- description -->
                    <div class="pt-5 title">
                      {{ e.agenda_description }}
                    </div>
                    <!-- buttons -->
                    <div
                      v-if="showOptions && isOwner"
                      class="gap-10 mt-4 align-center d-flex"
                    >
                      <postponementItem
                        v-if="e.type === 'New'"
                        :agenda_id="e.agenda_id"
                        @refreshAgenda="emitRefreshOldData"
                      />
                      <canselMeetingItem
                        v-if="e.type === 'New'"
                        :agenda_id="e.agenda_id"
                        @refreshAgenda="emitRefreshOldData"
                      />
                      <editMeetingItem
                        @editItem="editItem"
                        :formated_duration="formatSeconds(e.duration)"
                        :item_data="e"
                      />

                      <v-btn
                        class="meeting-terms-btn"
                        elevation="0"
                        v-if="
                          e.type === 'Partially Canceled' ||
                          e.type === 'Canceled' ||
                          e.type === 'Partially Delayed' ||
                          e.type === 'Delayed'
                        "
                        @click="restoreItem(e.agenda_id)"
                        :loading="loading"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.79489 16L2 12.1538M2 12.1538L5.79489 8.30751M2 12.1538L14 12.1538C16.2091 12.1538 18 10.3629 18 8.15376L18 8C18 5.79086 16.2091 4 14 4L10 4"
                            stroke="#475467"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <span class="text ms-3">{{ $t("restoreItem") }}</span>
                      </v-btn>
                    </div>
                  </div>
                  <!-- ---------------------recommendation---------------------- -->
                  <div
                    v-if="!attachmentOnly"
                    class="recommendation border-t-light pa-5"
                  >
                    <div class="sec-box-heading siz-18 font-weight-bold a-dark">
                      {{ $t("recommendation") }}
                    </div>
                    <RecommendationBox
                      v-if="e.recommendations?.length > 0"
                      :recommendations="e.recommendations"
                      @refreshOldData="emitRefreshOldData"
                      :stopEdit="false"
                    />
                    <div v-else>
                      {{ $t("noRecomendetions") }}
                    </div>

                    <!-- add new recommendations -->
                    <div v-if="userAddPermissions.create">
                      <addNewRecomendation
                        v-if="stopEdit == false"
                        :agendaItem="e"
                        @refreshOldData="emitRefreshOldData"
                      />
                    </div>
                  </div>
                  <!-- ---------------------tasks---------------------- -->
                  <div v-if="!attachmentOnly" class="tasks border-t-light pa-5">
                    <div
                      class="mt-0 sec-box-heading siz-18 font-weight-bold a-dark"
                    >
                      {{ $t("tasks") }}
                    </div>
                    <TasksBox
                      v-if="e.tasks?.length > 0"
                      :tasks="e.tasks"
                      :isOwner="isOwner"
                    />
                    <div v-else>
                      {{ $t("noTasksYet") }}
                    </div>

                    <!-- add new Task -->
                    <div v-if="userAddPermissions.create">
                      <addNewTask
                        v-if="stopEdit == false"
                        :agendaItem="e"
                        @refreshOldData="emitRefreshOldData"
                      />
                    </div>
                  </div>
                  <!-- ---------------------voting---------------------- -->
                  <div
                    v-if="!attachmentOnly"
                    class="mt-0 voting border-t-light pa-5"
                  >
                    <div
                      class="mt-0 sec-box-heading siz-18 font-weight-bold a-dark"
                    >
                      {{ $t("voting") }}
                    </div>

                    <div
                      v-if="e.votes?.length > 0"
                      v-for="vote in e.votes"
                      :key="vote.vote_id"
                      class="mt-4 voting-header"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <div
                          class="d-flex align-center gap-10 voting-title-height"
                        >
                          {{ vote.vote_title }}
                        </div>
                        <div
                          v-if="isMemberOwner(vote.owner) && !isEnded"
                          class="gap-10 vote-btns"
                        >
                          <edit-voting
                            v-if="vote.status === 'New'"
                            :voteDt="vote"
                            @refreshOldData="emitRefreshOldData"
                          />
                          <delete-dialog
                            :textAndIcon="false"
                            :id="vote.vote_id"
                            :headerTitle="'deleteVoting'"
                            :checkboxLabel="'confirmDeleteVoting'"
                            :deleteReasonText="'enterDeleteVotingReason'"
                            :deleteReasonLable="'deleteVotingReason'"
                            @delete="deleteVoting(vote.vote_id)"
                          />
                        </div>
                      </div>
                      <Voting
                        :voting_number="vote?.voting_number"
                        :voteing="vote"
                      />
                    </div>
                    <div v-else>
                      {{ $t("noVotesYet") }}
                    </div>

                    <!-- add new voting -->
                    <div
                      v-if="
                        userAddPermissions.create &&
                        !stopVoting &&
                        allowAddVoting &&
                        !isEnded
                      "
                    >
                      <addNewVoting
                        @refreshOldData="emitRefreshOldData"
                        :agendaItem="e"
                      />
                    </div>
                  </div>
                  <!-- ---------------------attachment---------------------- -->
                  <div class="mt-0 attachment border-t-light pa-5">
                    <div
                      class="mt-0 sec-box-heading siz-18 font-weight-bold a-dark"
                    >
                      {{ $t("attachment") }}
                    </div>
                    <div v-if="e.attachments?.length > 0">
                      <div
                        v-for="file in e.attachments"
                        class="gap-10 mt-5 align-center d-flex justify-space-between attachmentRow"
                        :key="file.file_id"
                      >
                        <div class="gap-10 d-flex align-center">
                          <div class="file-icone">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.3715 0L14.5668 0.195263L18.5668 4.19527L18.762 4.39052V4.66667V18C18.762 19.1045 17.8666 20 16.762 20H3.42871C2.32414 20 1.42871 19.1045 1.42871 18V2C1.42871 0.895432 2.32414 0 3.42871 0H14.0954H14.3715Z"
                                fill="#E0316B"
                              />
                              <path
                                d="M14.1302 12.2825C15.3438 10.1408 5.9206 12.0683 7.27697 13.9958C8.70473 16.1374 12.3455 5.71477 10.2039 5.71477C8.27641 5.64339 12.7025 14.7097 14.1302 12.2825Z"
                                stroke="white"
                                stroke-width="0.5"
                                stroke-miterlimit="10"
                              />
                            </svg>
                          </div>
                          <div
                            class="gap-5 align-center d-flex text-gray black-dark-color"
                          >
                            {{ file.file_name }}.
                            {{ getFileType(file.file_name) }}
                          </div>
                          <div class="siz-12 text-label black-dark-color">
                            {{ formatFileSize(file.file_size) }}
                          </div>
                        </div>
                        <div class="delete-attachment">
                          <deleteAttachment
                            v-if="isOwner"
                            :id="file.file_id"
                            @fileDeleted="emitRefreshOldData"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      {{ $t("noattachmentYet") }}
                    </div>
                    <div
                      v-if="userAddPermissions.create && isOwner && !isEnded"
                    >
                      <Attachment
                        @upload="addNewFile"
                        :agendaId="e.agenda_id"
                        :isLoading="isLoading"
                      />
                    </div>
                    <div v-else-if="allowMemberAddFile && !isEnded">
                      <Attachment
                        @upload="addNewFile"
                        :agendaId="e.agenda_id"
                        :isLoading="isLoading"
                      />
                    </div>
                    <!-- add new attachment -->
                  </div>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import RecommendationBox from "@/components/ui/meetingAgenda/RecommendationBox";
import TasksBox from "@/components/ui/meetingAgenda/TasksBox";
import Voting from "@/components/ui/meetingAgenda/Voting";
import Attachment from "@/components/ui/meetingAgenda/Attachment";
import postponementItem from "@/components/ui/dialogs/postponementItem";
import canselMeetingItem from "@/components/ui/dialogs/canselMeetingItem";
import editMeetingItem from "@/components/ui/dialogs/editMeetingItem";
import addNewRecomendation from "@/components/ui/dialogs/addNewRecomendation";
import addNewTask from "@/components/ui/dialogs/addNewTask";
import addAttachment from "@/components/ui/dialogs/addAttachment";
import addNewVoting from "@/components/ui/dialogs/addNewVoting";
import deleteAttachment from "@/components/ui/dialogs/deleteAttachment";
import editVoting from "@/components/ui/dialogs/editVoting";
import deleteDialog from "@/components/ui/dialogs/deleteDialog.vue";
import { ai } from "@/plugins/axios";

export default {
  name: "MeetingItemsDetails",
  components: {
    RecommendationBox,
    TasksBox,
    Voting,
    Attachment,
    postponementItem,
    canselMeetingItem,
    editMeetingItem,
    addNewRecomendation,
    addNewTask,
    addAttachment,
    addNewVoting,
    deleteAttachment,
    editVoting,
    deleteDialog,
  },
  props: {
    items: {
      type: Array,
    },
    meeting_id: {
      type: String,
      required: true,
    },

    stopVoting: {
      type: Boolean,
      default: false,
    },
    addInItem: {
      type: Boolean,
      default: true,
    },

    stopEdit: {
      type: Boolean,
      default: false,
    },

    attachmentOnly: {
      type: Boolean,
      default: false,
    },
    showOptions: {
      type: Boolean,
      default: false,
    },
    showDuration: {
      type: Boolean,
      default: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    isEnded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      space_id: this.$route.params.space_id,
      userPermissions: [],
      userAddPermissions: {},
      member_id: "",
      allowMemberAddFile: false,
      allowAddVoting: false,
    };
  },

  methods: {
    isMemberOwner(member) {
      return this.member_id === member;
    },
    async loadMemberRole() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.role.get_space_member_role`,
          { params: { space_id: this.space_id } }
        );
        this.userPermissions = response.data.message.role.permissions;
        this.getAddPermissions();
      } catch (err) {
        this.errorMessage = err.response?.data.message.detail;
        console.log(err);
      }
    },
    getAddPermissions() {
      const item = this.userPermissions.find((e) => e.parent === "Meeting");

      if (item) {
        this.userAddPermissions = item;
      } else {
        console.log("Item with parent 'Meeting' not found");
      }
    },
    formatSeconds(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      // Use padStart to ensure leading zeros
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;

      return formattedTime;
    },

    getFileType(fileName, type) {
      const lastDotIndex = fileName.lastIndexOf(".");

      if (
        lastDotIndex !== -1 &&
        lastDotIndex !== 0 &&
        lastDotIndex < fileName.length - 1
      ) {
        const extension = fileName.substring(lastDotIndex + 1);
        return extension.toLowerCase();
      } else {
        return undefined;
      }
    },
    formatFileSize(size) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;
      if (size >= gigabyte) {
        return (size / gigabyte).toFixed(2) + " " + this.$t("fileSizes.GB");
      } else if (size >= megabyte) {
        return (size / megabyte).toFixed(2) + " " + this.$t("fileSizes.MB");
      } else if (size >= kilobyte) {
        return (size / kilobyte).toFixed(2) + " " + this.$t("fileSizes.KB");
      } else {
        return size + " bytes";
      }
    },
    emitRefreshOldData(item) {
      this.$emit("refreshOldData", true);
    },

    // update agenda item
    emitUpdateAgenda(item) {
      this.$emit("UPDATE_AGENDA", item);
    },

    editItem(item) {
      try {
        ai.put(`method/agenda.api.meeting.agenda.update_agenda`, item);

        console.log(item);
      } catch (err) {
        this.errorMessage = err.response?.data.message.detail;
        console.log(err);
      }
    },

    async restoreItem(agenda_id) {
      try {
        this.loading = true;
        await ai.put("method/agenda.api.meeting.agenda.update_agenda", {
          agenda_id: agenda_id,
          type: "New",
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    async deleteVoting(id) {
      try {
        await ai.delete("method/agenda.api.meeting.agenda_vote.delete_vote", {
          params: {
            vote_id: id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async addNewFile(files) {
      this.isLoading = true;
      for (const file of files) {
        try {
          const attachmentFormData = new FormData();
          attachmentFormData.append("file", file);
          attachmentFormData.append("doctype", "Agenda Item");
          attachmentFormData.append("docname", file.agenda_id);
          await ai.post(
            "method/agenda.api.meeting.agenda.upload_agenda_file",
            attachmentFormData
          );
          this.isLoading = false;
          // this.emitRefreshOldData();
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      }
      // this.emitRefreshOldData();
    },

    async getGovernanceSettings() {
      try {
        const response = await ai.get(
          "method/agenda.api.space.space.get_space_module_settings",
          {
            params: {
              space_id: this.space_id,
              module: "Governance",
            },
          }
        );
        const governanceSetting = response.data.message.settings;
        this.allowMemberAddFile =
          governanceSetting.allow_members_to_attach_files;
        this.allowAddVoting = governanceSetting.allow_voting;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.loadMemberRole();
    this.getGovernanceSettings();
    this.member_id = JSON.parse(localStorage.getItem("user"))?.member_id;
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.meetingTimeContent {
  overflow-x: auto;
  overflow-y: hidden;
}

.attachmentRow {
  .delete-attachment {
    display: none;
  }
  &:hover {
    .delete-attachment {
      display: flex;
    }
  }
}
</style>
