<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn class="meeting-terms-btn" v-bind="props" elevation="0">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5 3L15 6M2.5 12L12.3595 1.79619C13.4211 0.734605 15.1422 0.734603 16.2038 1.79619C17.2654 2.85777 17.2654 4.57894 16.2038 5.64052L6 15.5L1 17L2.5 12Z"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text ms-3">
          {{ $t("editItem") }}
        </span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <!-- {{ item_data }} -->
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="closeModal()"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            <span>{{ $t("editItem") }}</span>
          </div>
        </div>

        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="editItem()"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="mt-5 mb-10 auth-form border-light rounded-4 ms-4">
          <v-text-field
            class="bg-transparent form-input-box"
            v-model="form.agenda_title"
            type="text"
            :placeholder="$t('ItemAddress')"
            :label="$t('agenda_title')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
          <v-text-field
            class="bg-transparent form-input-box"
            v-model="form.agenda_description"
            type="text"
            :placeholder="$t('addDecs')"
            :label="$t('agenda_description')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>

          <div class="date-container time-content form-input-box">
            <date-picker
              onlyTime="true"
              dateOnly="false"
              @selectedTime="setMeetingDuartion"
              fullTimePacker="true"
              class="10"
            />
            <div class="gap-10 d-flex align-center">
              <v-btn
                class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                evaluation="0"
                flat
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.6244 2.98274C19.3315 2.68985 18.8567 2.68985 18.5638 2.98274C18.2709 3.27564 18.2709 3.75051 18.5638 4.0434L19.6244 2.98274ZM21.0681 6.54775C21.361 6.84065 21.8359 6.84065 22.1288 6.54775C22.4217 6.25486 22.4217 5.77999 22.1288 5.48709L21.0681 6.54775ZM12.331 9.68699C12.331 9.27277 11.9953 8.93699 11.581 8.93699C11.1668 8.93699 10.831 9.27277 10.831 9.68699H12.331ZM10.831 13.3044C10.831 13.7186 11.1668 14.0544 11.581 14.0544C11.9953 14.0544 12.331 13.7186 12.331 13.3044H10.831ZM8.79844 0.536987C8.38422 0.536987 8.04844 0.872774 8.04844 1.28699C8.04844 1.7012 8.38422 2.03699 8.79844 2.03699V0.536987ZM14.0854 2.03699C14.4996 2.03699 14.8354 1.7012 14.8354 1.28699C14.8354 0.872774 14.4996 0.536987 14.0854 0.536987V2.03699ZM17.729 6.32187C17.4361 6.61477 17.4361 7.08964 17.729 7.38253C18.0219 7.67543 18.4967 7.67543 18.7896 7.38253L17.729 6.32187ZM20.3463 4.76525L20.8766 4.23492L20.3463 4.76525ZM10.831 9.68699V13.3044H12.331V9.68699H10.831ZM8.79844 2.03699H14.0854V0.536987H8.79844V2.03699ZM18.7896 7.38253L20.8766 5.29558L19.8159 4.23492L17.729 6.32187L18.7896 7.38253ZM20.0137 13.5305C20.0137 18.1877 16.2382 21.9631 11.581 21.9631V23.4631C17.0667 23.4631 21.5137 19.0161 21.5137 13.5305H20.0137ZM11.581 21.9631C6.92384 21.9631 3.14844 18.1877 3.14844 13.5305H1.64844C1.64844 19.0161 6.09542 23.4631 11.581 23.4631V21.9631ZM3.14844 13.5305C3.14844 8.87326 6.92384 5.09786 11.581 5.09786V3.59786C6.09542 3.59786 1.64844 8.04484 1.64844 13.5305H3.14844ZM11.581 5.09786C16.2382 5.09786 20.0137 8.87326 20.0137 13.5305H21.5137C21.5137 8.04484 17.0667 3.59786 11.581 3.59786V5.09786ZM18.5638 4.0434L19.8159 5.29558L20.8766 4.23492L19.6244 2.98274L18.5638 4.0434ZM19.8159 5.29558L21.0681 6.54775L22.1288 5.48709L20.8766 4.23492L19.8159 5.29558Z"
                    fill="#667085"
                  />
                </svg>
              </v-btn>

              <v-text-field
                v-model="form.duration"
                type="text"
                :placeholder="$t('enterItemDuration')"
                :label="$t('duration')"
                outlined
                solo
                dense
                variant="solo"
                hide-details="auto"
                persistent-placeholder
              ></v-text-field>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import DatePicker from "@/components/shared/DatePicker";

export default {
  name: "editMeetingItem",
  components: {
    DatePicker,
  },
  props: {
    item_data: {
      default: null,
    },
  },
  computed: {
    formattedDuration: {
      get() {
        // Convert the duration from seconds to HH:MM format
        const hours = Math.floor(this.form.duration / 3600);
        const minutes = Math.floor((this.form.duration % 3600) / 60);
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
      },
      set(value) {
        // Convert the HH:MM format to seconds and update the model
        const [hours, minutes] = value.split(":").map(Number);
        this.form.duration = hours * 3600 + minutes * 60;
      },
    },
  },
  // watch: {
  //   item_data: {
  //     handler: function (val, oldVal) {
  //       this.form.agenda_id = val.agenda_id;
  //       this.form.agenda_title = val.agenda_title;
  //       this.form.agenda_description = val.agenda_description;
  //       this.duration = item_data.duration;
  //     },
  //     deep: true,
  //   },
  // },

  data() {
    return {
      dialog: false,
      loading: false,
      handelDuration: null,
      form: {
        agenda_id: "",
        agenda_title: "",
        agenda_description: "",
        duration: "",
      },
    };
  },

  methods: {
    closeModal() {
      this.dialog = false;

      setTimeout(() => {
        this.form.agenda_title = "";
        this.form.agenda_description = "";
        this.form.duration = "";
      }, 500);

      this.$emit("closeModal");
    },
    setMeetingDuartion(duration) {
      let hours = duration.hours < 10 ? `0${duration.hours}` : duration.hours;
      let minutes =
        duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes;
      this.form.duration = `${hours}:${minutes}`;
    },
    setdurationInSeconds(duration) {
      const [hours, minutes] = duration.split(":").map(Number);

      // Convert hours and minutes to seconds
      const totalSeconds = (hours * 60 + minutes) * 60;

      return totalSeconds;
    },
    secondsToTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

      const formattedTime = `${formattedHours}:${formattedMinutes}`;

      return formattedTime;
    },
    editItem() {
      this.loading = true;
      const item = {
        agenda_id: this.form.agenda_id,
        agenda_title: this.form.agenda_title,
        agenda_description: this.form.agenda_description,
        duration: this.setdurationInSeconds(this.form.duration),
      };
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.$emit("editItem", item);
      this.dialog = false;
    },
  },

  mounted() {
    this.form.agenda_id = this.item_data.agenda_id;
    this.form.agenda_title = this.item_data.agenda_title;
    this.form.agenda_description = this.item_data.agenda_description;
    this.form.duration = this.secondsToTime(this.item_data.duration);
  },
};
</script>
