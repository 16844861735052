<template>
  <div v-if="entity_id">
    <div
      :class="openSideInfo ? 'openSide' : ''"
      class="entities-spaces-container position-absolute px-5 rounded"
    >
      <div class="my-5" v-if="space_id">
        <div
          v-if="space_id && entity_id !== 'personal-space'"
          class="d-flex align-center gap-20 cursor-pointer item-field-row"
          @click="$router.push(`/${entity_id}`)"
        >
          <div
            v-if="entity_info.company_logo"
            class="entity-img overflow-hidden"
          >
            <img
              width="100%"
              height="100%"
              :src="`https://dev.agenda.42.guru/` + entity_info.company_logo"
              alt=""
            />
          </div>
          <missingHeroImg v-else :svg="entitySvg" :width="32" :height="32" />
          <div class="entity-title">
            {{ entity_info.company_name }}
          </div>
        </div>
        <div
          class="d-flex align-center mt-5 gap-20 mr-10 item-field-row cursor-pointer"
          v-for="space in spaces"
          :key="space.space_id"
          @click="$router.push(`/${entity_id}/${space.space_id}`)"
          :class="{ active: $route.path === `/${entity_id}/${space.space_id}` }"
        >
          <div v-if="space.logo" class="space-img overflow-hidden">
            <img
              width="100%"
              height="100%"
              :src="`https://dev.agenda.42.guru/` + space.logo"
              alt=""
            />
          </div>
          <missingHeroImg
            v-else
            :svg="spaceSvg"
            :color="space.cover_color"
            :width="28"
            :height="28"
          />
          <div class="entity-title">
            {{ space.space_name }}
          </div>
        </div>
      </div>
      <div
        class="d-flex align-center gap-20 my-5 item-field-row cursor-pointer"
        v-for="entity in entities"
        :key="entity.entity_id"
        @click="$router.push(`/${entity.entity_id}`)"
        :class="{ active: $route.path === `/${entity.entity_id}` }"
      >
        <div v-if="entity.entity_logo" class="entity-img overflow-hidden">
          <img
            width="100%"
            height="100%"
            :src="`https://dev.agenda.42.guru//` + entity.entity_logo"
            alt=""
          />
        </div>
        <missingHeroImg v-else :svg="entitySvg" :width="32" :height="32" />
        <div class="entity-title">
          {{ entity.entity_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import missingHeroImg from "@/components/shared/missingHeroImg.vue";
import { ai } from "@/plugins/axios";

export default {
  name: "entitiesSpacesContainer",
  components: {
    missingHeroImg,
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      userId: "",
      entity_info: {},
      spaces: [],
      entities: [],
      spaceSvg: ` <svg
        data-v-73214ae6=""
        width="12"
        height="12"
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0C3.10156 0 0.75 2.35156 0.75 5.25V22.75C0.75 25.6484 3.10156 28 6 28H21.75H23.5H25.25V24.5H23.5V21H25.25V0H23.5H21.75H6ZM6 21H20V24.5H6C5.03203 24.5 4.25 23.718 4.25 22.75C4.25 21.782 5.03203 21 6 21ZM7.75 7H20V8.75H7.75V7ZM20 10.5V12.25H7.75V10.5H20Z"
          fill="#fff"
        ></path>
      </svg>`,
      entitySvg: ` <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 16.6663H17.5V8.33301C17.5 7.41253 16.7538 6.66634 15.8333 6.66634H12.5M9.16667 13.333H9.175M14.1667 13.333H14.175M5.83333 13.333H5.84167M9.16667 9.99967H9.175M14.1667 9.99967H14.175M5.83333 9.99967H5.84167M9.16667 6.66634H9.175M5.83333 6.66634H5.84167M12.5 16.6663V4.99967C12.5 4.0792 11.7538 3.33301 10.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663H12.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>`,
    };
  },
  props: {
    openSideInfo: {
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      this.entity_id = this.$route.params.entity_id;
      this.space_id = this.$route.params.space_id;

      this.entity_info = {};
      this.spaces = [];
      this.entities = [];

      this.loadSpaces();
      this.loadEntities();
      const entityInfoJson = localStorage.getItem("entity_info");
      console.log(entityInfoJson);
      if (entityInfoJson !== null) {
        this.entity_info = entityInfoJson;
      }
    },
  },
  methods: {
    async loadSpaces() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_all_spaces`,
          { params: { entity_id: this.entity_id } }
        );
        this.spaces = response.data.message.spaces;
      } catch (err) {
        console.log(err);
      }
    },
    async loadEntities() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_user_entities`,
          { params: { user_id: this.userId } }
        );
        this.entities = response.data.message.entity_information;
      } catch (err) {
        console.log(err);
      }
    },
    async loadEntityInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_entity_information`,
          { params: { entity_id: this.entity_id } }
        );
        this.entity_info = response.data.message;
        localStorage.setItem(
          "entity_info",
          JSON.stringify(response.data.message)
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.loadEntityInfo();
    this.loadSpaces();
    this.loadEntities();
    const entityInfoJson = localStorage.getItem("entity_info");
    if (entityInfoJson !== null) {
      this.entity_info = entityInfoJson;
    }

    this.userId = JSON.parse(localStorage.getItem("user"))?.user_id;
  },
};
</script>
