<template>
  <v-form class="mb-10 border auth-form rounded-4 mt-n6">
    <v-text-field
      class="bg-transparent form-input-box"
      v-model="form.task_title"
      type="text"
      :placeholder="$t('task_title')"
      :label="$t('task_title')"
      :rules="rules"
      outlined
      solo
      dense
      variant="solo"
      hide-details="auto"
      persistent-placeholder
    ></v-text-field>

    <div class="date-container border-b-light">
      <date-picker @selectedDate="setSubTaskDate" class="10" />
      <div class="gap-10 form-input-box d-flex align-center ps-5">
        <v-btn
          class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
          evaluation="0"
          flat
        >
          <svg
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.875 7.57139H17.625M3.91071 1V2.71449M15.375 1V2.71428M18.75 5.71428L18.75 18C18.75 19.6569 17.4069 21 15.75 21H3.75C2.09315 21 0.75 19.6568 0.75 18V5.71428C0.75 4.05742 2.09315 2.71428 3.75 2.71428H15.75C17.4069 2.71428 18.75 4.05742 18.75 5.71428Z"
              stroke="#667085"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>

        <v-text-field
          v-model="form.task_date"
          type="text"
          :placeholder="$t('taskDate')"
          :label="$t('taskDate')"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          :rules="rules"
        ></v-text-field>
      </div>
    </div>

    <!-- add other memnber -->
    <main-task-member
      @selectedMembers="selectedMembersSubTask"
      :currentMembers="selectedMembersFirst"
    />
  </v-form>
</template>

<script>
import AssignMember from "@/components/ui/dialogs/AssignMember";
import { tr } from "vuetify/locale";
import { ai } from "@/plugins/axios";
import moment from "moment";
import DatePicker from "@/components/shared/DatePicker";
import mainTaskMember from "@/components/ui/tasks/mainTaskMember";

export default {
  name: "sub-task",
  components: {
    AssignMember,
    DatePicker,
    mainTaskMember,
  },

  props: {
    current_members: {
      default: () => [],
    },

    subtaskFormIndex: {},
    subtask: {
      type: Object,
    },
    rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
  },
  watch: {
    form: {
      handler(val) {
        this.$emit("subtaskDataUpdated", val);
      },
      deep: true,
    },
  },

  data() {
    return {
      AssignMemberEmail: "",
      form: {
        index: this.subtaskFormIndex,
        task_id: "",
        task_title: "",
        task_date: "",
        task_assignee: "",
        first_name: "",
        last_name: "",
        user_image: "",
        status: "In Progress",
      },
      selectedMembersFirst: [],
    };
  },

  methods: {
    selectedMembersSubTask(e) {
      this.selectedMembersFirst = e;
      let selectedMembers = e.map((member) => ({
        member: member.member_id || member.member,
      }));
      this.form.task_assignee = selectedMembers;
    },

    setSubTaskDate(date) {
      this.form.task_date = date;
    },

    deleteSubTaskAssignee() {
      this.form.task_assignee = null;
    },
    OnlyDate(creation) {
      const dateParts = creation.split(" ");
      const date = dateParts[0];
      return date;
    },
  },
  mounted() {
    if (this.subtask) {
      this.form.task_id = this.subtask.task_id;
      this.form.task_title = this.subtask.task_title;
      this.form.task_date = this.OnlyDate(this.subtask.task_date);
      this.form.index = this.subtaskFormIndex;
      this.form.first_name = this.subtask.first_name;
      this.form.last_name = this.subtask.last_name;
      this.form.user_image = this.subtask.user_image;
      // this.form.task_assignee = this.subtask.task_assignee;

      // set assginee
      // this.form.task_assignee =
      // response.data.message.task_assignee;
      this.selectedMembersSubTask(this.subtask.task_assignee);
    }
    console.log("ssss", this.subtask);
  },
  created() {},
};
// nhq  abi
</script>
