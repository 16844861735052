<template>
  <div class="align-center d-flex gap-10">
    <div class="siz-12">
      {{ currentValue }}
      <span v-if="viewPercentage">({{ vote_option_percentage_floor }}%)</span>
    </div>
    <div class="progress-container">
      <div
        class="progress"
        :style="{
          width: progressPercentage + '%',
          backgroundColor: progressPercentage >= 50 ? '#12B76A' : '#475467',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VotingProgressBar",
  data() {
    return {};
  },
  props: {
    currentValue: {
      type: Number,
      default: 0,
    },
    vote_option_percentage: {
      type: Number,
      default: 100,
    },
    refranceValue: {
      type: Number,
      default: 100,
    },
    viewPercentage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    progressPercentage() {
      if (this.currentValue && this.refranceValue) {
        return (this.currentValue * 100) / this.refranceValue;
      } else {
        return "0";
      }
    },
    vote_option_percentage_floor() {
      if (isNaN(this.vote_option_percentage)) {
        return "0";
      } else {
        return Math.round(this.vote_option_percentage);
      }
    },
  },
};
</script>

<style lang="scss">
.progress-container {
  width: 100px;
  height: 8px;

  background: #eaecf0;
  border-radius: 8px;
  .progress {
    height: 100%;
    border-end-start-radius: 4px;
    border-start-start-radius: 4px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      // content: "";
      width: 8px;
      background: #eaecf0;
      height: 11px;
      left: -7px;
      top: -2px;
      transform: rotate(16deg);
    }
  }
}
</style>
