<template>
  <div>
    <div v-for="item in items" :key="item.id">
      <div class="meeting-row align-center justify-space-between d-flex">
        <div class="align-center d-flex gap-10">
          <!-- num -->
          <v-btn class="meating-btn-icone pa-0 ma-0" elevation="0">
            <div class="align-center d-flex gap-10">{{ item.id }}</div>
          </v-btn>

          <div>{{ item.head }}</div>
        </div>
      </div>
      <div class="term-container-ele rounded-4-bottom border-light border-t-0">
        <!-- ---------------------recommendation---------------------- -->
        <div class="recommendation border-t-light pa-5">
          <div class="siz-18 font-weight-bold a-dark mb-2">
            {{ $t("recommendation") }}
          </div>
          <RecommendationBox
            :recommendations="item.recommendations"
            @refreshOldData="refreshData"
            :stopEdit="userCurrentRole == 'Member'"
          />
        </div>
        <!-- ---------------------tasks---------------------- -->
        <div class="tasks border-t-light pa-5">
          <div class="mt-0 mb-2 siz-18 font-weight-bold a-dark">
            {{ $t("tasks") }}
          </div>
          <TasksBox :tasks="item.tasks" />
        </div>

        <!-- ---------------------voting---------------------- -->
        <div class="voting border-t-light mt-0 pa-5">
          <div class="mt-0 mb-5 siz-18 font-weight-bold a-dark">
            {{ $t("voting") }}
          </div>
          <div class="mb-5 siz-16 font-weight-medium">
            {{
              $t(
                "hereIsTheVotingTextWhichWillMostLikelyBeAQuestionDoYouAgreeWithThis"
              )
            }}
          </div>
          <agendaCurrentVotes
            v-for="vote in item.vote"
            :key="vote.vote_id"
            :item_data="vote"
            :meetingDt_evnet="meetingDt_evnet"
            @refreshOldData="refreshData"
          />
          <!-- <div
                    v-for="vote in item_data.vote"
                    :key="vote.vote_id"
                    class="voting-header mt-3"
                  >
                    <div class="d-flex align-center justify-space-between">
                      <div class="flex-grow-1">
                        {{ vote.vote_title }}
                      </div>
                      {{ item_data }}
                      <memberAgendaVoting
                        :openDialogToVote="openDialogToVote"
                      />
                    </div>
                    <div
                      class="vote-number align-center gap-20 d-flex"
                      v-if="item_data.voting?.voting_number"
                    >
                      <div>
                        3 من
                        {{ item_data.voting?.voting_number }} أصوات , 2 ملاحظات
                      </div>
                      <v-btn
                        class="squer-rouneded-btn squer-sm bg-red"
                        elevation="0"
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.3588 7.7019C13.6516 7.40901 13.6516 6.93414 13.3588 6.64124C13.0659 6.34835 12.591 6.34835 12.2981 6.64124L13.3588 7.7019ZM6.64124 12.2981C6.34835 12.591 6.34835 13.0659 6.64124 13.3588C6.93414 13.6516 7.40901 13.6516 7.7019 13.3588L6.64124 12.2981ZM12.2981 13.3588C12.591 13.6516 13.0659 13.6516 13.3588 13.3588C13.6516 13.0659 13.6516 12.591 13.3588 12.2981L12.2981 13.3588ZM7.7019 6.64124C7.40901 6.34835 6.93414 6.34835 6.64124 6.64124C6.34835 6.93414 6.34835 7.40901 6.64124 7.7019L7.7019 6.64124ZM17.25 10C17.25 14.0041 14.0041 17.25 10 17.25V18.75C14.8325 18.75 18.75 14.8325 18.75 10H17.25ZM10 17.25C5.99593 17.25 2.75 14.0041 2.75 10H1.25C1.25 14.8325 5.16751 18.75 10 18.75V17.25ZM2.75 10C2.75 5.99593 5.99593 2.75 10 2.75V1.25C5.16751 1.25 1.25 5.16751 1.25 10H2.75ZM10 2.75C14.0041 2.75 17.25 5.99593 17.25 10H18.75C18.75 5.16751 14.8325 1.25 10 1.25V2.75ZM12.2981 6.64124L9.46967 9.46967L10.5303 10.5303L13.3588 7.7019L12.2981 6.64124ZM9.46967 9.46967L6.64124 12.2981L7.7019 13.3588L10.5303 10.5303L9.46967 9.46967ZM13.3588 12.2981L10.5303 9.46967L9.46967 10.5303L12.2981 13.3588L13.3588 12.2981ZM10.5303 9.46967L7.7019 6.64124L6.64124 7.7019L9.46967 10.5303L10.5303 9.46967Z"
                            fill="white"
                          />
                        </svg>
                      </v-btn>
                    </div>
                    <Voting
                      :voting_number="item_data.voting?.voting_number"
                      :voteing="vote.vote_options"
                    />
                  </div> -->

          <!-- start voting -->
        </div>
        <!-- ---------------------attachment---------------------- -->
        <div class="attachment border-t-light mt-5 pa-5">
          <div class="mt-0 siz-18 font-weight-bold a-dark">
            {{ $t("attachment") }}
          </div>
          <div class="current-files ps-0 mb-5">
            <div
              v-for="file in item.attachments"
              class="mt-5 align-center gap-10 d-flex"
              :key="file.file_id"
            >
              <div class="file-icone me-3">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3715 0L14.5668 0.195263L18.5668 4.19527L18.762 4.39052V4.66667V18C18.762 19.1045 17.8666 20 16.762 20H3.42871C2.32414 20 1.42871 19.1045 1.42871 18V2C1.42871 0.895432 2.32414 0 3.42871 0H14.0954H14.3715Z"
                    fill="#E0316B"
                  />
                  <path
                    d="M14.1302 12.2825C15.3438 10.1408 5.9206 12.0683 7.27697 13.9958C8.70473 16.1374 12.3455 5.71477 10.2039 5.71477C8.27641 5.64339 12.7025 14.7097 14.1302 12.2825Z"
                    stroke="white"
                    stroke-width="0.5"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>
              <div class="align-center d-flex gap-5 text-gray">
                {{ file.file_name }}.
                {{ getFileType(file.file_name) }}
              </div>
              <div class="siz-12 text-label">
                {{ formatFileSize(file.file_size) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecommendationBox from "@/components/ui/meetingAgenda/RecommendationBox";
import TasksBox from "@/components/ui/meetingAgenda/TasksBox";
import Voting from "@/components/ui/meetingAgenda/Voting";
import Attachment from "@/components/ui/meetingAgenda/Attachment";
import postponementItem from "@/components/ui/dialogs/postponementItem";
import canselMeetingItem from "@/components/ui/dialogs/canselMeetingItem";
import editMeetingItem from "@/components/ui/dialogs/editMeetingItem";
import VoteDetailRow from "@/components/ui/rows/VoteDetailRow.vue";
import addNewRecomendation from "@/components/ui/dialogs/addNewRecomendation";
import addNewTask from "@/components/ui/dialogs/addNewTask";
import addAttachment from "@/components/ui/dialogs/addAttachment";
import addNewVoting from "@/components/ui/dialogs/addNewVoting";
import { ai } from "@/plugins/axios";

export default {
  name: "FinishedItems",
  props: {
    items: {
      type: Array,
    },
    addOptions: {
      type: Boolean,
      default: false,
    },
    meeting_id: {
      type: String,
    },
  },
  components: {
    RecommendationBox,
    TasksBox,
    Voting,
    Attachment,
    postponementItem,
    canselMeetingItem,
    editMeetingItem,
    VoteDetailRow,
    addNewRecomendation,
    addNewTask,
    addAttachment,
    addNewVoting,
  },
  data() {
    return {};
  },

  methods: {
    addClassFTime() {
      const fTiemLine = document.querySelectorAll(".main-elements-timeline");

      // fTiemLine.forEach((e) => {
      //   const firstEle = e.querySelectorAll(".v-timeline");
      //   firstEle[0].classList.add("gradientTop");
      //   firstEle[firstEle.length - 1].classList.add("gradientBottom");
      // });
    },

    getFileType(fileName, type) {
      const lastDotIndex = fileName.lastIndexOf(".");

      if (
        lastDotIndex !== -1 &&
        lastDotIndex !== 0 &&
        lastDotIndex < fileName.length - 1
      ) {
        const extension = fileName.substring(lastDotIndex + 1);
        return extension.toLowerCase();
      } else {
        return undefined;
      }
    },
    formatFileSize(size) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;
      if (size >= gigabyte) {
        return (size / gigabyte).toFixed(2) + " " + this.$t("fileSizes.GB");
      } else if (size >= megabyte) {
        return (size / megabyte).toFixed(2) + " " + this.$t("fileSizes.MB");
      } else if (size >= kilobyte) {
        return (size / kilobyte).toFixed(2) + " " + this.$t("fileSizes.KB");
      } else {
        return size + " bytes";
      }
    },

    refreshData() {
      this.$emit("refreshData");
    },
    async addNewFile(files) {
      for (const file of files) {
        try {
          const attachmentFormData = new FormData();
          attachmentFormData.append("file", file);
          attachmentFormData.append("doctype", "Agenda Item");
          attachmentFormData.append("docname", file.agenda_id);
          await ai.post(
            "method/agenda.api.meeting.agenda.upload_agenda_file",
            attachmentFormData
          );
        } catch (err) {
          console.log(err);
        }
      }
      this.refreshData();
    },
  },
  mounted() {
    //   ();
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.meetingTimeContent {
  overflow-x: auto;
}

.dt-item-timeline {
  .v-expansion-panel {
    // overflow-x: auto;
    min-width: 700px;
  }
  .v-timeline--vertical.v-timeline--align-start
    .v-timeline-divider
    .v-timeline-divider__before {
    height: calc(
      var(--v-timeline-line-size-offset) + var(--v-timeline-dot-size) / 2 -
        var(--v-timeline-line-inset) - 24px
    );
    // background: red !important;
  }
  .v-timeline--vertical.v-timeline
    .v-timeline-item:last-child
    .v-timeline-divider {
    // padding-block-start: 75px !important;
  }
  .v-expansion-panel-title--active {
    border-bottom: 0 !important;
    border-bottom-right-radius: 00px !important;
    border-bottom-left-radius: 00px !important;
  }
  .v-expansion-panel-title__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    gap: 5px;
    width: 46px;
    height: 46px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .mdi:before {
    font-size: 20px;
  }
  &.main-elements-timeline {
    &.meeting-ele {
      .meeting-row {
        background-color: #f9fafb;
        padding: 20px;
        gap: 10px;
        // width: 830px;
        height: 86px;
        border: 1px solid #eaecf0;
        border-radius: 8px;

        .time-alarm {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 13px;
          gap: 5px;
          height: 46px;
          min-width: 46px;
          width: auto;
          background: #ffffff;
          border: 1px solid #eaecf0;
          border-radius: 8px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .add-meeting-row {
        // background-color: $light;
        padding: 20px;
        gap: 20px;
        // width: 830px;
        height: 86px;
        border: 1px solid #eaecf0;
        border-radius: 8px;

        .term-type {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 89px;
          height: 42px;
          font-size: 14px;
          background: #ffffff;
          border: 1px solid #eaecf0;
          border-radius: 8px;
        }
      }
      .v-timeline-divider__dot .v-timeline-divider__inner-dot {
        background: #d0d5dd;
      }

      .v-timeline {
        display: grid;
        grid-template-columns: 0px 75px 2fr;
      }
    }

    .add-new-terms {
      .v-timeline-divider__dot .v-timeline-divider__inner-dot {
        // background: red;
        width: 8px;
        height: 8px;
      }
    }
  }
}

.meeting-row {
  background-color: #f9fafb;
  padding: 20px;
  gap: 20px;
  // width: 830px;
  height: 86px;
  border: 1px solid #eaecf0;
  border-radius: 8px;

  .time-alarm {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    gap: 5px;
    width: 84px;
    height: 46px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
