<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        elevation="0"
        class="fill-w-btn siz-14 send-gray-btn mt-0 rounded-4 rounded-0 w-100"
        :class="border ? 'border-light' : 'border-0 border-t-light'"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.7813 3.57698C19.3874 3.17985 18.8533 2.95674 18.2963 2.95674L5.69633 2.95674C5.13937 2.95674 4.60523 3.17985 4.2114 3.57698M11.9952 21.0435L11.9952 9.04346M11.9952 9.04346L7.19515 13.6286M11.9952 9.04346L16.7952 13.6286"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="ms-2"> {{ $t("addFile") }} </span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <v-btn
          color="transparent"
          class="main-header-btn img-24 pa-0 ma-0 ms-8"
          elevation="0"
          @click="dialog = false"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M19 19L5 5"
              stroke="#475467"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </v-btn>
        <div class="main-header">{{ $t("addFile") }}</div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <!-- add file component -->
        <div class="add-file-box">
          <input type="file" @change="handleFileUpload" />
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.7996 57.5998H15.9996C12.465 57.5997 9.59959 54.7343 9.59961 51.1997L9.59986 12.7999C9.59988 9.26526 12.4653 6.3999 15.9999 6.3999H44.8006C48.3352 6.3999 51.2006 9.26528 51.2006 12.7999V30.3999M41.6006 48.1134L48.1453 41.5999M48.1453 41.5999L54.4006 47.819M48.1453 41.5999V57.5999M20.8006 19.1999H40.0006M20.8006 28.7999H40.0006M20.8006 38.3999H30.4006"
              stroke="#475467"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="text-gray">
            <span> اسحب الملف وافلته هنا أو </span>
            <span class="text-decoration-underline color-primary">حدد ملف</span>
            من جهازك
          </div>
        </div>
        <!-- uploaded file -->
        <v-card elevation="0" class="border-light mt-5 mb-15">
          <v-card
            elevation="0"
            class="current-files align-center justify-space-between pa-5 w-100 d-flex"
            v-if="fileInfo"
          >
            <!-- file -->
            <div class="align-center d-flex gap-10 flex-grow-1">
              <div class="file-icone me-3">
                <svg
                  width="20"
                  height="20"
                  class="normal"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3715 0L14.5668 0.195263L18.5668 4.19527L18.762 4.39052V4.66667V18C18.762 19.1045 17.8666 20 16.762 20H3.42871C2.32414 20 1.42871 19.1045 1.42871 18V2C1.42871 0.895432 2.32414 0 3.42871 0H14.0954H14.3715Z"
                    fill="#E0316B"
                  />
                  <path
                    d="M14.1302 12.2825C15.3438 10.1408 5.9206 12.0683 7.27697 13.9958C8.70473 16.1374 12.3455 5.71477 10.2039 5.71477C8.27641 5.64339 12.7025 14.7097 14.1302 12.2825Z"
                    stroke="white"
                    stroke-width="0.5"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>
              <div class="align-center d-flex gap-5 text-gray">
                {{ fileInfo.name }}.
                {{ fileInfo.type }}
              </div>
              <div class="siz-12 text-label">
                {{ fileInfo.size }}
              </div>
            </div>
            <!-- delete file -->
            <v-btn
              class="squer-rouneded-btn squer-sm border-light close squer-sm"
              elevation="0"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8332 4.16675L4.1665 15.8334M15.8332 15.8334L4.1665 4.16675"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>
          </v-card>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import AboutMeetingBox from "@/components/ui/meetingAgenda/AboutMeetingBox";

export default {
  name: "AddFile",
  components: {
    AboutMeetingBox,
  },
  props: {
    border: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      fileInfo: {
        name: "عنوان الملف",
        type: "pdf",
        size: "100 ك. ب",
      },
      shareFile: false,
      Selectedtask: 1,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileInfo = {
          name: file.name,
          type: file.type,
          size: this.formatFileSize(file.size),
        };
      } else {
        this.fileInfo = null;
      }
    },
    formatFileSize(size) {
      const kilobyte = 1024;
      const megabyte = kilobyte * 1024;
      const gigabyte = megabyte * 1024;
      if (size >= gigabyte) {
        return (size / gigabyte).toFixed(2) + " GB";
      } else if (size >= megabyte) {
        return (size / megabyte).toFixed(2) + " MB";
      } else if (size >= kilobyte) {
        return (size / kilobyte).toFixed(2) + " KB";
      } else {
        return size + " bytes";
      }
    },
  },
};
</script>

<style lang="scss">
.popup-header {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f5f5f4;
}
.upload-file-popup {
  width: 970px;
  .popup-content {
    .add-file-box {
      // width: 830px;
      height: 180px;
      position: relative;

      border-radius: 8px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23EAECF0FF' stroke-width='2' stroke-dasharray='16' stroke-dashoffset='17' stroke-linecap='round'/%3e%3c/svg%3e");
      // background-image: url("@/assets/images/popup_icone/dashed_border.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      border-radius: 6px;
    }
    .popup-task {
      .v-label.v-label--clickable {
        color: #000;
      }
      .discreption {
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        padding-inline-start: 40px;
        color: #667085;
        margin-top: 12px;
      }
    }
    .line-ele {
      svg {
        width: 20px;
        height: 20px;
      }
      .line-sub {
        margin-inline-start: 10px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #475467;
      }
    }
  }
}
// start media query
@media (max-width: 992px) {
  .upload-file-popup w-100 {
    max-width: 970px;
    width: auto;
  }
}
</style>
