<template>
  <div>
    <div
      v-for="row in rows"
      :key="row.id"
      @click="handleRowClick(row)"
      class="entity-row d-flex align-center gap-24 cursor-pointer py-5 px-10 dark-brown-color"
    >
      <div
        v-if="row.imageUrl"
        class="row-img justify-center pa-0 ma-0 d-flex align-center smooth_rounded"
      >
        <img loading="lazy" :src="row.imageUrl" alt="" />
      </div>

      <missingHeroImg v-else :svg="row.svg" :color="row.color" />
      <div class="title">{{ row.title }}</div>
    </div>
  </div>
</template>

<script>
import missingHeroImg from "@/components/shared/missingHeroImg.vue";

export default {
  name: "entityTableRow",
  components: {
    missingHeroImg,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      spaceSvg: ` <svg
        data-v-73214ae6=""
        width="26"
        height="28"
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0C3.10156 0 0.75 2.35156 0.75 5.25V22.75C0.75 25.6484 3.10156 28 6 28H21.75H23.5H25.25V24.5H23.5V21H25.25V0H23.5H21.75H6ZM6 21H20V24.5H6C5.03203 24.5 4.25 23.718 4.25 22.75C4.25 21.782 5.03203 21 6 21ZM7.75 7H20V8.75H7.75V7ZM20 10.5V12.25H7.75V10.5H20Z"
          fill="#fff"
        ></path>
      </svg>`,
      entitySvg: ` <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 16.6663H17.5V8.33301C17.5 7.41253 16.7538 6.66634 15.8333 6.66634H12.5M9.16667 13.333H9.175M14.1667 13.333H14.175M5.83333 13.333H5.84167M9.16667 9.99967H9.175M14.1667 9.99967H14.175M5.83333 9.99967H5.84167M9.16667 6.66634H9.175M5.83333 6.66634H5.84167M12.5 16.6663V4.99967C12.5 4.0792 11.7538 3.33301 10.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663H12.5Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>`,

      rows: [],
    };
  },
  created() {
    this.generateRows();
  },
  methods: {
    generateRows() {
      if (this.type === "entity") {
        if (this.company.entity_logo) {
          this.rows.push({
            id: this.company.entity_id,
            imageUrl: `https://dev.agenda.42.guru//${this.company.entity_logo}`,
            svg: this.entitySvg,
            color: this.company.cover_color,
            title: this.company.entity_name,
          });
        } else {
          this.rows.push({
            id: this.company.entity_id,
            svg: this.entitySvg,
            color: this.company.cover_color,
            title: this.company.entity_name,
          });
        }
      } else if (
        this.type === "space" ||
        this.type === "personal space" ||
        this.type === "subSpace"
      ) {
        if (this.company.logo) {
          this.rows.push({
            id: this.company.space_id,
            svg: this.spaceSvg,
            color: this.company.cover_color,
            title: this.company.space_name,
            imageUrl: `https://dev.agenda.42.guru//${this.company.logo}`,
          });
        } else {
          this.rows.push({
            id: this.company.space_id,
            svg: this.spaceSvg,
            color: this.company.cover_color,
            title: this.company.space_name,
          });
        }
      }
    },
    handleRowClick(row) {
      if (this.type === "entity") {
        this.toEntity(row.id);
      } else {
        this.toSpace(row.id);
      }
    },
    toEntity(entityId) {
      this.$router.push(`/${entityId}`);
    },
    toSpace(spaceId) {
      if (this.type === "subSpace") {
        this.$router.push(`/${this.entity_id}/sub_space/${spaceId}`);
      } else if (this.type === "personal space") {
        this.$router.push(`/personal-space/${spaceId}`);
      } else {
        this.$router.push(`/${this.entity_id}/${spaceId}`);
      }
    },
    handleBrokenImage(event) {
      event.target.src = require("@/assets/images/brokenimg.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-row {
  .row-img {
    width: 48px;
    height: 48px;
    overflow: hidden;
    padding: 3px;
    border: 3px solid #fff;
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
