<template>
  <div class="vertical-item-timeline main-elements-timeline meeting-ele">
    <div class="mb-10 taskTimeLineContent">
      <!-- current meeting -->
      <v-timeline
        multiple
        class="tasks-container w-100"
        side="end"
        justify="start"
        align="start"
      >
        <!-- main task -->
        <v-timeline-item
          class="hide-bullet"
          justify="start"
          align="start"
          direction="horizontal"
        >
          <div class="">
            <div class="time-line-absolute-check">
              <v-checkbox
                class="custom-radio-circle checkbox-change-space"
                color="primary"
                :value="true"
                true-icon="mdi-check-circle"
                false-icon="mdi-circle-outline"
                hide-details
                v-model="taskStatus"
                :disabled="
                  task_content.subtasks?.filter((t) => t.status == 'Created')
                    .length > 0
                "
                @change="setTaskStatus(task_content.name)"
              ></v-checkbox>
            </div>
            <v-expansion-panels v-model="panal" class="" elevation="0">
              <v-expansion-panel elevation="0" class="dark-brown-color">
                <v-expansion-panel-title elevation="0">
                  <div
                    class="mt-0 task-time-line-title flex-grow-1 align-start justify-space-between d-flex"
                    v-if="task_content"
                  >
                    <div class="f-side">
                      <div class="position-relative d-flex align-center">
                        <div class="tast-title">
                          {{ task_content.task_title }}
                        </div>
                      </div>
                      <div class="mt-5 tast-desc siz-12 text-label">
                        {{ task_content.task_description }}
                      </div>
                    </div>
                    <div
                      v-if="task_content.task_assignee"
                      class="gap-10 e-side d-flex"
                    >
                      <div
                        class="multy-member-info pa-2px dark-brown-color"
                        v-for="e in task_content.task_assignee"
                        :key="e"
                      >
                        <div
                          class="d-flex flex-grow-1 align-center text-blue-gray siz-10 ps-1"
                        >
                          {{ e.full_name }}
                        </div>

                        <div class="d-flex align-center">
                          <div
                            class="justify-center img-container d-flex align-center"
                          >
                            <img
                              loading="lazy"
                              class="tag-img"
                              :src="
                                `https://dev.agenda.42.guru/` + e.user_image
                              "
                              alt=""
                              @error="handleMemberImage"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="justify-center tag-rounded-box flex-grow-1 task-time align-center text-blue-gray siz-10 me-1"
                      >
                        {{ formatDate(task_content.task_date) }}
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text class="mb-8">
                  <TaskDetailsBox @refreshTaskDetails="refreshTaskDetails" />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-timeline-item>
        <!-- sub tasks -->
        <v-timeline-item
          v-for="(subtask, index) in task_content.subtasks"
          :key="index"
          class="sup-task-timeline-container"
          justify="start"
          align="start"
          direction="horizontal"
        >
          <div class="">
            <v-expansion-panels class="" elevation="0">
              <v-expansion-panel elevation="0" class="dark-brown-color">
                <div class="d-flex mt-n1">
                  <div class="sup-check ms-n2 mt-n2">
                    <v-checkbox
                      class="custom-radio-circle"
                      color="primary"
                      :value="true"
                      true-icon="mdi-check-circle"
                      false-icon="mdi-circle-outline"
                      hide-details
                      v-model="subtask.taskStatus"
                      @change="setTaskStatus(subtask.name)"
                    ></v-checkbox>
                  </div>
                  <v-expansion-panel-title class="ps-0 pe-0" elevation="0">
                    <div
                      class="task-time-line-title flex-grow-1 align-center justify-space-between d-flex"
                      v-if="subtask"
                    >
                      <div class="f-side">
                        <div class="position-relative">
                          <div class="tast-title">
                            {{ subtask.task_title }}
                          </div>
                        </div>
                        <!-- <div class="mt-5 tast-desc siz-12 text-label">
                          {{ e.desc }}
                        </div> -->
                      </div>
                      <div class="gap-10 e-side d-flex">
                        <div
                          class="user-info-tags gap-10 e-side d-flex"
                          v-if="subtask.task_assignee"
                        >
                          <div v-for="e in subtask.task_assignee" :key="e">
                            <div class="multy-member-info">
                              <div
                                class="d-flex flex-grow-1 align-center text-blue-gray siz-10 ps-1"
                              >
                                <!-- {{ e.first_name }}
                                {{ e.last_name }} -->
                                {{ e.full_name }}
                              </div>

                              <div class="d-flex align-center">
                                <div
                                  class="justify-center img-container d-flex align-center"
                                >
                                  <img
                                    loading="lazy"
                                    class="tag-img"
                                    :src="
                                      `https://dev.agenda.42.guru/` +
                                      e.user_image
                                    "
                                    alt=""
                                    @error="handleMemberImage"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="justify-center tag-rounded-box flex-grow-1 task-time align-center text-blue-gray siz-10 me-1"
                        >
                          {{ formatDate(subtask.task_date) }}
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-title>
                </div>
                <v-expansion-panel-text class="mb-8">
                  <TaskDetailsBox
                    :subtaskData="subtask"
                    :is_sub="true"
                    @refreshTaskDetails="refreshTaskDetails"
                  />
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import TaskDetailsBox from "@/components/ui/tasks/TaskDetailsBox.vue";
import { ai } from "@/plugins/axios";
export default {
  name: "TaskDetailsTimeLine",
  props: {
    task_content: {
      type: Array,
    },
  },
  components: {
    TaskDetailsBox,
  },
  data() {
    return {
      taskStatus: "",
      panal: [0],
      Selectedtask: false,
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      task_id: this.$route.params.task_id,
      // task_content_dt: this.task_content_dt,
    };
  },

  methods: {
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    refreshTaskDetails() {
      this.$emit("refreshTaskDetails", true);
    },
    async setTaskStatus(taskId) {
      try {
        const response = await ai.put(`method/agenda.api.home.task.edit_task`, {
          task_id: taskId,
          status: this.taskStatus ? "Completed" : "Created",
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
      console.log(this.taskStatus);
    },
    formatDate(timestamp) {
      if (timestamp == undefined || timestamp == "") return "";
      const date = new Date(timestamp);
      const now = new Date();

      const dateFormatter = new Intl.DateTimeFormat("ar", {
        hour: "numeric",
        minute: "numeric",
      });

      if (this.isSameDay(date, now)) {
        return ` ${this.$t("today")} • ` + dateFormatter.format(date);
      } else if (this.isYesterday(date, now)) {
        return ` ${this.$t("yesterday")} • ` + dateFormatter.format(date);
      } else {
        return (
          date.toLocaleString("ar", { dateStyle: "long" }) +
          " • " +
          dateFormatter.format(date)
        );
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    },
    isYesterday(date1, date2) {
      const yesterday = new Date(date2);
      yesterday.setDate(date2.getDate() - 1);
      return this.isSameDay(date1, yesterday);
    },
    refreshTaskDetails(e) {
      this.$emit("refreshTaskDetails", e);
    },
  },
  watch: {
    // Watch for changes in task_content
    task_content: function (newTaskContent, oldTaskContent) {
      // Update task_content_dt when task_content changes
      // this.task_content_dt = this.task_content;
    },
  },

  mounted() {
    // this.task_content_dt = this.task_content;
  },
  created() {
    this.taskStatus = this.task_content.status == "Completed";
    console.log(this.task_content);
    this.task_content?.subtasks.forEach((st) => {
      st.taskStatus = st.status == "Completed";
    });
    // this.task_content_dt = this.task_content;
  },
};
</script>
