import { createApp, defineAsyncComponent } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/auth";
import vuetify from "@/plugins/vuetify";
import { createI18n } from "vue-i18n";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import "@/assets/style/main.scss";
import VOtpInput from "vue3-otp-input";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Select2 from "vue3-select2-component";
import "@/js/shephard.js";
import alert from "@/components/shared/alert.vue";
import "./registerServiceWorker";

// importing AOS css style globally
import "aos/dist/aos.css";

// google
import vue3GoogleLogin from "vue3-google-login";

// for new icone

// language
const i18n = createI18n({
  locale: localStorage.getItem("locale") || "ar",
  messages: {
    en: require("@/i18n/en.json"),
    ar: require("@/i18n/ar.json"),
  },
  globalInjection: true,
});

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(VueTelInput)
  .use(VOtpInput)
  .use(Select2)
  .use(VueDatePicker)
  .use(VCalendar)
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
  })
  .component("alert", alert)
  .component(
    "boardApp",
    defineAsyncComponent(() => import("@/components/layout/apps/boardApp.vue"))
  )
  .component(
    "entityApp",
    defineAsyncComponent(() => import("@/components/layout/apps/entityApp.vue"))
  )
  .component(
    "personalApp",
    defineAsyncComponent(() =>
      import("@/components/layout/apps/personalApp.vue")
    )
  );

app.config.performance = true;

app.mount("#app");
