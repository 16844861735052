<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        color="transparent"
        class="main-header-btn pa-0 ma-0"
        elevation="0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0004 12L12.0004 16.8M12.0004 8.44218V8.39999M2.40039 12C2.40039 6.69806 6.69846 2.39999 12.0004 2.39999C17.3023 2.39999 21.6004 6.69806 21.6004 12C21.6004 17.3019 17.3023 21.6 12.0004 21.6C6.69846 21.6 2.40039 17.3019 2.40039 12Z"
            stroke="#475467"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <v-btn
          color="transparent"
          class="main-header-btn img-24 pa-0 ma-0 ms-8"
          elevation="0"
          @click="dialog = false"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M19 19L5 5"
              stroke="#475467"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </v-btn>
        <div class="main-header">{{ info.meeting_title }}</div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8 cp-none"
          disabled
          elevation="0"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <!-- {{ info }} -->
      <div class="popup-content popup-padding-70 fixed-h-popup">
        <!-- meating data -->
        <div class="sup-des siz-18 text-center mt-6">
          {{ info.meeting_description }}
        </div>
        <!-- meeting link -->
        <div class="meating-ink align-center justify-center gap-5 mt-5">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.843 6.21045L6.21085 6.21045C5.22781 6.21045 4.27637 6.59658 3.57866 7.30462C2.88096 8.01265 2.48456 8.96199 2.50046 9.97577C2.48452 10.9895 2.88107 11.939 3.57866 12.6469C4.29217 13.371 5.21185 13.7572 6.19493 13.7572H8.82708M12.157 13.7894H14.7892C15.7722 13.7894 16.7236 13.4033 17.4213 12.6952C18.119 11.9872 18.5154 11.0379 18.4995 10.0241C18.4995 9.02648 18.1031 8.07711 17.4054 7.36907C16.7077 6.66103 15.7722 6.25877 14.7892 6.25875H12.157M6.5437 9.96473L14.4402 9.96473"
              stroke="#475467"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <a
            :href="info.meeting_url"
            class="a-dark ms-2 siz-18 me-2"
            target="_blank"
          >
            {{ info.meeting_url }}
          </a>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.7001 1.59985H4.5001C3.17461 1.59985 2.1001 2.67437 2.1001 3.99985V11.9999C2.1001 13.3254 3.17461 14.3999 4.5001 14.3999H12.5001C13.8256 14.3999 14.9001 13.3254 14.9001 11.9999V8.79985M10.8997 1.60005L14.9001 1.59985M14.9001 1.59985V5.19995M14.9001 1.59985L8.09951 8.39966"
              stroke="#98A2B3"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <!-- meeting loaction  -->
        <div class="meating-location align-center justify-center gap-5 mt-5">
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.50013 17C7.50013 17 13.761 11.4348 13.761 7.26087C13.761 3.80309 10.9579 1 7.50013 1C4.04234 1 1.23926 3.80309 1.23926 7.26087C1.23926 11.4348 7.50013 17 7.50013 17Z"
              stroke="#475467"
              stroke-width="1.25"
            />
            <path
              d="M9.50038 7.00013C9.50038 8.1047 8.60495 9.00013 7.50038 9.00013C6.39581 9.00013 5.50038 8.1047 5.50038 7.00013C5.50038 5.89556 6.39581 5.00013 7.50038 5.00013C8.60495 5.00013 9.50038 5.89556 9.50038 7.00013Z"
              stroke="#475467"
              stroke-width="1.25"
            />
          </svg>

          <div
            href="https://www.zoom.com/meeting/examplelink"
            class="a-dark ms-2 siz-18 me-2"
          >
            {{ info.location }}
          </div>
        </div>
        <!-- about meeting  -->
        <div class="about-meeting mt-10 pa-0 mb-10">
          <v-container>
            <v-row class="justify-center">
              <v-col
                class="MeetingBoxConatiner"
                v-for="e in meetingInfo"
                :key="e"
                md="4"
                sm="4"
                cols="6"
              >
                <AboutMeetingBox :meetingInfo="e" />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import AboutMeetingBox from "@/components/ui/meetingAgenda/AboutMeetingBox";

export default {
  name: "MeetingInfoDialog",
  components: {
    AboutMeetingBox,
  },
  props: {
    info: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,

      meetingInfo: [
        {
          icone: require("@/assets/images/Calendar.svg"),
          head: "تاريخ الاجتماع",
          title: "الأحد، 1 ديسمبر 2022",
        },
        {
          icone: require("@/assets/images/clock.svg"),
          head: "موعد بدء الإجتماع",
          title: "10:00 ص",
        },
        {
          icone: require("@/assets/images/stopwatch-03.svg"),
          head: " مده الاجتماع",
          title: "2h 30m",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.MeetingBoxConatiner {
  // border for second container
  &:nth-child(2) {
    border-left: 1px solid #f2f4f7;
    border-right: 1px solid #f2f4f7;
  }
}

@media (max-width: 960px) {
  .MeetingBoxConatiner {
    .box-text {
      font-size: 14px;
    }
    // border for second container
    &:nth-child(2) {
      border-left: 0px solid #f2f4f7;
      border-right: 0px solid #f2f4f7;
    }
  }
}
</style>
