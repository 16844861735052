<template>
  <transition name="fade-transition">
    <div class="toolTip" v-if="isActive">
      <div class="toolTip-content iox-r main-shadow">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    text: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    // openDropdown() {
    //   // toggle isActive state
    //   this.isActive = !this.isActive;
    // },
  },
};
</script>
