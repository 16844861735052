<template>
  <div
    :class="height_auto ? 'h-auto' : 'squer-felid'"
    class="squer-felid"
    @mouseover="showControllers = true"
    @mouseleave="showControllers = false"
  >
    <div class="squer-felid__title">
      {{ statistic ? statistic.title : title }}
    </div>
    <div class="counter_percentage w-100">
      <Counter
        :pesentageSgin="pesentageSgin"
        :percentage="
          statistic ? statistic.percentage : currentVal ? currentVal : 0
        "
        :showControllers="showControllers"
        :justify="justify"
        @percentage="updateCount($event, statistic)"
      />
      <!-- <div v-else>{{ statistic.percentage }} %</div> -->
    </div>
  </div>
</template>

<script>
import Counter from "../shared/Counter";
export default {
  name: "SquerFelid",

  components: {
    Counter,
  },
  props: {
    title: {
      type: String,
    },
    statistic: {
      type: Object,
    },
    pesentageSgin: {
      type: Boolean,
      default: true,
    },
    justify: {
      type: Boolean,
      default: false,
    },
    height_auto: {
      type: Boolean,
      default: false,
    },
    currentVal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      counter_persantage: 1,
      showControllers: false,
    };
  },

  methods: {
    updateCount(percentage, statistic) {
      // this.counter_persantage = percentage;
      this.$emit("updateCount", percentage, statistic);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables.scss" as *;
.squer-felid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px 10px;
  padding-bottom: 18px;
  min-height: 163px;

  width: calc(100% / 5);

  border-inline-start: 1px solid $light;
  flex: 1 0 0;

  &.h-auto {
    height: auto;
    min-height: auto;
  }

  &:first-of-type {
    border-inline-start: 0.5px solid transparent;
    border-radius: 4px 0px 0px 4px;
  }
  &:last-of-type {
    // border-inline-start: 0.5px solid transparent;
    // border-radius: 0px 4px 4px 0px;
  }

  .counter_percentage {
  }
  &:not(:last-child) {
    // border-inline-end: 1px solid $light;
  }
}

.squer-felid__title {
  text-align: center;
  font-weight: 400;
  color: $label-gray;
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 12px;
}
.counter_percentage {
  font-size: 18px;
  font-weight: 500;
}
@media (max-width: 600px) {
  .squer-felid {
    min-width: calc(100% / 2);

    // border: 0.5px solid $light;
    border: 0px solid $light;

    border-top: 0.5px solid $light;

    // odd border 0
    &:nth-child(odd) {
      border-inline-end: 0px solid $light;
      border-inline-start: 0px solid $light;
    }
    &:nth-child(even) {
      border-inline-end: 0px solid $light;
      border-inline-start: 0.5px solid $light;
    }

    &:last-child {
      border-top: 0.5px solid $light;
    }
  }
}
</style>
