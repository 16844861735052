<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        elevation="0"
        class="mt-2 border-t rounded-lg fill-w-btn siz-14 send-gray-btn-light w-100 pa-0 border-slim-light white-svg"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        {{ $t("addNewTask") }}
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("addNewTask") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form ref="form" class="mt-5 border auth-form rounded-4 mb-15">
          <div class="form-inputs-container slim-form">
            <v-text-field
              class="form-input-box"
              v-model="form.title"
              type="text"
              :placeholder="$t('taskHead')"
              :label="$t('enterTaskHead')"
              outlined
              solo
              dense
              variant="solo"
              hide-details="auto"
              persistent-placeholder
              :rules="rules"
            ></v-text-field>
            <v-text-field
              class="form-input-box"
              v-model="form.description"
              type="text"
              :placeholder="$t('taskDecs')"
              :label="$t('enterTaskDecs')"
              outlined
              solo
              dense
              variant="solo"
              hide-details="auto"
              persistent-placeholder
              :rules="rules"
            ></v-text-field>

            <div class="position-relative">
              <meeting-task-assginee @memberSelected="handleMemberSelected" />
            </div>
            <!-- start icone fild -->
            <div
              class="gap-10 form-input-box d-flex align-center date-container border-t"
            >
              <date-picker @selectedDate="setDate" class="10" />
              <v-btn
                class="squer-rouneded-btn bg-light-gray pa-0 ma-0 border-rd-8"
                evaluation="0"
                flat
              >
                <svg
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.875 7.57139H17.625M3.91071 1V2.71449M15.375 1V2.71428M18.75 5.71428L18.75 18C18.75 19.6569 17.4069 21 15.75 21H3.75C2.09315 21 0.75 19.6568 0.75 18V5.71428C0.75 4.05742 2.09315 2.71428 3.75 2.71428H15.75C17.4069 2.71428 18.75 4.05742 18.75 5.71428Z"
                    stroke="#667085"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
              <v-text-field
                v-model="form.date"
                type="text"
                placeholder="الأحد، 1 يناير 2024"
                :label="$t('date')"
                outlined
                solo
                dense
                variant="solo"
                hide-details="auto"
                persistent-placeholder
                :rules="rules"
              ></v-text-field>
            </div>
          </div>
          <!-- <v-btn
            class="mt-0 border-0 send-gray-btn siz-16 text-blue-gray font-weight-regular rounded-4-bottom rounded-0 border-t-light"
            :loading="loading"
            height="68px"
            elevation="0"
            block
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7852 3.57649C19.3913 3.17936 18.8572 2.95625 18.3002 2.95625L5.70023 2.95625C5.14328 2.95625 4.60914 3.17936 4.21531 3.57649M11.9991 21.043L11.9991 9.04297M11.9991 9.04297L7.19906 13.6281M11.9991 9.04297L16.7991 13.6281"
                stroke="#475467"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span class="ms-3"> {{ $t("addFile") }} </span>
          </v-btn> -->
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import DatePicker from "@/components/shared/DatePicker.vue";
import meetingTaskAssginee from "@/components/ui/meetingAgenda/meetingTaskAssginee";

import { ai } from "@/plugins/axios";
export default {
  name: "addNewTask",

  components: {
    DatePicker,
    meetingTaskAssginee,
  },

  props: {
    agendaItem: {
      type: Object,
    },
  },
  data() {
    return {
      // selectedMembers: [],
      dialog: false,
      loading: false,

      form: {
        title: "",
        description: "",
        task_assignee: [
          {
            member: "facility_coordinator@agenda.com",
          },
        ],
        date: "",
      },
      rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
    };
  },
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        try {
          const request = {
            task_title: this.form.title,
            task_description: this.form.description,
            task_assignee: this.form.task_assignee,
            task_date: this.form.date,
            agenda_id: this.$props.agendaItem.agenda_id,
          };
          await ai.post(
            `method/agenda.api.meeting.agenda_task.create_new_task`,
            request
          );
          this.dialog = false;
          this.loading = false;
          this.$emit("refreshOldData", true);
          // empty form
          this.form = {
            title: "",
            description: "",
            task_assignee: "",
            date: "",
          };
        } catch (err) {
          this.errorMessage = err.response?.data.message.detail;
          console.log(err);
          this.loading = false;
        }
      }
    },
    setDate(date) {
      this.form.date = date;
    },

    handleMemberSelected(selectedValue) {
      let selectedMembers = selectedValue.map((member) => ({
        member: member.member_id || member.member,
      }));
      this.form.task_assignee = selectedMembers;
    },
  },
};
</script>
