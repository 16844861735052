<template>
  <router-link
    class="table-ele-box d-flex justify-space-between shareholder-row"
    :to="'shareholders/view/' + shareholder.member_id"
  >
    <div class="box-info align-center gap-10 d-flex">
      <!-- person data -->
      <img
        loading="lazy"
        :src="`https://dev.agenda.42.guru/files/` + shareholder.user_image"
        @error="handleMemberBrokenImage"
        alt=""
      />
      <div class="ele-name">
        {{ shareholder.full_name }}
      </div>
      <!-- <div class="ele-tag">
        {{
          $t("shareholders.list.membership_id") +
          " " +
          shareholder.share_holder_id
        }}
      </div> -->
    </div>
    <div class="d-flex">
      <div class="line-ele d-flex gap-10">
        <div class="tool-icon order-1">
          <div class="align-center d-flex gap-10">
            <div class="ele-tag">
              {{ shareholder.shares + " " + $t("shareholders.list.shares") }}
            </div>
            <div class="ele-tag percentage-tag text-white">
              {{ "%" + shareholder.shares }}
            </div>
            <v-btn
              class="bg-white squer-rouneded-btn squer-40 pa-0 ma-0 edit-shareholder-btn"
              elevation="0"
              link
              :to="'shareholders/edit/' + shareholder.member_id"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4471 16.0359L11.0657 15.3426C11.8246 14.492 13.1863 14.6026 13.7981 15.5645C14.3681 16.4606 15.6062 16.6304 16.3964 15.921L17.5176 14.9143M2.48242 16.2251L6.12074 15.492C6.31389 15.4531 6.49124 15.3579 6.63052 15.2186L14.7752 7.06939C15.1657 6.67868 15.1655 6.04536 14.7747 5.65497L13.0493 3.93158C12.6586 3.54135 12.0256 3.54162 11.6353 3.93217L3.48972 12.0822C3.35071 12.2213 3.25579 12.3983 3.21684 12.591L2.48242 16.2251Z"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ShareholderTableRow",
  components: {},
  props: {
    shareholder: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/style/base/variables" as *;

.shareholder-row {
  transition: all ease-in 0.3s;
  .percentage-tag {
    background-color: $text-gray;

    &:hover {
      background-color: $text-gray;
    }
  }
  .edit-shareholder-btn {
    display: none;
  }
  &:hover {
    .edit-shareholder-btn {
      display: flex;
    }
  }
}

.table-ele-box {
  padding: 10px 40px 10px 40px;
  min-height: 62px;
  min-width: 725px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    .ele-tag {
      background: #fff;
    }
    .percentage-tag {
      background-color: $text-gray !important;
    }
  }

  .box-info {
    img {
      width: 36px;
      height: 36px;
      clip-path: inset(0px 0px 0px round 14px);

      &.img-7 {
        width: 10px;
        height: 11px;
      }

      &.img-20 {
        width: 20px;
        height: 20px;
      }

      &.img-24 {
        width: 24px;
        height: 24px;
      }
    }

    .ele-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      &.news {
        align-items: center;
      }
    }

    .ele-tag {
      font-size: 10px;
      background: $light-gray;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 55px;
      height: 22px;
      padding: 0 10px;
      transition: all 0.3s ease-in-out;

      &.ele-tag-sm {
        width: 40px;
        height: 22px;
        color: #fff !important;
        margin-left: 10px;
      }

      &.bg-info {
        background-color: $info !important;
      }
    }

    .representative-container {
      padding: 5px;
      padding-inline-start: 2px;

      img {
        width: 18px;
        height: 18px;
      }

      .representative-name {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #475467;
        margin-inline-start: 5px;
      }
    }
  }

  &:nth-child(odd) {
    background: #f9fafb;

    .box-info {
      .ele-tag {
        font-size: 10px;
        background: #f2f4f7;
      }

      .representative-container {
        padding: 5px;
        padding-inline-start: 2px;

        img {
          width: 18px;
          height: 18px;
        }

        .representative-name {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #475467;
          margin-inline-start: 5px;
        }
      }
    }

    &:hover {
      .box-info {
        .ele-tag {
          background: #fff;
        }
      }
    }
  }

  .table-ele-box-control {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    background: #f2f4f7;

    .ele-tag {
      background: #fff;
    }

    .table-ele-box-control {
      display: flex;
    }
  }
}

.news-ele {
  padding: 10px 40px 10px 40px;
  min-height: 62px;
  min-width: 788px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    .ele-tag {
      background: #fff;
    }
  }

  .box-info {
    img {
      width: 36px;
      height: 36px;
      clip-path: inset(0px 0px 0px round 14px);

      &.img-7 {
        width: 10px;
        height: 11px;
      }

      &.img-20 {
        width: 20px;
        height: 20px;
      }

      &.img-24 {
        width: 24px;
        height: 24px;
      }
    }

    .ele-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      &.news {
        align-items: center;
      }
    }

    .ele-tag {
      font-size: 10px;
      background: $light-gray;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 55px;
      height: 22px;
      padding: 0 10px;
      transition: all 0.3s ease-in-out;

      &.ele-tag-sm {
        width: 40px;
        height: 22px;
        color: #fff !important;
        margin-left: 10px;
      }

      &.bg-info {
        background-color: $info !important;
      }
    }

    .representative-container {
      padding: 5px;
      padding-inline-start: 2px;

      img {
        width: 18px;
        height: 18px;
      }

      .representative-name {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #475467;
        margin-inline-start: 5px;
      }
    }
  }

  &:nth-child(odd) {
    .box-info {
      .ele-tag {
        font-size: 10px;
        background: #f2f4f7;
      }

      .representative-container {
        padding: 5px;
        padding-inline-start: 2px;

        img {
          width: 18px;
          height: 18px;
        }

        .representative-name {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #475467;
          margin-inline-start: 5px;
        }
      }
    }
  }

  .table-ele-box-control {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    .table-ele-box-control {
      display: flex;
    }
  }

  .news-rounded-btn {
    background: #f2f4f7;
  }
}
</style>
