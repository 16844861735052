<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <button v-bind="props" class="border-light pa-2 rounded-lg w-36 h-36">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 4.00012L16 7.00012M3.5 13.0001L13.3595 2.79631C14.4211 1.73473 16.1422 1.73473 17.2038 2.79631C18.2654 3.85789 18.2654 5.57906 17.2038 6.64064L7 16.5001L2 18.0001L3.5 13.0001Z"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42">{{ $t("editVote") }}</div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form v-model="valid" ref="form" class="news-form">
          <div class="form-inputs-container border-slim-light my-10">
            <v-text-field
              v-model="form.title"
              :label="$t('votes.add.title_label')"
              :placeholder="$t('votes.add.title_placeholder')"
              class="bg-transparent form-input-box"
              dense
              hide-details="auto"
              outlined
              persistent-placeholder
              solo
              type="email"
              variant="solo"
            ></v-text-field>
            <v-text-field
              v-for="(option, index) in form.vote_options_table"
              :key="option.vote_option_id"
              v-model="option.vote_option"
              :label="$t('votes.add.choice_label') + '#' + (index + 1)"
              :placeholder="$t('votes.add.choice_placeholder')"
              class="bg-transparent form-input-box"
              dense
              hide-details="auto"
              outlined
              persistent-placeholder
              solo
              type="text"
              variant="solo"
            ></v-text-field>
            <v-btn
              class="border-0 fill-w-btn text-gray add-more-button dark-brown-color"
              elevation="0"
              @click="addChoice()"
            >
              <svg
                fill="none"
                height="24"
                viewBox="0 0 25 24"
                width="25"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5031 7.19995L12.5031 16.8M17.3031 12L7.70312 12"
                  stroke="#475467"
                  stroke-linecap="round"
                  stroke-width="1.5"
                />
              </svg>
              {{ $t("votes.add.add_new_choice_button") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "editVoting",
  props: {
    voteDt: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        title: "",
        vote_options_table: [],
      },
      choicesCount: 1,
    };
  },
  updated() {
    this.form = {
      title: this.voteDt.vote_title,
      vote_options_table: this.voteDt.vote_options_table,
    };
    console.log(this.voteDt.vote_options_table);
  },
  methods: {
    addChoice() {
      this.choicesCount++;
      this.form.vote_options_table.push({
        id: this.choicesCount,
        label: this.$t("votes.add.choice_label") + " #" + this.choicesCount,
        placeholder:
          this.$t("votes.add.choice_placeholder") +
          " " +
          this.ordinalsAr(this.choicesCount),
        content: "",
      });
    },
    ordinalsAr(num, isFeminine = false) {
      num %= 100; // only handle the lowest 2 digits (1-99) ignore others
      let the = "ال",
        unit = num % 10,
        ordinal =
          the +
          [
            ,
            "أول",
            "ثاني",
            "ثالث",
            "رابع",
            "خامس",
            "سادس",
            "سابع",
            "ثامن",
            "تاسع",
            "عاشر",
          ][num === 10 ? num : unit], // letters for lower part of ordinal string
        female = isFeminine ? "ة" : "", // add letter "ة" if Feminine
        ones = (unit === 1 ? the + "حادي" : ordinal) + female; // special cases for 21, 31, 41, etc.
      return num < 11
        ? ordinal + (isFeminine && num === 1 ? "ى" : female) // from 1 to 10
        : num < 20
        ? ones + " عشر" + female // from 11 to 19
        : (unit ? ones + " و" : "") + // else 20 to 99
          "ال" + // always add "ال"
          [, , "عشر", "ثلاث", "أربع", "خمس", "ست", "سبع", "ثمان", "تسع"][ // letters for 20, 30, 40...
            ~~(num / 10)
          ] +
          "ون";
    },
    async submitForm() {
      try {
        this.loading = true;
        // Filter out empty text fields
        const nonEmptyOptions = this.form.vote_options_table.filter((option) =>
          option?.vote_option?.trim()
        );

        // Update form data with non-empty options
        this.form.vote_options_table = nonEmptyOptions;

        ai.put("method/agenda.api.meeting.agenda_vote.edit_vote", {
          vote_id: this.voteDt.vote_id,
          vote_title: this.form.title,
          vote_options_table: this.form.vote_options_table,
        });

        setTimeout(() => {
          this.$emit("refreshOldData", true);
          this.loading = false;
          this.dialog = false;
        }, 500);
      } catch (error) {
        this.loading = false;
        this.dialog = false;
        console.log(error);
      }
    },
  },
};
</script>
