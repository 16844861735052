<template>
  <!--  smooth_rounded bx  no-space-img -->
  <div
    class="img-content smooth_rounded bx cursor-pointer"
    :style="{
      width: width ? `${width}px` : '48px',
      height: height ? `${height}px` : '48px',
    }"
  >
    <div
      class="svg-container smooth_rounded bx"
      :style="{
        background: color ? color : '#12b76a',
      }"
    >
      <div v-html="svg"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
    mainColor: {
      default: "#12b76a",
      type: String,
    },
    width: {
      default: "48",
      type: String,
    },
    height: {
      default: "48",
      type: String,
    },
    svg: {
      type: String,
    },
  },
};
</script>

<style></style>
