<template>
  <div>
    <div
      class="new-header-page bg-white py-5 d-flex align-center justify-space-between pe-10 rounded-lg w-100"
    >
      <div
        class="layout-container d-flex align-center justify-space-between px-5"
      >
        <div class="header-back-name d-flex align-center">
          <transition name="slide-x-transition">
            <router-link
              class="maringForSummary"
              :to="{
                name: 'space',
                params: {
                  entity_id: entity_id,
                  space_id: space_id,
                },
              }"
            >
              <v-btn class="app-btn smooth_rounded" elevation="0">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="11.9996" r="1.25" fill="white" />
                </svg>
              </v-btn>
            </router-link>
          </transition>
          <div class="header-title-content d-flex align-center">
            <div class="align-center d-flex gap-15">
              <v-btn
                class="bg-primary pa-0 ma-0 app-btn smooth_rounded mt-0"
                elevation="0"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C11.2552 2 12.4428 2.28906 13.5 2.80423M16.5 5L9.5 12L7.5 10"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
            </div>
            <div class="side-head ms-6 letter-42">
              {{ $t("tasks") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-padding layout-container mt-value">
      <v-card class="main-page-container form-container mt-35px" elevation="0">
        <inner-header mb="mb-0">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/tasks`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                {{ $t("addTask") }}
              </div>
            </div>
            <v-btn
              @click="addTask()"
              :loading="isLoading"
              color="primary"
              class="new-plus ios-r"
              elevation="0"
            >
              <div class="siz-14 me-2">
                {{ $t("save") }}
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </inner-header>
        <v-card class="overflow-visible ms-10 me-10" elevation="0">
          <v-form ref="form" class="auth-form">
            <v-card class="mt-6 overflow-visible" elevation="0">
              <task-line
                :form="form"
                :space_id="space_id"
                @dataUpdated="setUpdatedData"
                @subtaskDataUpdated="setUpdatedData"
                @pushAllTasks="pushAllTasks"
                @formValidity="handleFormValidity"
              />
            </v-card>
          </v-form>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InnerHeader from "@/components/shared/InnerHeader";
import taskLine from "@/components/ui/tasks/taskLine";
import { ai } from "@/plugins/axios";

export default defineComponent({
  name: "addTask",
  components: {
    InnerHeader,
    taskLine,
  },

  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,

      form: {
        taskCategory: "",
        task_title: "",
        task_description: "",
        task_date: "",
        task_assignee: "",
        subtasks: [],
      },

      allTasks: [],
      finalTaskFormat: null,
      isLoading: false,
      validTaskForm: false,
    };
  },

  methods: {
    pushAllTasks(task) {
      this.allTasks = task;
    },
    setUpdatedData(formData) {
      if (formData.index != null) {
        if (!this.form.subtasks[formData.index]) {
          this.form.subtasks[formData.index] = {};
        }
        this.form.subtasks[formData.index].index = formData.index;
        this.form.subtasks[formData.index].task_title = formData.task_title;
        this.form.subtasks[formData.index].task_date = formData.task_date;
        this.form.subtasks[formData.index].task_assignee =
          formData.task_assignee;
      } else {
        this.form.task_title = formData.task_title;
        this.form.task_description = formData.task_description;
        this.form.taskCategory = formData.taskCategory;
        this.form.task_date = formData.task_date;
        this.form.task_assignee = formData.task_assignee;
      }
    },
    formatAddTaskRequest() {
      const request = {
        space_id: this.space_id,
        task_title: this.allTasks.task_title,
        task_description: this.allTasks.task_description,
        task_date: this.allTasks.task_date,
        task_assignee: this.allTasks.task_assignee,
        status: "In Progress",
        subtasks: this.allTasks.subtasks,
      };

      return request;
    },
    handleFormValidity(isFormValid) {
      console.log("Form validity in parent:", isFormValid);
      this.validTaskForm = isFormValid;
    },
    async addTask() {
      if (this.validTaskForm) {
        this.finalTaskFormat = this.formatAddTaskRequest();
        this.isLoading = true;
        try {
          const response = await ai.post(
            `method/agenda.api.space.task.create_task`,
            this.formatAddTaskRequest()
          );
          this.isLoading = false;
          this.$router.push(`/${this.entity_id}/${this.space_id}/tasks`);
          console.log(response);
        } catch (err) {
          this.isLoading = false;
          console.log(err);
        }
      }
    },
  },

  created() {
    document.title = this.$t("addTask");
  },
});
</script>
