<template>
  <div class="launchPad-main-holder">
    <div class="launchPad-container">
      <div
        class="border-b d-flex align-center justify-space-between launchPad-hero ps-10 pe-10"
      >
        <div class="hero-data">
          <div class="gap-10 d-flex align-center">
            <router-link
              @mouseenter="openTip()"
              @mouseleave="closeTip()"
              class="toolTipBtn agenda_link smooth_rounded rounded-0"
              to="/"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9842 3.14878L13.9861 1.59375C13.9245 1.60171 13.8899 1.60583 13.828 1.61351L12.2734 3.61205C12.2811 3.67367 12.2854 3.70825 12.2931 3.76986L14.2912 5.32489C14.3528 5.31721 14.3874 5.31282 14.4492 5.30527L16.0038 3.30673C15.9961 3.24511 15.9919 3.21053 15.9842 3.14864V3.14878Z"
                  fill="#475467"
                />
                <path
                  d="M8.75009 0.41387L4.21109 0L3.11531 1.13087C3.47394 2.13481 3.5601 3.54973 3.09637 4.6301C1.80303 7.64397 -0.85707 11.5233 0.273152 15.193H0.269448L0.561539 16H4.96348V15.6213L4.79843 15.1651C2.75448 9.56622 2.97276 8.36371 4.20849 4.94215H4.49495L6.65168 10.9017L8.49669 16H13.6031V13.8242L8.75009 0.41387Z"
                  fill="#475467"
                />
              </svg>
            </router-link>

            <!--  :isActive="openToolTip" -->
            <tool-tip>
              <div class="font-weight-bold text-primary">
                <img
                  loading="lazy"
                  :src="require('@/assets/images/Logo.svg')"
                  alt=""
                />
              </div>
              <div>
                أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة
                أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة
                أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة
              </div>
            </tool-tip>

            <svg
              width="58"
              height="48"
              viewBox="0 0 58 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.5172 16.2688C25.4963 16.2799 25.4747 16.2912 25.4526 16.3029C25.4472 16.26 25.4409 16.2264 25.436 16.2005C25.4297 16.1677 25.4285 16.1607 25.4273 16.1461L25.4095 15.927L25.3458 15.8774C25.3984 15.8966 25.4538 15.9171 25.5095 15.9382C25.597 15.9712 25.6825 16.0046 25.7579 16.0358L25.7411 16.1456C25.7383 16.1472 25.7354 16.1489 25.7323 16.1507C25.6788 16.1817 25.6058 16.2217 25.5172 16.2688ZM25.123 15.6857C25.1227 15.6859 25.1224 15.686 25.1221 15.6862L25.1221 15.6862L25.123 15.6857Z"
                fill="#12B76A"
                stroke="#12B76A"
              />
              <path
                d="M5.58739 20.853C5.01538 20.3921 2.43913 18.2827 2.43913 18.2827H2.26101L0.46097 21.2659L5.94653 24.3499C6.47655 24.5745 6.49972 25.4286 5.80317 25.4286H2.20164C1.91346 25.4286 1.73099 25.0172 1.90042 24.7463L3.36015 22.6419L1.62672 21.7037C1.52535 22.1942 1.50798 22.3446 0.510207 24.4226C-0.76271 27.2555 0.391459 30.6814 3.46731 30.6814H5.05303L7.64665 27.3166V26.119C7.64665 24.4556 7.54963 22.4354 5.58739 20.853Z"
                fill="#332C2B"
              />
              <path
                d="M43.6309 25.4005H39.0447C38.3351 25.4005 37.7587 24.7447 37.7587 23.9336V20.7538L37.64 20.6993L35.8602 21.2709V21.512C36.4568 22.7493 36.7638 23.3472 36.7638 24.1484C36.7638 24.8554 36.3743 25.4005 35.3953 25.4005H19.6772C19.4325 25.4005 19.221 25.3691 19.0429 25.3063C18.8648 25.2435 18.6925 25.1378 18.5476 24.9611C17.5339 23.7255 16.5811 19.5166 16.3581 18.1638H15.9395L13.8571 19.9081V20.3541C13.8571 20.3541 15.8628 22.8765 16.4782 23.8775C16.8186 24.4308 16.5521 25.4021 15.9598 25.4021H12.3192C11.6371 25.4021 10.9883 25.0652 10.5409 24.4804H10.3179L9.25781 27.0606V27.315L11.863 30.6798H15.5181L16.6824 27.8518H17.1661C18.2015 29.6705 19.2413 30.6798 22.0536 30.6798H35.4055L36.5669 27.8518H37.1056C38.5161 30.4386 39.9715 30.6798 42.4333 30.6798H43.6353V25.4005H43.6309Z"
                fill="#332C2B"
              />
              <path
                d="M56.0605 13.5584C55.3654 13.864 54.9541 13.8277 54.8252 13.4478C54.7832 13.3222 54.7991 13.1884 54.873 13.0513C54.9483 12.9126 55.0699 12.8068 55.2423 12.7309C55.5536 12.5938 55.807 12.6664 56.143 12.8366L56.5717 11.3945L56.5485 11.307C56.4384 11.2475 56.3255 11.198 56.2111 11.16C56.2111 11.16 55.9258 11.0642 55.6463 11.0509C55.4551 11.0427 54.6703 11.0642 54.1431 11.7563C53.7116 12.3212 53.5986 13.1438 53.8332 13.8905C54.0157 14.0986 54.0852 14.1828 54.2604 14.3943L54.2749 14.4868L53.6406 14.7659L53.7652 15.4977L57.1871 14.7032L57.2639 14.6074L57.2393 13.1752L57.1799 13.0695L56.0619 13.5617L56.0605 13.5584Z"
                fill="#332C2B"
              />
              <path
                d="M54.6628 30.6797H55.4926L57.3781 27.7742V15.5968L53.875 16.2906V16.6474C54.3978 20.592 54.6787 26.0761 54.6628 30.6814V30.6797Z"
                fill="#332C2B"
              />
              <path
                d="M2.26639 17.5493L0.572065 16.0461L0.554688 15.8925L1.8725 13.9598L2.00717 13.94L3.7015 15.4432L3.71888 15.5968L2.40107 17.5295L2.26639 17.5493Z"
                fill="#332C2B"
              />
              <path
                d="M4.78983 17.6996L3.0955 16.1964L3.07812 16.0428L4.39593 14.1101L4.53061 14.0903L6.22494 15.5935L6.24232 15.7471L4.92451 17.6798L4.78983 17.6996Z"
                fill="#332C2B"
              />
              <path
                d="M35.8133 17.6996L34.1189 16.1964L34.1016 16.0428L35.4194 14.1101L35.554 14.0903L37.2484 15.5935L37.2658 15.7471L35.9479 17.6798L35.8133 17.6996Z"
                fill="#332C2B"
              />
              <path
                d="M42.1805 35.8239L40.4861 34.3208L40.4688 34.1671L41.7866 32.2345L41.9212 32.2147L43.6156 33.7178L43.6329 33.8715L42.3151 35.8041L42.1805 35.8239Z"
                fill="#332C2B"
              />
              <path
                d="M57.3822 15.5968L58.002 18.4165L57.1983 19.6438L56.8203 16.3583L57.3822 15.5968Z"
                fill="#332C2B"
              />
              <path
                d="M53.0541 25.4005C52.2562 25.3972 51.2396 25.0552 50.5981 24.3697C48.8082 22.4569 46.7562 19.1945 43.8874 19.0359V19.0326H42.726L41.7065 22.1249V22.6766H42.7535C47.1414 22.6617 47.9697 23.1705 49.9884 25.08V25.4021H40.3047L42.7347 30.6814H51.8971L53.5697 26.6096L53.0527 25.4021L53.0541 25.4005Z"
                fill="#332C2B"
              />
            </svg>
          </div>
          <div class="gap-10 d-flex algin-center">
            <router-link class="d-flex align-center" to="/ask">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-help-circle"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
            </router-link>
            <div
              @click="logOut()"
              class="cursor-pointer d-flex align-center justify-space-between text-red"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-log-out"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                <polyline points="16 17 21 12 16 7" />
                <line x1="21" x2="9" y1="12" y2="12" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <Suspense>
        <template #default>
          <router-view
            @showUnderApps="handelDisapperApps"
            v-slot="{ Component }"
          >
            <transition name="fade-transition">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip";

import { ai } from "@/plugins/axios";

export default {
  name: "launghPad",
  components: {
    ToolTip,
  },

  data() {
    return {
      openToolTip: false,
      accounts: [
        { image: "https://dev.agenda.42.guru///files/img5.jpg" },
        { image: "https://dev.agenda.42.guru///files/img5.jpg" },
        { image: "https://dev.agenda.42.guru///files/img5.jpg" },
      ],
    };
  },

  methods: {
    openTip() {
      this.openToolTip = true;
    },
    closeTip() {
      this.openToolTip = false;
    },
    logOut() {
      this.$store.dispatch("saveUser", null);
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("user_id");
      localStorage.removeItem("entity_id");
      localStorage.removeItem("entity_info");
      localStorage.removeItem("space_id");
      localStorage.removeItem("currentRole");
      localStorage.removeItem("userRoles");
      this.$store.dispatch("setUserRole", "");
      this.$router.push("/login");
    },
  },

  watch: {
    $route(to, from) {},
  },

  mounted() {
    this.memberId = JSON.parse(localStorage.getItem("user")).member_id;
  },
  beforeDestroy() {},
};
</script>

<style lang="scss">
.launchPad-container {
}
.launchPad-hero {
  height: 60px;
  position: fixed;
  width: 100%;

  z-index: 999;
  margin: auto;
  inset: 0;

  bottom: inherit;
  background: #00ff8c00;
  .hero-data {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: auto;
  }
}
.launchPad-main-holder {
  background-color: #00ff8d59;
  min-height: 100dvh;
  margin: auto;
}
</style>
