<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="squer-rouneded-btn squer-40 bg-white pa-0 ma-0 hv-gray-1"
        elevation="0"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              d="M8.40156 15.6L15.6016 8.40002M12.0016 21.6C6.69963 21.6 2.40156 17.302 2.40156 12C2.40156 6.69809 6.69963 2.40002 12.0016 2.40002C17.3035 2.40002 21.6016 6.69809 21.6016 12C21.6016 17.302 17.3035 21.6 12.0016 21.6Z"
              stroke="#E0316B"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </g>
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div
        class="popup-header justify-space-between align-center d-flex dark-brown-color"
      >
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42">{{ $t("stopMember") }}</div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 mt-5 mb-10 ms-4">
          <!-- member -->
          <div class="d-flex gap-10 pa-5 border-b-light align-center">
            <img
              loading="lazy"
              :src="`https://dev.agenda.42.guru/${member.user_image}`"
              class="member-pic"
              alt=""
              @error="handleMemberBrokenImage"
            />
            <div class="font-weight-bold">{{ member.full_name }}</div>
          </div>
          <!-- mdi-check -->
          <v-checkbox
            class="squer-check-box sm-checkbox pt-5 pb-5 rounded-2 ps-3 pe-4 pt-5 pb-5 check-24 algin-start"
            v-model="form.agree"
            color="primary"
            :label="$t('SuspendAccConfirmation')"
            true-icon="mdi-check"
            hide-details
          ></v-checkbox>
          <!-- input to type reason -->
          <v-text-field
            class="form-input-box border-t"
            v-model="form.stopBoardReason"
            type="text"
            :placeholder="$t('enterStopMemberReson')"
            :label="$t('stopMemberReason')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "delete-role",
  components: {},
  props: {
    id: {
      default: "",
    },
    member: {
      default: {},
    },
  },
  data() {
    return {
      dialog: false,

      form: {
        agree: true,
        stopBoardReason: "",
      },
    };
  },
  methods: {
    openDi() {
      this.$emit("openDi");
    },
    async submitForm() {
      if (this.form.stopBoardReason) {
        await this.disableUserAccount();
      }
    },
    async disableUserAccount() {
      try {
        const request = {
          member_id: this.member.member_id,
          reason: this.form.stopBoardReason,
        };
        await ai.post(`method/agenda.api.user.user.disable_user`, request);
        this.dialog = false;
      } catch (err) {
        console.log(err);
      }
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
  },
};
</script>
