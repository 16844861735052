<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn class="meeting-terms-btn" v-bind="props" elevation="0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8327 4.16669L4.16602 15.8334M15.8327 15.8334L4.16602 4.16669"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        <span class="text ms-3">
          {{ $t("canselMeetingItem") }}
        </span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("canselMeetingItem") }}
          </div>
        </div>
        <v-btn
          color="red"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="editItem"
          :loading="isLoading"
        >
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 1.72021L5.06747 13.7202L1 9.62974"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <!-- <v-form class="auth-form border    rounded-4 mt-5 ps-4 mb-6">
        </v-form> -->

        <div
          class="mt-10 ps-4 mb-15 d-flex justify-center align-center flex-column gap-24"
        >
          <div>
            {{
              $t(
                "WouldYouLikeToConfirmDeletingTheItemAndCancelDiscussingItFromThisMeeting"
              )
            }}
          </div>
          <div class="d-flex">
            {{ $t("IfYouWouldLikeToPostponeItToAnotherMeetingYouCanDo") }}
            <postponement-item
              :meeting_id="meeting_id"
              :item_id="item_id"
              :fromModal="true"
              @refreshAgenda="dialog = false"
            />
            {{ $t("InsteadOfDeletingIt") }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import postponementItem from "@/components/ui/dialogs/postponementItem";
import { ai } from "@/plugins/axios";

export default {
  name: "canselMeetingItem",

  components: {
    postponementItem,
  },

  props: {
    meeting_id: {
      type: String,
      default: 0,
    },
    agenda_id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: {
        share: false,
      },
      dialog: false,
      isLoading: false,
    };
  },
  // coputed to
  methods: {
    async editItem(item) {
      try {
        this.isLoading = true;
        await ai.put("method/agenda.api.meeting.agenda.update_agenda", {
          agenda_id: this.$props.agenda_id,
          type: "Partially Canceled",
        });
        this.isLoading = false;
        this.dialog = false;
        this.$emit("refreshAgenda", true);
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
};
</script>
