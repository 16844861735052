<template>
  <div class="layout-padding layout-container">
    <v-card
      class="main-page-container form-container small-header-title"
      elevation="0"
    >
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              link
              :to="`/${entity_id}/${space_id}/tasks`"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("editTask") }}
            </div>
          </div>
          <v-btn
            @click="editTask()"
            :loading="isLoading"
            color="primary"
            class="new-plus ios-r"
            elevation="0"
          >
            <div class="siz-14 me-2">
              {{ $t("save") }}
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </inner-header>
      <v-card class="overflow-visible ms-10 me-10" elevation="0">
        <v-form class="auth-form">
          <v-card class="mt-6 overflow-visible" elevation="0">
            <task-line
              :form="form"
              :space_id="space_id"
              @subtaskDataUpdated="setUpdatedData"
              @afterEdit="afterEdit"
              @pushAllTasks="pushAllTasks"
            />
          </v-card>
        </v-form>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InnerHeader from "@/components/shared/InnerHeader";
import uploading from "@/components/ui/uploading";
import taskLine from "@/components/ui/tasks/taskLine";
import Select2 from "vue3-select2-component";
import { ai } from "@/plugins/axios";
import moment from "moment";

export default defineComponent({
  name: "editTask",
  components: {
    InnerHeader,
    uploading,
    taskLine,
    Select2,
  },

  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      task_id: this.$route.params.task_id,
      formData: [],
      finalTaskFormat: null,
      isLoading: false,
      allTasks: [],
    };
  },

  methods: {
    afterEdit(task) {
      this.allTasks = task;
      console.log("afterEdit", task);
      console.log("afterEdit this.allTasks", this.allTasks);
    },
    setUpdatedData(formData) {
      this.formData = formData;
      // alert(formData);
      if (formData.index != null) {
        if (!this.form.subtasks[formData.index]) {
          this.form.subtasks[formData.index] = {};
        }
        this.form.subtasks[formData.index].index = formData.index;
        this.form.subtasks[formData.index].task_title = formData.task_title;
        this.form.subtasks[formData.index].task_date = formData.task_date;
        // set assginee
        // this.form.subtasks[formData.index].task_assignee =
        //   formData.task_assignee;
      } else {
        this.form.task_title = formData.task_title;
        this.form.task_description = formData.task_description;
        this.form.taskCategory = formData.taskCategory;
        this.form.task_date = formData.task_date;
        this.form.task_assignee = formData.task_assignee;
      }
    },
    formatEditTaskRequest() {
      const request = {
        task_id: this.task_id,
        space_id: this.space_id,
        status: "In Progress",

        task_title: this.allTasks.task_title,
        task_description: this.allTasks.task_description,
        task_date: this.allTasks.task_date,
        task_assignee: this.allTasks.task_assignee,
        subtasks: this.allTasks.subtasks,
      };

      return request;
    },
    async editTask() {
      console.log(this.formatEditTaskRequest());
      this.finalTaskFormat = this.formatEditTaskRequest();
      this.isLoading = true;
      try {
        const response = await ai.post(
          `method/agenda.api.space.task.update_task`,
          this.formatEditTaskRequest()
        );
        this.isLoading = false;
        // this.$router.push(
        //   `/${this.entity_id}/${space_id}/tasks`
        // );
        this.$router.push({ name: "taskDetails" });

        console.log(response);
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
  },

  created() {
    document.title = this.$t("editTask");
  },
});
</script>
