<template>
  <div class="form-input-box">
    <label class="mb-2 typo__label v-label siz-12">{{
      $t("chooseMembers")
    }}</label>
    <VueMultiselect
      v-model="multiValue"
      :options="source"
      :multiple="true"
      :close-on-select="false"
      :placeholder="$t('uCanSearchForMembers')"
      label="full_name"
      track-by="full_name"
      @update:modelValue="passSelected()"
    >
      <template v-slot:option="{ option }">
        <div class="d-flex align-center">
          <img
            :src="`https://dev.agenda.42.guru/` + option.user_image"
            class="dropdown-member-pic"
            alt=""
            @error="handleMemberImage"
          />
          <span> {{ option.full_name }}</span>
        </div>
      </template>
    </VueMultiselect>
    <!-- <pre class="language-json"><code>{{ multiValue  }}</code></pre> -->
  </div>
</template>

<script>
import { ai } from "@/plugins/axios";
import VueMultiselect from "vue-multiselect";
export default {
  name: "allSpaceMembers",
  components: {
    VueMultiselect,
  },
  props: {
    id: {
      default: "",
    },
    selectedItems: {
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        member: "",
      },
      spaceMembers: [],
      space_id: this.$route.params.space_id,

      multiValue: [],

      value: {},
      source: [],
    };
  },
  methods: {
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
    async loadMembers() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.space.get_space_members`,
          { params: { space_id: this.space_id } }
        );

        const allMembers = response.data.message;

        this.source = allMembers;
        console.log(this.source);
      } catch (err) {
        console.log(err);
      }
    },
    passSelected() {
      this.$emit("memberSelected", this.multiValue);
    },
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/Image-2.svg");
    },
  },
  async created() {
    await this.loadMembers();
    this.multiValue = this.selectedItems;
  },
};
</script>

<style scoped lang="scss">

</style>
