<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-if="textAndIcon"
        v-bind="props"
        class="main-submit-form bg-red rounded-lg"
        block
        elevation="0"
        @click="openDi()"
      >
        <div v-if="svg">
          <div class="d-flex align-center justify-center" v-html="svg"></div>
        </div>
        <svg
          v-else
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4.5L18 4.5M14 18.5H6C4.89543 18.5 4 17.6046 4 16.5V5.5C4 4.94771 4.44772 4.5 5 4.5H15C15.5523 4.5 16 4.94771 16 5.5V16.5C16 17.6046 15.1046 18.5 14 18.5ZM8 4.5H12C12.5523 4.5 13 4.05228 13 3.5V2.5C13 1.94772 12.5523 1.5 12 1.5H8C7.44772 1.5 7 1.94772 7 2.5V3.5C7 4.05228 7.44772 4.5 8 4.5Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        <span class="mx-3">{{ $t(`${btnTitle}`) }}</span>
      </v-btn>
      <v-btn
        v-else
        v-bind="props"
        class="start-btn pa-2 rounded-lg w-36 h-36 bg-red d-flex align-center justify-center"
        elevation="0"
        @click="openDi()"
      >
        <div v-if="svg">
          <div class="d-flex align-center justify-center" v-html="svg"></div>
        </div>
        <svg
          v-else
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.39844 3.15005L12.5984 3.15005M9.79844 12.95H4.19844C3.42524 12.95 2.79844 12.3232 2.79844 11.55V3.85005C2.79844 3.46345 3.11184 3.15005 3.49844 3.15005H10.4984C10.885 3.15005 11.1984 3.46345 11.1984 3.85005V11.55C11.1984 12.3232 10.5716 12.95 9.79844 12.95ZM5.59844 3.15005H8.39844C8.78504 3.15005 9.09844 2.83665 9.09844 2.45005V1.75005C9.09844 1.36345 8.78504 1.05005 8.39844 1.05005H5.59844C5.21184 1.05005 4.89844 1.36345 4.89844 1.75005V2.45005C4.89844 2.83665 5.21184 3.15005 5.59844 3.15005Z"
            stroke="white"
            stroke-width="1.25"
            stroke-linecap="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div
        class="popup-header justify-space-between align-center d-flex dark-brown-color"
      >
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head letter-42 ms-6 dark-brown-color">
            {{ $t(`${headerTitle}`) }}
          </div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          :disabled="!form.agree"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 my-10">
          <!-- mdi-check -->
          <v-checkbox
            class="squer-check-box sm-checkbox rounded-2 ps-2 pe-4"
            v-model="form.agree"
            color="primary"
            :label="$t(`${checkboxLabel}`)"
            true-icon="mdi-check"
            hide-details
          ></v-checkbox>
          <!-- input to type reason -->
          <v-text-field
            class="form-input-box border-t"
            v-model="form.deleteReason"
            type="text"
            :placeholder="$t(`${deleteReasonText}`)"
            :label="$t(`${deleteReasonLable}`)"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteDialog",
  components: {},
  props: {
    svg: {
      type: String,
    },
    textAndIcon: {
      type: Boolean,
      required: true,
    },
    btnTitle: {
      type: String,
      required: true,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    checkboxLabel: {
      type: String,
      required: true,
    },
    deleteReasonText: {
      type: String,
      required: true,
    },
    deleteReasonLable: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        agree: false,
        deleteReason: "",
      },
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      if (this.form.agree) {
        setTimeout(() => {
          this.loading = false;
          this.dialog = false;
          this.$emit("delete", this.$props.id);
        }, 500);
      }
    },
    openDi() {
      this.$emit("openDi");
    },
  },
};
</script>
