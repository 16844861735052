<template>
  <div class="about-meeting-box box align-center justify-center flex-column">
    <div
      class="squer-rouneded-btn rdx-12 align-center justify-center bg-light-gray pa-0 ma-0"
      elevation="0"
    >
      <img loading="lazy" :src="meetingInfo.icone" alt="" />
    </div>
    <div class="box-name text-sm-gray mt-1 mb-1">{{ meetingInfo.head }}</div>
    <div class="box-text">{{ meetingInfo.title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    meetingInfo: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
