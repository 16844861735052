<template>
  <div
    class="rounded-4"
    :class="taskDetails?.comments.length === 0 ? 'border-0' : 'border-light'"
  >
    <div class="pt-4 pb-4">
      <div
        v-for="e in taskDetails?.comments"
        :key="e.id"
        class="mb-4 member-task-comment align-center d-flex gap-15 ps-5 pe-5 pb-0"
      >
        <!-- member tag -->
        <!-- <div class="flex-shrink-0 tag-rounded-box pe-0 ps-2">
          <div class="gap-5 d-flex align-center">
            <div>{{ e.first_name }} {{ e.last_name }}</div>
            <img
              loading="lazy"
              class="tag-img me-1"
              :src="`https://dev.agenda.42.guru/` + e.user_image"
              alt=""
              @error="handleMemberImage"
            />
          </div>
        </div> -->
        <div class="d-flex align-center">
          <img
            loading="lazy"
            class="me-1 circle-46"
            :src="`https://dev.agenda.42.guru/` + e.user_image"
            alt=""
            @error="handleMemberImage"
          />
        </div>
        <!-- member comment -->
        <div class="siz-12 text-label mt-2">
          <div class="bg-light-gray pa-2 rounded">
            <div class="siz-10 mb-1">{{ e.first_name }} {{ e.last_name }}</div>

            {{ e.content }}
          </div>
          <div class="mt-2 member-comment siz-12 text-light-gray">
            {{ formatDate(e.date) }}
          </div>
        </div>
      </div>
    </div>

    <addTaskComment
      class="mt-n4"
      :task_id="taskDetails?.task_id"
      @refreshTaskDetails="refreshTaskDetails"
      :noComment="taskDetails?.comments.length"
    />
  </div>
</template>

<script>
import addTaskComment from "@/components/ui/dialogs/addTaskComment";
import { ai } from "@/plugins/axios";

export default {
  name: "TaskDetailsBox",
  components: {
    addTaskComment,
  },

  props: {
    is_sub: {
      default: false,
    },
    subtaskData: {
      default: {},
    },
  },

  data() {
    return {
      // will catch with apis
      task_id: this.$route.params.task_id,
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      taskDetails: null,
    };
  },

  methods: {
    async getTaskDetails() {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.space.task.get_task_details`,
          { params: { task_id: this.task_id } }
        );
        this.loading = false;
        if (this.is_sub) {
          this.taskDetails = this.subtaskData;
        } else {
          this.taskDetails = response.data.message;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    formatDate(timestamp) {
      if (timestamp == undefined || timestamp == "") return "";
      const date = new Date(timestamp);
      const now = new Date();

      const dateFormatter = new Intl.DateTimeFormat("ar", {
        hour: "numeric",
        minute: "numeric",
      });

      if (this.isSameDay(date, now)) {
        return ` ${this.$t("today")} • ` + dateFormatter.format(date);
      } else if (this.isYesterday(date, now)) {
        return ` ${this.$t("yesterday")} • ` + dateFormatter.format(date);
      } else {
        return (
          date.toLocaleString("ar", { dateStyle: "long" }) +
          " • " +
          dateFormatter.format(date)
        );
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    },
    isYesterday(date1, date2) {
      const yesterday = new Date(date2);
      yesterday.setDate(date2.getDate() - 1);
      return this.isSameDay(date1, yesterday);
    },
    handleMemberImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    refreshTaskDetails(e) {
      this.$emit("refreshTaskDetails", e);
      this.taskDetails.comments.push(e);
    },
  },
  mounted() {
    this.getTaskDetails();
  },
};
</script>

<style>
.member-placeholder {
  filter: brightness(1.4);
}
</style>
