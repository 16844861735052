<template>
  <div>
    <div
      v-for="(e, index) in mainTaskMember"
      :key="index"
      class="gap-10 d-flex justify-space-between pa-5 align-center border-b-light"
    >
      <div class="gap-10 d-flex align-center">
        <img
          loading="lazy"
          :src="`https://dev.agenda.42.guru/` + e.user_image"
          class="member-pic"
          alt=""
          @error="handleMemberBrokenImage"
        />
        <div class="font-weight-bold">
          {{ e.full_name }}
        </div>
      </div>
      <v-btn
        class="squer-rouneded-btn squer-36 bg-light-gray pa-0 ma-0 ms-2"
        elevation="0"
      >
        <img
          loading="lazy"
          :src="require('@/assets/images/deletedx.svg')"
          alt=""
          @click="deleteMainTaskAssignee(e)"
        />
      </v-btn>
    </div>
    <AssignMember
      :selectedItems="mainTaskMember"
      @addAssginedMember="loadMainTaskMember"
      :inPage="true"
    />
  </div>
</template>

<script>
import AssignMember from "@/components/ui/dialogs/AssignMember";

export default {
  name: "main_task_members",
  components: {
    AssignMember,
  },
  props: {
    currentMembers: {
      default: [],
    },
    dialogText: {
      
    }
  },
  watch: {
    currentMembers() {
      this.mainTaskMember = this.currentMembers;
    },
  },
  data() {
    return {
      mainTaskMember: [],
      selectedMembers: [],
    };
  },
  methods: {
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    loadMainTaskMember(e) {
      this.mainTaskMember = e;

      this.$emit("selectedMembers", this.mainTaskMember);
    },
    deleteMainTaskAssignee(e) {
      this.mainTaskMember.forEach((item, index) => {
        if (
          item.member ||
          item.member_id == e.member ||
          item.member ||
          item.member_id == e.member_id
        ) {
          this.mainTaskMember.splice(index, 1);
          console.log(item.member, item.member_id, e.member, e.member_id);
          this.$emit("selectedMembers", this.mainTaskMember);
        }
      });
    },
  },
};
</script>

<style></style>
