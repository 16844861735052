<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        elevation="0"
        class="mt-1 fill-w-btn siz-14 send-gray-btn-light w-100 pa-0 rounded-lg border-slim-light white-svg"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 4.00024L10 16.0002M16 10.0002L4 10.0002"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>

        {{ $t("addNewRecommendation") }}
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("addNewRecomendation") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="my-10 border auth-form rounded-4">
          <div class="custom-field-input">
            <label class="v-label siz-12" for="">{{
              $t("RecomendationText")
            }}</label>
            <textarea
              class="form-input-box pa-0"
              v-model="form.title"
              type="msg"
              cols="30"
              rows="4"
              :placeholder="$t('enterRecomendation')"
            ></textarea>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import SquerFelid from "@/components/ui/SquerFelid";
import { ai } from "@/plugins/axios";

export default {
  name: "addNewREcommendation",

  components: {
    SquerFelid,
  },
  props: {
    agendaItem: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        title: "",
      },
    };
  },
  methods: {
    async submitForm() {
      if (this.form.title) {
        try {
          this.loading = true;
          const request = {
            recommendations: "this is a recommendation",
            title: this.form.title,
            agenda_id: this.agendaItem.agenda_id,
          };
          await ai.post(
            `method/agenda.api.meeting.recommendation.create_recommendation`,
            request
          );
          this.loading = false;
          this.dialog = false;
          this.$emit("refreshOldData", true);
          setTimeout(() => {
            // empty form
            this.form.title = "";
          }, 1000);
        } catch (err) {
          this.errorMessage = err.response?.data.message.detail;
          console.log(this.errorMessage);
        }
      }
    },
  },
};
</script>
