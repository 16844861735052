<template>
  <div
    text="text"
    svg="svg"
    class="justify-center w-400-auto d-flex align-center flex-column"
  >
    <!-- image -->
    <div v-if="svg">
      <div v-html="svg"></div>
    </div>
    <div v-else>
      <!-- <img src="./assets/imgae/frame.png" alt=""> -->
    </div>

    <!-- text -->
    <div class="mt-10 text-center font-weight-bold">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "emptyPage",
  props: {
    svg: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped></style>
