<template>
  <div>
    <div
      class="new-header-page bg-white py-5 d-flex align-center justify-space-between pe-10 rounded-lg w-100"
    >
      <div
        class="layout-container d-flex align-center justify-space-between px-5"
      >
        <div class="header-back-name d-flex align-center">
          <!-- back button -->
          <transition name="slide-x-transition">
            <router-link
              class="maringForSummary"
              :to="{
                name: 'space',
                params: {
                  entity_id: entity_id,
                  space_id: space_id,
                },
              }"
            >
              <v-btn class="app-btn smooth_rounded" elevation="0">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="11.9996" r="1.25" fill="white" />
                </svg>
              </v-btn>
            </router-link>
          </transition>
          <div class="header-title-content d-flex align-center">
            <div class="align-center d-flex gap-15 group-gray-light">
              <v-btn class="app-btn smooth_rounded mt-0">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3545 17.1421L13.3548 14.464C13.3549 12.9847 12.1557 11.7854 10.6764 11.7854H4.67868C3.19957 11.7854 2.00047 12.9843 2.0003 14.4634L2 17.1421M17.9998 17.1423L18 14.4641C18.0001 12.9848 16.8009 11.7855 15.3216 11.7855M12.8386 3.38359C13.4964 3.87163 13.9226 4.65401 13.9226 5.53595C13.9226 6.41789 13.4964 7.20027 12.8386 7.6883M10.4115 5.5358C10.4115 7.01503 9.21235 8.21418 7.73312 8.21418C6.25389 8.21418 5.05474 7.01503 5.05474 5.5358C5.05474 4.05657 6.25389 2.85742 7.73312 2.85742C9.21235 2.85742 10.4115 4.05657 10.4115 5.5358Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
            </div>
            <div class="side-head ms-6 letter-42">
              {{ $t("members") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-padding layout-container mt-value">
      <v-card class="main-page-container form-container mt-35px" elevation="0">
        <inner-header mb="mb-0">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/members`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                {{ $t("addMember") }}
              </div>
            </div>
            <v-btn
              color="primary"
              class="new-plus ios-r"
              elevation="0"
              @click="submitForm"
              :loading="loading"
            >
              <div class="siz-14 me-2">
                {{ $t("save") }}
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </inner-header>
        <v-card class="inner-container add-vote" elevation="0">
          <!-- <div class="mt-6 mb-6 center-header">{{ $t("member(s)Data") }}</div> -->
          <v-form class="mt-6 mb-10 border border-t-0 auth-form rounded-4">
            <addMember @memberInfo="memberInfo" />
          </v-form>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
import InnerHeader from "@/components/shared/InnerHeader";
import addMember from "@/components/ui/addMember";
import { ai } from "@/plugins/axios";
export default {
  name: "addSpaceMember",
  components: {
    InnerHeader,
    addMember,
  },
  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      error: false,
      membersToAdd: 1,
      members: [],
      roles: [],
      loading: false,
    };
  },

  methods: {
    async submitForm() {
      this.loading = true;
      try {
        await ai.post(`method/agenda.api.space.member.add_member_to_space`, {
          space_id: this.members.space_id,
          user: this.members.user,
          roles: this.members.roles,
        });
        this.loading = false;
        this.$router.push(`/${this.entity_id}/${this.space_id}/members`);
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },

    memberInfo(e) {
      this.members = e;
      console.log(this.members);
    },

    // async loadRoles() {
    //   try {
    //     const response = await ai.get(
    //       `method/agenda.api.space.role.get_space_roles`,
    //       { params: { space_id: this.space_id } }
    //     );
    //     this.roles = response.data.message.roles;
    //     this.loading = false;
    //   } catch (err) {
    //     console.log(err);
    //     this.loading = false;
    //   }
    // },
  },
  created() {
    document.title = this.$t("addMember");
    // this.loadRoles();
  },
};
</script>
