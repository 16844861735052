<template>
  <div>
    <div
      class="new-header-page bg-white py-5 d-flex align-center justify-space-between pe-10 rounded-lg w-100"
    >
      <div
        class="layout-container d-flex align-center justify-space-between px-5"
      >
        <div class="header-back-name d-flex align-center">
          <!-- back button -->
          <transition name="slide-x-transition">
            <router-link
              class="maringForSummary"
              :to="{
                name: 'space',
                params: {
                  entity_id: entity_id,
                  space_id: space_id,
                },
              }"
            >
              <v-btn class="app-btn smooth_rounded" elevation="0">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="1.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="6.9996" r="1.25" fill="white" />
                  <circle cx="2" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="7" cy="11.9996" r="1.25" fill="white" />
                  <circle cx="12" cy="11.9996" r="1.25" fill="white" />
                </svg>
              </v-btn>
            </router-link>
          </transition>
          <div class="header-title-content d-flex align-center">
            <div class="align-center d-flex gap-15 group-purple">
              <v-btn class="app-btn smooth_rounded mt-0">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2C14.4183 2.00003 18 5.58176 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58173 5.58175 1.99997 10 2ZM10 2L10 10M10 10L4.5 15.5M10 10L4.5 4.50005"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-btn>
            </div>
            <div class="side-head ms-6 letter-42">
              {{ $t("shareholders.list.page_title") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-padding layout-container mt-value">
      <v-card class="main-page-container form-container mt-35px" elevation="0">
        <inner-header mb="mb-0">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/shareholders`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                {{ $t("shareholders.add.page_title") }}
              </div>
            </div>
            <v-btn
              color="primary"
              class="new-plus ios-r"
              elevation="0"
              :loading="loading"
              @click="submitForm"
            >
              <div class="siz-14 me-2">
                {{ $t("save") }}
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </inner-header>
        <v-card class="inner-container py-0" elevation="0">
          <div class="center-header my-10">
            {{ $t("shareholders.add.member_detail_title") }}
          </div>
          <v-card class="d-flex mb-10" elevation="0">
            <div class="form-content form-vertical-center w-100">
              <v-card class="form-auth pa-0" elevation="0" width="100%">
                <v-form
                  v-model="valid"
                  ref="form"
                  class="news-form form-inputs-container slim-form border-0"
                >
                  <div class="">
                    <v-row :key="index" class="ma-0 border-light rounded-t-4">
                      <v-col class="pa-0" cols="9" md="9" sm="9">
                        <v-text-field
                          v-model="form.member_id"
                          :label="$t('shareholders.add.email_label')"
                          :placeholder="
                            $t('shareholders.add.email_placeholder')
                          "
                          class="form-input-box bg-transparent rounded-4 border-0"
                          dense
                          hide-details="auto"
                          outlined
                          persistent-placeholder
                          solo
                          type="email"
                          variant="solo"
                          :rules="emailRules"
                        ></v-text-field>
                      </v-col>
                      <v-col class="border-s pa-0" cols="3" md="3" sm="3">
                        <v-text-field
                          v-model="form.shares"
                          :label="$t('shareholders.add.number_of_shares_label')"
                          :placeholder="
                            $t('shareholders.add.number_of_shares_placeholder')
                          "
                          class="form-input-box bg-transparent"
                          dense
                          hide-details="auto"
                          outlined
                          persistent-placeholder
                          solo
                          variant="solo"
                          type="number"
                          :rules="rules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="groups.length > 0"
                        class="border-t pa-5"
                        cols="12"
                        md="12"
                        sm="12"
                      >
                        <div class="flex-10g">
                          <button
                            v-for="group in groups"
                            :key="group.group_id"
                            class="select-type-btn"
                            :class="{ active: isSelected(group.group_id) }"
                            @click="toggleSelection(group.group_id)"
                            type="button"
                          >
                            {{ group.group }}
                          </button>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card>
            </div>
          </v-card>
        </v-card>
      </v-card>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import InnerHeader from "@/components/shared/InnerHeader.vue";
import { ai } from "@/plugins/axios";

export default defineComponent({
  name: "editShareholder",
  components: {
    InnerHeader,
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      member_id: this.$route.params.shareholder_id,
      loading: false,
      valid: true,
      shareholders: [
        {
          email: "",
          numberOfShares: "",
          percentageOfShares: "",
        },
      ],
      selectedGroups: [],
      rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
      emailRules: [
        (v) => !!v || this.$t("mail_must_be_valid"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mail_must_be_valid"),
      ],
      form: {
        member_id: "",
        shares: null,
        groups: [
          {
            group_id: "",
          },
        ],
      },
      groups: [],
    };
  },
  props: {},
  methods: {
    async getShareholderDetails() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.member.get_member_details`,
          {
            params: {
              space_id: this.space_id,
              member_id: this.member_id,
            },
          }
        );
        const details = response.data.message;
        this.form.member_id = details.member_id;
        this.form.shares = details.shares;
        this.selectedGroups = details.groups;
      } catch (err) {
        console.log(err);
      }
    },
    async loadGroups() {
      try {
        const response = await ai.get(
          "method/agenda.api.space.group.get_all_groups",
          {
            params: {
              space_id: this.space_id,
            },
          }
        );
        this.groups = response.data.message;
      } catch (error) {
        console.log(error);
      }
    },
    addShareholder() {
      this.shareholders.push({
        email: "",
        numberOfShares: "",
        percentageOfShares: "",
      });
    },
    isSelected(groupId) {
      return this.selectedGroups.some((group) => group.group_id === groupId);
    },
    toggleSelection(groupId) {
      const index = this.selectedGroups.findIndex(
        (group) => group.group_id === groupId
      );

      if (index === -1) {
        this.selectedGroups.push({ group_id: groupId });
      } else {
        this.selectedGroups.splice(index, 1);
      }
    },
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        try {
          await ai.put(`method/agenda.api.space.member.update_shareholder`, {
            space_id: this.space_id,
            member_id: this.form.member_id,
            shares: this.form.shares,
            groups: this.selectedGroups,
          });
          this.loading = false;
          this.$router.push(`/${this.entity_id}/${this.space_id}/shareholders`);
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
  },
  created() {
    document.title = this.$t("shareholders.add.page_title");
  },
  mounted() {
    this.loadGroups();
    this.getShareholderDetails();
  },
});
</script>
