<template>
  <div class="layout-padding layout-container with-side-action-margin mt-35px">
    <div class="d-flex">
      <v-card
        class="main-page-container flex-grow-1 dark-brown-color meeting-header"
        elevation="0"
      >
        <inner-header mb="mb-0" class="with-action-side">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/tasks`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                {{ $t("taskDetails") }}
              </div>
            </div>
            <v-btn
              class="text-white main-header-btn img-24 pa-0 ma-0 me-0 d-none"
              elevation="0"
              link
              :to="`/tasks-logs/${$route.params.id}`"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5175 11.9999C20.5175 17.3018 16.2183 21.5999 10.915 21.5999C5.61168 21.5999 1.3125 17.3018 1.3125 11.9999C1.3125 6.69797 5.61168 2.3999 10.915 2.3999C14.4693 2.3999 17.5725 4.33046 19.2328 7.1999M17.8833 13.0534L20.2839 10.6534L22.6845 13.0534M14.5125 14.6176L10.9125 13.4176V8.3999"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </inner-header>
        <v-card class="layout-padding-40 dark-brown-color" elevation="0">
          <div>
            <TaskDetailsTimeLine
              class="mt-6 ms-n14"
              :task_content="taskDetails"
              v-if="taskDetails"
              @refreshTaskDetails="refreshTaskDetails"
            />

            <!-- file -->

            <!-- <div class="pb-6 ps-7">
              <v-card
                elevation="0"
                class="pt-5 pb-5 current-files align-center justify-space-between ps-5 mt-n2 w-100 d-flex"
                v-if="fileInfo"
              >
                <div class="gap-5 align-center d-flex flex-grow-1">
                  <div class="file-icone sm-file me-2">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.3715 0L14.5668 0.195263L18.5668 4.19527L18.762 4.39052V4.66667V18C18.762 19.1045 17.8666 20 16.762 20H3.42871C2.32414 20 1.42871 19.1045 1.42871 18V2C1.42871 0.895432 2.32414 0 3.42871 0H14.0954H14.3715Z"
                        fill="#E0316B"
                      />
                      <path
                        d="M14.1302 12.2825C15.3438 10.1408 5.9206 12.0683 7.27697 13.9958C8.70473 16.1374 12.3455 5.71477 10.2039 5.71477C8.27641 5.64339 12.7025 14.7097 14.1302 12.2825Z"
                        stroke="white"
                        stroke-width="0.5"
                        stroke-miterlimit="10"
                      />
                    </svg>
                  </div>
                  <div class="gap-5 align-center d-flex siz-12">
                    {{ fileInfo.name }}.
                    <span class="text-gray">
                      {{ fileInfo.type }}
                    </span>
                  </div>
                  <div class="siz-12 text-label ms-1">
                    {{ fileInfo.size }}
                  </div>
                </div>
                <div>
                  <v-btn
                    class="squer-rouneded-btn squer-30 bg-light-gray squer-sm pa-0 ma-0 ms-2"
                    elevation="0"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.45898 11.9134C2.68872 12.145 3.0003 12.2752 3.32519 12.2752H10.6752C11.0001 12.2752 11.3117 12.145 11.5414 11.9134M7.00087 1.72461V8.72461M7.00087 8.72461L9.80087 6.04994M7.00087 8.72461L4.20087 6.04994"
                        stroke="#475467"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-btn>
                  <v-btn
                    class="squer-rouneded-btn squer-30 bg-light-gray squer-sm pa-0 ma-0 ms-2"
                    elevation="0"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9695 6.9999C11.9695 10.0927 9.46165 12.5999 6.36805 12.5999C3.27446 12.5999 0.766602 10.0927 0.766602 6.9999C0.766602 3.90711 3.27446 1.3999 6.36805 1.3999C8.44138 1.3999 10.2516 2.52606 11.2201 4.1999M10.4329 7.61444L11.8332 6.21444L13.2336 7.61444M8.4666 8.52692L6.3666 7.82692V4.8999"
                        stroke="#475467"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-btn>
                  <v-btn
                    class="squer-rouneded-btn squer-30 bg-light-gray squer-sm pa-0 ma-0 ms-2"
                    elevation="0"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.85721 2.80013C9.13045 2.52675 9.57355 2.52656 9.84702 2.79972L11.0548 4.00609C11.3283 4.27936 11.3285 4.72269 11.0552 4.99619L5.35386 10.7006C5.25637 10.7982 5.13222 10.8648 4.99702 10.892L2.4502 11.4052L2.96429 8.86134C2.99156 8.72641 3.058 8.60252 3.15531 8.50516L8.85721 2.80013Z"
                        stroke="#475467"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </v-btn>
                  <DeleteFile />
                </div>
              </v-card>
               <addTaskFile border="true" />
            </div> -->
          </div>
        </v-card>
      </v-card>
      <div class="action-side-page meeting-layout ps-10">
        <!-- actions -->
        <div class="expansion-center-container">
          <v-expansion-panels
            v-model="panel"
            multiple
            class="bg-transparent expansion-circle"
            elevation="0"
          >
            <v-expansion-panel
              color="transparent"
              class="bg-transparent"
              elevation="0"
            >
              <v-expansion-panel-title
                hide-actions
                class="justify-start gap-20 pt-0 bg-transparent d-flex ps-0 pe-0 pt-0"
                elevation="0"
              >
                <v-btn elevation="0" class="icone-arrow white-cir"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                      stroke="#0A0A0A"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </v-btn>
                <div class="expansion-label">
                  {{ $t("actions") }}
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div class="btns d-flex flex-column gap-20">
                  <v-btn
                    height="52"
                    elevation="0"
                    @click="
                      this.$router.push(
                        `/${entity_id}/${space_id}/tasks/edit/${task_id}`
                      )
                    "
                    class="justify-center px-6 py-4 rounded-lg delete-btn d-flex align-center bg-dark-grey gap-10"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 4.00012L16 7.00012M3.5 13.0001L13.3595 2.79631C14.4211 1.73473 16.1422 1.73473 17.2038 2.79631C18.2654 3.85789 18.2654 5.57906 17.2038 6.64064L7 16.5001L2 18.0001L3.5 13.0001Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    {{ $t("editTask") }}
                  </v-btn>
                  <AssignMember
                    :inPage="true"
                    :taks="taskDetails"
                    :sideAction="true"
                  />
                  <RequestExtensionTime
                    class="mt-5"
                    :inPage="true"
                    :sideAction="true"
                    :task="taskDetails"
                    @refreshTaskDetails="refreshTaskDetails"
                  />
                  <endTask
                    v-if="taskDetails?.status !== 'Completed'"
                    @endTask="endTask"
                  />
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import InnerHeader from "@/components/shared/InnerHeader";
import TaskDetailsTimeLine from "@/components/ui/tasks/TaskDetailsTimeLine.vue";
import DeleteFile from "@/components/ui/dialogs/DeleteFile";
import addTaskFile from "@/components/ui/dialogs/addTaskFile";
import AssignMember from "@/components/ui/dialogs/AssignMember";
import DeleteTask from "@/components/ui/dialogs/DeleteTask";
import endTask from "@/components/ui/dialogs/endTask";
import RequestExtensionTime from "@/components/ui/dialogs/RequestExtensionTime";
import { ai } from "@/plugins/axios";

export default defineComponent({
  name: "taskDetails",
  components: {
    InnerHeader,
    TaskDetailsTimeLine,
    DeleteFile,
    addTaskFile,
    AssignMember,
    DeleteTask,
    endTask,
    RequestExtensionTime,
  },

  data() {
    return {
      // will catch with apis
      task_id: this.$route.params.task_id,
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      taskDetails: null,
      fileInfo: {
        name: "عنوان الملف",
        type: "pdf",
        size: "100 ك. ب",
      },
      task: {
        finished: false,
        id: "1",
        main_task: {},

        subTasks: [],
      },
      panel: [0],
      canCopy: false,
      link: "https://www.zoom.com/meeting/examplelink",
    };
  },

  methods: {
    async endTask() {
      try {
        ai.put("method/agenda.api.space.task.update_task", {
          task_id: this.task_id,
          status: "Completed",
        });

        this.$router.push(`/${this.entity_id}/${this.space_id}/tasks`);
      } catch (error) {
        console.log(error);
      }
    },
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    async getTaskDetails(e) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.space.task.get_task_details`,
          { params: { task_id: this.task_id } }
        );
        this.loading = false;
        this.taskDetails = response.data.message;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    refreshTaskDetails(e) {
      this.getTaskDetails();
      // this.taskDetails.comments.push(e);
      console.log(this.taskDetails.comments.length);
      // console.log(this.taskDetails);
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
    },
  },
  mounted() {
    this.getTaskDetails();
    this.canCopy = !!navigator.clipboard;
  },

  async created() {
    document.title = this.$t("taskDetails");
  },
});
</script>
