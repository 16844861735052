// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import ar from "vuetify/lib/locale/ar";

// colors
import colors from "vuetify/lib/util/colors";

// Vuetify
import { createVuetify, ThemeDefinition } from "vuetify";

const light = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#12B76A",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const dark = {
  dark: true,
  colors: {
    background: "#121212",
    surface: "#121212",
    primary: "#12B76A",

    secondary: "#03DAC6",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export default createVuetify(
  // Vuetify options
  {
    locale: {
      locale: localStorage.getItem("locale") || "ar",
      rtl: {
        ar: true,
      },
    },
    theme: {
      defaultTheme: localStorage.getItem("theme") || "light",
      themes: {
        light,
        dark,
      },
    },
  }
);
