<template>
  <div>
    <div class="auth-container position-relative d-flex">
      <router-view class="form-side-auth" v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
      <div class="form-auth-footer">
        <div class="form-auth-footer-container siz-12 mt-4">
          <div class="gap-5 footer-links align-center d-flex justify-center">
            <router-link to="/terms-and-conditions">{{
              $t("termsAndConditions")
            }}</router-link>
            <div class="point"></div>
            <router-link to="/privacy-policy">{{
              $t("privacyPolicy")
            }}</router-link>
            <div class="point"></div>
            <router-link to="/contact-us">{{ $t("contactUS") }}</router-link>
          </div>
        </div>
        <CopyRight />
      </div>

      <div class="auth-side d-flex align-center justify-center">
        <svg
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M159.799 31.4878L139.825 15.9375C139.209 16.0171 138.863 16.0583 138.245 16.1351L122.704 36.1205C122.781 36.7367 122.823 37.0825 122.9 37.6986L142.875 53.2489C143.491 53.1721 143.836 53.1282 144.455 53.0527L159.996 33.0673C159.919 32.4511 159.876 32.1053 159.799 31.4864V31.4878Z"
            fill="white"
          />
          <path
            d="M87.4686 4.1387L42.0934 0L31.1391 11.3087C34.7242 21.3481 35.5855 35.4973 30.9498 46.301C18.0205 76.4397 -8.57182 115.233 2.72673 151.93H2.6897L5.60966 160H49.6147V156.213L47.9648 151.651C27.532 95.6622 29.714 83.6371 42.0673 49.4215H44.931L66.4912 109.017L84.9354 160H135.983V138.242L87.4686 4.1387Z"
            fill="white"
          />
        </svg>
      </div>

      <!-- <div class="abos-li">
      <router-link
        @mouseenter="openTip()"
        @mouseleave="closeTip()"
        class="toolTipBtn agenda_link ms-4 smooth_rounded"
        to="/"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.9724 4.72317L20.9752 2.39062C20.8828 2.40256 20.8309 2.40874 20.7381 2.42027L18.4062 5.41808C18.4178 5.5105 18.4242 5.56237 18.4357 5.65479L21.4329 7.98734C21.5253 7.97581 21.5771 7.96923 21.6699 7.95791L24.0018 4.96009C23.9903 4.86767 23.9839 4.8158 23.9724 4.72297V4.72317Z"
            fill="#475467"
          />
          <path
            d="M13.1173 0.620805L6.30883 0L4.66515 1.6963C5.20309 3.20221 5.33233 5.3246 4.63675 6.94515C2.69673 11.466 -1.29342 17.285 0.401916 22.7895H0.396359L0.834495 24H7.4374V23.4319L7.18983 22.7477C4.12391 14.3493 4.45133 12.5456 6.30492 7.41323H6.73462L9.9697 16.3525L12.7372 24H20.3969V20.7362L13.1173 0.620805Z"
            fill="#475467"
          />
        </svg>
      </router-link>

      <tool-tip :isActive="openToolTip">
        <div class="font-weight-bold text-primary">
          <img
            loading="lazy"
            :src="require('@/assets/images/Logo.svg')"
            alt=""
          />
        </div>
        <div>
          أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة أشرح لك
          كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت
          كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة
        </div>
      </tool-tip>
    </div> -->
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip";

export default {
  name: "AuthLayout",
  components: {
    ToolTip,
  },
  data() {
    return {
      openToolTip: false,
    };
  },
  methods: {
    openTip() {
      this.openToolTip = true;
    },
    closeTip() {
      this.openToolTip = false;
    },
  },
};
</script>

<style lang="scss">
.abos-li {
  position: absolute;
  // top: 55px;
  // left: 55px;
  top: 31px;
  left: 35px;
  z-index: 999;
  .toolTipBtn {
    width: 64px !important;
    height: 64px !important;
    border-radius: 0;
  }
}
</style>
