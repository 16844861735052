<template>
  <div class="border-t">
    <v-row class="pa-0 ma-0">
      <!-- <v-col md="12" cols="12" class="pa-0 border-b-light">
        <v-text-field
          class="bg-transparent form-input-box"
          v-model="member.firstName"
          type="text"
          :placeholder="$t('enterMemberFirstName')"
          :label="$t('memberFirstName')"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          :rule="{ required: true, email: true }"
        ></v-text-field>
      </v-col> -->
      <v-col md="12" cols="12" class="pa-0">
        <!-- <v-text-field
          class="bg-transparent form-input-box"
          v-model="member.user"
          type="text"
          :placeholder="$t('memberMail')"
          :label="$t('memberMail')"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          :rule="{ required: true, email: true }"
        ></v-text-field> -->
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col md="9" cols="8" class="pa-0">
        <v-text-field
          class="bg-transparent form-input-box border-0"
          v-model="form.user"
          type="email"
          :placeholder="$t('enterMemberEmail')"
          :label="$t('memberEmail')"
          :rules="nameOFRepresentativeMemberRules"
          outlined
          solo
          dense
          variant="solo"
          hide-details="auto"
          persistent-placeholder
          @input="emitData"
        ></v-text-field>
      </v-col>
      <!-- <v-col md="3" cols="4" class="border-s pa-0">
        <div class="form-input-box position-relative border-0">
          <label class="form-label" for="">{{ $t("role") }}</label>

          <Select2
            class="select-form w-100"
            v-model="form.role"
            :options="roles"
            :settings="{ settingOption: value, settingOption: value }"
            @change="myChangeEvent($event)"
            @select="mySelectEvent($event)"
            :placeholder="$t('manger')"
            :searchable="false"
          />

          <div class="select-arrow-down">
            <svg
              width="8"
              height="6"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.20313 1.40001L4.00313 4.60001L0.803126 1.40001"
                stroke="#0A0A0A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </v-col> -->
      <v-col
        v-if="roles.length > 0"
        class="border-t pa-5"
        cols="12"
        md="12"
        sm="12"
      >
        <div class="flex-10g">
          <button
            v-for="role in roles"
            :key="role.id"
            class="select-type-btn"
            :class="{ active: isSelected(role.id) }"
            @click="toggleSelection(role.id)"
            type="button"
          >
            {{ role.text }}
          </button>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { ai } from "@/plugins/axios";
export default {
  components: {
    Select2,
  },
  data() {
    return {
      roles: [],
      form: {
        user: "",
        roles: [],
        space_id: this.$route.params.space_id,
      },
      nameOFRepresentativeMemberRules: [
        (v) => !!v || this.$t("required"),
        (v) => (v && v.length <= 500) || this.$t("max500"),
        // email
        (v) => /.+@.+\..+/.test(v) || this.$t("emailInValid"),
      ],
      entity_id: this.$route.params.entity_id,
    };
  },
  methods: {
    emitData() {
      console.log(this.form);
      this.$emit("memberInfo", this.form);
    },

    isSelected(id) {
      this.emitData();
      return this.form.roles.some((role) => role.role === id);
    },
    toggleSelection(id) {
      const index = this.form.roles.findIndex((role) => role.role === id);

      if (index === -1) {
        this.form.roles.push({ role: id });
      } else {
        this.form.roles.splice(index, 1);
      }
      this.emitData();
    },

    async loadRoles() {
      console.log("loadRoles");
      try {
        const response = await ai.get(
          `method/agenda.api.space.role.get_space_roles`,
          { params: { space_id: this.form.space_id } }
        );
        this.roles = response.data.message.roles.map((role) => {
          return {
            id: role.role_id,
            text: role.role_name,
          };
        });

        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
  },

  mounted() {
    this.loadRoles();
  },
  created() {
    document.title = this.$t("addMember");
  },
};
</script>
