<template>
  <div :class="['tag-checkbox', { cp: !disabled }]" class="tag-checkbox">
    <!--  @change="selectElement()" -->
    <input
      class="cup siz-0"
      type="checkbox"
      :value="interest.interest_id"
      :disabled="!edit"
      @change="toggelInterest(interest)"
      v-model="selectedInterests"
    />
    <div
      class="tag-container d-flex align-center gap-1 w-100 justify-center h-100"
    >
      <img
        class="mix-blend-multiply"
        loading="lazy"
        :src="`https://dev.agenda.42.guru//` + interest.icon"
        alt=""
        @error="handleBrokenImage"
      />
      <div class="tag-title">{{ interest.interest_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InterestedTag",
  props: {
    interest: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    userInterests: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedInterests: [],
    };
  },

  methods: {
    toggelInterest(interestId) {
      this.$emit("toggelInterest", interestId);
    },
    handleBrokenImage(e) {
      e.target.src = require("@/assets/images/gallary.svg");
    },
  },

  mounted() {
    // loop in this.userInterests and pass id of each ele in selectedInterests
    this.userInterests.forEach((element) => {
      this.selectedInterests.push(element.interest_id);
    });
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.tag-checkbox {
  position: relative;
  height: 29px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-wrap: wrap;

  .tag-container {
    // border: 1px solid $light;
    border-radius: 40px;
    min-width: 79px;
    max-width: 470px;
    min-height: 29px;
    transition: all 0.3s ease-in-out;
    padding: 10px 12px;
    background: $light-gray;
    border: 1px solid transparent;

    img {
      width: 18px;
      height: 18px;
      object-fit: contain;
    }
    .tag-title {
      font-size: 12px;
      // color: $label-gray;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    &:checked + .tag-container {
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      // img {
      //   filter: invert(6);
      // }
    }
  }
}
</style>
