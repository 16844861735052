<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <div class="text-primary cp ms-1 me-1" v-bind="props" v-if="fromModal">
        {{ $t("fromHere") }}
      </div>
      <v-btn v-else class="meeting-terms-btn" v-bind="props" elevation="0">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.166 11L9.16602 10V5.81855M17.166 9C17.166 4.58172 13.5843 1 9.16602 1C4.74774 1 1.16602 4.58172 1.16602 9C1.16602 13.4183 4.74774 17 9.16602 17C9.67871 17 10.1801 16.9518 10.666 16.8596"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span class="text ms-3">
          {{ $t("postponementItem") }}
        </span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100 dark-brown-color">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head ms-6 letter-42 dark-brown-color">
            {{ $t("postponementItem") }}
          </div>
        </div>
        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="postponementItem"
          :loading="isLoading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <!-- <v-form class="mt-5 mb-6 border auth-form rounded-4 ps-4">
        </v-form> -->

        <div
          class="justify-center gap-24 mt-10 ps-4 mb-15 d-flex align-center flex-column"
        >
          <div>
            {{
              $t(
                "wouldYouLikeToConfirmPostponingTheItemAndCancelDiscussingItFromThisMeeting"
              )
            }}
          </div>
          <div>
            {{
              $t(
                "TheItemWillBeMovedToDeferredItemsAndYouCanAddItToAnyOtherMeetingByChoosingToAddDeferredItem"
              )
            }}
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "PostponementItem",

  components: {},

  props: {
    fromModal: {
      type: Boolean,
      default: false,
    },
    agenda_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        share: false,
      },
      dialog: false,
      isLoading: false,
    };
  },
  // coputed to
  methods: {
    // to postpone item
    async postponementItem() {
      try {
        this.isLoading = true;
        await ai.put("method/agenda.api.meeting.agenda.update_agenda", {
          agenda_id: this.agenda_id,
          type: "Partially Delayed",
        });
        this.isLoading = false;
        this.dialog = false;
        this.$emit("refreshAgenda", true);
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
};
</script>
