<template>
  <div
    class="upload-profile-image"
    :class="{
      'upload-profile-image-gray': !imageUrl,
      cricled: cricled,
      cover: cover,
      logoPic: logoPic,
    }"
  >
    <div v-if="!imageUrl" class="icone">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.90469 22.0971L15.1047 12.4971L19.9047 17.2971M4.90469 22.0971H16.9047C18.8929 22.0971 20.5047 20.4853 20.5047 18.4971V12.4971M4.90469 22.0971C2.91646 22.0971 1.30469 20.4853 1.30469 18.4971V6.49707C1.30469 4.50884 2.91646 2.89707 4.90469 2.89707H12.7047M19.3047 8.69118L19.3047 5.29707M19.3047 5.29707L19.3047 1.90295M19.3047 5.29707L15.9106 5.29707M19.3047 5.29707L22.6988 5.29707M8.50469 8.29707C8.50469 9.29118 7.6988 10.0971 6.70469 10.0971C5.71058 10.0971 4.90469 9.29118 4.90469 8.29707C4.90469 7.30295 5.71058 6.49707 6.70469 6.49707C7.6988 6.49707 8.50469 7.30295 8.50469 8.29707Z"
          stroke="#98A2B3"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="imageUrl" class="hover-actions">
      <div class="gap-10 actions-btns">
        <v-btn
          class="bg-white squer-rouneded-btn squer-sm pa-0 ma-0 position-relative"
          elevation="0"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5001 3L15.0001 6M2.50012 12L12.3596 1.79619C13.4212 0.734605 15.1423 0.734603 16.2039 1.79619C17.2655 2.85777 17.2655 4.57894 16.2039 5.64052L6.00012 15.5L1.00012 17L2.50012 12Z"
              stroke="#475467"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input type="file" accept="image/*" @change="handleImageChange" />
        </v-btn>
        <v-btn
          class="bg-white squer-rouneded-btn squer-sm pa-0 ma-0"
          elevation="0"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 18L13 10L17 14M4.5 18H14.5C16.1569 18 17.5 16.6569 17.5 15V9M4.5 18C2.84315 18 1.5 16.6569 1.5 15V5C1.5 3.34315 2.84315 2 4.5 2H11M18.5 6L16.5 4M16.5 4L14.5 2M16.5 4L14.5 6M16.5 4L18.5 2M7.5 6.5C7.5 7.32843 6.82843 8 6 8C5.17157 8 4.5 7.32843 4.5 6.5C4.5 5.67157 5.17157 5 6 5C6.82843 5 7.5 5.67157 7.5 6.5Z"
              stroke="#E0316B"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>
    </div>
    <img
      loading="lazy"
      v-if="imageUrl"
      :src="imageUrl"
      alt="Selected Image"
      @error="handleMemberBrokenImage"
      :class="height_auto ? 'height_auto' : ''"
    />
    <input type="file" accept="image/*" @change="handleImageChange" />
  </div>
</template>

<script>
export default {
  name: "UploadProfileImage",
  props: {
    logoPic: {
      type: Boolean,
      default: false,
    },
    currentImage: {
      type: String,
      default: "",
    },
    cricled: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    height_auto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: this.currentImage ? this.currentImage : "",
      imageFile: null,
    };
  },
  methods: {
    // handleImageChange(event) {
    //   this.imageFile = event.target.files[0];
    //   this.imageUrl = URL.createObjectURL(this.imageFile);
    //    let formData = new FormData();
    //   formData.append("image", this.imageFile);

    //   this.$emit("uploadImage", formData);
    // },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/Image-2.svg");
    },
    handleImageChange(event) {
      this.imageFile = event.target.files[0];
      let image = new Image();
      image.src = URL.createObjectURL(this.imageFile);

      image.onload = () => {
        this.imageUrl = image.src;
        let formData = new FormData();
        formData.append("image", this.imageFile);
        this.$emit("uploadImage", formData);
        console.log("com ", formData);
      };
    },
  },
  watch: {
    currentImage(val) {
      this.imageUrl = val;
    },
  },
};
</script>
