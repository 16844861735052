<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="main-submit-form bg-red rounded-lg"
        block
        elevation="0"
        @click="openDi()"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              d="M7 13L13 7M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </g>
        </svg>
        <span class="mx-3">{{ $t("canselMeeting") }}</span>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex align-center">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8 opa-0"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="side-head letter-42 ms-6">{{ $t("canselMeeting") }}</div>
        </div>
        <v-btn
          class="main-header-btn bg-red img-24 pa-0 ma-0 me-8"
          elevation="0"
          :disabled="!form.cancelation_reason"
          :loading="loading"
          @click="submitForm"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 mt-5 mb-10 ms-4">
          <div class="pa-5">
            <v-switch
              width="20px"
              height="20px"
              v-model="form.send_email_after_cancelation"
              hide-details
              inset
              :label="$t('sendMessageToInviteesAboutMeetingDeletion')"
              color="primary"
              :false-value="0"
              :true-value="1"
            ></v-switch>
            <v-switch
              width="20px"
              height="20px"
              v-model="form.postpone_meeting_agenda"
              hide-details
              inset
              :label="$t('postponeMeetingItems')"
              color="primary"
              :false-value="0"
              :true-value="1"
            ></v-switch>
            <v-switch
              width="20px"
              height="20px"
              v-model="form.keep_meeting_tasks_in_board_tasks_list"
              hide-details
              inset
              :label="$t('keepMeetingTasksInCouncilTaskList')"
              color="primary"
              :false-value="0"
              :true-value="1"
            ></v-switch>
          </div>
          <v-text-field
            class="form-input-box border-t"
            v-model="form.cancelation_reason"
            type="text"
            :placeholder="$t('entercancelMeetingReason')"
            :label="$t('cancelMeetingLable')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "deleteDialog",
  components: {},
  props: {
    meeting_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      dialog: false,
      loading: false,

      form: {
        send_email_after_cancelation: false,
        postpone_meeting_agenda: false,
        keep_meeting_tasks_in_board_tasks_list: false,
        cancelation_reason: "",
      },
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      if (this.form.cancelation_reason) {
        try {
          await ai.put("method/agenda.api.meeting.meeting.edit_meeting", {
            meeting_id: this.meeting_id,
            status: "Cancelled",
            cancelation_reason: this.form.cancelation_reason,
            send_email_after_cancelation:
              this.form.send_email_after_cancelation,
            postpone_meeting_agenda: this.form.postpone_meeting_agenda,
            keep_meeting_tasks_in_board_tasks_list:
              this.form.keep_meeting_tasks_in_board_tasks_list,
          });
          this.loading = false;
          this.dialog = false;
          this.$router.push(`/${this.entity_id}/${this.space_id}/meetings`);
        } catch (error) {
          this.loading = false;
          this.dialog = false;
          console.log(error);
        }
      }
    },
    openDi() {
      this.$emit("openDi");
    },
  },
};
</script>
