<template>
  <v-dialog v-model="dialog" class="pa-0 fixed-h-popup" width="auto">
    <template v-slot:activator="{ props }">
      <v-btn
        class="squer-rouneded-btn squer-sm bg-white pa-0 ma-0 border-light"
        elevation="0"
        v-bind="props"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 4.00012L16 7.00012M3.5 13.0001L13.3595 2.79631C14.4211 1.73473 16.1422 1.73473 17.2038 2.79631C18.2654 3.85789 18.2654 5.57906 17.2038 6.64064L7 16.5001L2 18.0001L3.5 13.0001Z"
            stroke="#475467"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </v-btn>
    </template>

    <v-card class="upload-file-popup w-100">
      <!-- {{ item_data }} -->
      <div class="popup-header justify-space-between align-center d-flex">
        <div class="d-flex gap-10">
          <v-btn
            color="transparent"
            class="main-header-btn img-24 pa-0 ma-0 ms-8"
            elevation="0"
            @click="dialog = false"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M19 19L5 5"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </v-btn>
          <div class="main-header">
            <span>{{ $t("editItem") }}</span>
          </div>
        </div>

        <v-btn
          color="primary"
          class="main-header-btn img-24 pa-0 ma-0 me-8"
          elevation="0"
          @click="submitForm"
          :loading="loading"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 5.71997L8.06747 17.72L4 13.6295"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </div>

      <div class="popup-content popup-padding-70 fixed-h-popup">
        <v-form class="auth-form border-light rounded-4 mt-5 mb-10 ms-4">
          <v-text-field
            class="form-input-box bg-transparent"
            v-model="form.title"
            type="text"
            :placeholder="$t('enterItemAddress')"
            :label="$t('itemAdderss')"
            outlined
            solo
            dense
            variant="solo"
            hide-details="auto"
            persistent-placeholder
          ></v-text-field>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ai } from "@/plugins/axios";

export default {
  name: "editMeetingRecommendation",
  components: {},
  props: {
    recommendation: {
      default: null,
    },
  },

  // watch if edit_item changed
  watch: {
    recommendation: {
      handler: function (val, oldVal) {
        if (val) {
          this.form.title = val.recommendation_title;
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,

      form: {
        title: "",
      },
    };
  },

  mounted() {
    if (this.recommendation) {
      this.form.title = this.recommendation.title;
    }
  },

  methods: {
    async submitForm() {
      if (this.form.title) {
        this.loading = true;
        try {
          const request = {
            recommendation_id: this.recommendation.recommendation_id,
            title: this.form.title,
          };
          await ai.post(
            `method/agenda.api.meeting.recommendation.edit_recommendation`,
            request
          );
          setTimeout(() => {
            this.loading = false;
            this.dialog = false;
            this.$emit("refreshOldData", true);
          }, 500);
        } catch (err) {
          this.errorMessage = err.response?.data.message.detail;
          this.loading = false;
          this.dialog = false;
          console.log(err);
        }
      }
    },
  },
};
</script>
